import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Content from '../../components/Content';
import Button from '../../components/formInputs/Button';
import TableRows from '../../components/Skeletons/TableRows';
import {
	addQuestionnaireResultBySectionAction,
	finalQuestionnaireResultAction,
	getMonthListAction,
	getOptionListAction,
	getQuestionnaireAction
} from '../../redux/actions/questionnaireActions';
import {
	ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET,
	FINAL_QUESTIONAIRE_RESULT_RESET
} from '../../redux/constants/questionnaireConstants';
import './HomeScreen.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { months } from '../../utils/validators';
import jwt_decode from 'jwt-decode';
import { getUserByIdAction } from '../../redux/actions/userActions';
import Spinner from '../../components/Spinner/Spinner';

const HomeScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);
	const questionaire = useSelector((state) => state.questionaire);
	const addQuestionnaireResult = useSelector((state) => state.addQuestionnaireResult);
	const finalQuestionnaireResult = useSelector((state) => state.finalQuestionnaireResult);

	const getMonthList = useSelector((state) => state.getMonthList);
	const getUserInfo = useSelector((state) => state.getUserInfo);
	// const getOptionList = useSelector((state) => state.getOptionList);
	// const questionnaireResultBySection = useSelector((state) => state.questionnaireResultBySection);

	const [sections, setSections] = React.useState([]);
	const [currentSection, setCurrentSections] = React.useState('Section 1');
	const [nextSection, setNextSections] = React.useState(null);
	const [prevSection, setPrevSections] = React.useState(null);
	const [monthList, setMonthList] = React.useState([]);
	const [disabledCheckBox, setDisabledCheckBox] = React.useState(false);
	const [finalSubmit, setFinalSubmit] = React.useState(false);
	// const [isSectionCompleted, setisSectionCompleted] = React.useState(false);
	const [sectionsTmp, setSectionsTmp] = React.useState('Section 1');


	React.useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
				dispatch(getMonthListAction());
				dispatch(getOptionListAction());
				dispatch(getQuestionnaireAction('Section 1', new Date().getMonth() + 1, new Date().getFullYear()));
				setDisabledCheckBox(false);
				var decoded = jwt_decode(userLogin.userInfo.data);
				dispatch(getUserByIdAction(decoded._id));
			} else {
				history('/Login');
			}
		},
		[userLogin, dispatch, history]
	);

	React.useEffect(
		() => {
			if (getMonthList && getMonthList.data && getMonthList.data.data && getMonthList.data.data.length > 0) {
				getMonthList.data.data.map((item) => {
					item.selected = false;
					if (item.month === new Date().getMonth() + 1 && item.year === new Date().getFullYear()) {
						item.selected = true;
					}
				});
				setMonthList(getMonthList.data.data);
			}
		},
		[getMonthList]
	);

	React.useEffect(
		() => {
			if (questionaire &&
				questionaire.data &&
				questionaire.data.data &&
				(!questionaire.data.data.isSectionCompleted && currentSection !== sectionsTmp)) {
				setCurrentSections(sectionsTmp);
				toast.error(questionaire.data.message, {
					icon: "✕"
				});
			} else if (
				questionaire &&
				questionaire.data &&
				questionaire.data.data &&
				questionaire.data.data.result &&
				questionaire.data.data.result.length > 0
			) {

				questionaire.data.data.result.map((el) => {
					el.options = [
						{
							title: 'Never True',
							description: '(Rarely)',
							id: 'never_true',
							value: 1,
							active: el.selectedOptionValue === 1 ? true : false,
							disabled: questionaire.data.data.isQuestionaryCompleted ? true : false
						},
						{
							title: 'Seldom True',
							description: '(Once a Week)',
							id: 'seldom_true',
							value: 2,
							active: el.selectedOptionValue === 2 ? true : false,
							disabled: questionaire.data.data.isQuestionaryCompleted ? true : false
						},
						{
							title: 'Sometimes True',
							description: '(2-3x a Week)',
							id: 'sometimes_true',
							value: 3,
							active: el.selectedOptionValue === 3 ? true : false,
							disabled: questionaire.data.data.isQuestionaryCompleted ? true : false
						},
						{
							title: 'Very True',
							description: '(4-7x a Week)',
							id: 'very_true',
							value: 4,
							active: el.selectedOptionValue === 4 ? true : false,
							disabled: questionaire.data.data.isQuestionaryCompleted ? true : false
						}
					];
				});

				setSectionsTmp(questionaire.data.data.result[0].section);
				// setisSectionCompleted(questionaire.data.data.isSectionCompleted ? true : false);
				setDisabledCheckBox(questionaire.data.data.isQuestionaryCompleted ? true : false);
				setSections(questionaire.data.data.result);
				setCurrentSections(questionaire.data.data.result[0].section);
				setNextSections(questionaire.data.data.nextSection);
				setPrevSections(questionaire.data.data.previousSection);
			}
		},
		[questionaire]
	);

	React.useEffect(
		() => {
			if (addQuestionnaireResult && addQuestionnaireResult.data && addQuestionnaireResult.data.message) {
				// const dateFilter = monthList.filter((item) => item.selected === true)[0];
				// if (dateFilter && dateFilter.month) {
				// 	dispatch(getQuestionnaireAction(currentSection, dateFilter.month, dateFilter.year));
				// } else {
				// 	dispatch(getQuestionnaireAction(currentSection, new Date().getMonth() + 1, new Date().getFullYear()));
				// }

				// toast.success(addQuestionnaireResult.data.message, {
				// 	icon: "✔"
				// });
				// dispatch({ type: ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET });
			} else if (addQuestionnaireResult && addQuestionnaireResult.error && addQuestionnaireResult.error.message) {
				toast.error(addQuestionnaireResult.error.message, {
					icon: "✕"
				});
				dispatch({ type: ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET });
			}
		},
		[addQuestionnaireResult, dispatch]
	);

	React.useEffect(
		() => {
			if (finalQuestionnaireResult && finalQuestionnaireResult.data && finalQuestionnaireResult.data.data && finalQuestionnaireResult.data.message) {
				setFinalSubmit(true);
				// const dateFilter = monthList.filter(item => item.selected === true)[0];
				// dispatch(getQuestionnaireAction(currentSection, dateFilter.month, dateFilter.year));
				// toast.success(finalQuestionnaireResult.data.message, {
				// 	icon: '✔'
				// });
				// dispatch(getQuestionnaireAction('Section 1', new Date().getMonth() + 1, new Date().getFullYear()));
				dispatch({ type: FINAL_QUESTIONAIRE_RESULT_RESET });
			} else if (finalQuestionnaireResult && finalQuestionnaireResult.data && !finalQuestionnaireResult.data.data && finalQuestionnaireResult.data.message) {
				toast.error(finalQuestionnaireResult.data.message, {
					icon: '✕'
				});
				setFinalSubmit(false);
				dispatch({ type: FINAL_QUESTIONAIRE_RESULT_RESET });
			} else if (finalQuestionnaireResult && finalQuestionnaireResult.error && finalQuestionnaireResult.error.message) {
				toast.error(finalQuestionnaireResult.error.message, {
					icon: '✕'
				});
				dispatch({ type: FINAL_QUESTIONAIRE_RESULT_RESET });
			}
		},
		[finalQuestionnaireResult, dispatch]
	);

	const onCheckBoxChange = (item, index, subIndex) => {
		let newFormValues = [...sections];
		item.options.map((item) => {
			if (item) return (item.active = false);
		});
		item.options[subIndex].active = !item.options[subIndex].active;
		item.selectedOptionValue = item.options[subIndex].value;
		setSections([...newFormValues]);
		dispatch(
			addQuestionnaireResultBySectionAction({
				section: item.section,
				kitID: getUserInfo.userInfo.data.kitActivatedID,
				selectedOptionID: item.options[subIndex].id,
				selectedOptionValue: item.options[subIndex].value,
				questionReferenceID: item._id
			})
		);
	};

	const finalResultHandler = () => {
		dispatch(
			finalQuestionnaireResultAction({
				kitID: getUserInfo.userInfo.data.kitActivatedID
			})
		);
	};

	const prevSectionHandler = async (title) => {
		const dateFilter = monthList.filter((item) => item.selected === true)[0];
		setCurrentSections(title.trim());
		if (dateFilter && dateFilter.month) {
			dispatch(getQuestionnaireAction(title.trim(), dateFilter.month, dateFilter.year));
		} else {
			dispatch(getQuestionnaireAction(title.trim(), new Date().getMonth() + 1, new Date().getFullYear()));
		}
	};

	const nextSectionHandler = async (title) => {
		const dateFilter = monthList.filter((item) => item.selected === true)[0];
		setCurrentSections(title.trim());
		if (dateFilter && dateFilter.month) {
			dispatch(getQuestionnaireAction(title.trim(), dateFilter.month, dateFilter.year));
		} else {
			dispatch(getQuestionnaireAction(title.trim(), new Date().getMonth() + 1, new Date().getFullYear()));
		}
	};

	const showFromMonthPicker = async (e, date) => {
		e.preventDefault();
		let newFormValues = [...monthList];
		newFormValues.map((item) => (item.selected = false));
		date.selected = !date.selected;
		setMonthList(newFormValues);
		if (date.month !== new Date().getMonth() + 1 && date.year === new Date().getFullYear()) {
			setDisabledCheckBox(true);
		} else setDisabledCheckBox(false);

		await dispatch(getQuestionnaireAction('Section 1', date.month, date.year));
	};

	return (
		<Content
			currentMenu='questionnaire'
			headerTitle='Questionnaire'
			navigationRoute={[
				{
					path: '/',
					label: 'Dashboard',
					routes: [
						{
							path: '/questionnaire',
							label: 'Questionnaire'
						}
					]
				}
			]}
			addBtn={false}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => { }}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={'search'}
			searchOnChange={''}>
			<div className='questionnaire-container'>
				{finalQuestionnaireResult.loading && <Spinner />}
				{
					!finalSubmit ?
						<React.Fragment>
							{monthList &&
								monthList.length > 0 &&
								monthList.map(
									(item, i) =>
										item.selected &&
										disabledCheckBox === true && (
											<div key={i} className='questionnaire-section-btn' style={{ padding: '0', marginTop: '0px', paddingTop: '6px' }}>
												<button
													className='filter-btn filter-btn-top'
													disabled={item.selected}
													onClick={(event) => {
														showFromMonthPicker(event, item);
													}}>
													{months[item.month - 1]} {item.year}
												</button>
												<div />
											</div>
										)
								)}

							<h1 className='questionnaire-heading'>
								{
									questionaire &&
										questionaire.data &&
										questionaire.data.data &&
										questionaire.data.data.isQuestionaryCompleted ?
										'Questionnaire Results' : 'Questionnaire'
								}
							</h1>

							{questionaire &&
								questionaire.data &&
								questionaire.data.data &&
								questionaire.data.data.isQuestionaryCompleted ? (
								<Fragment>
									<p className='questionnaire-subheading'>
										With the help of this questionnaire, you will come to understand the sources of your stress, how your body
										is handling that stress and what your physiological reactions are as a result of it.
									</p>
								</Fragment>
							) : questionaire &&
							questionaire.data &&
							questionaire.data.data &&
							questionaire.data.data.isQuestionaryCompleted === false && (
								<Fragment>
									{currentSection && currentSection === 'Section 1' ? (
										<p className='questionnaire-subheading'>
											With the help of this questoinairre, you will come to understand the sources of your stress, how your body
											is handling that stress and what your physiological reactions are as a result of it. The questionarie is
											dived up into five sections, questions may sound repetitive but know that each one plays a key part to
											recieving personalized recommendations. <br />
											*DO NOT TRY TO ANSWER HOW YOU WOULD LIKE THINGS TO BE, BUT RATHER ANSWER HOW THINGS REALLY ARE.
										</p>
									) : (
										<p className='questionnaire-subheading'>
											The questionarie is dived up into five sections, questions may sound repetitive but know that this is
											intentional and each one plays a key part to recieving personalized recommendations. With the help of this
											questoinairre, you will come to understand the sources of your stress, how your body is handling that stress
											and what your physiological reactions are as a result of it.
										</p>
									)}
								</Fragment>
							)}

							<div className='questionnaire-link'>
								<Button
									style={{
										backgroundColor: '#fffafa00',
										border: 'none',
										color: '#4B4B4B',
										fontWeight: '500',
										fontSize: '20px',
										lineHeight: '26px',
										justifyContent: 'center',
										// padding: '0.5rem 1rem'
										padding: '.5rem 0rem'
									}}
									label={currentSection && currentSection}
									icon={false}
								/>
							</div>

							<div className='comparison'>
								<table data-testid='questionnaire-table' className='table'>
									<thead>
										<tr>
											<th />
											<th className='questionnaire-table-heading'>
												<p className='questionnaire-table-heading_num'>
													<button disabled={true} onClick={() => { }} className={'table__button'}>
														1
													</button>
												</p>
												<p>
													Never True <br />
													(Rarely)
												</p>
											</th>
											<th className='questionnaire-table-heading'>
												<p className='questionnaire-table-heading_num'>
													<button disabled={true} onClick={() => { }} className={'table__button'}>
														2
													</button>
												</p>
												<p>
													Seldom True <br />
													(Once a Week)
												</p>
											</th>
											<th className='questionnaire-table-heading'>
												<p className='questionnaire-table-heading_num'>
													<button disabled={true} onClick={() => { }} className={'table__button'}>
														3
													</button>
												</p>
												<p>
													Sometimes True <br />
													(2-3x a Week)
												</p>
											</th>
											<th className='questionnaire-table-heading'>
												<p className='questionnaire-table-heading_num'>
													<button disabled={true} onClick={() => { }} className={'table__button'}>
														4
													</button>
												</p>
												<p>
													Very True <br />
													(4-7x a Week)
												</p>
											</th>
										</tr>
									</thead>
									<tbody>
										{!questionaire.loading ? (
											sections &&
											sections.length > 0 &&
											sections.map((res, index) => (
												<Fragment key={index}>
													<tr className='visible-row'>
														<td />
														<td colSpan={4}>
															<p>{res.question}</p>
														</td>
													</tr>
													<tr className='compare-row'>
														<td>
															<p>{res.question}</p>
														</td>
														<td>
															<button
																disabled={disabledCheckBox}
																onClick={() => {
																	onCheckBoxChange(res, index, 0);
																}}
																className={res.options[0].active ? 'table__button_active  table__button' : 'table__button'}>
																{res.options[0].value}
															</button>
														</td>
														<td>
															<button
																disabled={disabledCheckBox}
																onClick={() => {
																	onCheckBoxChange(res, index, 1);
																}}
																className={res.options[1].active ? 'table__button_active  table__button' : 'table__button'}>
																{res.options[1].value}
															</button>
														</td>
														<td>
															<button
																disabled={disabledCheckBox}
																onClick={() => {
																	onCheckBoxChange(res, index, 2);
																}}
																className={res.options[2].active ? 'table__button_active  table__button' : 'table__button'}>
																{res.options[2].value}
															</button>
														</td>
														<td>
															{res.options[3] && (
																<button
																	disabled={disabledCheckBox}
																	onClick={() => {
																		onCheckBoxChange(res, index, 3);
																	}}
																	className={
																		res.options[3].active ? (
																			'table__button_active table__button table__button'
																		) : (
																			'table__button table__button'
																		)
																	}>
																	{res.options[3].value}
																</button>
															)}
														</td>
													</tr>
												</Fragment>
											))
										) : (
											<TableRows columns='5' />
										)}
										<tr className='totalCount'>
											<td />
											<td colSpan={2} />
											<td style={{ textAlign: 'center' }}>
												<p
													style={{
														marginTop: '1.2rem',
														marginBottom: '1.4rem',
														fontWeight: '500',
														fontSize: '20px',
														lineHeight: '26px'
													}}>
													Total:
												</p>
											</td>
											<td style={{ textAlign: 'center' }}>
												<p
													style={{
														marginTop: '1.2rem',
														marginBottom: '1.4rem',
														fontWeight: '500',
														fontSize: '20px',
														lineHeight: '26px'
													}}>
													{sections &&
														sections.length > 0 &&
														sections.reduce(
															(res, item) => (item.selectedOptionValue ? res + item.selectedOptionValue : res),
															0
														)}
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className='questionnaire-section-btn'>
								{prevSection && prevSection !== currentSection ? (
									<Button
										style={{
											backgroundColor: '#fffafa00',
											border: 'none',
											color: '#4B4B4B',
											fontWeight: '500',
											fontSize: '20px',
											lineHeight: '26px',
											justifyContent: 'center',
											padding: '0.5rem'
										}}
										onClick={() => prevSectionHandler(prevSection)}
										label={'< ' + prevSection}
									/>
								) : (
									<div />
								)}

								{nextSection && nextSection !== currentSection ? (
									<Button
										style={{
											backgroundColor: '#fffafa00',
											border: 'none',
											color: '#4B4B4B',
											fontWeight: '500',
											fontSize: '20px',
											lineHeight: '26px',
											justifyContent: 'center',
											padding: '0.5rem'
										}}
										// disabled={!isSectionCompleted}
										onClick={() => nextSectionHandler(nextSection)}
										label={nextSection + ' >'}
									/>
								) : (
									<div>
										{currentSection &&
											currentSection === 'Section 5' && (
												<Button
													disabled={disabledCheckBox}
													style={{
														justifyContent: 'center',
														width: '100%',
														height: '5vh',
														fontWeight: '500',
														fontSize: '20px',
														lineHeight: '26px'
													}}
													label='Submit'
													icon={false}
													onClick={finalResultHandler}
												/>
											)}
									</div>
								)}
							</div>
							{monthList &&
								monthList.length > 1 && (
									<div className='questionnaire-section-btn'>
										<div>
											<h1 className='questionnaire-heading'>Your Archived Results</h1>

											<div className='MonthYearPicker'>
												{monthList.map((item, i) => (
													<div key={i}>
														<button
															className='filter-btn'
															disabled={item.selected}
															onClick={(event) => {
																showFromMonthPicker(event, item);
															}}>
															{months[item.month - 1]} {item.year}
														</button>
													</div>
												))}
											</div>
										</div>
									</div>
								)}
						</React.Fragment>
						:
						<React.Fragment>
							<h1 className='questionnaire-heading'>
								Questionnaire
							</h1>
							<p className='questionnaire-subheading'>
								The questionarie is dived up into five sections, questions may sound repetitive but know that this is
								intentional and each one plays a key part to recieving personalized recommendations. With the help of this
								questoinairre, you will come to understand the sources of your stress, how your body is handling that stress
								and what your physiological reactions are as a result of it.
							</p>

							<div className='questionnaire-heading'>
								<div className="toastMessage">
									<div className='msg-grid'>
										<svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M6.66502 18.4519L0.557011 11.721C0.521785 11.6822 0.490992 11.6339 0.466404 11.579C0.441816 11.524 0.423917 11.4635 0.413736 11.4009C0.403556 11.3382 0.401294 11.2747 0.407082 11.214C0.41287 11.1533 0.426593 11.0965 0.447462 11.047L1.35886 8.86667C1.44599 8.65853 1.63561 8.62238 1.78312 8.7858L5.80401 13.2153C5.95153 13.3787 6.14216 13.3401 6.22928 13.132L11.4655 0.472736C11.5522 0.262438 11.7432 0.226035 11.8921 0.38919L13.4376 2.10125C13.5851 2.26466 13.6339 2.56493 13.5481 2.77281L7.84226 16.5572L7.84397 16.5591L7.09065 18.3708C7.00352 18.5789 6.81254 18.6153 6.66502 18.4519Z" fill="#77B778" />
										</svg>
										{/* {finalQuestionnaireResult && finalQuestionnaireResult.data && finalQuestionnaireResult.data.message} */}
										<h1 className='successmsg'>Success! <span style={{ fontWeight: '400' }}>&nbsp;Your lifestyle questionnaire is complete. </span></h1>
									</div>
								</div>
							</div>

						</React.Fragment>
				}
			</div>
		</Content>
	);
};

export default HomeScreen;
