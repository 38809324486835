import React, { useState} from 'react';
import { useDispatch} from 'react-redux';
import Footer from '../Footers/Footer';
import LogOut from '../Modal/LogoutModal';
import { logout } from '../../redux/actions/userActions'; 
import WebHeader from '../WebHeader/Header';

const WebsiteContent = ({
	children,
	style
}) => {
	const dispatch = useDispatch(); 
	const [modalLogOutState, setModalLogOutState] = useState(false);
 
	const onLogOutModalClose = () => {
		setModalLogOutState(false);
	};

	const onLogOutHandler = () => {
		setModalLogOutState(false);
		dispatch(logout());
	};

	return (
		<>
			<main className='main-container'>
				<WebHeader />
				<div className='mainContent'>
					<div className='webcontent' style={style}>
						{children}
					</div>

					<Footer />

					<LogOut data={{ modalLogOutState, onLogOutModalClose, onLogOutHandler }} />
				</div>
			</main>
		</>
	);
};

export default WebsiteContent;
