import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import { InputCustomBox } from "../../components/formInputs/InputBox";
import { logout, resetPasswordAction } from "../../redux/actions/userActions";
import "./ResetPasswordScreen.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RESET_PASSWORD_RESET } from "../../redux/constants/userConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { stateValidatorSchema } from "../../utils/validators";
import FormValidation from "../../utils/formValidation";
import Spinner from "../../components/Spinner/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { token } = useParams();
  const resetPassword = useSelector((state) => state.resetPassword);
  const { loading } = resetPassword;

  React.useEffect(() => {
    if (
      resetPassword &&
      resetPassword.userInfo &&
      resetPassword.status === 200
    ) {
      toast.success(resetPassword.userInfo.message, {
        icon: "✔",
      });
      dispatch(logout());
      history("/login");
      dispatch({ type: RESET_PASSWORD_RESET });
    } else if (
      resetPassword &&
      resetPassword.error &&
      resetPassword.error.message
    ) {
      toast.error(resetPassword.error.message, {
        icon: "✕",
      });
      dispatch({ type: RESET_PASSWORD_RESET });
    }
  }, [resetPassword, dispatch, history]);

  // Login form
  const userSchema = {
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
  };

  const handleSubmit = (state) => {
    if (token === "" && token.trim() === "") {
      setErrors({
        ...errors,
        confirmPassword: "Reset password link is not valid",
      });
      setDisable(true);
    } else if (state.password === "" && state.password.trim() === "") {
      setErrors({ ...errors, confirmPassword: "Please enter your password" });
      setDisable(true);
    } else if (
      state.password !== state.confirmPassword &&
      state.password.trim() !== state.confirmPassword.trim()
    ) {
      setErrors({ ...errors, confirmPassword: "Password does not match" });
      setDisable(true);
    } else {
      dispatch(
        resetPasswordAction({
          token: token,
          newPassword: state.password,
        })
      );
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setDisable,
    setValues,
    setErrors,
  } = FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  const { password, confirmPassword } = values;

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="login_back-btn">
        <BackButton label="< Back" onClick={() => history("/Login")} />
      </div>
      <div className="main-container" style={{ height: "90vh" }}>
        {loading && <Spinner />}
        <div className="resetPassword_container">
          <div className="resetPassword_container__form">
            <div>
              <h1 className="resetPassword_container__heading">
                Reset Password
              </h1>
            </div>
            <Fragment>
              <InputCustomBox
                id="password"
                type="password"
                placeholder="Password"
                errorMessage={
                  errors && errors.password && dirty.password && errors.password
                }
                value={password}
                onChange={handleOnChange}
                label={false}
                icon="key"
              />

              <InputCustomBox
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                errorMessage={
                  errors &&
                  errors.confirmPassword &&
                  dirty.confirmPassword &&
                  errors.confirmPassword
                }
                value={confirmPassword}
                onChange={handleOnChange}
                label={false}
                icon="key"
              />

              <Button
                disabled={disable}
                style={{ justifyContent: "center", width: "100%" }}
                onClick={handleOnSubmit}
                label="Reset Password"
                icon={false}
              />

              <div className="resetPassword-container">
                Already have an account? &nbsp;
                <Link to="/login" className="resetPassword-text">
                  Sign In
                </Link>
                .
              </div>
            </Fragment>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="resetPassword__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="resetPassword_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="resetPassword__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordScreen;
