
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
	userLoginReducer,
	logOutUserReducer,
	getUserByIdReducer,
	getActiviteKitReducer,
	registerKitReducer,
	changePwdReducer,
	getUserAddressReducer,
	addUserAddressReducer,
	getActiviteKitStatusReducer,
	updateUserReducer,
	updateUserAddressReducer,
	removeUserAddressReducer,
	resetPasswordReducer,
	getBillingAddressReducer,
	addBillingAddressReducer,
	updateBillingAddressReducer,
	removeBillingAddressReducer,
	forgotPasswordReducer,
	getNotificationsReducer,
	subcribeNewsLetterReducer
} from './reducers/userReducers';
import {
	addQuestionnaireResultBySectionReducer,
	getOptionListReducer,
	getQuestionnaireReducer,
	getQuestionnaireResultBySectionReducer,
	getMonthListReducer,
	finalQuestionnaireResultReducer,
	getKitByDescriptionReducer,
} from './reducers/questionnaireReducers';
import {
	getCartByUserReducer,
	getCollectionByIdReducer,
	getCollectionReducer,
	getProductReducer,
	addCartByUserReducer,
	getCartCountByUserReducer,
	checkOutReducer,
	cartProductReducer,
	getRecommendationByIdReducer
} from './reducers/productReducers';
import { getTestResultPDFURLReducer, getTestResultReducer } from './reducers/testResultReducers';
import { orderConfirmReducer, orderHistoryReducer } from './reducers/orderReducers'
import { cancelSubscriptionReducer, updateSubscriptionReducer } from './reducers/subscriptionReducers';


const reducer = combineReducers({
	//User
	userLogin: userLoginReducer,
	getUserInfo: getUserByIdReducer,
	activiteKit: getActiviteKitReducer,
	registerKit: registerKitReducer,
	changePwd: changePwdReducer,
	getActiviteKitStatus: getActiviteKitStatusReducer,
	resetPassword: resetPasswordReducer,
	forgotPassword: forgotPasswordReducer,
	notifications: getNotificationsReducer,
	subcribeNewsLetter: subcribeNewsLetterReducer,

	//Questionaire
	getOptionList: getOptionListReducer,
	questionaire: getQuestionnaireReducer,
	questionnaireResultBySection: getQuestionnaireResultBySectionReducer,
	addQuestionnaireResult: addQuestionnaireResultBySectionReducer,
	finalQuestionnaireResult: finalQuestionnaireResultReducer,
	getMonthList: getMonthListReducer,
	getKitByDescription: getKitByDescriptionReducer,

	//Product
	getProductList: getProductReducer,
	cartProducts: cartProductReducer,

	//Collections
	getCollections: getCollectionReducer,
	getCollectionById: getCollectionByIdReducer,
	getRecommendationById: getRecommendationByIdReducer,

	//Account
	getUserAddress: getUserAddressReducer,
	addUserAddress: addUserAddressReducer,
	updateUserAddress: updateUserAddressReducer,
	removeUserAddress: removeUserAddressReducer,

	getPaymentMethod: getBillingAddressReducer,
	addPaymentMethod: addBillingAddressReducer,
	updatePaymentMethod: updateBillingAddressReducer,
	removePaymentMethod: removeBillingAddressReducer,

	//Cart
	addCartByUser: addCartByUserReducer,
	updateUser: updateUserReducer,
	getCartByUser: getCartByUserReducer,
	getCartCountByUser: getCartCountByUserReducer,
	checkOut: checkOutReducer,

	//test results
	getTestResult: getTestResultReducer,
	getTestResultPDFURL: getTestResultPDFURLReducer,

	//logout
	logOutUser: logOutUserReducer,

	//ordeer history
	orderHistory: orderHistoryReducer,
	orderConfirm: orderConfirmReducer,

	//subscription
	cancelSubscription: cancelSubscriptionReducer,
	updateSubscription: updateSubscriptionReducer
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
	cartProducts: {
		products: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
		cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
	}
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
