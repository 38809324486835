import {
	GET_QUESTIONAIRE_SECTION_REQUEST,
	GET_QUESTIONAIRE_SECTION_SUCCESS,
	GET_QUESTIONAIRE_SECTION_FAIL,
	GET_QUESTIONAIRE_SECTION_RESET,

	GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST,
	GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
	GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
	GET_QUESTIONAIRE_RESULT_BY_SECTION_RESET,

	ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST,
	ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
	ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
	ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET,

	GET_OPTION_LIST_REQUEST,
	GET_OPTION_LIST_SUCCESS,
	GET_OPTION_LIST_FAIL,
	GET_OPTION_LIST_RESET,

	GET_FILTER_MONTH_REQUEST,
	GET_FILTER_MONTH_SUCCESS,
	GET_FILTER_MONTH_FAIL,
	GET_FILTER_MONTH_RESET,

	FINAL_QUESTIONAIRE_RESULT_REQUEST,
	FINAL_QUESTIONAIRE_RESULT_FAIL,
	FINAL_QUESTIONAIRE_RESULT_RESET,
	FINAL_QUESTIONAIRE_RESULT_SUCCESS,
	GET_KIT_DESCRIPTIONS_REQUEST,
	GET_KIT_DESCRIPTIONS_SUCCESS,
	GET_KIT_DESCRIPTIONS_FAIL,
	GET_KIT_DESCRIPTIONS_RESET,
} from '../constants/questionnaireConstants';

export const getQuestionnaireReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_QUESTIONAIRE_SECTION_REQUEST:
			return { loading: true };

		case GET_QUESTIONAIRE_SECTION_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_QUESTIONAIRE_SECTION_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUESTIONAIRE_SECTION_RESET:
			return {};

		default:
			return state;
	}
};

export const getQuestionnaireResultBySectionReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST:
			return { loading: true };

		case GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL:
			return { loading: false, error: action.payload };

		case GET_QUESTIONAIRE_RESULT_BY_SECTION_RESET:
			return {};

		default:
			return state;
	}
};



export const finalQuestionnaireResultReducer = (state = {}, action) => {
	switch (action.type) {
		case FINAL_QUESTIONAIRE_RESULT_REQUEST:
			return { loading: true };

		case FINAL_QUESTIONAIRE_RESULT_SUCCESS:
			return { data: action.payload, loading: false };

		case FINAL_QUESTIONAIRE_RESULT_FAIL:
			return { loading: false, error: action.payload };

		case FINAL_QUESTIONAIRE_RESULT_RESET:
			return {};

		default:
			return state;
	}
};



export const addQuestionnaireResultBySectionReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST:
			return { loading: true };

		case ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL:
			return { loading: false, error: action.payload };

		case ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET:
			return {};

		default:
			return state;
	}
};

export const getOptionListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_OPTION_LIST_REQUEST:
			return { loading: true };

		case GET_OPTION_LIST_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_OPTION_LIST_FAIL:
			return { loading: false, error: action.payload };

		case GET_OPTION_LIST_RESET:
			return {};

		default:
			return state;
	}
};


export const getMonthListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_FILTER_MONTH_REQUEST:
			return { loading: true };

		case GET_FILTER_MONTH_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_FILTER_MONTH_FAIL:
			return { loading: false, error: action.payload };

		case GET_FILTER_MONTH_RESET:
			return {};

		default:
			return state;
	}
};


export const getKitByDescriptionReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_KIT_DESCRIPTIONS_REQUEST:
			return { loading: true };

		case GET_KIT_DESCRIPTIONS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_KIT_DESCRIPTIONS_FAIL:
			return { loading: false, error: action.payload };

		case GET_KIT_DESCRIPTIONS_RESET:
			return {};

		default:
			return state;
	}
};
