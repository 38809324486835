import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const TableRows = ({ columns = '2' }) => {
	const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9];

	return rows.map((row) => (
		<tr key={row}>
			<td colSpan={columns}>
				<div className={[SkeletonStyles.tableRowSkeleton, SkeletonStyles.skeleton].join(' ')} />
			</td>
		</tr>
	));
};

export default TableRows;
