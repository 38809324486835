import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import Button, { BackButton } from "../../components/formInputs/Button";
import InputBox, { InputCustomBox } from "../../components/formInputs/InputBox";

import { inputPhoneMasking } from '../../utils/phonemask';
import {
  gettActiviteKitStatusAction,
  getUserByIdAction,
  registerActivationKitAction,
} from "../../redux/actions/userActions";
import "./RegisterScreen.css";
import { stateValidatorSchema } from "../../utils/validators";
import { useNavigate } from "react-router-dom";
import InputsSections from "../../components/Modal/InputSections";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import SelectBox from "../../components/formInputs/SelectBox";
import FormValidation from "../../utils/formValidation";
import jwt_decode from "jwt-decode";
import Spinner from "../../components/Spinner/Spinner";
import { USER_LOGOUT } from "../../redux/constants/userConstants";

import moment from "moment";

import {
  getUserInfo,
  getStateByCountry,
} from "../../redux/actions/userActions";

const { REACT_APP_BASE_URL } = process.env;

const RegisterWithKitScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const getActiviteKitStatus = useSelector(
    (state) => state.getActiviteKitStatus
  );
  const { loading } = userLogin;

  const [userEmail, setUserEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [onboarded, setOnboarded] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [addAddress, setAddAddress] = useState(true);

  const [userData, setUserData] = useState({
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    email: { value: "", error: "" },
    gender: { value: "select", error: "" },
    genderAssigned: { value: "select", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
    address: { value: "", error: "" },
    apartment: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    zip: { value: "", error: "" },
    phone: { value: "", error: "" },
    dob: { value: "", error: "" },
  });

  useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      dispatch(getUserByIdAction(decoded._id));
      dispatch(gettActiviteKitStatusAction());
    } else if (
      userLogin.userInfo &&
      !userLogin.userInfo.data &&
      userLogin.userInfo.message
    ) {
      toast.error(userLogin.userInfo.message, {
        icon: "✕",
      });
      dispatch({ type: USER_LOGOUT });
    }
  }, [userLogin, dispatch]);

  useEffect(() => {
    if (
      getActiviteKitStatus &&
      getActiviteKitStatus.userInfo &&
      getActiviteKitStatus.userInfo.data &&
      getActiviteKitStatus.userInfo.data.isKitActivated === "activated"
    ) {
      localStorage.removeItem("activiteKitId");
      history("/");
    } else if (
      getActiviteKitStatus &&
      getActiviteKitStatus.userInfo &&
      !getActiviteKitStatus.userInfo.data &&
      getActiviteKitStatus.userInfo.message &&
      getActiviteKitStatus.userInfo.message
    ) {
      history("/activate-kit");
    }
  }, [getActiviteKitStatus, history]);

  // Register form


  useEffect(() => {
    getStateListHandler();
  }, []);

  const getUserData = async (e) => {
    e.preventDefault();
    const email = userEmail;
    const userInfo = await getUserInfo(email);
    if (userInfo) {
      setEmailVerified(true);
      setValues((prevState) => ({ ...prevState, ...userInfo }));
      const updatedErrors = { ...errors };
      for (const error in errors)
        if (userInfo[error]) updatedErrors[error] = "";

      setErrors({ ...errors, ...updatedErrors });
      if (userInfo.onboarded) setOnboarded(true);
      if (userInfo.address) {
        setValues((prevState) => {
          delete prevState.phone;
          prevState.dob = null;
          return prevState;
        });
        setUserData((prevState) => {
          delete prevState.phone;
          return prevState;
        });
        setHasAddress(true);
        setAddAddress(false);
      }
      return;
    }
    // user does not exist, fill in email on form
    setEmailVerified(true);
    setValues((prevState) => ({ ...prevState, email }));
    setErrors({ ...errors, email: "" });
  };

  const getStateListHandler = async () => {
    const stateData = await getStateByCountry();
    setStateList(stateData);
  };

  const registerHandler = (state) => {
    if (state.password === "" && state.password.trim() === "") {
      setErrors({ ...errors, confirmPassword: "Please enter your password" });
      setDisable(true);
    } else if (
      state.password !== state.confirmPassword &&
      state.password.trim() !== state.confirmPassword.trim()
    ) {
      setErrors({ ...errors, confirmPassword: "Password does not match" });
      setDisable(true);
    } else {
      let addressData = {};
      if (!hasAddress) {
        const {
          address: address1,
          apartment: address2,
          city,
          zip: postalCode,
          phone
        } = state;
        addressData = {
          address1,
          address2,
          city,
          state: state.state,
          postalCode,
          phone
        };
      }
      console.log("submitting...");
      dispatch(
        registerActivationKitAction({
          firstname: state.firstName,
          lastname: state.lastName,
          email: state.email,
          gender: state.gender,
          genderAssignedBirth: state.genderAssigned,
          password: state.password,
          dob: state.dob,
          role: "customer",
          kitActivatedID: localStorage.getItem("activiteKitId"),
          ...addressData,
        })
      );
    }
  };

  const handleOnboardedSubmit = (e) => {
    dispatch(
      registerActivationKitAction({
        firstname: values.firstName,
        lastname: values.lastName,
        role: "customer",
        email: values.email,
        password: values.password,
        dob: state.dob,
        kitActivatedID: localStorage.getItem("activiteKitId"),
        onboarded,
      })
    );
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setDisable,
    setValues,
    setErrors,
  } = FormValidation(userData, stateValidatorSchema, registerHandler);

  const {
    firstName,
    lastName,
    email,
    gender,
    genderAssigned,
    password,
    confirmPassword,
    address,
    apartment,
    city,
    state,
    zip,
    phone,
    dob
  } = values;

  useEffect(() => {
    if (disable && !Object.values(values).includes("")) setDisable(false);
  }, [values]);

  const containerStyle = {
    height: "90vh",
  };

  if (emailVerified && !onboarded) containerStyle.height = "120vh";

  const submitCallback = (e) => {
    e.preventDefault();
    if (!emailVerified) return getUserData(e);
    if (addAddress) return setAddAddress(false);
    if (onboarded) return handleOnboardedSubmit(e);
    handleOnSubmit(e);
  };

  console.log("gender: ", gender);
  console.log("genderAssigned: ", genderAssigned);

  return (
    <>
      <div className="login_back-btn">
        <BackButton label="< Back" onClick={() => history("/Login")} />
      </div>
      <div className="main-container" style={containerStyle}>
        {loading && <Spinner />}
        <div className="register_container">
          <div className="register_container__form">
            <div>
              <h1 className="register_container__heading">Activate Your Kit</h1>
            </div>
            <div style={{ fontSize: 18, marginBottom: 10 }}>
              Kit ID: {localStorage.getItem("activiteKitId")}
            </div>
            <form onSubmit={submitCallback} autocomplete="off" style={{ width: "100%" }}>
              <input autocomplete="false" name="hidden" type="text" style={{ "display": "none" }} />
              {renderForm()}
            </form>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="register__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="register_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="register__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );

  function renderForm() {
    if (!emailVerified) {
      return (
        <>
          <InputBox
            containerStyle={{ marginBottom: 4 }}
            id="email"
            type="email"
            placeholder="Email"
            name="email"
            errorMessage={errors && errors.email && dirty.email && errors.email}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            label={false}
            icon="email"
          />
          <div className="register-description">
            Enter the email address you used when you purchased your Kit, or if someone else purchased the Kit on your behalf please enter the email address you would like to use for your Account.
          </div>
          <Button
            disabled={!userEmail.length}
            style={{
              justifyContent: "center",
              width: "100%",
              marginTop: 0,
            }}
            type="submit"
            label="Next"
            icon={false}
          />
        </>
      );
    }

    if (emailVerified && !onboarded) {
      return (
        <>
          {!hasAddress && !addAddress ? (
            <>
              <h1 className="register_form__heading">Shipping Address</h1>

              <InputBox
                containerStyle={{ flex: 2 }}
                id="address"
                type="text"
                placeholder="Ex. 123 Stress Free Lane"
                name="address"
                errorMessage={
                  errors && errors.address && dirty.address && errors.address
                }
                value={address}
                onChange={handleOnChange}
                label="Street Address"
                icon="pin"
              />
              <InputBox
                containerStyle={{ flex: 1 }}
                id="apartment"
                type="text"
                placeholder="Ex. Apt 456"
                name="apartment"
                errorMessage={
                  errors &&
                  errors.apartment &&
                  dirty.apartment &&
                  errors.apartment
                }
                value={apartment}
                onChange={handleOnChange}
                label="Apt, Suite, Building, Floor"
                icon="pin"
              />

              <InputBox
                id="city"
                type="text"
                placeholder="Ex. San Diego"
                errorMessage={
                  errors && errors.city && dirty.city && errors.city
                }
                value={city}
                onChange={handleOnChange}
                label="City"
                name="city"
                icon={false}
              />

              <InputsSections>
                <SelectBox
                  errorMessage={
                    errors && errors.state && dirty.state && errors.state
                  }
                  value={state}
                  onChange={handleOnChange}
                  icon={false}
                  label="State"
                  name="state"
                  containerStyle={{ width: "100%" }}
                  textBoxStyle={{ width: "100%" }}
                >
                  <option value="">Select State</option>
                  {stateList &&
                    stateList.length > 0 &&
                    stateList.map((item, index) => (
                      <option value={item.name} key={index + 1}>
                        {item.name}
                      </option>
                    ))}
                </SelectBox>

                <InputBox
                  id="zip"
                  type="text"
                  placeholder="Ex. 92101"
                  name="zip"
                  errorMessage={errors && errors.zip && dirty.zip && errors.zip}
                  value={zip}
                  onChange={handleOnChange}
                  label="Postal Code"
                  maxLength={6}
                  icon={false}
                />
              </InputsSections>
              <InputsSections>
                <InputBox
                  id="phone"
                  type='text'
                  placeholder='Ex. (123) 456 - 7890'
                  name='phone'
                  errorMessage={errors && errors.phone && dirty.phone && errors.phone}
                  value={inputPhoneMasking(phone)}
                  onChange={(event) => {
                    event.target.value = inputPhoneMasking(event.target.value)
                    handleOnChange(event);
                  }}
                  label='Phone'
                  icon='phone'
                />
              </InputsSections>
            </>
          ) : (
            <>
              <h1 className="register_form__heading">Profile</h1>
              <InputsSections style={{ flexDirection: "row" }}>
                <InputBox
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  errorMessage={
                    errors &&
                    errors.firstName &&
                    dirty.firstName &&
                    errors.firstName
                  }
                  value={firstName}
                  onChange={handleOnChange}
                  label="First Name"
                  icon="user"
                />

                <InputBox
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  errorMessage={
                    errors &&
                    errors.lastName &&
                    dirty.lastName &&
                    errors.lastName
                  }
                  value={lastName}
                  onChange={handleOnChange}
                  label="Last Name"
                  icon="user"
                />
              </InputsSections>
              <InputsSections style={{ flexDirection: "row" }}>
                <InputBox
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  errorMessage={
                    errors && errors.email && dirty.email && errors.email
                  }
                  value={email}
                  onChange={handleOnChange}
                  label="Email"
                  icon="email"
                  readOnly={true}
                />
              </InputsSections>
              <InputsSections style={{ flexDirection: "row" }}>
                <SelectBox
                  errorMessage={
                    errors && errors.gender && dirty.gender && errors.gender
                  }
                  value={gender}
                  onChange={handleOnChange}
                  icon={false}
                  label="Gender"
                  id="gender"
                  name="gender"
                  containerStyle={{ width: "100%" }}
                  textBoxStyle={{ width: "100%" }}
                >
                  <option value="select">
                    --Select Gender--
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non-binary">Non-Binary</option>
                </SelectBox>

                <SelectBox
                  errorMessage={
                    errors &&
                    errors.genderAssigned &&
                    dirty.genderAssigned &&
                    errors.genderAssigned
                  }
                  value={genderAssigned}
                  onChange={handleOnChange}
                  icon={false}
                  label="Gender assigned at birth"
                  id="genderAssigned"
                  name="genderAssigned"
                  containerStyle={{ width: "100%" }}
                  textBoxStyle={{ width: "100%" }}
                >
                  <option value="select">
                    --Select Gender Assigned At Birth--
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </SelectBox>
              </InputsSections>
              <InputsSections>
                <InputBox
                  id="dob"
                  type='date'
                  placeholder='Ex. 05/02/1989'
                  errorMessage={errors && errors.dob && dirty.dob && errors.dob}
                  value={moment(dob).format("YYYY-MM-DD")}
                  onChange={handleOnChange}
                  label='Date of Birth'
                  icon='calendar'
                />
              </InputsSections>
              <InputBox
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                errorMessage={
                  errors && errors.password && dirty.password && errors.password
                }
                value={password}
                onChange={handleOnChange}
                label="Password"
                icon="key"
              />
              <InputBox
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                errorMessage={
                  errors &&
                  errors.confirmPassword &&
                  dirty.confirmPassword &&
                  errors.confirmPassword
                }
                value={confirmPassword}
                onChange={handleOnChange}
                label="Confirm password"
                icon="key"
              />
            </>
          )}

          <Button
            disabled={
              (addAddress
                ? !password.length || password !== confirmPassword
                : disable) || gender === "select" || genderAssigned === "select"
            }
            style={{
              justifyContent: "center",
              width: "100%",
              marginTop: 10,
            }}
            // onClick={addAddress ? () => setAddAddress(false) : null}
            type="submit"
            label={addAddress ? "Next" : "Finish"}
            icon={false}
          />

          {/* <div className='register-container '>
							  Already have an account?  &nbsp;
							  <Link to='/login' className='register-text'>
								  Sign In
							  </Link>.
						  </div> */}
        </>
      );
    }
    if (onboarded) {
      return (
        <>
          <InputBox
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            errorMessage={errors && errors.email && dirty.email && errors.email}
            value={email}
            onChange={handleOnChange}
            label="Email"
            icon="email"
            readOnly={true}
          />
          <InputBox
            id="password"
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={handleOnChange}
            label="Password"
            icon="key"
          />
          <Button
            style={{ justifyContent: "center", width: "100%" }}
            type="submit"
            label="Activate"
            icon={false}
          />
        </>
      );
    }
  }
};

export default RegisterWithKitScreen;

// import { REGISTER_KIT_RESET } from '../../redux/constants/userConstants';
// const registerKit = useSelector((state) => state.registerKit);
// React.useEffect(
// 	() => {
// 		if (registerKit && registerKit.userInfo && registerKit.userInfo.data) {
// 			var decoded = jwt_decode(registerKit.userInfo.data);
// 			dispatch(getUserByIdAction(decoded._id));
// 			dispatch(gettActiviteKitStatusAction());
// 			// history('/login');
// 			toast.success(registerKit.userInfo.message, {
// 				icon: "✔"
// 			});
// 			dispatch({ type: REGISTER_KIT_RESET });
// 		} else if (registerKit && registerKit.error && registerKit.error.message) {
// 			toast.error(registerKit.error.message, {
// 				icon: "✕"
// 			});
// 			dispatch({ type: REGISTER_KIT_RESET });
// 		} else if (registerKit && registerKit.userInfo && !registerKit.userInfo.data) {
// 			toast.error(registerKit.userInfo.message, {
// 				icon: "✕"
// 			});
// 			dispatch({ type: REGISTER_KIT_RESET });
// 		}
// 	},
// 	[registerKit, dispatch, history]
// );

// React.useEffect(
// 	() => {
// 		if (registerKit && registerKit.userInfo && registerKit.userInfo.data) {
// 			toast.success(registerKit.userInfo.message, {
// 				icon: "✔"
// 			});
// 			dispatch({ type: REGISTER_KIT_RESET });
// 			history('/login');
// 		} else if (registerKit && registerKit.userInfo && !registerKit.userInfo.data) {
// 			toast.error(registerKit.userInfo.message, {
// 				icon: "✕"
// 			});
// 			dispatch({ type: REGISTER_KIT_RESET });
// 		}
// 	},
// 	[registerKit, dispatch, history]
// );
