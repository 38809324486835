import React from 'react';
import SelectBox from './SelectBox';
import InputsSections from '../../components/Modal/InputSections';
import { inputPhoneMasking } from '../../utils/phonemask';
import Button from './Button';
import TextBox from './TextBox';

const AddressForm = ({ data }) => {
	const {
		handleSubmit,
		handleOnChange,
		shippingAddress,
		setValues, 
		stateList,
		errors,
		dirty,
		disable
	} = data;


	const {
		firstName,
		lastName,
		address,
		appartment,
		city,
		state,
		zip,
		phone,
		company, 
	} = shippingAddress;



	React.useEffect(
		() => {
			if (state && stateList.length > 0) {
				setValues({ ...shippingAddress, state_code: stateList.find(o => o.name === state).state_code })
			}
		},
		[state, stateList]
	);

	return (
		<form onSubmit={handleSubmit} style={{ marginBottom: '1rem' }}>
			<InputsSections>
				<TextBox
					name="firstName"
					type='text'
					placeholder='First Name'
					errorMessage={errors && errors.firstName && dirty.firstName && errors.firstName}
					value={firstName}
					onChange={handleOnChange}
					label='First Name'
					icon='user'
				/>
				<TextBox
					name="lastName"
					type='text'
					placeholder='Last Name'
					errorMessage={errors && errors.lastName && dirty.lastName && errors.lastName}
					value={lastName}
					onChange={handleOnChange}
					label='Last Name'
					icon='user'
				/>
			</InputsSections>
			<InputsSections>
				<TextBox
					id="address"
					type='text'
					placeholder='Street Address'
					name='address'
					errorMessage={errors && errors.address && dirty.address && errors.address}
					value={address}
					onChange={handleOnChange}
					maxLength={150}
					label='Street Address'
					icon='pin'
				/>
			</InputsSections>
			<InputsSections>
				<TextBox
					id="appartment"
					type='text'
					placeholder='C/O , Apt, Suite, Building, Floor'
					name='appartment'
					errorMessage={errors && errors.appartment && dirty.appartment && errors.appartment}
					value={appartment}
					onChange={handleOnChange}
					label='C/O , Apt, Suite, Building, Floor'
					icon='pin'
					maxLength={150}
				/>
			</InputsSections> 
			<InputsSections>
				<TextBox
					id="city"
					type='text'
					placeholder='City'
					errorMessage={errors && errors.city && dirty.city && errors.city}
					value={city}
					onChange={handleOnChange}
					label='City'
					name='city'
					icon={false}
				/>
				<SelectBox
					errorMessage={errors && errors.state && dirty.state && errors.state}
					value={state}
					onChange={handleOnChange}
					icon={false}
					label='State'
					name='state'
					containerStyle={{ width: '100%' }}
					textBoxStyle={{ width: '100%' }}
				>
					<option value='' >Select State</option>
					{
						stateList &&
						stateList.length > 0 &&
						stateList.map((item, index) => (
							<option value={item.name} key={index + 1}>
								{item.name}
							</option>
						))}
				</SelectBox>
				<TextBox
					id="zip"
					type='text'
					placeholder='Postal Code'
					name='zip'
					errorMessage={errors && errors.zip && dirty.zip && errors.zip}
					value={zip}
					onChange={handleOnChange}
					label='Postal Code'
					maxLength={6}
					icon={false}
				/>
			</InputsSections>
			<InputsSections>
				<TextBox
					id="phone"
					type='text'
					placeholder='Phone'
					name='phone'
					errorMessage={errors && errors.phone && dirty.phone && errors.phone}
					value={inputPhoneMasking(phone)}
					onChange={(event) => {
						event.target.value = inputPhoneMasking(event.target.value)
						handleOnChange(event);
					}}
					label='Phone'
					icon='phone'
				/>

				<TextBox
					id="company"
					type='text'
					placeholder='Company'
					name='company'
					errorMessage={errors && errors.company && dirty.company && errors.company}
					value={company}
					onChange={handleOnChange}
					label='Company'
					icon={false}
				/>
			</InputsSections>
			<InputsSections style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
				<Button
					label='Continue' //to shipping
					style={{ marginTop: '1rem' }}
					disabled={disable}
					onClick={handleSubmit}
				/>
			</InputsSections>
		</form>
	);
};

export default AddressForm;
