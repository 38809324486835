import { Range, useThumbOverlap } from "react-range";
import { RangeColor } from "./rangeStyle";
import React from "react";

export function RangeInput({ min, max, step, values, onChange, pdfMode }) {
  const ThumbLabel = ({ rangeRef, values, index }) => {
    const [labelValue] = useThumbOverlap(rangeRef, values, index);
    return (
      <div
        style={{
          display: "block",
          position: "absolute",
          top: "-30px",
          color: "#fff",
          fontWeight: "400",
          fontSize: "1.5rem",
          padding: "4px",
          borderRadius: "4px",
          backgroundColor: "#4B4B4B",
          whiteSpace: "nowrap",
          left: "-1.8rem",
          width: "40px",
          textAlign: "center",
        }}
      >
        {JSON.parse(labelValue).toFixed(1)}
      </div>
    );
  };

  const rangeRef = React.createRef();
  const r = 1.6;
  const d = (parseFloat(max) - parseFloat(min)) / r;
  const aMax = parseFloat(max) + d;
  const aMin = parseFloat(min) - d;

  let value = values[0];
  if (value > aMax) {
    value = aMax;
  }
  if (value < aMin) {
    value = aMin;
  }

  if (pdfMode) value *= 0.93;

  return (
    <Range
      step={step}
      min={aMin}
      max={aMax}
      values={[value]}
      draggableTrack={false}
      disabled={false}
      onChange={(values) => onChange(values)}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "8px",
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
          }}
        >
          <RangeColor />
          {children}
        </div>
      )}
      renderThumb={({ props, index }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "2vh",
            width: "0.2vh",
            backgroundColor: "#4B4B4B",
            zIndex: 1,
            position: "absolute",
            top: 0,
          }}
        >
          <ThumbLabel
            rangeRef={rangeRef.current}
            values={[JSON.parse(values[0])]}
            index={index}
          />
        </div>
      )}
    />
  );
}
