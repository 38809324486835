import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import Button from '../../components/formInputs/Button';
import InputBox from '../../components/formInputs/InputBox';
import SelectBox from '../../components/formInputs/SelectBox';
import InputsSections from '../../components/Modal/InputSections';
import {
	addAdressAction,
	changePasswordAction,
	getAdressAction,
	getPaymentMethodAction,
	getStateByCountryAction,
	getUserByIdAction,
	removeAdressAction,
	removePaymentMethodAction,
	updateAdressAction,
	updatePaymentMethodAction,
	updateUserAction
} from '../../redux/actions/userActions';
import {
	ADD_BILLING_ADDRESS_RESET,
	ADD_USER_ADDRESS_RESET,
	CHANGE_PWD_RESET,
	DELETE_BILLING_ADDRESS_RESET,
	DELETE_USER_ADDRESS_RESET,
	UPDATE_BILLING_ADDRESS_RESET,
	UPDATE_USER_ADDRESS_RESET,
	UPDATE_USER_RESET
} from '../../redux/constants/userConstants';
import { stateValidatorSchema } from '../../utils/validators';
import './AccountScreen.css';
import CreateEditAddress from './CreateEditAddress';
import CreateEditPayment from './CreateEditPayment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import moment from "moment";
// import NoData from '../../components/NoData';
import Spinner from '../../components/Spinner/Spinner';
import {
	inputDateMasking,
	inputPhoneMasking,
	unMasking,
	convertDate
} from '../../utils/phonemask';
import FormValidation from '../../utils/formValidation';
import { loadStripe } from '@stripe/stripe-js';
import {
	Elements,
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../redux/actions/ip';
import { countryObj } from '../../utils/for';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AccountScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);
	const updateUser = useSelector((state) => state.updateUser);
	const changePwd = useSelector((state) => state.changePwd);
	const getUserInfo = useSelector((state) => state.getUserInfo);

	const getUserAddress = useSelector((state) => state.getUserAddress);
	const addUserAddress = useSelector((state) => state.addUserAddress);
	const updateUserAddress = useSelector((state) => state.updateUserAddress);
	const removeUserAddress = useSelector((state) => state.removeUserAddress);

	const getPaymentMethod = useSelector((state) => state.getPaymentMethod);
	const addPaymentMethod = useSelector((state) => state.addPaymentMethod);
	const updatePaymentMethod = useSelector((state) => state.updatePaymentMethod);
	const removePaymentMethod = useSelector((state) => state.removePaymentMethod);

	const [stateList, setStateList] = useState([]);
	const [showAddNewAddressModal, setShowAddNewAddressModal] = useState(false);
	const [showAddNewPaymentModal, setShowAddNewPaymentModal] = useState(false);
	const [shippingAddress, setShippingAddress] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);

	React.useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
				var decoded = jwt_decode(userLogin.userInfo.data);
				dispatch(getUserByIdAction(decoded._id));
				getStateListHandler();
				dispatch(getAdressAction());
				dispatch(getPaymentMethodAction());
			} else {
				history('/login');
			}
		},
		[userLogin, dispatch, history]
	);


	const getStateListHandler = async () => {
		const data = await dispatch(getStateByCountryAction());
		setStateList(data.data)
	}

	// Profile form

	const userData = {
		firstName: { value: "", error: "" },
		lastName: { value: "", error: "" },
		dob: { value: "", error: "" },
		email: { value: "", error: "" },
		gender: { value: "", error: "" },
		genderAssigned: { value: "", error: "" }
	};

	const updateAccountHandler = (state) => {
		dispatch(updateUserAction({
			firstname: state.firstName,
			lastname: state.lastName,
			dob: state.dob,
			email: state.email,
			gender: state.gender,
			genderAssignedBirth: state.genderAssigned
		}));
	};

	const {
		values: profileValues,
		errors: profileErrors,
		dirty: profileDirty,
		handleOnChange: profileHandleOnChange,
		handleOnSubmit: profileHandleOnSubmit,
		disable: isProfileValid,
		setDisable,
		setValues: setProfileValues,
		setErrors: setProfileErrors
	} = FormValidation(userData, stateValidatorSchema, updateAccountHandler);


	const {
		firstName,
		lastName,
		dob,
		email,
		gender,
		genderAssigned
	} = profileValues;


	React.useEffect(
		() => {
			if (getUserInfo && getUserInfo.userInfo && getUserInfo.userInfo.data) {
				setProfileValues({
					firstName: getUserInfo.userInfo.data.firstname,
					lastName: getUserInfo.userInfo.data.lastname,
					dob: getUserInfo.userInfo.data.dob,
					email: getUserInfo.userInfo.data.email,
					gender: getUserInfo.userInfo.data.gender,
					genderAssigned: getUserInfo.userInfo.data.genderAssignedBirth
				})

				setProfileErrors({
					firstName: '',
					lastName: '',
					dob: '',
					email: '',
					gender: '',
					genderAssigned: ''
				})
			}
		},
		[getUserInfo]
	);


	React.useEffect(
		() => {
			if (updateUser && updateUser.userInfo && updateUser.userInfo.data) {
				toast.success(updateUser.userInfo.message, {
					icon: "✔"
				});
				dispatch({ type: UPDATE_USER_RESET });
			} else if (updateUser && updateUser.userInfo && !updateUser.userInfo.data) {
				dispatch({ type: UPDATE_USER_RESET });
				toast.error(updateUser.userInfo.message, {
					icon: "✕"
				});
			}
		},
		[updateUser, dispatch]
	);


	// Change password form  
	const passwordSchema = {
		password: { value: "", error: "" },
		confirmPassword: { value: "", error: "" },
	};

	const [pwdMsgErrors, setPwdErrors] = React.useState('');
	const changePasswordHandler = (state) => {
		if (state.password !== state.confirmPassword && state.password.trim() !== state.confirmPassword.trim()) {
			setPwdErrors('Password does not match');
			setPwdDisable(true)
		} else {
			setPwdErrors('');
			dispatch(changePasswordAction({
				"password": state.password
			}));
		}
	};

	const {
		values: pwdValues,
		errors: pwdErrors,
		dirty: pwdDirty,
		handleOnChange: pwdsHandleOnChange,
		handleOnSubmit: pwdsHandleOnSubmit,
		disable: pwdDisable,
		setDisable: setPwdDisable,
		setValues: setPwdValue,
	} = FormValidation(passwordSchema, stateValidatorSchema, changePasswordHandler);

	const {
		password,
		confirmPassword,
	} = pwdValues;


	// User Add Shipping Address
	const newShippingAddrSchema = {
		firstName: { value: "", error: "" },
		lastName: { value: "", error: "" },
		address: { value: "", error: "" },
		appartment: { value: "", error: "" },
		city: { value: "", error: "" },
		state: { value: "", error: "" },
		zip: { value: "", error: "" },
		phone: { value: "", error: "" },
		company: { value: "", error: "" },
		isPrimary: { value: false, error: "" },
	};

	const addNewShippingAddrHandler = (shippingNewAddress) => {
		dispatch(addAdressAction({
			firstname: shippingNewAddress.firstName,
			lastname: shippingNewAddress.lastName,
			streetaddress: shippingNewAddress.address,
			building: shippingNewAddress.appartment,
			city: shippingNewAddress.city,
			state: shippingNewAddress.state,
			postalcode: shippingNewAddress.zip,
			phone: unMasking(shippingNewAddress.phone),
			company: shippingNewAddress.company,
			isPrimary: shippingNewAddress.isPrimary,
			country: countryObj.name,
			country_code: countryObj.iso2,
			state_code: stateList.find(o => o.name === shippingNewAddress.state).state_code,
		}));
	};


	const {
		values: newShippingAddrValues,
		errors: newShippingAddrErrors,
		dirty: newShippingAddrDirty,
		handleOnChange: newShippingAddrHandleOnChange,
		handleOnSubmit: newShippingAddrHandleOnSubmit,
		disable: newShippingAddrDisable,
		setValues: setShippingAddrValues,
		setErrors: setShippingAddrErrors,
		setDirty: setShippingAddrDirty
	} = FormValidation(newShippingAddrSchema, stateValidatorSchema, addNewShippingAddrHandler);

	// Add Payment Method
	const newPaymentMethodSchema = {
		firstName: { value: "", error: "" },
		lastName: { value: "", error: "" },
		address: { value: "", error: "" },
		appartment: { value: "", error: "" },
		city: { value: "", error: "" },
		state: { value: "", error: "" },
		zip: { value: "", error: "" },
		isPrimary: { value: false, error: "" },
		nameOnCard: { value: "", error: "" },
		// country: { value: "", error: "" },
		// phone: { value: "", error: "" },
		// company: { value: "", error: "" },
		// state_code: { value: "", error: "" }
	};

	React.useEffect(
		() => {
			if (changePwd && changePwd.userInfo && changePwd.userInfo.message) {
				toast.success(changePwd.userInfo.message, {
					icon: "✔"
				});
				dispatch({ type: CHANGE_PWD_RESET });
			} else if (changePwd && changePwd.userInfo && !changePwd.userInfo.status) {
				dispatch({ type: CHANGE_PWD_RESET });
				toast.error(changePwd.userInfo.message, {
					icon: "✕"
				});
			}
		},
		[changePwd, dispatch]
	);


	React.useEffect(
		() => {
			if (getUserAddress && getUserAddress.userInfo && getUserAddress.userInfo.data && getUserAddress.userInfo.data.length > 0) {
				let shippingAddressList = [];
				getUserAddress.userInfo.data.forEach((item, index) => {
					shippingAddressList.push({
						_id: item._id,
						firstName: item.firstname,
						firstNameError: '',
						lastName: item.lastname,
						lastNameError: '',
						address: item.streetaddress,
						addressError: '',
						appartment: item.building,
						appartmentError: '',
						city: item.city,
						cityError: '',
						state: item.state,
						stateError: '',
						zip: item.postalcode,
						zipError: '',
						phone: item.phone,
						phoneError: '',
						company: item.company,
						companyError: '',
						isPrimary: item.isPrimary, // ? item.isPrimary : false
						customerID: item.customerID,
						country: item.country,
						country_code: item.country_code,
						state_code: item.state_code,
						isAddressValid: false,
					})
				})
				setShippingAddress([...shippingAddressList]);
			}
		},
		[getUserAddress]
	);


	React.useEffect(
		() => {
			if (getPaymentMethod && getPaymentMethod.userInfo && getPaymentMethod.userInfo.data && getPaymentMethod.userInfo.data.data) {
				let PaymentList = [];
				let paymentProfiles = getPaymentMethod.userInfo.data.data;
				paymentProfiles.forEach((item, index) => {
					let address = item.billing_details && item.billing_details.address && item.billing_details.address;
					PaymentList.push({
						id: item.id,
						cardNo: item.card.last4,
						expirationDate:inputDateMasking(convertDate(item.card.exp_month, item.card.exp_year)),// inputDateMasking(convertDate(item.card.exp_month, item.card.exp_year)),
						expirationDateError: '',
						nameOnCard: item.billing_details && item.billing_details.name && item.billing_details.name,
						nameOnCardError: '',
						brand: item.card.brand,
						firstName: item.billing_details && item.billing_details.name && item.billing_details.name.length > 0 && item.billing_details.name.split(' ')[0],
						firstNameError: '',
						lastName: item.billing_details && item.billing_details.name && item.billing_details.name.length > 0 && item.billing_details.name.split(' ')[1],
						lastNameError: '',
						address: address.line1,
						addressError: '',
						appartment: address.line2,
						appartmentError: '',
						city: address.city,
						cityError: '',
						state: address.state,
						stateError: '',
						zip: address.postal_code,
						zipError: '',
						isPrimary: item.defaultPaymentProfile ? item.defaultPaymentProfile : false,
						isPaymentValid: false,
						// phone: inputPhoneMasking(item.billTo.phoneNumber),
						// phoneError: '',
						// company: item.billTo.company,
						// companyError: '',
						// customerID:  item.billTo.customerID,
						// country_code:  item.billTo.country,
						// state_code:  item.billTo.state_code,
					})
				})
				setPaymentMethods([...PaymentList]);
			}
		},
		[getPaymentMethod]
	);

	React.useEffect(
		() => {
			if (updateUserAddress && updateUserAddress.userInfo && updateUserAddress.userInfo.data) {
				toast.success(updateUserAddress.userInfo.message, {
					icon: "✔"
				});
				dispatch(getAdressAction());
				dispatch({ type: UPDATE_USER_ADDRESS_RESET });
			} else if (updateUserAddress && updateUserAddress.error && updateUserAddress.error.message) {
				dispatch({ type: UPDATE_USER_ADDRESS_RESET });
				toast.error(updateUserAddress.error.message, {
					icon: "✕"
				});
			}
		},
		[updateUserAddress, dispatch]
	);

	React.useEffect(
		() => {
			if (addUserAddress && addUserAddress.userInfo && addUserAddress.userInfo.data) {
				toast.success(addUserAddress.userInfo.message, {
					icon: "✔"
				});
				clearData();
				dispatch(getAdressAction());
				dispatch({ type: ADD_USER_ADDRESS_RESET });
			} else if (addUserAddress && addUserAddress.error && addUserAddress.error.message) {
				dispatch({ type: ADD_USER_ADDRESS_RESET });
				toast.error(addUserAddress.error.message, {
					icon: "✕"
				});
			}
		},
		[addUserAddress, dispatch]
	);

	React.useEffect(
		() => {
			if (removeUserAddress && removeUserAddress.userInfo && removeUserAddress.userInfo.data) {
				toast.success(removeUserAddress.userInfo.message, {
					icon: "✔"
				});
				dispatch(getAdressAction());
				dispatch({ type: DELETE_USER_ADDRESS_RESET });
			} else if (removeUserAddress && removeUserAddress.error && removeUserAddress.error.message) {
				dispatch({ type: DELETE_USER_ADDRESS_RESET });
				toast.error(removeUserAddress.error.message, {
					icon: "✕"
				});
			}
		},
		[removeUserAddress, dispatch]
	);

	React.useEffect(
		() => {
			if (addPaymentMethod && addPaymentMethod.userInfo && addPaymentMethod.userInfo.data) {
				toast.success(addPaymentMethod.userInfo.message, {
					icon: "✔"
				});
				clearData();
				dispatch(getPaymentMethodAction());
				dispatch({ type: ADD_BILLING_ADDRESS_RESET });
			} else if (addPaymentMethod && addPaymentMethod.error && addPaymentMethod.error.message) {
				toast.error(addPaymentMethod.error.message, {
					icon: "✕"
				});
				dispatch({ type: ADD_BILLING_ADDRESS_RESET });
			}
		},
		[addPaymentMethod, dispatch]
	);

	React.useEffect(
		() => {
			if (updatePaymentMethod && updatePaymentMethod.userInfo && updatePaymentMethod.userInfo.data) {
				toast.success(updatePaymentMethod.userInfo.message, {
					icon: "✔"
				});
				dispatch(getPaymentMethodAction());
				dispatch({ type: UPDATE_BILLING_ADDRESS_RESET });
			} else if (updatePaymentMethod && updatePaymentMethod.error && updatePaymentMethod.error.message) {
				toast.error(updatePaymentMethod.error.message, {
					icon: "✕"
				});
				dispatch({ type: UPDATE_BILLING_ADDRESS_RESET });
			}
		},
		[updatePaymentMethod, dispatch]
	);


	React.useEffect(
		() => {
			if (removePaymentMethod && removePaymentMethod.userInfo && removePaymentMethod.userInfo.data) {
				toast.success(removePaymentMethod.userInfo.message, {
					icon: "✔"
				});
				dispatch(getPaymentMethodAction());
				dispatch({ type: DELETE_BILLING_ADDRESS_RESET });
			} else if (removePaymentMethod && removePaymentMethod.userInfo && !removePaymentMethod.userInfo.data) {
				dispatch({ type: DELETE_BILLING_ADDRESS_RESET });
				toast.error(removePaymentMethod.userInfo.message, {
					icon: "✕"
				});
			}
		},
		[removePaymentMethod, dispatch]
	);



	const handleAddAddressModalClose = () => {
		clearData();
		setShowAddNewAddressModal(false);
	}

	const clearData = () => {
		setShippingAddrValues({
			firstName: "",
			lastName: "",
			address: "",
			appartment: "",
			city: "",
			state: "",
			zip: "",
			phone: "",
			company: "",
			isPrimary: false,
		})
		setShippingAddrErrors({
			firstName: "*This is required field",
			lastName: "*This is required field",
			address: "*This is required field",
			appartment: "*This is required field",
			city: "*This is required field",
			state: "*This is required field",
			zip: "*This is required field",
			phone: "*This is required field",
			company: "*This is required field",
			isPrimary: "*This is required field",
		})
		setShippingAddrDirty({
			firstName: false,
			lastName: false,
			address: false,
			appartment: false,
			city: false,
			state: false,
			zip: false,
			phone: false,
			company: false,
			isPrimary: false,
		})

		setShowAddNewAddressModal(false);
		setShowAddNewPaymentModal(false);
	}

	const handleAddPaymentModalClose = () => {
		setShowAddNewPaymentModal(false)
	}



	const updateShippingAddressHandler = (e, data) => {
		e.preventDefault();
		let newFormValues = [...shippingAddress];
		if (data.firstName === '' && data.firstName.trim() === '') {
			data.firstNameError = 'Please enter first name';
			setShippingAddress([...newFormValues]);
		} else if (data.lastName === '' && data.lastName.trim() === '') {
			data.lastNameError = 'Please enter last name';
			setShippingAddress([...newFormValues]);
		} else if (data.address === '' && data.address.trim() === '') {
			data.addressError = 'Please enter address';
			setShippingAddress([...newFormValues]);
		// } else if (data.appartment === '' && data.appartment.trim() === '') {
		// 	data.appartmentError = 'Please enter appartment name';
		// 	setShippingAddress([...newFormValues]);
		} else if (data.city === '' && data.city.trim() === '') {
			data.cityError = 'Please enter city name';
			setShippingAddress([...newFormValues]);
		} else if (data.state === '' && data.state.trim() === '') {
			data.stateError = 'Please select state name';
			setShippingAddress([...newFormValues]);
		} else if (data.zip === '' && data.zip.trim() === '') {
			data.zipError = 'Please enter postal code';
			setShippingAddress([...newFormValues]);
		} else if (data.phone === '' && data.phone.trim() === '') {
			data.phoneError = 'Please enter phone number';
			setShippingAddress([...newFormValues]);
		// } else if (data.company === '' && data.company.trim() === '') {
		// 	data.companyError = 'Please enter company name';
		// 	setShippingAddress([...newFormValues]);
		} else {
			dispatch(updateAdressAction(data._id, {
				firstname: data.firstName,
				lastname: data.lastName,
				streetaddress: data.address,
				building: data.appartment,
				city: data.city,
				state: data.state,
				postalcode: data.zip,
				phone: unMasking(data.phone),
				company: data.company,
				country: data.country,
				isPrimary: data.isPrimary,
				country_code: countryObj.iso2,
				state_code: stateList.find(o => o.name === data.state).state_code,
			}));
		}
	};

	const deleteShippingAddressHandler = (e, data) => {
		e.preventDefault();
		dispatch(removeAdressAction(data._id));
	}

	const handleChange = (e, index, item) => {
		e.preventDefault();
		item.isAddressValid = false;

		let newFormValues = [...shippingAddress];
		item[e.target.name] = e.target.value;
		const key = Object.keys(item)[Object.values(item).indexOf(e.target.value)];
		Object.keys(item).map((k) => {
			if (k === key + 'Error') {
				item[k] = '';
			}
		});

		if (item.firstName === '' && item.firstName.trim() === '') {
			item.firstNameError = 'Please enter first name';
			item.isAddressValid = true;
		} else item.firstNameError = '';

		if (item.lastName === '' && item.lastName.trim() === '') {
			item.lastNameError = 'Please enter last name';
			item.isAddressValid = true;
		} else item.lastNameError = '';

		if (item.address === '' && item.address.trim() === '') {
			item.addressError = 'Please enter address';
			item.isAddressValid = true;
		} else item.addressError = '';

		// if (item.appartment === '' && item.appartment.trim() === '') {
		// 	item.appartmentError = 'Please enter appartment name';
		// 	item.isAddressValid = true;
		// } else item.appartmentError = '';

		if (item.city === '' && item.city.trim() === '') {
			item.cityError = 'Please enter city name';
			item.isAddressValid = true;
		} else item.cityError = '';

		if (item.state === '' && item.state.trim() === '') {
			item.stateError = 'Please select state name';
			item.isAddressValid = true;
		} else item.stateError = '';

		if (item.zip === '' && item.zip.trim() === '') {
			item.zipError = 'Please enter postal code';
			item.isAddressValid = true;
		} else item.zipError = '';

		if (item.phone === '' && item.phone.trim() === '') {
			item.phoneError = 'Please enter phone number';
			item.isAddressValid = true;
		} else item.phoneError = '';

		// if (item.company === '' && item.company.trim() === '') {
		// 	item.companyError = 'Please enter company name';
		// 	item.isAddressValid = true;
		// } else item.companyError = '';

		setShippingAddress([...newFormValues]);
	}

	const updatePaymentMethodHandler = (e, data) => {
		e.preventDefault();

		let newFormValues = [...paymentMethods];
		if (data.expirationDate === '' && data.expirationDate.trim() === '') {
			data.expirationDateError = 'Please enter expiration date';
			setPaymentMethods([...newFormValues]);
		} else if (data.nameOnCard === '' && data.nameOnCard.trim() === '') {
			data.nameOnCardError = 'Please enter name on card';
			setPaymentMethods([...newFormValues]);
		} else if (data.firstName === '' && data.firstName.trim() === '') {
			data.firstNameError = 'Please enter first name';
			setPaymentMethods([...newFormValues]);
		} else if (data.lastName === '' && data.lastName.trim() === '') {
			data.lastNameError = 'Please enter last name';
			setPaymentMethods([...newFormValues]);
		} else if (data.address === '' && data.address.trim() === '') {
			data.addressError = 'Please enter address';
			setPaymentMethods([...newFormValues]);
		} else if (data.appartment === '' && data.appartment.trim() === '') {
			data.appartmentError = 'Please enter appartment name';
			setPaymentMethods([...newFormValues]);
		} else if (data.city === '' && data.city.trim() === '') {
			data.cityError = 'Please enter city name';
			setPaymentMethods([...newFormValues]);
		} else if (data.state === '' && data.state.trim() === '') {
			data.stateError = 'Please select state name';
			setPaymentMethods([...newFormValues]);
		} else if (data.zip === '' && data.zip.trim() === '') {
			data.zipError = 'Please enter postal code';
			setPaymentMethods([...newFormValues]);
		} else {
			dispatch(updatePaymentMethodAction({
				exp_month: data.expirationDate.split('/')[0],
				exp_year: data.expirationDate.split('/')[1],
				city: data.city,
				country: countryObj.iso2,
				line1: data.address,
				line2: data.appartment,
				postal_code: data.zip,
				state: data.state,
				name: data.nameOnCard,
				id: data.id
			}));
		}
	};

	const deletePaymentMethodHandler = (e, data) => {
		e.preventDefault();
		dispatch(removePaymentMethodAction(data.id));
	}

	const paymentHandleOnChange = (e, index, item) => {
		e.preventDefault();
		item.isPaymentValid = false;

		let newFormValues = [...paymentMethods];
		item[e.target.name] = e.target.value;
		const key = Object.keys(item)[Object.values(item).indexOf(e.target.value)];
		Object.keys(item).map((k) => {
			if (k === key + 'Error') {
				item[k] = '';
			}
		});

		if (item.expirationDate === '' && item.expirationDate.trim() === '') {
			item.expirationDateError = 'Please enter expiry date';
			item.isPaymentValid = true;
		} else item.expirationDateError = '';

		if (item.nameOnCard === '' && item.nameOnCard.trim() === '') {
			item.nameOnCardError = 'Please enter name on card';
			item.isPaymentValid = true;
		} else item.nameOnCardError = '';

		if (item.firstName === '' && item.firstName.trim() === '') {
			item.firstNameError = 'Please enter first name';
			item.isPaymentValid = true;
		} else item.firstNameError = '';

		if (item.lastName === '' && item.lastName.trim() === '') {
			item.lastNameError = 'Please enter last name';
			item.isPaymentValid = true;
		} else item.lastNameError = '';

		if (item.address === '' && item.address.trim() === '') {
			item.addressError = 'Please enter address';
			item.isPaymentValid = true;
		} else item.addressError = '';

		// if (item.appartment === '' && item.appartment.trim() === '') {
		// 	item.appartmentError = 'Please enter appartment name';
		// 	item.isPaymentValid = true;
		// } else 
		item.appartmentError = '';

		if (item.city === '' && item.city.trim() === '') {
			item.cityError = 'Please enter city name';
			item.isPaymentValid = true;
		} else item.cityError = '';

		if (item.state === '' && item.state.trim() === '') {
			item.stateError = 'Please select state name';
			item.isPaymentValid = true;
		} else item.stateError = '';

		if (item.zip === '' && item.zip.trim() === '') {
			item.zipError = 'Please enter postal code';
			item.isPaymentValid = true;
		} else item.zipError = '';

		if (item.phone === '' && item.phone.trim() === '') {
			item.phoneError = 'Please enter phone number';
			item.isPaymentValid = true;
		} else item.phoneError = '';

		// if (item.company === '' && item.company.trim() === '') {
		// 	item.companyError = 'Please enter company name';
		// 	item.isPaymentValid = true;
		// } else 
		item.companyError = '';

		setPaymentMethods([...newFormValues]);
	}


	return (
		<Elements stripe={stripePromise}>
			<Content
				currentMenu='account'
				headerTitle='Account'
				navigationRoute={[
					{
						path: "/",
						label: "Dashboard",
						routes: [
							{
								path: "/account",
								label: "Account",
							},
						]
					}
				]}
				addBtn={false}
				addBtnText='Add New User'
				addBtnIcon='plus'
				addBtnClick={() => { }}
				search={true}
				searchPlaceholder='Search Users...'
				searchIcon='search'
				searchvalue={'search'}
				searchOnChange={''}>
				<div className='account-container'>
					<h6 className='account-heading'>
						Account
					</h6>
					{
						(getUserInfo.loading ||
							getUserAddress.loading) &&
						<Spinner />
					}
					<div className='account-content'>
						<h1 className='account_form--heading'>Profile</h1>
						<form onSubmit={profileHandleOnSubmit}>
							<InputsSections style={{ flexDirection: 'row' }}>
								<InputBox
									id="firstName"
									type='text'
									placeholder='Ex. John'
									errorMessage={profileErrors && profileErrors.firstName && profileDirty.firstName && profileErrors.firstName}
									value={firstName}
									onChange={profileHandleOnChange}
									label='First Name'
									icon='user'
								/>

								<InputBox
									id="lastName"
									type='text'
									placeholder='Ex. Doe'
									errorMessage={profileErrors && profileErrors.lastName && profileDirty.lastName && profileErrors.lastName}
									value={lastName}
									onChange={profileHandleOnChange}
									label='Last Name'
									icon='user'
								/>
							</InputsSections>
							<InputsSections>

								<InputBox
									id="email"
									type='email'
									placeholder='Ex. Johndoe01@gmail.com'
									errorMessage={profileErrors && profileErrors.email && profileDirty.email && profileErrors.email}
									value={email}
									onChange={profileHandleOnChange}
									label='Email'
									icon='email'
								/>

								<InputBox
									id="dob"
									type='date'
									placeholder='Ex. 05/02/1989'
									errorMessage={profileErrors && profileErrors.dob && profileDirty.dob && profileErrors.dob}
									value={moment(dob).format("YYYY-MM-DD")}
									onChange={profileHandleOnChange}
									label='Date of Birth'
									icon='calendar'
								/>
							</InputsSections>
							<InputsSections>
								<SelectBox
									errorMessage={profileErrors && profileErrors.gender && profileDirty.gender && profileErrors.gender}
									value={gender}
									onChange={profileHandleOnChange}
									icon={false}
									label='Gender'
									name='gender'
									containerStyle={{ width: '100%' }}
									textBoxStyle={{ width: '100%' }}
								>
									<option value=''>
										Select
									</option>
									<option value='male'>
										Male
									</option>
									<option value='female'>
										Female
									</option>
									<option value='non-binary'>
										Non-Binary
									</option>
								</SelectBox>

								<SelectBox
									errorMessage={profileErrors && profileErrors.genderAssigned && profileDirty.genderAssigned && profileErrors.genderAssigned}
									value={genderAssigned}
									onChange={profileHandleOnChange}
									icon={false}
									label='Gender assigned at birth'
									name='genderAssigned'
									containerStyle={{ width: '100%' }}
									textBoxStyle={{ width: '100%' }}
								>
									<option value=''>
										Select
									</option>
									<option value='male'>
										Male
									</option>
									<option value='female'>
										Female
									</option>
								</SelectBox>
							</InputsSections>
							<div className='account-section-btn'>
								<Button
									disabled={isProfileValid}
									style={{
										justifyContent: 'center'
									}}
									label="Update"
									onClick={profileHandleOnSubmit}
								/>
							</div>
						</form>
					</div>

					<div className='account-content'>
						<h1 className='account_form--heading'>Password</h1>
						<form onSubmit={pwdsHandleOnSubmit}>
							<InputsSections>
								<InputBox
									id="password"
									type='password'
									name='password'
									placeholder='Ex. John@doe123-@#$_&'
									errorMessage={pwdErrors && pwdErrors.password && pwdDirty.password && pwdErrors.password}
									value={password}
									onChange={pwdsHandleOnChange}
									label='New Password'
									icon='key'
								/>

								<InputBox
									id="confirmPassword"
									type='password'
									name='confirmPassword'
									placeholder='Ex. John@doe123-@#$_&'
									errorMessage={pwdErrors && pwdErrors.confirmPassword && pwdDirty.confirmPassword && pwdErrors.confirmPassword}
									value={confirmPassword}
									onChange={pwdsHandleOnChange}
									label='Confirm Password'
									icon='key'
								/>
							</InputsSections>
							<InputsSections>
								{pwdMsgErrors && <p style={{ color: '#D35959' }}>{pwdMsgErrors}</p>}
							</InputsSections>
							<div className='account-section-btn'>
								<Button
									disabled={pwdDisable || pwdErrors.confirmPassword.length > 0}
									style={{
										justifyContent: 'center'
									}}
									label="Update"
									onClick={pwdsHandleOnSubmit}
								/>
							</div>
						</form>
					</div>


					<div className='account-content'>
						<h1 className='account_form--heading'>Primary Address</h1>
						{
							shippingAddress &&
								shippingAddress.length > 0 ? (
								shippingAddress.map((item, index) =>
									<form key={index}>
										{
											index == 1 &&
											<h1 className='account_form--heading'>All Addresses</h1>
										}
										<InputsSections>
											<InputBox
												id="firstName"
												type='text'
												placeholder='Ex. John'
												value={item.firstName}
												onChange={e => {
													handleChange(e, index, item)
												}}
												errorMessage={item.firstNameError}
												label='First Name'
												icon='user'
											/>

											<InputBox
												id="lastName"
												type='text'
												placeholder='Ex. Doe'
												value={item.lastName}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.lastNameError}
												label='Last Name'
												icon='user'
											/>
										</InputsSections>

										<InputsSections>
											<InputBox
												id="address"
												type='text'
												placeholder='Ex. 123 Stress Free Lane'
												value={item.address}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.addressError}
												label='Street Address'
												icon='pin'
												maxLength="150"
											/>
										</InputsSections>

										<InputsSections>
											<InputBox
												id="appartment"
												type='text'
												placeholder='Ex. Apt 456'
												value={item.appartment}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.appartmentError}
												label='C/O , Apt, Suite, Building, Floor'
												icon='pin'
												maxLength="150"
											/>
										</InputsSections>

										<InputsSections>
											<InputBox
												id="city"
												type='text'
												placeholder='Ex. San Diego'
												value={item.city}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.cityError}
												label='City'
												icon={false}
											/>
											<SelectBox
												value={item.state}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.stateError}
												icon={false}
												label='State'
												name='state'
												id="state"
												containerStyle={{ width: '100%' }}
												textBoxStyle={{ width: '100%' }}
											>
												{
													stateList &&
													stateList.length > 0 &&
													stateList.map((type, index) => (
														<option value={type.name} key={index + 1}>
															{type.name}
														</option>
													))}
											</SelectBox>

											<InputBox
												id="zip"
												type='text'
												placeholder='Ex. 00000'
												value={item.zip}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.zipError}
												maxLength="6"
												label='Postal Code'
												icon={false}
											/>
										</InputsSections>

										<InputsSections>
											<InputBox
												id="phone"
												name='phone'
												type='text'
												placeholder='Ex. (123) 456 - 7890'
												value={inputPhoneMasking(item.phone)}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.phoneError}
												label='Phone'
												icon='phone'
											/>

											<InputBox
												id="company"
												name="company"
												type='text'
												placeholder='N/A'
												value={item.company}
												onChange={e => handleChange(e, index, item)}
												errorMessage={item.companyError}
												label='Company'
												icon={false}
											/>
										</InputsSections>
										<div className='account-section-btn'>
											<Button
												disabled={item.isAddressValid}
												style={{
													justifyContent: 'center'
												}}
												label="Update"
												onClick={(e) => updateShippingAddressHandler(e, item)}
											/>
											{
												item &&
												shippingAddress.length > 1 &&
												JSON.stringify(item.isPrimary) === 'false' &&
												<Button
													varient="danger"
													style={{
														justifyContent: 'center'
													}}
													label="Delete"
													onClick={(e) => deleteShippingAddressHandler(e, item)}
												/>
											}
										</div>
									</form>
								)) :
								(
									<></>
									// <NoData
									// 	title='No Data Found!'
									// 	subTitle='We could not find any Primary Shipping Address. please add new address'
									// 	height='40vh'
									// />
								)
						}
					</div>

					{/* <div className='account-content'>
						<p className='account_form--heading2'>You don't have any additional remembered addresses on file.</p>
						<div className='account-section-btn big-btn'>
							<Button
								disabled={shippingAddress.length >= 3}
								style={{
									justifyContent: 'center'
								}}
								label="New Address"
								onClick={() => setShowAddNewAddressModal(true)}
							/>
						</div>
					</div> */}

					{/* <div className='account-content'>
						<h1 className='account_form--heading'>Primary Payment Method</h1>
						{
							paymentMethods &&
								paymentMethods.length > 0 ? (
								paymentMethods.map((item, index) =>
									<form key={index}>
										{
											index == 1 &&
											<h1 className='account_form--heading'>All Payment Methods</h1>
										}
										<div className="account_form--heading3">
											Accepted Cards: <img src='./assets/accept_card.png' width='80vh' alt='stresskase payment' />
										</div>
										<div>
											<InputsSections>
												<InputBox
													id="cardNumber"
													type='text'
													placeholder='0001 9870 8765 6751'
													value={'XXXX XXXX XXXX ' + item.cardNo}
													onChange={e => paymentHandleOnChange(e, index, item)}
													errorMessage={item.cardNoError}
													label='Card Number'
													icon='user'
													disabled={true}
												/>
												<InputBox
													id="expirationDate"
													type='text'
													placeholder='MM/YYYY'
													value={item.expirationDate}
													onChange={e => {
														e.target.value = inputDateMasking(e.target.value)
														paymentHandleOnChange(e, index, item)
													}}
													errorMessage={item.expirationDateError}
													label='Expiration Date'
													icon='user'
												/>
											</InputsSections>
											<InputsSections>
												<InputBox
													id="nameOnCard"
													type='text'
													placeholder='Ex. John Doe'
													value={item.nameOnCard}
													onChange={e => paymentHandleOnChange(e, index, item)}
													errorMessage={item.nameOnCardError}
													disabled={true}
													label='Name on Card'
													icon='user'
												/>
											</InputsSections>
										</div>
										<h1 className='account_form--heading'>Billing Address</h1>

										<InputsSections>
											<InputBox
												id="firstName"
												type='text'
												placeholder='Ex. John'
												value={item.firstName}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.firstNameError}
												label='First Name'
												icon='user'
											/>

											<InputBox
												id="lastName"
												type='text'
												placeholder='Ex. Doe'
												value={item.lastName}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.lastNameError}
												label='Last Name'
												icon='user'
											/>
										</InputsSections>
										<InputsSections>
											<InputBox
												id="address"
												type='text'
												placeholder='Ex. 123 Stress Free Lane'
												value={item.address}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.addressError}
												label='Street Address'
												icon='pin'
												maxLength="150"
											/>
										</InputsSections>

										<InputsSections>
											<InputBox
												id="appartment"
												type='text'
												placeholder='Ex. Apt 456'
												value={item.appartment}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.appartmentError}
												label='C/O , Apt, Suite, Building, Floor'
												icon='pin'
												maxLength="150"
											/>
										</InputsSections>
										<InputsSections>
											<InputBox
												id="city"
												type='text'
												placeholder='Ex. San Diego'
												value={item.city}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.cityError}
												label='City'
												icon={false}
											/>
											<SelectBox
												value={item.state}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.stateError}
												icon={false}
												label='State'
												name='state'
												id="state"
												containerStyle={{ width: '100%' }}
												textBoxStyle={{ width: '100%' }}
											>
												{
													stateList &&
													stateList.length > 0 &&
													stateList.map((type, index) => (
														<option value={type.name} key={index + 1}>
															{type.name}
														</option>
													))}
											</SelectBox>

											<InputBox
												id="zip"
												type='text'
												placeholder='Ex. 00000'
												value={item.zip}
												onChange={e => paymentHandleOnChange(e, index, item)}
												errorMessage={item.zipError}
												label='Postal Code'
												icon={false}
												maxLength="6"
											/>
										</InputsSections>
										<InputsSections>
										<InputBox
											id="phone"
											name='phone'
											type='text'
											placeholder='Ex. (123) 456 - 7890'
											value={inputPhoneMasking(item.phone)}
											onChange={e => handleChange(e, index, item)}
											errorMessage={item.phoneError}
											label='Phone'
											icon='phone'
										/>

										<InputBox
											id="company"
											name="company"
											type='text'
											placeholder='N/A'
											value={item.company}
											onChange={e => handleChange(e, index, item)}
											errorMessage={item.companyError}
											label='Company'
											icon={false}
										/>
									</InputsSections>


										<div className='account-section-btn'>
											<Button
												disabled={item.isPaymentValid}
												style={{
													justifyContent: 'center'
												}}
												label="Update"
												onClick={(e) => updatePaymentMethodHandler(e, item)}
											/>
											{
												item &&
												paymentMethods.length > 1 &&
												JSON.stringify(item.isPrimary) === 'false' &&
												<Button
													varient="danger"
													style={{
														justifyContent: 'center'
													}}
													label="Delete"
													onClick={(e) => deletePaymentMethodHandler(e, item)}
												/>
											}
										</div>
									</form>
								)) :
								(
									<></>
									// <NoData
									// 	title='No Data Found!'
									// 	subTitle='We could not find any Primary Billing Address. please add new Billing Address'
									// 	height='40vh'
									// />
								)
						}
					</div> */}

					{/* <div className='account-content'>
						<p className='account_form--heading2'>You don't have any additional payment methods on file.</p>
						<div className='account-section-btn big-btn'>
							<Button
								disabled={paymentMethods.length >= 3}
								style={{
									justifyContent: 'center',
									width: '230px'
								}}
								label="Add Payment Method"
								onClick={() => setShowAddNewPaymentModal(true)}
							/>
						</div>
					</div> */}
				</div >

				<CreateEditAddress
					data={{
						showAddModal: showAddNewAddressModal,
						handleAddModalClose: handleAddAddressModalClose,
						handleOnChange: newShippingAddrHandleOnChange,
						handleSubmit: newShippingAddrHandleOnSubmit,
						shippingAddress: newShippingAddrValues,
						setValues: setShippingAddrValues,
						errors: newShippingAddrErrors,
						dirty: newShippingAddrDirty,
						disable: newShippingAddrDisable,
						stateList
					}}
				/>

				<CreateEditPayment
					data={{
						showAddModal: showAddNewPaymentModal,
						handleAddModalClose: handleAddPaymentModalClose,
						// handleSubmit: newPaymentMethodHandleOnSubmit, 
						// handleOnChange: newPaymentMethodHandleOnChange,
						// newPaymentMethod: newPaymentMethodValues,
						// setValues: setPaymentMethodValues,
						// errors: newPaymentMethodErrors,
						// dirty: newPaymentMethodDirty,
						// disable: newPaymentMethodDisable, 
						getStateListHandler,
						stateList,
						newPaymentMethodSchema
					}}
				/>

			</Content >
		</Elements >
	);
};

export default AccountScreen;



