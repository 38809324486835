import React from 'react';
import Modal from '../../components/Modal';
import ModalForm from '../../components/Modal/ModalForm';
import InputBox from '../../components/formInputs/InputBox';
import SelectBox from '../../components/formInputs/SelectBox';
import InputsSections from '../../components/Modal/InputSections';
import CheckBox from '../../components/formInputs/CheckBox';
import InputsSection from '../../components/Modal/InputsSection';
import { inputPhoneMasking } from '../../utils/phonemask';
import Button from '../../components/formInputs/Button';

const CreateEditAddress = ({ data }) => {
	const {
		showAddModal,
		handleAddModalClose,
		handleSubmit,
		handleOnChange,
		shippingAddress,
		setValues, 
		stateList,
		errors,
		dirty,
		disable
	} = data;


	const {
		firstName,
		lastName,
		address,
		appartment,
		city,
		state,
		zip,
		phone,
		company,
		isPrimary,
	} = shippingAddress;


	React.useEffect(
		() => {
			if (state && stateList.length > 0) {
				setValues({ ...shippingAddress, state_code: stateList.find(o => o.name === state).state_code })
			}
		},
		[state, stateList]
	);

	return (
		<Modal show={showAddModal}>
			<div className='modal__heading--container'>
				<h2 className='modal__heading2'>Add Shipping Address</h2>
				<button onClick={handleAddModalClose} className='modal__heading--btn'>
					<svg className='modal__heading--icon modal__heading-close-icon'>
						<use xlinkHref={`/assets/sprite.svg#icon-close`} />
					</svg>
				</button>
			</div>


			<ModalForm
				onSubmit={handleSubmit}
				style={{ marginBottom: '1rem' }}
			>
				<div className="modal_container">
					<InputsSections style={{ flexDirection: 'row' }}>
						<InputBox
							id="firstName"
							type='text'
							placeholder='Ex. John'
							name='firstName'
							errorMessage={errors && errors.firstName && dirty.firstName && errors.firstName}
							value={firstName}
							onChange={handleOnChange}
							label='First Name'
							icon='user'
						/>

						<InputBox
							id="lastName"
							type='text'
							placeholder='Ex. Doe'
							name='lastName'
							errorMessage={errors && errors.lastName && dirty.lastName && errors.lastName}
							value={lastName}
							onChange={handleOnChange}
							label='Last Name'
							icon='user'
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="address"
							type='text'
							placeholder='Ex. 123 Stress Free Lane'
							name='address'
							errorMessage={errors && errors.address && dirty.address && errors.address}
							value={address}
							onChange={handleOnChange}
							maxLength={150}
							label='Street Address'
							icon='pin'
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="appartment"
							type='text'
							placeholder='Ex. Apt 456'
							name='appartment'
							errorMessage={errors && errors.appartment && dirty.appartment && errors.appartment}
							value={appartment}
							onChange={handleOnChange}
							label='C/O , Apt, Suite, Building, Floor'
							icon='pin'
							maxLength={150}
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="city"
							type='text'
							placeholder='Ex. San Diego'
							errorMessage={errors && errors.city && dirty.city && errors.city}
							value={city}
							onChange={handleOnChange}
							label='City'
							name='city'
							icon={false}
						/>

						<SelectBox
							errorMessage={errors && errors.state && dirty.state && errors.state}
							value={state}
							onChange={handleOnChange}
							icon={false}
							label='State'
							name='state'
							containerStyle={{ width: '100%' }}
							textBoxStyle={{ width: '100%' }}
						>
							<option value='' >Select State</option>
							{
								stateList &&
								stateList.length > 0 &&
								stateList.map((item, index) => (
									<option value={item.name} key={index + 1}>
										{item.name}
									</option>
								))}
						</SelectBox>

						<InputBox
							id="zip"
							type='text'
							placeholder='Ex. 92101'
							name='zip'
							errorMessage={errors && errors.zip && dirty.zip && errors.zip}
							value={zip}
							onChange={handleOnChange}
							label='Postal Code'
							maxLength={6}
							icon={false}
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="phone"
							type='text'
							placeholder='Ex. (123) 456 - 7890'
							name='phone'
							errorMessage={errors && errors.phone && dirty.phone && errors.phone}
							value={inputPhoneMasking(phone)}
							onChange={(event) => {
								event.target.value = inputPhoneMasking(event.target.value)
								handleOnChange(event);
							}}
							label='Phone'
							icon='phone'
						/>

						<InputBox
							id="company"
							type='text'
							placeholder='N/A'
							name='company'
							errorMessage={errors && errors.company && dirty.company && errors.company}
							value={company}
							onChange={handleOnChange}
							label='Company'
							icon={false}
						/>
					</InputsSections>
				</div>

				<div className="modal_container_footer">
					<InputsSection>
						<CheckBox
							id="isPrimary"
							type='checkbox'
							name='isPrimary'
							style={{ gap: '1.8rem' }}
							checked={isPrimary}
							onChange={(event) => setValues({ ...shippingAddress, isPrimary: !isPrimary })}
							errorMessage={false}
							label='Set as default shipping address'
							icon='key'
						/>
					</InputsSection>
					<Button
						disabled={disable}
						style={{
							justifyContent: 'center',
							width: '100%'
						}}
						label='Save'
						onClick={handleSubmit}
					/>
				</div>
			</ModalForm>
		</Modal>
	);
};

export default CreateEditAddress;
