import React from 'react';
import styles from './CheckBox.module.css';

const CheckBox = ({ name, label, onChange, checked, style }) => {
	return (
		<div className={styles.container} style={style}>
			<input type='checkbox' id={name} name={name} className={styles.checkBox} onChange={onChange} checked={checked} />
			<label htmlFor={name}>{label}</label>
		</div>
	);
};

export default CheckBox;
