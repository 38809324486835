

import React from 'react';
import OrderHistoryDetailCardStyles from './OrderHistoryDetailCardStyles.module.css';

const OrderConfirmDetail = ({
	data,
}) => {
	const billing_Address = data && data.payment && data.payment.charges && data.payment.charges.data[0].billing_details;

	return (
		<div className='order-history-detail-box' style={{ border: '1px solid #d9d9d9', borderRadius: '1rem', backgroundColor: 'white' }}>
			<div className={OrderHistoryDetailCardStyles.column}>
				<div className={OrderHistoryDetailCardStyles.column}>
					<div className='order-history-detail-box-ship' >
						<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
							<span >
								Customer Information<br />
							</span>
							{data.order && data.order.contact_email ? data.order.contact_email : data.order.email}
						</h1>

						<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>

						</h1>
					</div>
					<div className='order-history-detail-box-address'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryDetailsCard}>
							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span > Billing Address </span>
								<br />
								{billing_Address && billing_Address.address && billing_Address.name}
								{billing_Address && billing_Address.address && billing_Address.address.line1} ,
								{billing_Address && billing_Address.address && billing_Address.address.line2}  <br />
								{billing_Address && billing_Address.address && billing_Address.address.city},<br />
								{billing_Address && billing_Address.address && billing_Address.address.country},
								{billing_Address && billing_Address.address && billing_Address.address.state},
								{billing_Address && billing_Address.address && billing_Address.address.postal_code}<br />
								{/* {billing_Address && billing_Address.company},<br /> */}
								{/* {billing_Address && inputPhoneMasking(billing_Address.phone)}, */}
							</h1>

							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >Payment Method  </span> <br />
								{data.payment.charges.data[0].calculated_statement_descriptor}   <br />
								{data.payment.charges.data[0].payment_method_details.type}  <br />
								************{data.payment.charges.data[0].payment_method_details.card.last4} <br />
								{/* 
								${data.current_subtotal_price} */}
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderConfirmDetail;
