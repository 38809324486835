import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import { InputCustomBox } from "../../components/formInputs/InputBox";
import "./ActivateKitScreen.css";
import { useNavigate } from "react-router-dom";
import {
  myActiviteKitAction,
  validateActiviteKitAction,
} from "../../redux/actions/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { REGISTER_KIT_RESET } from "../../redux/constants/userConstants";
import FormValidation from "../../utils/formValidation";
import { stateValidatorSchema } from "../../utils/validators";
import Spinner from "../../components/Spinner/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const ActivateKitScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const activiteKit = useSelector((state) => state.activiteKit);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading } = activiteKit;
  const [activiteKitLoader, setActiviteKitLoader] = React.useState(false);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
    } else {
      history("/activate-your-kit");
    }
  }, [userLogin, history]);

  // Acitiviate kit Password form
  const userSchema = {
    activiteKitId: { value: "", error: "" },
  };

  const handleSubmit = async (state) => {
    console.log("kitId: ", state.activiteKitId);
    setActiviteKitLoader(true);
    let data = await dispatch(validateActiviteKitAction(state.activiteKitId));
    if (data.data === "Valid") {
      await activiteKitHandler(state.activiteKitId);
    } else {
      setActiviteKitLoader(false);
      toast.error(data.message, {
        icon: "✕",
      });
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setErrors,
  } = FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  console.log(values);

  const { activiteKitId } = values;

  const activiteKitHandler = React.useCallback(
    async (activiteKitId) => {
      let data = await dispatch(myActiviteKitAction({ kitId: activiteKitId }));
      if (data.status == 200) {
        toast.success(data.data.message, {
          icon: "✔",
        });
        setActiviteKitLoader(false);
        history("/");
      } else {
        setActiviteKitLoader(false);
        setErrors({ ...errors, activiteKitId: data.data.message });
        toast.error(data.data.message, {
          icon: "✕",
        });
      }
    },
    [history]
  );

  React.useEffect(() => {
    if (activiteKit && activiteKit.userInfo && activiteKit.userInfo.data) {
      toast.success(activiteKit.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: REGISTER_KIT_RESET });
      history("/");
    } else if (
      activiteKit &&
      activiteKit.userInfo &&
      !activiteKit.userInfo.data
    ) {
      dispatch({ type: REGISTER_KIT_RESET });
      toast.error(activiteKit.userInfo.message, {
        icon: "✕",
      });
    }
  }, [activiteKit, dispatch, history]);

  return (
    <>
      <div className="main-container" style={{ height: "90vh" }}>
        {loading || (activiteKitLoader && <Spinner />)}
        <div className="activate_kit_container">
          <div className="activate_kit_container__form">
            <div>
              <h1 className="activate_kit_container__heading">
                Activate Your Kit
              </h1>
            </div>
            <form onSubmit={handleOnSubmit} autocomplete="off" style={{ width: "100%" }}>
              <input autocomplete="false" name="hidden" type="text" style={{"display":"none"}}/>
              <InputCustomBox
                id="activiteKitId"
                type="text"
                name="activiteKitId"
                placeholder="Kit ID Number"
                value={activiteKitId}
                onChange={handleOnChange}
                errorMessage={
                  errors &&
                  errors.activiteKitId &&
                  dirty.activiteKitId &&
                  errors.activiteKitId
                }
                label={false}
                icon={false}
              />

              <div className="activity-description">
                This ID number is found in your stresskase order email and also
                inside your kit.
              </div>

              <Button
                disabled={disable}
                style={{ justifyContent: "center", width: "100%" }}
                label="Next"
                icon={false}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="activate_kit__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="activate_kit_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="activate_kit__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            {/* {new Date(Date.now()).getFullYear()}. */}
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ActivateKitScreen;
