import React from 'react';
import TextBox from './TextBox.module.css';

const TextareaBox = ({ placeholder, value, onChange, errorMessage, label, icon, style, rows }) => {
	return (
		<div className={TextBox.form_input} style={{ width: '100%', height: '100px', margin: '0px 3rem' }}>
			{label && (
				<label htmlFor={label} className={TextBox.form_input__label}>
					{label}
				</label>
			)}
			<div className={TextBox.form_input__container} style={style}>
				<textarea
					rows={rows ? rows : '2'}
					id={label}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className={TextBox.form_input__box}
				/>
			</div>
			<div style={{ height: '1rem' }}>
				{errorMessage && <p className={TextBox.form_input__error}>{errorMessage}</p>}
			</div>
		</div>
	);
};

export default TextareaBox;
