import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import Footer from '../Footers/Footer';
import { getUserByIdAction } from '../../redux/actions/userActions';
import WebHeader from '../WebHeader/Header';
import Breadcrumbs from '../../navigation/Breadcrumbs';
import { generateAppRoutes } from '../../navigation/utilBreadcrumbs';
import './content.css';
import ActivitySkeleton from '../Skeletons/HeaderSkeleton';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Content = ({ children, currentMenu, navigationRoute }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);

	React.useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
				var decoded = jwt_decode(userLogin.userInfo.data);
				dispatch(getUserByIdAction(decoded._id));
			} else {
				history('/login');
			}
		},
		[userLogin, dispatch, history]
	);

	return (
		<React.Fragment>
			<main className='main-container'>
				<WebHeader data-testid='WebHeader' />
				{/* <hr width="95%;" color="#D1D1D1" size="1" /> */}
				<div className='mainContent'>
					<div className='insidecontent'>
						<div className='nav-header_container'>
							<div className='nav-header'>
								<div className='nav-header-heading' style={{ fontSize: '4rem', lineHeight: '5.3rem', fontWeight: '500' }}>
									{' '}
									Hi,
									{userLogin && !userLogin.loading ? userLogin && userLogin.userInfo && userLogin.userInfo.data ? (
										<span style={{ textTransform: 'capitalize', fontSize: '4rem', color: '#4B4B4B', fontWeight: '500' }}>
											{' '}
											{jwt_decode(userLogin.userInfo.data).firstname} {jwt_decode(userLogin.userInfo.data).lastname}
										</span>
									) : (
										<Fragment />
									) : (
										<Fragment>
											<ActivitySkeleton />
										</Fragment>
									)}
								</div>
								{navigationRoute &&
									navigationRoute.length > 0 && (
										<Breadcrumbs data-testid='Breadcrumbs' route={generateAppRoutes(navigationRoute)[0]} />
									)}
							</div>
						</div>
						<div className='content'>
							<Sidebar data-testid='Sidebar' current={currentMenu} />
							{children}
						</div>
					</div>
					{/* <hr width="95%;" color="#D1D1D1" size="1" /> */}
					<Footer data-testid='Footer' />
				</div>
			</main>
		</React.Fragment>
	);
};

export default Content;

