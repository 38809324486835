import axios from "axios";
import { BASE_URL } from "./ip";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_RESET,
  POST_ACTIVATION_KITS_REQUEST,
  POST_ACTIVATION_KITS_SUCCESS,
  POST_ACTIVATION_KITS_FAIL,
  REGISTER_KIT_REQUEST,
  REGISTER_KIT_SUCCESS,
  REGISTER_KIT_FAIL,
  CHANGE_PWD_REQUEST,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  ADD_USER_ADDRESS_REQUEST,
  ADD_USER_ADDRESS_SUCCESS,
  ADD_USER_ADDRESS_FAIL,
  GET_USER_ADDRESS_REQUEST,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  GET_ACTIVATION_KITS_STATUS_SUCCESS,
  GET_ACTIVATION_KITS_STATUS_FAIL,
  GET_ACTIVATION_KITS_STATUS_REQUEST,
  GET_ACTIVATION_KITS_STATUS_RESET,
  UPDATE_USER_ADDRESS_REQUEST,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS_REQUEST,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ADD_BILLING_ADDRESS_REQUEST,
  ADD_BILLING_ADDRESS_SUCCESS,
  ADD_BILLING_ADDRESS_FAIL,
  GET_BILLING_ADDRESS_REQUEST,
  GET_BILLING_ADDRESS_SUCCESS,
  GET_BILLING_ADDRESS_FAIL,
  DELETE_BILLING_ADDRESS_REQUEST,
  DELETE_BILLING_ADDRESS_SUCCESS,
  DELETE_BILLING_ADDRESS_FAIL,
  UPDATE_BILLING_ADDRESS_FAIL,
  UPDATE_BILLING_ADDRESS_SUCCESS,
  UPDATE_BILLING_ADDRESS_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_RESET,
  SUBSCRIBE_NEWSLETTER_REQUEST,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAIL,
} from "../constants/userConstants";
import {
  GET_USER_BY_CART_COUNT_RESET,
  GET_USER_BY_CART_RESET,
} from "../constants/productConstants";

export const login =
  (email, password, isRemember = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/user/login`,
        { email, password, isRemember },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userToken", data.data);
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (err) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const forgotPasswordAction = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data, status } = await axios.post(
      `${BASE_URL}/user/forgot-password`,
      { email },
      config
    );

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
      status: status,
    });
  } catch (err) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getUserByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_BY_ID_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${BASE_URL}/user/${id}`, config);

    dispatch({
      type: GET_USER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_USER_BY_ID_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getActiviteKitAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: POST_ACTIVATION_KITS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.patch(
        `${BASE_URL}/user/activate-kit-id`,
        formData,
        config
      );

      dispatch({
        type: POST_ACTIVATION_KITS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: POST_ACTIVATION_KITS_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const validateActiviteKitAction = (id) => async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/zrt/verify/kit/${id}`,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const myActiviteKitAction = (formData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const data = await axios.post(
      `${BASE_URL}/kit/activate-kit`,
      formData,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const gettActiviteKitStatusAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ACTIVATION_KITS_STATUS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/kit/kit-activation-status`,
      config
    );

    dispatch({
      type: GET_ACTIVATION_KITS_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_ACTIVATION_KITS_STATUS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const registerAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_KIT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`${BASE_URL}/user`, formData, config);

    dispatch({
      type: REGISTER_KIT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: REGISTER_KIT_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const registerWithWebAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`${BASE_URL}/user`, formData, config);

    const dataObj = {
      data: data.token,
      message: data.message,
    };

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: dataObj,
    });

    localStorage.setItem("userToken", dataObj.data);
    localStorage.setItem("userInfo", JSON.stringify(dataObj));
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const registerActivationKitAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}/user/register-with-activation-id?timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      formData,
      config
    );

    const dataObj = {
      data: data.data,
      message: data.message,
    };

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: dataObj,
    });

    localStorage.setItem("userToken", dataObj.data);
    localStorage.setItem("userInfo", JSON.stringify(dataObj));
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }

  // try {
  // 	dispatch({ type: REGISTER_KIT_REQUEST });

  // 	const config = {
  // 		headers: {
  // 			'Content-Type': 'application/json'
  // 		}
  // 	};

  // 	const { data } = await axios.post(`${BASE_URL}/user/register-with-activation-id?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

  // 	// localStorage.setItem('userInfo', JSON.stringify(data));

  // 	dispatch({
  // 		type: REGISTER_KIT_SUCCESS,
  // 		payload: data
  // 	});
  // } catch (err) {
  // 	dispatch({
  // 		type: REGISTER_KIT_FAIL,
  // 		payload: err.response && err.response.data ? err.response.data : err.message
  // 	});
  // }
};

export const changePasswordAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHANGE_PWD_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/user/change-password`,
        formData,
        config
      );

      dispatch({
        type: CHANGE_PWD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: CHANGE_PWD_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateUserAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${BASE_URL}/user?timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getAdressAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${BASE_URL}/address`, config);

    dispatch({
      type: GET_USER_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_USER_ADDRESS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const addAdressAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_USER_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}/address?timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      formData,
      config
    );

    dispatch({
      type: ADD_USER_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: ADD_USER_ADDRESS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const updateAdressAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_USER_ADDRESS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/address?id=${id}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_USER_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: UPDATE_USER_ADDRESS_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const removeAdressAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_USER_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(`${BASE_URL}/address?id=${id}`, config);

    dispatch({
      type: DELETE_USER_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: DELETE_USER_ADDRESS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getCountryAction = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${BASE_URL}/country`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const getStateByCountryAction =
  (country = "") =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/country/state?country=${country}`,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data
        ? err.response.data
        : err.message;
    }
  };

export const resetPasswordAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data, status } = await axios.post(
      `${BASE_URL}/user/set-reset-password`,
      formData,
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
      status: status,
    });
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const addPaymentMethodAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_BILLING_ADDRESS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/payment-method/attach`,
        formData,
        config
      );

      dispatch({
        type: ADD_BILLING_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADD_BILLING_ADDRESS_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const getPaymentMethodAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_BILLING_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`${BASE_URL}/payment-method`, config);
    //?authorizeNetCustomerID=${id}
    dispatch({
      type: GET_BILLING_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_BILLING_ADDRESS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const validatePromoCodeStripeAction = (code) => async (dispatch) => {
  try {
    // const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        // Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/payment-method/validate-promo-code?code=${code}`,
      config
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const validatePromoCodeAction = (code) => async (dispatch) => {
  try {
    // const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        // Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/shopify/discount-code/code?code=${code}`,
      config
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const updatePaymentMethodAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_BILLING_ADDRESS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/payment-method`,
        formData,
        config
      );
      // ?customerPaymentProfileId=${id}

      dispatch({
        type: UPDATE_BILLING_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: UPDATE_BILLING_ADDRESS_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const removePaymentMethodAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_BILLING_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}/payment-method/detach`,
      { id: id },
      config
    );

    dispatch({
      type: DELETE_BILLING_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: DELETE_BILLING_ADDRESS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getNotificationsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/notification/for-user`,
      config
    );

    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_NOTIFICATIONS_RESET,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const readNotificationAction = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.patch(
      `${BASE_URL}/notification/${id}/read`,
      {},
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const subcribeNewsLetterAction = (email) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_NEWSLETTER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/20464119/e72cd1bb-882d-470d-81ad-81c7cdd63b4d`,
      {
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: email,
          },
        ],
        context: {
          pageUri: window.location.href,
          pageName: window.location.pathname,
        },
      },
      config
    );

    dispatch({
      type: SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: SUBSCRIBE_NEWSLETTER_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("activiteKitId");
  localStorage.removeItem("products");
  localStorage.removeItem("cart");
  localStorage.removeItem("checkOutList");
  localStorage.clear();
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: GET_USER_BY_ID_RESET });
  dispatch({ type: GET_USER_BY_CART_RESET });
  dispatch({ type: GET_USER_BY_CART_COUNT_RESET });
  dispatch({ type: GET_ACTIVATION_KITS_STATUS_RESET });
  if (window.location.hash !== "#/mini-cart") {
    window.open(window.location.origin, "_self");
  }
};

export const getUserInfo = async (email) => {
  try {
    const userInfo = await axios.get(`${BASE_URL}/user/user-info/${email}`);
    return userInfo.data;
  } catch (err) {
    console.log(err);
  }
};

export const getStateByCountry = async (country = "US") => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/country/state?country=${country}`
    );
    return data.data;
  } catch (err) {
    console.log(err);
  }
};
