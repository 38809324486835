import React from "react";
import Content from "../../components/Content";
import "./RecommendationsScreen.css";

import styles from "./RecommendationsScreen.module.css";

import Button, { ButtonPrefix } from "../../components/formInputs/Button";

const ASMScreen = () => {
  // const history = useNavigate();

  const printHandler = () => console.log("Printing...");
  const shareOpenHandler = () => console.log("Sharing...");
  const downloadHandler = () => console.log("Downloading...");

  return (
    <Content
      currentMenu="RecommendationsSupplements"
      headerTitle="Recommendations + Supplements"
      navigationRoute={[
        {
          path: "/",
          label: "Dashboard",
          routes: [
            {
              path: "/recommendations-supplements",
              label: "Recommendations + Supplements",
            },
          ],
        },
      ]}
      addBtn={false}
      addBtnText="Add New User"
      addBtnIcon="plus"
      addBtnClick={() => {}}
      search={true}
      searchPlaceholder="Search Users..."
      searchIcon="search"
      searchvalue={"search"}
      searchOnChange={""}
    >
      <div className="recommendations-container" style={{ marginTop: 30 }}>
        {/* {renderActions()} */}
        <div className="asmscreen-content">
          <h1 className="recommendations-heading1" style={{ fontSize: 28 }}>
            General Lifestyle Recommendations
          </h1>
          <p className="recommendations-subheading2">
            Below you will find general lifestyle recommendations that are here
            to assist in your journey of stress managment. There are five
            sections: Sleep, Exercise, Nutrition, Detox and Recharge.
          </p>

          <h1 className="recommendations-heading2">Sleep</h1>
          <p className="recommendations-subheading2">
            High evening cortisol not only sabotages the quantity of sleep, but
            even more importantly, it keeps you from getting the stages of sleep
            that are required for recovery and repair of the body and brain. It
            is difficult to improve other areas of your health because the body
            feels it is in crisis. During sleep the brain recovers in ways that
            it can’t during the day. The brain’s lymphatic system is turned on
            helping to clear all the waste products of metabolism. Thinking
            takes a lot of energy. Creating and using energy also produces waste
            products. Our ability to think and function mentally is directly
            related to how well this cleaning process happens at night. Deep
            sleep is mainly the time this process happens. REM sleep (or rapid
            eye movement sleep) is the time of sleep when we dream. A third of
            our sleep each night needs to be made up of a combination of deep
            sleep and REM sleep. When we don’t get the quality sleep we need,
            cortisol levels rise in response to our bodies lack of recovery.
          </p>

          <h1 className="recommendations-heading2">Tips for Sleep:</h1>

          <ul className="collection_popup_decimal recommendations-subheading2">
            <li>
              Avoid eating large meals and large quantities of liquid before
              bedtime. It is best to cut off all calories 3 hours before bed.
            </li>
            <li>
              This allows your body to repair and recover instead of having to
              digest and process food.
            </li>
            <li>
              Minimize caffeine intake and avoid both caffeine and alcohol 4-5
              hours before bedtime.
            </li>
            <li>
              Avoid intense exercise 4-5 hours before the time you go to sleep.
            </li>
            <li>
              Follow a regular sleep wake schedule each day and try to maintain
              this schedule even on weekends and while traveling the best you
              can.
            </li>
            <li>
              Minimize light in your bedroom from windows, alarm clocks, night
              lights, TV, laptops, tablets and cell phones. Light disrupts our
              brain’s natural circadian rhythm. When the sun goes down,
              melatonin levels rise, and with first morning sun, melatonin
              levels fall.
            </li>
            <li>
              Minimize sound in your bedroom from pets, music, and other
              disturbances. Use earplugs or white noise like a fan or air filter
              if sound cannot be minimized.
            </li>
            <li>
              Maintain a moderate temperature and good ventilation in your
              bedroom.
            </li>
            <li>
              If you cannot fall asleep, go to another room and start a quiet
              and not too engaging activity. Return to bed only when you feel
              sleepy
            </li>
            <li>
              If you have trouble breathing through your nose or you snore,
              address common allergies (dust, mold, pets). Nasal strips and air
              filters may be helpful.
            </li>
          </ul>

          <p className="recommendations-subheading2">
            If you suspect obstructive sleep apnea (where you stop breathing
            during sleep and wake up gasping for breath) contact your doctor to
            have a sleep study done. Untreated sleep apnea leads to high blood
            pressure and drives cortisol production up leading to other
            diseases.
          </p>

          <h1 className="recommendations-heading2">Exercise </h1>
          <p className="recommendations-subheading2">
            Our bodies are meant to move each day. Exercise is perhaps the most
            effective component in managing fatigue and chronic pain. Physical
            activity prevents muscle wasting, increases mood and the ability to
            cope with stress. Physical inactivity contributes to over three
            million preventable deaths worldwide each year (that’s six percent
            of all deaths). It is the fourth leading cause of death due to
            non-communicable diseases. Exercise can also be a valuable way to
            balance our stress response. Even though our cortisol rises during
            exercise, the chemicals that are released during this time improve
            mood, increase our metabolism (making us more efficient in burning
            calories), improve our blood pressure, and helps prevent all types
            of cancer.
          </p>

          <h1 className="recommendations-heading2">Exercise Tips:</h1>
          <ul className="collection_popup_decimal recommendations-subheading2">
            <li>
              The best exercise programs combine aerobic, flexibility and
              strength training. But the MOST important thing is to just get
              started doing something.
            </li>
            <li>
              If you haven’t exercised in a while, start with a “graded”
              program. Graded means that you start easy to see what you can
              handle and then slowly build up the intensity over time. If you
              start intense exercise too early, you can expect to experience a
              discouraging increase in pain and thereby decrease the chance of
              continuing.
            </li>
            <li>
              Try to exercise daily, it is easier to just make exercise part of
              your daily routine than decide what days you will or won’t
              exercise. It is easier to be 100 percent than 90 percent
              committed.
            </li>
            <li>
              Resistance exercise improves muscle tone, increases endurance,
              decreases abdominal fat and increases testosterone levels.
            </li>
            <li>
              Flexibility exercises like yoga can reduce muscle and joint pain,
              especially pain in the shoulders, neck and back.
            </li>
          </ul>

          <h1 className="recommendations-heading2">Nutrition</h1>
          <p className="recommendations-subheading2">
            Health and energy require good nutrition. Ensuring you have a
            healthy diet is imperative for healing from sickness and avoiding
            most chronic diseases. Stress raises our cortisol levels, thereby
            inhibiting our body’s ability to digest and absorb nutrients which
            in turn causes an imbalance of bacteria in our intestines leading
            many gastrointestinal disorders. Think of the digestive track as a
            long tube that goes through your body but is not in your body. It is
            a place with more bacteria than human cells and the barrier between
            the sterile environment of the body and the toxic environment of the
            digestive track is only one cell thick. Keeping this barrier healthy
            and strong is of upmost importance.
          </p>

          <h1 className="recommendations-heading2">Nutrition Tips:</h1>

          <ul className="collection_popup_decimal recommendations-subheading2">
            <li>
              Eat a diet high in colorful (phytonutrient rich) vegetables and
              fruit. The color of the fruits and vegetables alone positively
              affect our DNA.
            </li>
            <li>
              Eat protein that is high quality - pasture raised chicken and
              eggs, grass fed dairy and red meat, turkey free of nitrites or
              preservatives.
            </li>
            <li>
              Avoid processed foods (foods that have a label of ingredients),
              sugars, baked goods and alcohol
            </li>
          </ul>

          <h1 className="recommendations-heading2">Detox</h1>
          <p className="recommendations-subheading2">
            We are exposed to many chemicals and toxins each day. In fact, we
            now know that the amount of toxins we are exposed to in 1 month is
            exponentially higher than what people were exposed to 2 generations
            ago. This is concerning since some of our organs are very sensitive
            to being damaged by the toxins in the environment. These toxins come
            from pesticides, chemicals and heavy metals in our food and water
            and even the air we breathe. These toxins pose an extra stress on
            our bodies and can lead to foggy brain, hormone abnormalities,
            anxiety, depression, sickness, and cancer. Our body is amazingly
            equipped at clearing out these toxins but there is a limit to what
            it can handle. If we cross over the threshold, exposing our bodies
            to more toxins than it can clear, the consequences can be
            detrimental to our health.
          </p>

          <h1 className="recommendations-heading2">Tips for Detoxing:</h1>
          <ul className="collection_popup_decimal recommendations-subheading2">
            <li>
              Drink 1/3 to 1/2 your body weight in ounces of clean water each
              day. Water should be clear of chemicals. You can check EWG.org to
              evaluate what is in your drinking water where you live.
            </li>
            <li>
              Sweat! Sweating rids your body of toxins. Be sure to rinse off
              soon after exercise or sauna use so that you don’t re-absorb
              toxins back into your body.
            </li>
            <li>
              Eat more fiber! Fiber not only feeds the bacteria working hard to
              maintain our health in our digestive tracks but also binds heavy
              metals and other toxins allowing us to rid our body of them. The
              average American gets 8 grams of fiber in his or her diet. We need
              a minimum of 50 grams a day.
            </li>
          </ul>

          <h1 className="recommendations-heading2">Recharging</h1>
          <p className="recommendations-subheading2">
            Managing stress is so important to our health and quality of life.
            It is of utmost importance to find ways to help your body get rid of
            stress. Having supportive people in your life and restorative
            practices will help your body and immune system stay balanced and in
            a state of health. Daily practices of mindfulness and spending time
            with people who uplift and show you loving kindness are necessary to
            recharge your mind and body.
          </p>

          <h1 className="recommendations-heading2">Tips for Recharging:</h1>
          <ul className="collection_popup_decimal recommendations-subheading2">
            <li>
              Find a stress relief technique that works for you and practice it
              every day. Some examples are: meditation, prayer, deep-breathing
              techniques, listening to relaxation/guided meditation recordings,
              singing, walking, sitting in a peaceful area, contact with pets,
              or anything that brings you joy and allows you to relax.
            </li>
            <li>
              Avoid stressful people and situations as much as possible. People
              who are negative, unsupportive, and abusive will drain you
              mentally and physically and emotionally sap your energy.
            </li>
            <li>
              Start trying to listen to your body attend to its needs when it
              needs rest. You will recover more quickly if you can recognize
              your body’s need for rest.
            </li>
            <li>
              Each day you have a finite amount of energy available. Trial and
              error will help you fine-tune that inner sense of how much energy
              you have each day and at what point you are better off stopping to
              rest.
            </li>
            <li>
              You will feel and do your best by pushing almost to the limit of
              your daily energy allowance. However, be careful to moderate your
              activity so that you stay within your daily energy allowance and
              not exceed it.
            </li>
          </ul>
        </div>
      </div>
    </Content>
  );

  function renderActions() {
    return (
      <div className="recommendations-container-top">
        <div></div>
        <div className="recommendations-actionBtn">
          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="printer"
            onClick={printHandler}
            label={"Print"}
          />

          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="share"
            onClick={shareOpenHandler}
            label={"Share"}
          />

          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="download"
            onClick={downloadHandler}
            label={"Download"}
          />
        </div>
      </div>
    );
  }
};

export default ASMScreen;
