import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Content from '../../components/Content';
import Button, { BackButton } from '../../components/formInputs/Button';
// import { getOrderDetailsAction } from '../../redux/actions/orderActions';
import OrderHistoryDetailsCard from '../../components/OrderHistoryDetailsCard';
import './OrderHistoryScreen.css';

const OrderDetailsScreen = () => {
	const history = useNavigate();
	const { id } = useParams();
	// const dispatch = useDispatch();
	const orderHistory = useSelector((state) => state.orderHistory);
	const [orderHistoryList, setOrderHistoryList] = useState([]);

	// useEffect(() => {
	// 	dispatch(getOrderDetailsAction());
	// }, [dispatch])

	useEffect(() => {
		if (orderHistory && orderHistory.data && orderHistory.data.data && orderHistory.data.data && orderHistory.data.data.orderList.length > 0) {
			const filterData = orderHistory.data.data.orderList.filter(o => o.order.order_number === JSON.parse(id));
			setOrderHistoryList(filterData);
		}
	}, [orderHistory])

	return (
		<Content
			currentMenu='OrderHistory'
			headerTitle='Order History'
			navigationRoute={[
				{
					path: "/",
					label: "Dashboard",
					routes: [
						{
							path: "/order-history",
							label: "Order History",
							routes: [
								{
									path: "/supplement-collections",
									label: "Order : " + id,
								},
							]
						},
					]
				}
			]}
			addBtn={false}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => { }}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={'search'}
			searchOnChange={''}>
			<div className='orderHistory-details-container'>
				<h6 className='orderHistory-heading'>
					Previous Order Details
					<p>View details about your previous orders here.   </p>
				</h6>

				<div className='orderHistory-content' >
					<div className='orderHistory-card'>
						{orderHistoryList &&
							orderHistoryList.length > 0 &&
							orderHistoryList && orderHistoryList.map((data, i) =>
								<OrderHistoryDetailsCard data={data} key={i} />
							)
						}
					</div>

					<div className='recommendations-section-previous-btn'>
						<BackButton
							style={{
								backgroundColor: '#fffafa00',
								border: 'none',
								color: '#4B4B4B',
								fontWeight: '500',
								fontSize: '18px',
								lineHeight: '23px',
								justifyContent: 'center',
								padding: '0.5rem'
							}}
							label="< Order History"
							onClick={() => history('/order-history')}
						/>
					</div>
				</div>  
			</div>
		</Content>
	);
};

export default OrderDetailsScreen;
