import React from 'react';
import CryptoJS from 'crypto-js';
import { Encrypt_key } from '../redux/actions/ip';
import { getProductImageAction } from '../redux/actions/productActions';

export default function For({ of, children }) {
  let child = React.Children.only(children);
  let elements = of.map((props, idx) =>
    React.cloneElement(child, {
      key: props.id ?? idx,
      ...props
    })
  );

  return React.createElement(React.Fragment, null, elements);
}


export function encryptData(encryptData) {
  const data = JSON.stringify(encryptData);
  const eData = CryptoJS.AES.encrypt(data, Encrypt_key).toString();

  // const decryptedProduct = CryptoJS.AES.decrypt(eData, Encrypt_key);
  // const decryptedString = decryptedProduct.toString(CryptoJS.enc.Utf8) 
  // console.log(decryptedString, 'decryptedString'); 
  // return decryptedString;

  return eData;
}



export function decryptData(decryptData) {
  const decryptedObj = CryptoJS.AES.decrypt(decryptData, Encrypt_key);
  const decryptedString = decryptedObj.toString(CryptoJS.enc.Utf8)
  console.log(decryptedString, 'decryptedString');
  return decryptedString;
}


export const OrderImage = async (id, dispatch) => {
  const { data } = await dispatch(getProductImageAction(id));

  if (data && data.orderImage && data.orderImage.src) {
    return data.orderImage.src;
  } else return 'https://cdn.shopify.com/s/files/1/0628/7520/5811/products/Rectangle90.png?v=1658165943';
}


export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export const countryObj = {
  "name": "United States",
  "iso2": "US",
  "iso3": "USA",
  "state_code": "",
  "country_ref": "",
  "type": "country"
}