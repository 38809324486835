import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { FORGOT_PASSWORD_RESET } from "../../redux/constants/userConstants";
import { forgotPasswordAction } from "../../redux/actions/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import FormValidation from "../../utils/formValidation";
import { stateValidatorSchema } from "../../utils/validators";
import ModalForm from "../../components/Modal/ModalForm";
import InputBox from "./InputBox";
import Button from "../../components/formInputs/Button";

const { REACT_APP_BASE_URL } = process.env;

const ForgotPasswordModel = ({ showFogotPwdModal, handleCancelModalClose }) => {
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state) => state.forgotPassword);

  React.useEffect(() => {
    if (
      forgotPassword &&
      forgotPassword.userInfo &&
      forgotPassword.userInfo.message &&
      forgotPassword.status === 200
    ) {
      toast.success(forgotPassword.userInfo.message, {
        icon: "✔",
      });
      handleCancelModalClose();
      dispatch({ type: FORGOT_PASSWORD_RESET });
    } else if (
      forgotPassword &&
      forgotPassword.error &&
      forgotPassword.error.message
    ) {
      toast.error(forgotPassword.error.message, {
        icon: "✕",
      });
      dispatch({ type: FORGOT_PASSWORD_RESET });
    }
  }, [forgotPassword, dispatch]);

  // Fogot Password form
  const userSchema = {
    email: { value: "", error: "" },
  };

  const handleSubmit = (state) => {
    dispatch(forgotPasswordAction(state.email));
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  const { email } = values;

  return (
    <Modal show={showFogotPwdModal}>
      <div className="modal__heading--container">
        <h2 className="modal__heading2"></h2>
        <button
          onClick={handleCancelModalClose}
          className="modal__heading--btn"
        >
          <svg className="modal__heading--icon modal__heading-close-icon">
            <use
              xlinkHref={`${REACT_APP_BASE_URL}/wp-content/uploads/2022/09/sprite.svg#icon-close`}
            />
          </svg>
        </button>
      </div>
      <ModalForm onSubmit={handleOnSubmit}>
        {forgotPassword.loading && (
          <div className="spinner_style">
            <img
              src={`${REACT_APP_BASE_URL}/wp-content/uploads/2022/09/loaing_circle.gif`}
              style={{
                width: "15rem",
                position: "absolute",
              }}
              alt="Loading..."
            />
          </div>
        )}
        <div className="forgot_password_container">
          <div className="forgot_password_container__form">
            <div>
              <h1 className="forgot_password_container__heading">
                Forgot Password?
              </h1>

              <div className="forgot_password_description">
                Enter your email address and we'll send you the <br />{" "}
                instructions on how to reset your password.
              </div>
            </div>
            <Fragment>
              <InputBox
                id="email"
                type="text"
                placeholder="Email"
                name="email"
                errorMessage={
                  errors && errors.email && dirty.email && errors.email
                }
                value={email}
                onChange={handleOnChange}
                label={false}
                icon="email"
              />

              <Button
                disabled={disable}
                style={{
                  justifyContent: "center",
                  width: "100%",
                  padding: "2rem",
                }}
                onClick={handleOnSubmit}
                label="Send Instructions"
                icon={false}
              />
            </Fragment>
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
};

export default ForgotPasswordModel;
