import React from 'react';
import RadioButtonsStyle from './RadioButtons.module.css';

const RadioButtons = ({ label, radioButtons, checked, onChange, error, style }) => {
	return (
		<div className={RadioButtonsStyle.modal_input}>

			{/* <label className={RadioButtonsStyle.form_input__label}>{label}</label> */}
			<div className='modal__radioButtons' style={style}>
				{radioButtons &&
					radioButtons.map((radioButton, index) => (
						<div key={index} className='modal__radioButton'>
							<input
								type='radio'
								id={radioButton.label}
								name={label}
								value={radioButton.value}
								checked={checked === radioButton.value}
								onChange={(e) => onChange(e)}
								className='modal__radioButton--input'
							/>
							<span className='modal__radioButton--radio' />
							<label htmlFor={radioButton.label} className='modal__radioButton--label'>
								{radioButton.label}
							</label>
						</div>
					))}
			</div>
			<br />
			{error && <p className={RadioButtonsStyle.form_input__error}>{error}</p>}
		</div>
	);
};


export const RadioButtonsForCard = ({ label, value, checked, onChange, style, icon }) => {
	return (
		<div className={RadioButtonsStyle.modal_input}>
			<div className='modal__radioButtons' style={style}>
				<div className='modal__radioButton'>
					<input
						type='radio'
						id={label}
						name={label}
						value={value}
						checked={checked === value}
						onChange={(e) => onChange(e)}
						className='modal__radioButton--input'
					/>
					<span className='modal__radioButton--addrRadioBtn' />
					<label htmlFor={label} className='modal__radioButton--label' style={{marginLeft:'1rem', fontSize: '2rem', fontWeight: '500', display: 'flex', gap: '1rem' }}>
					 {label} <svg className={RadioButtonsStyle.brand__icon}>
							<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
						</svg>
					</label>
				</div>
			</div>
		</div>
	);
};

export default RadioButtons;
