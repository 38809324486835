import React from "react";
import ButtonStyles from "./Button.module.css";

const { REACT_APP_BASE_URL } = process.env;

const Button = ({
  varient = "primary",
  label,
  icon,
  onClick,
  style,
  disabled = false,
  type,
}) => {
  return (
    <button
      className={
        varient === "primary"
          ? ButtonStyles.button
          : varient === "secondary"
          ? ButtonStyles.secondary_button
          : varient === "danger" && ButtonStyles.button__delete
      }
      type={type}
      style={style}
      onClick={onClick}
      data-testid="btn-component"
      disabled={disabled}
    >
      {label}

      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
    </button>
  );
};

export const ButtonPrefix = ({
  varient = "primary",
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={
        varient === "primary"
          ? ButtonStyles.button
          : varient === "danger" && ButtonStyles.button__delete
      }
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
      <span>{label}</span>
    </button>
  );
};

export const ButtonOutline = ({
  varient = "primary",
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={ButtonStyles.button__outline}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
    </button>
  );
};

export const ButtonCustom = ({
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={ButtonStyles.button__custom}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
    </button>
  );
};

export const WebButton = ({
  varient = "primary",
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={
        varient === "primary"
          ? ButtonStyles.button
          : varient === "secondary"
          ? ButtonStyles.secondary_button
          : varient === "danger" && ButtonStyles.button__delete
      }
      style={style}
      onClick={onClick}
      data-testid="btn-component"
      disabled={disabled}
    >
      {label}

      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use
            xlinkHref={`${REACT_APP_BASE_URL}/wp-content/uploads/2022/09/sprite.svg#icon-${icon}`}
          />
        </svg>
      )}
    </button>
  );
};

export const BackButton = ({
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={ButtonStyles.back_button}
      style={style}
      onClick={onClick}
      data-testid="btn-component"
      disabled={disabled}
    >
      {label}

      {icon && (
        <svg className={ButtonStyles.button__icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
      )}
    </button>
  );
};

export const MiniButton = ({
  varient = "primary",
  label,
  icon,
  onClick,
  style,
  disabled = false,
}) => {
  return (
    <button
      className={
        varient === "primary"
          ? ButtonStyles.button
          : varient === "secondary"
          ? ButtonStyles.secondary_button
          : varient === "danger" && ButtonStyles.button__delete
      }
      style={style}
      onClick={onClick}
      data-testid="btn-component"
      disabled={disabled}
    >
      {label}

      {icon && (
        <svg
          className={ButtonStyles.button__icon}
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.238594 13H3.83859L7.58859 8.29L11.2186 13H14.7586L9.35859 6.16L14.0086 0.369999H10.4086L7.58859 4.06L4.79859 0.369999H1.31859L5.81859 6.16L0.238594 13Z"
            fill="#4B4B4B"
          />
        </svg>
      )}
    </button>
  );
};

export default Button;
