export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';
export const LOGOUT_USER_RESET = 'LOGOUT_USER_RESET';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';
export const GET_USER_BY_ID_RESET = 'GET_USER_BY_ID_RESET';

export const POST_ACTIVATION_KITS_REQUEST = 'POST_ACTIVATION_KITS_REQUEST';
export const POST_ACTIVATION_KITS_SUCCESS = 'POST_ACTIVATION_KITS_SUCCESS';
export const POST_ACTIVATION_KITS_FAIL = 'POST_ACTIVATION_KITS_FAIL';
export const POST_ACTIVATION_KITS_RESET = 'POST_ACTIVATION_KITS_RESET';


export const REGISTER_KIT_REQUEST = 'REGISTER_KIT_REQUEST';
export const REGISTER_KIT_SUCCESS = 'REGISTER_KIT_SUCCESS';
export const REGISTER_KIT_FAIL = 'REGISTER_KIT_FAIL';
export const REGISTER_KIT_RESET = 'REGISTER_KIT_RESET';


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

export const GET_USER_ADDRESS_REQUEST = 'GET_USER_ADDRESS_REQUEST';
export const GET_USER_ADDRESS_SUCCESS = 'GET_USER_ADDRESS_SUCCESS';
export const GET_USER_ADDRESS_FAIL = 'GET_USER_ADDRESS_FAIL';
export const GET_USER_ADDRESS_RESET = 'GET_USER_ADDRESS_RESET';

export const ADD_USER_ADDRESS_REQUEST = 'ADD_USER_ADDRESS_REQUEST';
export const ADD_USER_ADDRESS_SUCCESS = 'ADD_USER_ADDRESS_SUCCESS';
export const ADD_USER_ADDRESS_FAIL = 'ADD_USER_ADDRESS_FAIL';
export const ADD_USER_ADDRESS_RESET = 'ADD_USER_ADDRESS_RESET';

export const CHANGE_PWD_REQUEST = 'CHANGE_PWD_REQUEST';
export const CHANGE_PWD_SUCCESS = 'CHANGE_PWD_SUCCESS';
export const CHANGE_PWD_FAIL = 'CHANGE_PWD_FAIL';
export const CHANGE_PWD_RESET = 'CHANGE_PWD_RESET';


export const GET_ACTIVATION_KITS_STATUS_REQUEST = 'GET_ACTIVATION_KITS_STATUS_REQUEST';
export const GET_ACTIVATION_KITS_STATUS_SUCCESS = 'GET_ACTIVATION_KITS_STATUS_SUCCESS';
export const GET_ACTIVATION_KITS_STATUS_FAIL = 'GET_ACTIVATION_KITS_STATUS_FAIL';
export const GET_ACTIVATION_KITS_STATUS_RESET = 'GET_ACTIVATION_KITS_STATUS_RESET';

export const UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST';
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAIL = 'UPDATE_USER_ADDRESS_FAIL';
export const UPDATE_USER_ADDRESS_RESET = 'UPDATE_USER_ADDRESS_RESET';


export const DELETE_USER_ADDRESS_REQUEST = 'DELETE_USER_ADDRESS_REQUEST';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAIL = 'DELETE_USER_ADDRESS_FAIL';
export const DELETE_USER_ADDRESS_RESET = 'DELETE_USER_ADDRESS_RESET';


export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';


export const GET_BILLING_ADDRESS_REQUEST = 'GET_BILLING_ADDRESS_REQUEST';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAIL = 'GET_BILLING_ADDRESS_FAIL';
export const GET_BILLING_ADDRESS_RESET = 'GET_BILLING_ADDRESS_RESET';

export const ADD_BILLING_ADDRESS_REQUEST = 'ADD_BILLING_ADDRESS_REQUEST';
export const ADD_BILLING_ADDRESS_SUCCESS = 'ADD_BILLING_ADDRESS_SUCCESS';
export const ADD_BILLING_ADDRESS_FAIL = 'ADD_BILLING_ADDRESS_FAIL';
export const ADD_BILLING_ADDRESS_RESET = 'ADD_BILLING_ADDRESS_RESET';

export const UPDATE_BILLING_ADDRESS_REQUEST = 'UPDATE_BILLING_ADDRESS_REQUEST';
export const UPDATE_BILLING_ADDRESS_SUCCESS = 'UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAIL = 'UPDATE_BILLING_ADDRESS_FAIL';
export const UPDATE_BILLING_ADDRESS_RESET = 'UPDATE_BILLING_ADDRESS_RESET';

export const DELETE_BILLING_ADDRESS_REQUEST = 'DELETE_BILLING_ADDRESS_REQUEST';
export const DELETE_BILLING_ADDRESS_SUCCESS = 'DELETE_BILLING_ADDRESS_SUCCESS';
export const DELETE_BILLING_ADDRESS_FAIL = 'DELETE_BILLING_ADDRESS_FAIL';
export const DELETE_BILLING_ADDRESS_RESET = 'DELETE_BILLING_ADDRESS_RESET';


export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
 

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATIONS_RESET = 'GET_NOTIFICATIONS_RESET';



export const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAIL = 'SUBSCRIBE_NEWSLETTER_FAIL';
export const SUBSCRIBE_NEWSLETTER_RESET = 'SUBSCRIBE_NEWSLETTER_RESET';
