import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import RadioButtons from '../../components/formInputs/RadioButtons/RadioButtons';
import { ButtonCustom, ButtonOutline } from '../../components/formInputs/Button';
import InputsSections from '../../components/Modal/InputSections';
import TextareaBox from '../../components/formInputs/TextareaBox';

const CancelSubscriptionModal = ({ data }) => {
	const [resonText, setResonText] = React.useState(null);
	const [otherText, setOtherText] = React.useState({
		value: '',
		error: ''
	});

	const {
		showAddModal,
		handleCancelModalClose,
		cancelSubscriptionHandler,
	} = data;

	const [cancelStatusList, setCancelStatusList] = React.useState([
		{
			id: 1,
			label: 'Dissatisfied with product',
			value: 'Dissatisfied with product'
		},
		{
			id: 2,
			label: 'I have too many of this product',
			value: 'I have too many of this product'
		},
		{
			id: 3,
			label: 'This product is too expensive',
			value: 'This product is too expensive',
		},
		{
			id: 4,
			label: 'I prefer a different brand',
			value: 'I prefer a different brand',
		},
		{
			id: 5,
			label: 'A subscription doesn’t suit my needs',
			value: 'A subscription doesn’t suit my needs',
		}, {
			id: 6,
			label: 'Other',
			value: 'Other',
		}
	]);


	const onChangeRadioBtnHandler = (event) => { 
		setResonText(event.target.value)
	};



	return (
		<div className="cancelSubscription">
			<Modal show={showAddModal}>
				<ModalHeading heading={'Cancel Item Subscription'} onClose={handleCancelModalClose} />
				<ModalForm onSubmit={cancelSubscriptionHandler} >
					<hr width="95%;" color="#D1D1D1" size="1" />
					<p className="subscriptions-heading2">Please let us know why your’re cancelling your subscription. <br /> Select from the following:</p> <br />
					<InputsSections style={{ paddingLeft: '0rem', display: 'contents' }}>
						<RadioButtons
							label={false}
							placeholder={false}
							radioButtons={cancelStatusList}
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: '2rem',
								fontSize: '1.8rem',
								width: 'max-content'
							}}
							checked={resonText}
							onChange={onChangeRadioBtnHandler}
						// error={type.error}
						/>
					</InputsSections>

					{/* {
						resonText &&
						resonText === 'Other' && */}
						<InputsSections>
							<TextareaBox
								label={false}
								icon={false}
								placeholder={false}
								style={{
									width: '100%',
									backgroundColor: '#f0f0f0e6',
									border: '1px solid #4B4B4B',
									borderRadius: '0.3rem',
									height: '117px',
								}}
								value={otherText.value}
								onChange={(event) => {
									setOtherText({ value: event.target.value, error: '' });
								}}
								errorMessage={otherText.error}
							/>
						</InputsSections>
					{/* } */}


					<div className='cancelSubscription-section-btn'>
						<ButtonCustom
							style={{
								width: '100%',
								justifyContent: 'center',
								padding: '1rem',
								backgroundColor: '#D35959 !important',
								fontSize: '1.8rem',
								fontWeight: '400',
								fontFamily: 'inherit'
							}}
							label="Cancel Subscription"
							onClick={(e) => {
								if (resonText && resonText === 'Other') {
									cancelSubscriptionHandler(e, otherText.value)
								} else if (resonText) {
									cancelSubscriptionHandler(e, resonText)
								}
							}}
						/>
					</div>
				</ModalForm>
			</Modal>
		</div>
	);
};

export default CancelSubscriptionModal;
