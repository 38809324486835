import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import { InputCustomBox } from "../../components/formInputs/InputBox";
import {
  gettActiviteKitStatusAction,
  getUserByIdAction,
  login,
} from "../../redux/actions/userActions";
import "./LoginScreen.css";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../components/formInputs/CheckBox";
import jwt_decode from "jwt-decode";
import FormValidation from "../../utils/formValidation";
import { stateValidatorSchema } from "../../utils/validators";
import {
  addCartSyncDataAction,
  deleteAllItemCartAction,
  getCartCountByUserAction,
  getCartSyncDataAction,
} from "../../redux/actions/productActions";
import { encryptData } from "../../utils/for";
import {
  GET_USER_BY_CART_COUNT_RESET,
  GET_USER_BY_CART_RESET,
} from "../../redux/constants/productConstants";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { PRODUCT_COLLECTION_BY_ID_PROD } from "../../redux/actions/ip";
import {
  LOGOUT_USER_RESET,
  USER_LOGOUT,
} from "../../redux/constants/userConstants";

const { REACT_APP_BASE_URL } = process.env;

const LoginScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading } = userLogin;
  const [isRemember, setIsRemember] = useState(false);
  const getActiviteKitStatus = useSelector(
    (state) => state.getActiviteKitStatus
  );
  const cartProducts = useSelector((state) => state.cartProducts.cart);

  // Login form
  const userSchema = {
    email: { value: "", error: "" },
    passwordLogin: { value: "", error: "" },
  };

  const handleSubmit = (state) => {
    dispatch(login(state.email, state.passwordLogin, isRemember));
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setErrors,
  } = FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  const { email, passwordLogin } = values;

  useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      dispatch(getUserByIdAction(decoded._id));
      dispatch(gettActiviteKitStatusAction());

      // if (isRemember) {
      // 	setWithExpiry('userInfo', userLogin.userInfo, 5000);
      // }

      // sync data automatically Start
      if (cartProducts && cartProducts.length > 0) {
        getCart();
      }
    } else if (userLogin && userLogin.userInfo && userLogin.userInfo.message) {
      setErrors({ ...errors, passwordLogin: userLogin.userInfo.message });
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: LOGOUT_USER_RESET });
      toast.error(userLogin.userInfo.message, {
        icon: "✕",
      });
    }
  }, [userLogin, cartProducts, dispatch]);

  React.useEffect(() => {
    if (
      getActiviteKitStatus &&
      getActiviteKitStatus.userInfo &&
      getActiviteKitStatus.userInfo.data &&
      getActiviteKitStatus.userInfo.data.isKitActivated === "activated"
    ) {
      history("/");
    } else if (
      getActiviteKitStatus &&
      getActiviteKitStatus.userInfo &&
      !getActiviteKitStatus.userInfo.data &&
      getActiviteKitStatus.userInfo.message &&
      getActiviteKitStatus.userInfo.message
    ) {
      history("/activate-kit");
    }
  }, [getActiviteKitStatus, history]);

  // sync data automatically Start
  const getCart = React.useCallback(async () => {
    const data = await dispatch(getCartSyncDataAction());
    syncCartHandler(data);
  }, [dispatch]);

  const syncCartHandler = React.useCallback(
    async (getCartByUser) => {
      let products = [];
      if (
        getCartByUser &&
        getCartByUser.data &&
        getCartByUser.data.product &&
        getCartByUser.data.product.length > 0
      ) {
        getCartByUser.data.product.forEach((item) => {
          if (item.product) {
            products.push({
              variant_id: item.variant_id
                ? item.variant_id
                : item.variants[0].id,
              quantity: item.quantity ? item.quantity : item.quantity,
              product_id: item.product_id ? item.product_id : item.id,
              frequency: item.frequency,
              collectionId: item.collectionId,
              product_type: item.product_type,
            });
          }
        });
      }

      if (cartProducts && cartProducts.length > 0) {
        cartProducts.forEach((item) => {
          products.push({
            variant_id: item.variant_id ? item.variant_id : item.variants[0].id,
            quantity: item.quantity ? item.quantity : item.quantity,
            product_id: item.product_id ? item.product_id : item.id,
            frequency: item.frequency,
            collectionId: PRODUCT_COLLECTION_BY_ID_PROD,
            product_type: item.product_type,
          });
        });
      }

      const arrayUniqueByKey = [
        ...new Map(products.map((item) => [item["product_id"], item])).values(),
      ];

      const addCartByUser = await dispatch(
        addCartSyncDataAction({
          products: encryptData(arrayUniqueByKey),
        })
      );

      if (addCartByUser && addCartByUser.data && addCartByUser.data) {
        localStorage.removeItem("cart");
        localStorage.removeItem("products");
        dispatch(deleteAllItemCartAction());
        dispatch(getCartCountByUserAction());
        dispatch({ type: GET_USER_BY_CART_RESET });
        dispatch({ type: GET_USER_BY_CART_COUNT_RESET });
      }
    },
    [dispatch]
  );
  // sync data automatically End

  // const setWithExpiry = (key, value, ttl) => {
  // 	const now = new Date()
  // 	// `item` is an object which contains the original value
  // 	// as well as the time when it's supposed to expire
  // 	value.expiry = now.getTime() + ttl;
  // 	localStorage.setItem(key, JSON.stringify(value))
  // }

  // const getWithExpiry = (key) => {
  // 	const itemStr = localStorage.getItem(key)

  // 	// if the item doesn't exist, return null
  // 	if (!itemStr) {
  // 		return null
  // 	}

  // 	const item = JSON.parse(itemStr)
  // 	const now = new Date()

  // 	// compare the expiry time of the item with the current time
  // 	if (now.getTime() > item.expiry) {
  // 		// If the item is expired, delete the item from storage
  // 		// and return null
  // 		localStorage.removeItem(key)
  // 		return null
  // 	}
  // 	return item.value
  // }

  return (
    <>
      <div className="login_back-btn">
        <BackButton
          label="< Back"
          onClick={() => window.open(`${REACT_APP_BASE_URL}/`, "_self")}
        />
      </div>
      <form onSubmit={handleOnSubmit}>
        <div className="main-container" style={{ height: "90vh" }}>
          {loading && <Spinner />}
          <div className="login_container">
            <div className="login_container__form">
              <div>
                <h1 className="login_container__heading">Sign In</h1>
              </div>
              <Fragment>
                <InputCustomBox
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  errorMessage={
                    errors && errors.email && dirty.email && errors.email
                  }
                  value={email}
                  onChange={handleOnChange}
                  label={false}
                  icon="email"
                />

                <InputCustomBox
                  id="passwordLogin"
                  type="password"
                  placeholder="Password"
                  name="passwordLogin"
                  errorMessage={
                    errors &&
                    errors.passwordLogin &&
                    dirty.passwordLogin &&
                    errors.passwordLogin
                  }
                  value={passwordLogin}
                  onChange={handleOnChange}
                  label={false}
                  icon="key"
                />
                <div className="login-container">
                  <Link
                    to="/forgot-password"
                    className="login-text-forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="login-container-remember-text">
                  <CheckBox
                    id="remember"
                    type="checkbox"
                    checked={isRemember}
                    onChange={(e) => {
                      setIsRemember(!isRemember);
                    }}
                    errorMessage={false}
                    label="Remember this device"
                    icon="key"
                  />
                </div>

                <Button
                  disabled={disable}
                  style={{ justifyContent: "center", width: "100%" }}
                  type="submit"
                  label="Sign In"
                  icon={false}
                />

                <div className="login-kit-container">
                  Need to activate your kit? &nbsp;
                  <Link to="/activate-your-kit" className="login-text">
                    Let’s activate it.
                  </Link>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="login__copyright">
            <img
              src="./assets/logo.png"
              alt="Stresskase Logo"
              className="login_container__logo"
              onClick={() => {
                window.open(`${REACT_APP_BASE_URL}/`, "_self");
              }}
            />
            <p className="login__copyright-text">
              &copy; All Rights Reserved By Stresskase 2021.
              {/* {new Date(Date.now()).getFullYear()}. */}
              <span>
                <a
                  href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
                &nbsp;|&nbsp;
                <a
                  href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginScreen;
