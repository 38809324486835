import React from 'react'
import ReactPaginate from 'react-paginate';

const Pagination = ({ list, onPageChange, rowsPerPage, pageNumber, totalPageSize }) => {
    const pageNo = totalPageSize / rowsPerPage;
    
    return (
        <div >
            {
                pageNo > 1 &&
                <ReactPaginate
                    nextClassName={''}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    pageCount={pageNo}
                    onPageChange={onPageChange}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    containerClassName={'paginationBttns'}
                    previousLinkClassName={'previousBttn'}
                    nextLinkClassName={'nextBttn'}
                    disabledClassName={'paginationDisabled'}
                    activeClassName={'paginationActive'}
                    forcePage={pageNumber - 1}
                />
            }
        </div>
    )
}

export default Pagination