import React from 'react';
import OrderHistoryDetailCardStyles from './OrderHistoryDetailCardStyles.module.css';

const OrderHistoryDetailsCard = ({
	data,
}) => {
 
	return (
		<div className='order-history-detail-box'>
			<div className={OrderHistoryDetailCardStyles.orderHistoryAddressContainer}>
				<div className={OrderHistoryDetailCardStyles.column}>
					<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading1}>
						Receipt <br />
						Order Number: {data.order.order_number}
					</h1>
				</div>
				<div className={OrderHistoryDetailCardStyles.column}>

				</div>
				<div className={OrderHistoryDetailCardStyles.column}>

				</div>
			</div>

			<div className={OrderHistoryDetailCardStyles.orderHistoryCardContainer}>
				<div className={OrderHistoryDetailCardStyles.columnImageThumb}> 
					{data.order && data.order.line_items[0] && <img src={data.order.line_items[0].image.src} alt="Order History thumb" className={OrderHistoryDetailCardStyles.orderCardImage} />}

					<div className={OrderHistoryDetailCardStyles.orderHistoryCardForMobile}>
						<div className={OrderHistoryDetailCardStyles.column}>

						</div>
						<div className={OrderHistoryDetailCardStyles.column}>

						</div>
						<div className={OrderHistoryDetailCardStyles.column}>
							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading1}>
								{data.order.line_items[0].name}<br />
								<p style={{ fontWeight: "400" }}>
									Qty: {data.order.line_items[0].quantity} <br />
									Kit ID: {data.order.line_items[0].id} <br />
								</p>
							</h1>
						</div>
					</div>


					{/* {
						data &&
						data.order &&
						data.order.line_items.length > 0 &&
						data.order.line_items.map((item, i) =>
							<>
								{data.image ? <img src={data.image} alt="Order History thumb" className={OrderHistoryDetailCardStyles.orderCardImage} /> :
									<img src={'https://cdn.shopify.com/s/files/1/0628/7520/5811/products/Rectangle90.png?v=1658165943'} alt="Order History thumb" className={OrderHistoryDetailCardStyles.orderCardImage} />
								}

								<div className={OrderHistoryDetailCardStyles.orderHistoryCardForMobile}>
									<div className={OrderHistoryDetailCardStyles.column}>

									</div>
									<div className={OrderHistoryDetailCardStyles.column}>

									</div>


									<div className={OrderHistoryDetailCardStyles.column}>
										<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading1}>
											{item.title}<br />
											<p >
												Qty: {item.fulfillable_quantity} <br />
												Kit ID: {item.id} <br />
											</p>
										</h1>
									</div>
								</div>
							</>
						)} */}

				</div>
				<div className={OrderHistoryDetailCardStyles.column}>
					<div className={OrderHistoryDetailCardStyles.orderHistoryCard}>
						<div className='order-history-detail-box-stress-test'>
							<div className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading1}>
									{data.order.line_items[0].name}<br />
									<p style={{ display: 'inline' }}>
										Qty: {data.order.line_items[0].quantity} <br />
										Kit ID: {data.order.line_items[0].id} <br />
									</p>
								</h1>
							</div>
							<div className={OrderHistoryDetailCardStyles.column}>
							</div>
							<div className={OrderHistoryDetailCardStyles.column}>
							</div>
						</div>
					</div>
					<div className='order-history-detail-box-tracking'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryDetailsCard}>
							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<u >Tracking No: {' '}
									{data.zrtFulfillmentDetails?.responseObjectFullfillment?.trackingNumber || 'N/A'}<br />
								</u>
							</h1>

							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >
									Date:
								</span>
								{
									new Date(data.order.processed_at).toLocaleDateString('en-US', {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit'
									})
								}
							</h1>
						</div>
					</div>
					<div className='order-history-detail-box-ship'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryDetailsCard}>
							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >
									Shipped To:<br />
								</span>
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.name}
								<br/>
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.address1} ,
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.address2}  <br />
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.city},
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.country},
								{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.zip}
							</h1>

							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >
									Shipping Method:
								</span>
								<br/>
								{data.zrtFulfillmentDetails?.responseObjectFullfillment?.carrier || 'N/A'}<br />
								{`$${data.payment.charges.data[0].billing_details.shipping}`} <br />
							</h1>
						</div>
					</div>
					<div className='order-history-detail-box-address'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryDetailsCard}>
							<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >	Billing Address: </span>
								<br />
								{data.payment.charges.data[0].billing_details.name}<br />
								{data.payment.charges.data[0].billing_details.address.line1}, {data.payment.charges.data[0].billing_details.address.line2} <br />
								{data.payment.charges.data[0].billing_details.address.city}, {data.payment.charges.data[0].billing_details.address.country},
								{data.payment.charges.data[0].billing_details.address.postal_code}
							</h1>

							{/* <h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>
								<span >Payment Method:  </span> <br />
								Credit Card
								{data.payment.charges.data[0].payment_method_details.type}  <br /> 
								 ************{data.payment.charges.data[0].payment_method_details.card.last4} <br />
								${data.order.total_price}
							</h1> */}
						</div>
					</div>
					<div className='order-history-detail-box-totals'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryPaymentDetailsCard}>
							<p className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading3}>Subtotal </h1>
							</p>
							<p className={OrderHistoryDetailCardStyles.column}>

							</p>
							<p className={OrderHistoryDetailCardStyles.column} style={{ textAlign: 'right' }}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>${data.order.current_subtotal_price}</h1>
							</p>
						</div>
						<div className={OrderHistoryDetailCardStyles.orderHistoryPaymentDetailsCard}>
							<p className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading3}>Shipping </h1>
							</p>
							<p className={OrderHistoryDetailCardStyles.column}>

							</p>
							<p className={OrderHistoryDetailCardStyles.column} style={{ textAlign: 'right' }}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>${data.payment.charges.data[0].billing_details.shipping ? data.payment.charges.data[0].billing_details.shipping : "0.00"}</h1>
							</p>
						</div>
						<div className={OrderHistoryDetailCardStyles.orderHistoryPaymentDetailsCard}>
							<p className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading3}>Taxes </h1>
							</p>
							<p className={OrderHistoryDetailCardStyles.column}>

							</p>
							<p className={OrderHistoryDetailCardStyles.column} style={{ textAlign: 'right' }}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>${data.order.current_total_tax}</h1>
							</p>
						</div>
						<div className={OrderHistoryDetailCardStyles.orderHistoryPaymentDetailsCard}>
							<p className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading3}>	Order Discount</h1>
							</p>
							<p className={OrderHistoryDetailCardStyles.column}>

							</p>
							<p className={OrderHistoryDetailCardStyles.column} style={{ textAlign: 'right' }}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCardHeading2}>${data.order.current_total_discounts}</h1>
							</p>
						</div>
					</div>
					<div className='order-history-detail-box-total'>
						<div className={OrderHistoryDetailCardStyles.orderHistoryPaymentDetailsCard}>
							<p className={OrderHistoryDetailCardStyles.column}>
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCard_total}>Total </h1>
							</p>
							<p className={OrderHistoryDetailCardStyles.column}>

							</p>
							<p className={OrderHistoryDetailCardStyles.column} style={{ textAlign: 'right' }} >
								<h1 className={OrderHistoryDetailCardStyles.orderHistoryCard_total}>${data.order.total_price} </h1>
							</p>
						</div>
					</div>
				</div>
			</div >

		</div >
	);
};

export default OrderHistoryDetailsCard;
