export const phoneMasking = (number) => {
    const num = `${number.substring(0, 3)}${number.substring(3) ? '-' : ''}${number.substring(3, 6)}${number.substring(6) ? '-' : ''}${number.substring(6, number.length)}`;
    return num
};

export const inputPhoneMasking = (e) => {
    let value = e
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{3})(\d)/, "$1-$2")
    value = value.replace(/(\d{3})(\d)/, "$1-$2")
    value = value.substr(0, 12)

    return value
};

export const unMasking = (e) => {
    let value = e
    value = value.replaceAll("-", "")

    return value
}

export const sortData = (arr) => {
    return arr.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    });
}



export const CardUnMasking = (e) => {
    let value = e
    value = value.replaceAll(" ", "")

    return value
};



export const inputCardMasking = (e) => {
    let value = e
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{4})(\d)/, "$1 $2")
    value = value.replace(/(\d{4})(\d)/, "$1 $2")
    value = value.replace(/(\d{4})(\d)/, "$1 $2")
    value = value.replace(/(\d{4})(\d)/, "$1 $2")

    value = value.substr(0, 19)
    return value
};


export const inputSecurityCodeMasking = (e) => {
    let value = e
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{4})(\d)/, "$1 $2")
    value = value.substr(0, 4)

    return value
}


export const convertDate = (mm, yyyy) => {
    if (mm.toString().length === 1) {
        return '0' + mm + '/' + yyyy; 
    } else {
        return mm + '/' + yyyy;
    }
};

export const inputDateMasking = (e) => {
    let value = e;
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d{2})(\d{4})/, "$1/$2")
    value = value.substr(0, 7);
    return value
};


export const wpContentCheck = (page) => {
    let checkElement = document.querySelector('.site-header');
    if (checkElement) {
        let acc = document.querySelector('.site-header'); 
        if (page && acc.style.cssText === "display: block;") {
            document.querySelector('.site-header').style.display = 'none';
            document.querySelector('.et-footers-wrapper').style.display = 'none';
        } else {
            document.querySelector('.site-header').style.display = 'block';
            document.querySelector('.et-footers-wrapper').style.display = 'block';
        }
    }
}


