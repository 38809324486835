import React from 'react';
import SelectBoxStyle from './SelectBox.module.css';

const SelectBox = ({
	disabled = false,
	children,
	icon,
	name,
	label,
	value,
	onChange,
	errorMessage,
	style,
	textBoxStyle,
	containerStyle
}) => {
	return (
		<div className={SelectBoxStyle.form_input} style={textBoxStyle}>
			{label && (
				<label
					htmlFor={label}
					className={errorMessage && errorMessage.length > 0 ? SelectBoxStyle.form_input_invalid_label : SelectBoxStyle.form_input__label}>
					{label}
				</label>
			)}
			<div className={SelectBoxStyle.textBox} style={textBoxStyle}>
				<div
					className={errorMessage && errorMessage.length > 0 ? SelectBoxStyle.textBox_invalid_input : SelectBoxStyle.textBox__input}
					style={containerStyle}>
					<select
						disabled={disabled}
						value={value}
						name={name}
						id={name}
						className={SelectBoxStyle.textBox__input_box}
						onChange={onChange}
						style={style}>
						{children}
					</select>
				</div>
			</div>
			{errorMessage && <div style={{ height: '1rem' }}>
				<p className={SelectBoxStyle.form_input__error}>{errorMessage}</p>
			</div>}
		</div>
	);
};


export const SelectInput = ({
	disabled = false,
	children,
	icon,
	name,
	label,
	value,
	onChange,
	errorMessage,
	style,
	textBoxStyle,
	containerStyle
}) => {
	return (
		<div className={SelectBoxStyle.form_input} style={textBoxStyle}>
			{label && (
				<label
					htmlFor={label}
					className={errorMessage && errorMessage.length > 0 ? SelectBoxStyle.form_input_invalid_label : SelectBoxStyle.form_input__label}>
					{label}
				</label>
			)}
			<div className={SelectBoxStyle.textBox} style={textBoxStyle}>
				<div
					className={errorMessage && errorMessage.length > 0 ? SelectBoxStyle.textBox_invalid_input : SelectBoxStyle.textBox__input}
					style={containerStyle}>
					<select
						disabled={disabled}
						value={value}
						name={name}
						id={name}
						className={SelectBoxStyle.textBox__input_box}
						onChange={onChange}
						style={style}>
						{children}
					</select>
				</div>
			</div>
			{errorMessage && <p className={SelectBoxStyle.form_input__error}>{errorMessage}</p>}
		</div>
	);
};

export default SelectBox;


