import React, { useEffect } from 'react';
import Button from '../formInputs/Button';
import InputBox from '../formInputs/InputBox';
import './PromoCodeAccordion.css';

const PromoCodeAccordion = ({ title, data, style }) => {

	const { values, errors, dirty, handleOnChange, handleOnSubmit, disable, setValues, onSubmitForm } = data

	useEffect(() => {
		let acc = document.getElementsByClassName('PromoCodeAccordion_accordion');
		if (acc) {
			// acc[0].classList.toggle('active');
			// acc[0].nextElementSibling.classList.toggle('show');

			for (let i = 0; i < acc.length; i++) {
				acc[i].onclick = function () {
					this.classList.toggle('active');
					this.nextElementSibling.classList.toggle('show');
				};
			}
		}
	}, []);


	const { promoCode } = values;



	return (
		<div className="PromoCodeAccordions">
			<div className="PromoCodeAccordion">
				<div>
					<p className="PromoCodeAccordion_accordion PromoCodeAccordion_accordion_title">
						{title}
					</p>
					<div className="panel">
						<form onSubmit={handleOnSubmit}>
							<div style={style}>
								<InputBox
									id='promoCode'
									type='text'
									placeholder='Enter Promo Code'
									label={false}
									icon={false}
									value={promoCode}
									onChange={handleOnChange}
									style={{
										height: '52px',
										borderRadius: '3px 0px 0px 3px'
									}}
									errorMessage={errors && errors.promoCode && dirty.promoCode && errors.promoCode}
								/>
								<Button
									disabled={disable}
									style={{
										justifyContent: 'center',
										width: '10rem',
										height: '100%',
										backgroundColor: '#77B778', 
										borderRadius: '0px 3px 3px 0px',
										border: '1px solid #4B4B4B', 
										color: '#FFFFFF',
										height: '52px',
									}}
									label='Apply'
									icon={false}
									onClick={handleOnSubmit}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PromoCodeAccordion;
