import React from 'react';
import TextBox from './TextBox';
import styles from './CheckoutScreen.module.css';
import { useDispatch } from 'react-redux';
import { addPaymentMethodAction } from '../../redux/actions/userActions';
import InputsSections from '../../components/Modal/InputSections';
import { stateValidatorSchema } from '../../utils/validators';
import FormValidation from '../../utils/formValidation';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import SelectBox from './SelectBox';
import Button from './Button';
import { countryObj } from '../../utils/for';

const StripePayment = ({ data }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    
    const { 
        stateList,
        newPaymentMethodSchema
    } = data;

    const addNewPaymentMethodHandler = async (state) => {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                address: {
                    city: state.city,
                    country: countryObj.iso2,
                    line1: state.address,
                    line2: state.appartment,
                    postal_code: state.zip,
                    state: state.state
                },
                name: state.firstName + ' ' + state.lastName,
                // email: null,
                // phone: null
            },
        })

        if (!error) {
            let paymentData = {}
            const { id } = paymentMethod;
            paymentData.id = id;
            paymentData.isDefault = true;
            if (paymentData.id) {
                await dispatch(addPaymentMethodAction(paymentData));
            }
        }
    };

    const {
        handleOnChange,
        handleOnSubmit,
        errors,
        dirty,
        disable,
        values: newPaymentMethod,
        // setValues: setNewPaymentMethodValues,
        // setErrors: setNewPaymentMethodErrors,
        // setDirty: setNewPaymentMethodDirty
    } = FormValidation(newPaymentMethodSchema, stateValidatorSchema, addNewPaymentMethodHandler);

    const {
        firstName,
        lastName,
        address,
        appartment,
        city,
        state,
        zip,
        country,
        // company,
        // phone, 
    } = newPaymentMethod;

    // const sameShippingHandler = () => { 
    //     if (!isSame) {  
    //         setNewPaymentMethodValues({
    //             ...newPaymentMethod,
    //             firstNameShippingAddr: firstName,
    //             lastNameShippingAddr: lastName,
    //             addressShippingAddr: address,
    //             appartmentShippingAddr: appartment,
    //             cityShippingAddr: city,
    //             stateShippingAddr: state,
    //             zipShippingAddr: zip,
    //             countryShippingAddr: country,
    //             isSame: !isSame
    //         })
    //         setNewPaymentMethodErrors({
    //             ...errors,
    //             firstNameShippingAddr: "",
    //             lastNameShippingAddr: "",
    //             addressShippingAddr: "",
    //             appartmentShippingAddr: "",
    //             cityShippingAddr: "",
    //             stateShippingAddr: "",
    //             zipShippingAddr: "",
    //             countryShippingAddr: "",
    //             isSame: "",
    //             country: "",
    //         })

    //         setNewPaymentMethodDirty({
    //             ...dirty,
    //             firstNameShippingAddr: false,
    //             lastNameShippingAddr: false,
    //             addressShippingAddr: false,
    //             appartmentShippingAddr: false,
    //             cityShippingAddr: false,
    //             stateShippingAddr: false,
    //             zipShippingAddr: false,
    //             isSame: false,
    //             countryShippingAddr: false,
    //         })
    //     } else {
    //         setNewPaymentMethodValues({
    //             ...newPaymentMethod,
    //             firstNameShippingAddr: '',
    //             lastNameShippingAddr: '',
    //             addressShippingAddr: '',
    //             appartmentShippingAddr: '',
    //             cityShippingAddr: '',
    //             stateShippingAddr: '',
    //             zipShippingAddr: '',
    //             countryShippingAddr: '',
    //             isSame: !isSame
    //         })
    //         setNewPaymentMethodErrors({
    //             ...errors,
    //             firstNameShippingAddr: "*This is required field",
    //             lastNameShippingAddr: "*This is required field",
    //             addressShippingAddr: "*This is required field",
    //             appartmentShippingAddr: "*This is required field",
    //             cityShippingAddr: "*This is required field",
    //             stateShippingAddr: "*This is required field",
    //             zipShippingAddr: "*This is required field",
    //             countryShippingAddr: "*This is required field", 
    //         })

    //         setNewPaymentMethodDirty({
    //             ...dirty,
    //             firstNameShippingAddr: false,
    //             lastNameShippingAddr: false,
    //             addressShippingAddr: false,
    //             appartmentShippingAddr: false,
    //             cityShippingAddr: false,
    //             stateShippingAddr: false,
    //             zipShippingAddr: false,
    //             isSame: false,
    //             countryShippingAddr: false,
    //         })
    //     }
    // }

    return (
        <form onSubmit={handleOnSubmit} style={{ marginBottom: '1rem' }}> 
            <InputsSections style={{ alignItems: 'flex-start' }}>
                <div className={styles.paymentCardContainer}>
                    <div className={styles.paymentCardNumber}>
                        <label className={styles.paymentCardLabel} >
                            Card Number
                        </label>
                        <CardNumberElement
                            options={{
                                showIcon: true,
                                style: {
                                    base: {
                                        iconColor: '#4B4B4B',
                                        color: '#4B4B4B',
                                        fontFamily: 'inherit',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        border: '0px solid #d9d9d9',
                                        fontSmoothing: 'antialiased',
                                        ':-webkit-autofill': {
                                            color: '#4B4B4B'
                                        },
                                        '::placeholder': {
                                            color: '#4B4B4B',
                                            fontWeight: '500',
                                            fontFamily: 'inherit',
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={styles.paymentCardNumber}>
                        <label className={styles.paymentCardLabel} >
                            Security Code
                        </label>
                        <CardCvcElement
                            options={{
                                showIcon: true,
                                style: {
                                    base: {
                                        iconColor: '#4B4B4B',
                                        color: '#4B4B4B',
                                        fontFamily: 'inherit',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        fontSmoothing: 'antialiased',
                                        ':-webkit-autofill': {
                                            color: '#4B4B4B'
                                        },
                                        '::placeholder': {
                                            color: '#4B4B4B',
                                            fontWeight: '500',
                                            fontFamily: 'inherit',
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={styles.paymentCardNumber}>
                        <label className={styles.paymentCardLabel} >
                            Expiration Date
                        </label>
                        <CardExpiryElement
                            options={{
                                showIcon: true,
                                style: {
                                    base: {
                                        iconColor: '#4B4B4B',
                                        color: '#4B4B4B',
                                        fontFamily: 'inherit',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        fontSmoothing: 'antialiased',
                                        ':-webkit-autofill': {
                                            color: '#4B4B4B'
                                        },
                                        '::placeholder': {
                                            color: '#4B4B4B',
                                            fontWeight: '500',
                                            fontFamily: 'inherit',
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </InputsSections>

            <InputsSections style={{ alignItems: 'flex-start', marginBottom: '2rem' }}>
                <h1 className='account_form--heading' >Billing Address</h1>
            </InputsSections>

            <InputsSections style={{ alignItems: 'flex-start' }}>
                <TextBox
                    name="firstName"
                    type='text'
                    placeholder='First Name'
                    errorMessage={errors && errors.firstName && dirty.firstName && errors.firstName}
                    value={firstName}
                    onChange={handleOnChange}
                    label='First Name'
                    icon='user'
                />
                <TextBox
                    name="lastName"
                    type='text'
                    placeholder='Last Name'
                    errorMessage={errors && errors.lastName && dirty.lastName && errors.lastName}
                    value={lastName}
                    onChange={handleOnChange}
                    label='Last Name'
                    icon='user'
                />
            </InputsSections>
            <InputsSections style={{ alignItems: 'flex-start' }}>
                <TextBox
                    name="address"
                    type='text'
                    placeholder='Street Address'
                    errorMessage={errors && errors.address && dirty.address && errors.address}
                    value={address}
                    onChange={handleOnChange}
                    label='Street Address'
                    icon='pin'
                    maxLength="150"
                />
            </InputsSections>
            <InputsSections style={{ alignItems: 'flex-start' }}>
                <TextBox
                    id="appartment"
                    name="appartment"
                    type='text'
                    placeholder='C/O , Apt, Suite, Building, Floor'
                    errorMessage={errors && errors.appartment && dirty.appartment && errors.appartment}
                    value={appartment}
                    onChange={handleOnChange}
                    label='C/O , Apt, Suite, Building, Floor'
                    icon='pin'
                    maxLength="150"
                />
            </InputsSections>
            <InputsSections style={{ alignItems: 'flex-start' }}>
                <TextBox
                    id="city"
                    type='text'
                    errorMessage={errors && errors.city && dirty.city && errors.city}
                    value={city}
                    onChange={handleOnChange}
                    name='city'
                    label='City'
                    placeholder='City'
                    icon={false}
                />
            </InputsSections>
            <InputsSections style={{ alignItems: 'flex-start' }}>
                <SelectBox
                    errorMessage={errors && errors.state && dirty.state && errors.state}
                    value={state}
                    onChange={handleOnChange}
                    icon={false}
                    label='State'
                    name='state'
                    containerStyle={{ width: '100%' }}
                    textBoxStyle={{ width: '100%' }}
                >
                    <option value='' >Select State</option>
                    {
                        stateList &&
                        stateList.length > 0 &&
                        stateList.map((item, index) => (
                            <option value={item.name} key={index + 1}>
                                {item.name}
                            </option>
                        ))}
                </SelectBox>
                <TextBox
                    id="zip"
                    type='text'
                    placeholder='Postal Code'
                    name='zip'
                    errorMessage={errors && errors.zip && dirty.zip && errors.zip}
                    value={zip}
                    onChange={handleOnChange}
                    label='Postal Code'
                    maxLength="6"
                    icon={false}
                />
            </InputsSections>

            <InputsSections style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <Button
                    label='Continue' //to shipping
                    style={{ marginTop: '1rem' }}
                    disabled={disable}
                    onClick={handleOnSubmit}
                />
            </InputsSections>
        </form>
    );
};

export default StripePayment;





 