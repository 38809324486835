import React from "react";
import "./SelectBox.css";

const SelectBox = ({
  disabled = false,
  children,
  icon,
  name,
  label,
  value,
  onChange,
  errorMessage,
  style,
  textBoxStyle,
  containerStyle,
  required = true,
}) => {
  return (
    <div className="form_input" style={textBoxStyle}>
      {label && (
        <label
          htmlFor={label}
          className={
            errorMessage && errorMessage.length > 0
              ? "form_input_invalid_label"
              : "form_input__label"
          }
        >
          {label}
        </label>
      )}
      <div className="textBox" style={textBoxStyle}>
        {icon && (
          <label className="textBox__label">
            <svg className="textBox__label--icon">
              <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
            </svg>
          </label>
        )}
        <div
          className={
            errorMessage && errorMessage.length > 0
              ? "textBox_invalid_input"
              : "textBox__input"
          }
          style={containerStyle}
        >
          <select
            disabled={disabled}
            value={value}
            name={name}
            id={name}
            className="textBox__input--box"
            onChange={onChange}
            style={style}
            required={required}
          >
            {children}
          </select>
        </div>
      </div>
      <div style={{ height: "1rem" }}>
        {errorMessage && <p className="form_input__error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export const SelectInput = ({
  disabled = false,
  children,
  icon,
  name,
  label,
  value,
  onChange,
  errorMessage,
  style,
  textBoxStyle,
  containerStyle,
}) => {
  return (
    <div className="form_input" style={textBoxStyle}>
      {label && (
        <label
          htmlFor={label}
          className={
            errorMessage && errorMessage.length > 0
              ? "form_input_invalid_label"
              : "form_input__label"
          }
        >
          {label}
        </label>
      )}
      <div className="textBox" style={textBoxStyle}>
        {icon && (
          <label className="textBox__label">
            <svg className="textBox__label--icon">
              <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
            </svg>
          </label>
        )}
        <div
          className={
            errorMessage && errorMessage.length > 0
              ? "textBox_invalid_input"
              : "textBox__input"
          }
          style={containerStyle}
        >
          <select
            disabled={disabled}
            value={value}
            name={name}
            id={name}
            className="textBox__input--box"
            onChange={onChange}
            style={style}
          >
            {children}
          </select>
        </div>
      </div>

      {errorMessage && <p className="form_input__error">{errorMessage}</p>}
    </div>
  );
};

export default SelectBox;
