import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Content from "../../components/Content";
import Button from "../../components/formInputs/Button";
import Spinner from "../../components/Spinner/Spinner";
import SubscriptionsCard from "../../components/SubscriptionsCard";
import {
  cancelSubscriptionsAction,
  getSubscriptionsAction,
  updateSubscriptionsAction,
} from "../../redux/actions/subscriptionActions";
import {
  CANCEL_SUBSCRIPTION_RESET,
  UPDATE_SUBSCRIPTION_RESET,
} from "../../redux/constants/subscriptionConstants";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import "./SubscriptionsScreen.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import FormValidation from "../../utils/formValidation";
import { stateValidatorSchema } from "../../utils/validators";
import AddressFormModal from "./AddressFormModal";
import { getStateByCountryAction } from "../../redux/actions/userActions";
import {
  convertDate,
  inputDateMasking,
  unMasking,
} from "../../utils/phonemask";
import CreateEditPayment from "./CreateEditPayment";
import { countryObj } from "../../utils/for";

const { REACT_APP_BASE_URL } = process.env;

const SubscriptionsScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const cancelSubscription = useSelector((state) => state.cancelSubscription);
  const updateSubscription = useSelector((state) => state.updateSubscription);
  const getUserInfo = useSelector((state) => state.getUserInfo);

  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [subscriptionsListLoading, setSubscriptionsListLoading] =
    React.useState(false);
  const [subscriptionsList, setSubscriptionsList] = React.useState([]);
  const [productDetailModal, setProductDetailModal] = React.useState(null);
  const [stateList, setStateList] = React.useState([]);
  const [showAddNewAddressModal, setShowAddNewAddressModal] =
    React.useState(false);
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);

  React.useEffect(() => {
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      !getUserInfo.userInfo.data.isSubscription
    ) {
      history("/");
    }
  }, [getUserInfo, history]);

  React.useEffect(() => {
    if (
      cancelSubscription &&
      cancelSubscription.data &&
      cancelSubscription.data.message
    ) {
      toast.success(cancelSubscription.data.message, {
        icon: "✔",
      });
      getSubscriptionData();
      setProductDetailModal(null);
      setShowCancelModal(false);
      dispatch({ type: CANCEL_SUBSCRIPTION_RESET });
    } else if (
      cancelSubscription &&
      cancelSubscription.error &&
      cancelSubscription.error.message
    ) {
      dispatch({ type: CANCEL_SUBSCRIPTION_RESET });
      toast.error(cancelSubscription.error.message, {
        icon: "✕",
      });
    }
  }, [cancelSubscription, dispatch]);

  React.useEffect(() => {
    if (
      updateSubscription &&
      updateSubscription.data &&
      updateSubscription.data.message
    ) {
      toast.success(updateSubscription.data.message, {
        icon: "✔",
      });
      dispatch({ type: UPDATE_SUBSCRIPTION_RESET });
      getSubscriptionData();
    } else if (
      updateSubscription &&
      updateSubscription.error &&
      updateSubscription.error.message
    ) {
      toast.error(updateSubscription.error.message, {
        icon: "✕",
      });
      dispatch({ type: UPDATE_SUBSCRIPTION_RESET });
    }
  }, [updateSubscription, dispatch]);

  const getSubscriptionData = React.useCallback(async () => {
    setSubscriptionsListLoading(true);
    const data = await dispatch(getSubscriptionsAction());
    data &&
      data.data &&
      data.data.map((item) => {
        item.subscriptionNextDateString = moment
          .unix(item?.subscriptionNextDate)
          .format("MM/DD/YYYY");
      });
    setSubscriptionsList(data.data);
    setSubscriptionsListLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    getSubscriptionData();
    getStateListHandler();
  }, [getSubscriptionData]);

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const cancelSubscriptionHandler = (e, text) => {
    e.preventDefault();
    dispatch(
      cancelSubscriptionsAction({
        id: productDetailModal._id,
        reason: text,
      })
    );
  };

  const updateSubscriptionHandler = (e, data) => {
    e.preventDefault();
    let billingAddress = data.address;
    delete billingAddress["id"];
    delete billingAddress["customer_id"];
    delete billingAddress["default"];
    let billing_details = data.paymentMethod.billing_details;

    dispatch(
      updateSubscriptionsAction({
        id: data._id,
        subscriptionDate: parseInt(
          (new Date(data.subscriptionNextDateString).getTime() / 1000).toFixed(
            0
          )
        ),
        // Date.parse(new Date(data.subscriptionNextDateString)),
        quantity: data.quantity,
        frequency: data.frequency,
        billingAddress: billingAddress,
        paymentMethod: data.paymentMethod && data.paymentMethod.id,
        paymentMethodDetails: {
          city:
            billing_details &&
            billing_details.address &&
            billing_details.address.city,
          country:
            billing_details &&
            billing_details.address &&
            billing_details.address.country,
          line1:
            billing_details &&
            billing_details.address &&
            billing_details.address.line1,
          line2:
            billing_details &&
            billing_details.address &&
            billing_details.address.line2,
          postal_code:
            billing_details &&
            billing_details.address &&
            billing_details.address.postal_code,
          state:
            billing_details &&
            billing_details.address &&
            billing_details.address.state,
          name:
            billing_details && billing_details.address && billing_details.name,
          exp_month: data.paymentMethod && data.paymentMethod.exp_month,
          exp_year: data.paymentMethod && data.paymentMethod.exp_year,
        },
      })
    );
  };

  const onQtyChange = (event, item) => {
    let newFormValues = [...subscriptionsList];
    item.quantity = event.target.value;
    setSubscriptionsList([...newFormValues]);
  };

  const onFrequncyChange = (event, item) => {
    let newFormValues = [...subscriptionsList];
    item.frequency = event.target.value;
    setSubscriptionsList([...newFormValues]);
  };

  const getStateListHandler = async () => {
    const data = await dispatch(getStateByCountryAction());
    setStateList(data.data);
  };

  const newShippingAddrSchema = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    address: { value: "", error: "" },
    appartment: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    zip: { value: "", error: "" },
    phone: { value: "", error: "" },
    company: { value: "", error: "" },
    isPrimary: { value: false, error: "" },
  };

  const addNewShippingAddrHandler = (shippingNewAddress) => {
    subscriptionsList.map((addrObj) => {
      if (addrObj._id === productDetailModal._id) {
        addrObj.address.first_name = shippingNewAddress.firstName;
        addrObj.address.last_name = shippingNewAddress.lastName;
        addrObj.address.address1 = shippingNewAddress.address;
        addrObj.address.appartment = shippingNewAddress.appartment;
        addrObj.address.city = shippingNewAddress.city;
        addrObj.address.state = shippingNewAddress.state;
        addrObj.address.zip = shippingNewAddress.zip;
        addrObj.address.phone = unMasking(shippingNewAddress.phone);
        addrObj.address.company = shippingNewAddress.company;
        addrObj.address.country = countryObj.name;
        addrObj.address.country_code = countryObj.iso2;
        addrObj.address.state_code = stateList.find(
          (o) => o.name === shippingNewAddress.state
        ).state_code;
      }
    });
    setSubscriptionsList([...subscriptionsList]);
    handleAddAddressModalClose();
  };

  const {
    values: newShippingAddrValues,
    errors: newShippingAddrErrors,
    dirty: newShippingAddrDirty,
    handleOnChange: newShippingAddrHandleOnChange,
    handleOnSubmit: newShippingAddrHandleOnSubmit,
    disable: newShippingAddrDisable,
    setValues: setShippingAddrValues,
    setErrors: setShippingAddrErrors,
    setDirty: setShippingAddrDirty,
  } = FormValidation(
    newShippingAddrSchema,
    stateValidatorSchema,
    addNewShippingAddrHandler
  );

  const handleAddAddressModalClose = () => {
    clearData();
    setShowAddNewAddressModal(false);
  };

  const editAddressHandler = (data) => {
    setProductDetailModal(data);
    let addrObj = data.address;
    setShippingAddrValues({
      firstName: addrObj.first_name,
      lastName: addrObj.last_name,
      address: addrObj.address1,
      appartment: addrObj.address2,
      city: addrObj.city,
      state: addrObj.province ? addrObj.province : addrObj.state,
      zip: addrObj.zip,
      phone: addrObj.phone,
      company: addrObj.company,
    });
    setShippingAddrErrors({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      company: "",
    });
    setShippingAddrDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      phone: false,
      company: false,
    });
    setShowAddNewAddressModal(true);
  };

  const clearData = () => {
    setShippingAddrValues({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      company: "",
    });
    setShippingAddrErrors({
      firstName: "*This is required field",
      lastName: "*This is required field",
      address: "*This is required field",
      appartment: "*This is required field",
      city: "*This is required field",
      state: "*This is required field",
      zip: "*This is required field",
      phone: "*This is required field",
      company: "*This is required field",
    });
    setShippingAddrDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      phone: false,
      company: false,
    });
    setShowAddNewAddressModal(false);
  };

  // Add Payment Method
  const paymentMethodSchema = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    address: { value: "", error: "" },
    appartment: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    zip: { value: "", error: "" },
    nameOnCard: { value: "", error: "" },
    cardNo: { value: "", error: "" },
    expirationDate: { value: "", error: "" },
  };

  const updatePaymentHandler = (dataObj) => {
    subscriptionsList.map((addrObj) => {
      if (addrObj._id === productDetailModal._id) {
        addrObj.paymentMethod.billing_details.address.line1 = dataObj.address;
        addrObj.paymentMethod.billing_details.address.line2 =
          dataObj.appartment;
        addrObj.paymentMethod.billing_details.address.city = dataObj.city;
        addrObj.paymentMethod.billing_details.address.country = countryObj.iso2; // dataObj.country;
        addrObj.paymentMethod.billing_details.address.postal_code = dataObj.zip;
        addrObj.paymentMethod.billing_details.address.state = dataObj.state;
        addrObj.paymentMethod.billing_details.name =
          dataObj.firstName + " " + dataObj.lastName;
        addrObj.paymentMethod.last4 = dataObj.cardNo;
        addrObj.paymentMethod.exp_month =
          dataObj.expirationDate && dataObj.expirationDate.split("/")[0];
        addrObj.paymentMethod.exp_year =
          dataObj.expirationDate && dataObj.expirationDate.split("/")[1];
      }
    });
    setSubscriptionsList([...subscriptionsList]);
    paymentModalClose();
  };

  const {
    values: paymentFormValues,
    errors: paymentFormErrors,
    dirty: paymentFormDirty,
    handleOnChange: paymentFormHandleOnChange,
    handleOnSubmit: paymentFormHandleOnSubmit,
    disable: paymentFormDisable,
    setValues: setPaymentFormValues,
    setErrors: setPaymentFormErrors,
    setDirty: setPaymentFormDirty,
  } = FormValidation(
    paymentMethodSchema,
    stateValidatorSchema,
    updatePaymentHandler
  );

  const paymentModalClose = () => {
    clearDataPayment();
    setShowPaymentModal(false);
  };

  const editPaymentHandler = (data) => {
    setProductDetailModal(data);
    let paymentObj =
      data.paymentMethod &&
      data.paymentMethod.billing_details &&
      data.paymentMethod.billing_details;
    let country = countryObj.name;

    setPaymentFormValues({
      firstName: paymentObj && paymentObj.name.split(" ")[0],
      lastName: paymentObj && paymentObj.name.split(" ")[1],
      address: paymentObj.address && paymentObj.address.line1,
      appartment: paymentObj.address && paymentObj.address.line2,
      city: paymentObj.address && paymentObj.address.city,
      state: paymentObj.address && paymentObj.address.state,
      zip: paymentObj.address && paymentObj.address.postal_code,
      country: country && country,
      nameOnCard: paymentObj && paymentObj.name,
      cardNo:
        data.paymentMethod && data.paymentMethod && data.paymentMethod.last4,
      expirationDate: inputDateMasking(
        convertDate(data.paymentMethod.exp_month, data.paymentMethod.exp_year)
      ), // inputDateMasking(data.paymentMethod.exp_month + '/' + data.paymentMethod.exp_year),
    });

    setPaymentFormErrors({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      nameOnCard: "",
      cardNo: "",
      expirationDate: "",
    });

    setPaymentFormDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      nameOnCard: false,
      cardNo: false,
      expirationDate: false,
    });

    setShowPaymentModal(true);
  };

  const clearDataPayment = () => {
    setPaymentFormValues({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      nameOnCard: "",
      cardNo: "",
      expirationDate: "",
    });
    setPaymentFormErrors({
      firstName: "*This is required field",
      lastName: "*This is required field",
      address: "*This is required field",
      appartment: "*This is required field",
      city: "*This is required field",
      state: "*This is required field",
      zip: "*This is required field",
      nameOnCard: "*This is required field",
      cardNo: "*This is required field",
      expirationDate: "*This is required field",
    });
    setPaymentFormDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      nameOnCard: false,
      cardNo: false,
      expirationDate: false,
    });
  };

  // const clearData = () => {
  //     setNewPaymentMethodValues({
  //         firstName: '',
  //         lastName: '',
  //         address: '',
  //         appartment: '',
  //         city: '',
  //         state: '',
  //         zip: '',
  //         // isPrimary: '',
  //         // nameOnCard: '',
  //     })
  //     setNewPaymentMethodErrors({
  //         firstName: "*This is required field",
  //         lastName: "*This is required field",
  //         address: "*This is required field",
  //         appartment: "*This is required field",
  //         city: "*This is required field",
  //         state: "*This is required field",
  //         zip: "*This is required field",
  //         // isPrimary: "*This is required field",
  //         // nameOnCard: "*This is required field",
  //     })
  //     setNewPaymentMethodDirty({
  //         firstName: false,
  //         lastName: false,
  //         address: false,
  //         appartment: false,
  //         city: false,
  //         state: false,
  //         zip: false,
  //         // isPrimary: false,
  //         // nameOnCard: false,
  //     })
  // }

  return (
    <Content
      currentMenu="subscriptions"
      headerTitle="Subscriptions"
      navigationRoute={[
        {
          path: "/",
          label: "Dashboard",
          routes: [
            {
              path: "/subscriptions",
              label: "Subscriptions",
            },
          ],
        },
      ]}
      addBtn={false}
      addBtnText="Add New User"
      addBtnIcon="plus"
      addBtnClick={() => {}}
      search={true}
      searchPlaceholder="Search Users..."
      searchIcon="search"
      searchvalue={"search"}
      searchOnChange={""}
    >
      {(subscriptionsListLoading || updateSubscription.loading) && <Spinner />}
      <div className="subscriptions-container">
        <div className="subscriptions-heading">
          <h1 className="subscriptions-heading1">Subscriptions</h1>
          <p className="subscriptions-heading2">
            Save 10% with every refill order. You can adjust the shipping
            frequency or cancel your automatic subscription at any time.
            Shipping is always free. Reminder: Subscription discounts do not
            apply to the initial product purchase - the discount applies to
            refill orders only.
          </p>

          <p className="subscriptions-heading2">
            For past or processing order status, go to the
            <Link to="/order-history" className="subscriptions-link">
              <span> Order History </span>
            </Link>{" "}
            tab. For questions about how subscriptions work, check out our
            <a
              href={`${REACT_APP_BASE_URL}/help-center/`}
              className="subscriptions-link"
              target="_blank"
            >
              <span> Help Center </span>
            </a>{" "}
            page.
          </p>
        </div>
        <div className="subscriptions-content">
          {subscriptionsList &&
            subscriptionsList.length > 0 &&
            subscriptionsList.map((item, i) => (
              <div className="subscriptions-card" key={i}>
                <SubscriptionsCard
                  data={item}
                  setShowCancelModal={setShowCancelModal}
                  subscriptionsList={subscriptionsList}
                  setSubscriptionsList={setSubscriptionsList}
                  updateSubscriptionHandler={updateSubscriptionHandler}
                  setProductDetailModal={setProductDetailModal}
                  onQtyChange={onQtyChange}
                  onFrequncyChange={onFrequncyChange}
                  editAddressHandler={editAddressHandler}
                  editPaymentHandler={editPaymentHandler}
                />
              </div>
            ))}
          <div className="subscriptions-section-btn">
            <Button
              style={{
                backgroundColor: "#fffafa00",
                border: "none",
                color: "#4B4B4B",
                fontWeight: "500",
                fontSize: "1.8rem",
                justifyContent: "center",
                padding: "0.5rem",
              }}
              label="Shop All Stresskase Supplements >"
              onClick={() => history("/supplement-collections")}
            />
          </div>
          <div className="subscriptions-desc-text">
            By subscribing, you accept our{" "}
            <a href={`${REACT_APP_BASE_URL}/terms-of-service/`} target="_blank">
              Terms
            </a>{" "}
            ,
            <a href={`${REACT_APP_BASE_URL}/terms-of-service/`} target="_blank">
              Subscription Policy
            </a>{" "}
            and authorize us to automatically renew your subscription(s),
            charging the subscription price(s) listed plus applicable taxes, on
            your default payment method, at the frequency you selected, unless
            you change or cancel your subscription online at any time. Pay when
            your subscription ships.
          </div>
        </div>
      </div>

      <CancelSubscriptionModal
        data={{
          showAddModal: showCancelModal,
          handleCancelModalClose,
          cancelSubscriptionHandler,
        }}
      />

      <AddressFormModal
        data={{
          showAddModal: showAddNewAddressModal,
          handleAddModalClose: handleAddAddressModalClose,
          handleOnChange: newShippingAddrHandleOnChange,
          handleSubmit: newShippingAddrHandleOnSubmit,
          shippingAddress: newShippingAddrValues,
          setValues: setShippingAddrValues,
          errors: newShippingAddrErrors,
          dirty: newShippingAddrDirty,
          disable: newShippingAddrDisable,
          getStateListHandler,
          stateList,
        }}
      />

      <CreateEditPayment
        data={{
          showAddModal: showPaymentModal,
          handleAddModalClose: paymentModalClose,
          handleOnSubmit: paymentFormHandleOnSubmit,
          handleOnChange: paymentFormHandleOnChange,
          newPaymentMethod: paymentFormValues,
          setValues: setPaymentFormValues,
          errors: paymentFormErrors,
          dirty: paymentFormDirty,
          disable: paymentFormDisable,
          getStateListHandler,
          stateList,
        }}
      />
    </Content>
  );
};

export default SubscriptionsScreen;
