import React, { Fragment, useState } from "react";
import "./Header.css";
import Button, { ButtonOutline } from "../../components/formInputs/Button";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getUserByIdAction, logout } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { getCartCountByUserAction } from "../../redux/actions/productActions";

const { REACT_APP_BASE_URL } = process.env;

const WebHeader = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const getUserInfo = useSelector((state) => state.getUserInfo);
  const getCartCountByUser = useSelector((state) => state.getCartCountByUser);
  const cartProducts = useSelector((state) => state.cartProducts.cart);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [cartCount, setCartCount] = useState(0);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      dispatch(getUserByIdAction(decoded._id));
      dispatch(getCartCountByUserAction());
    }
  }, [userLogin, dispatch]);

  React.useEffect(() => {
    if (
      getCartCountByUser &&
      getCartCountByUser.data &&
      getCartCountByUser.data.data
    ) {
      setCartCount(getCartCountByUser.data.data);
    } else {
      setCartCount(0);
    }
  }, [getCartCountByUser]);

  React.useEffect(() => {
    if (cartProducts && cartProducts.length > 0) {
      setCartCount(cartProducts.length);
    } else {
      setCartCount(0);
    }
  }, [cartProducts]);

  const toggle_viewCart = () => {
    history("/cart");
  };

  const logOutAction = () => {
    dispatch(logout());
    history("/login");
  };

  const dashboardHandler = () => {
    history("/");
  };

  return (
    <div className="website_header_container">
      <header data-testid="WebHeader" className="website_header">
        <div className="mobile-menu">
          <button className="close_menu_btn" onClick={handleClick}>
            {click ? (
              <svg className="header-icon-in menu-icon">
                <use xlinkHref="assets/sprite.svg#CloseMenu" />
              </svg>
            ) : (
              <svg className="header-icon-in menu-icon">
                <use xlinkHref="assets/sprite.svg#MenuIcon" />
              </svg>
            )}
          </button>
        </div>

        <img
          src="./assets/logo.png"
          alt="Logo"
          className="logo_website"
          onClick={() => {
            window.open(`${REACT_APP_BASE_URL}/`, "_self");
          }}
        />
        <nav className="navigation_website">
          <div>
            <ul
              className={
                click
                  ? " navigation__links nav-options active"
                  : "navigation__links nav-options"
              }
            >
              <li
                onClick={closeMobileMenu}
                className="mobile-menu"
                // style={{ width: '100%', border: '1px solid #F0F0F0' }}>
                style={{
                  width: "100%",
                  borderBottom: "1px solid #F0F0F0",
                  marginBottom: ".4rem",
                  paddingBottom: "1.5rem",
                }}
              >
                <ButtonOutline
                  disabled={
                    getUserInfo &&
                    getUserInfo.userInfo &&
                    getUserInfo.userInfo.data &&
                    getUserInfo.userInfo.data.kitActivatedID &&
                    getUserInfo.userInfo.data.kitActivatedID !== "None"
                  }
                  varient="primary"
                  label="Activate Kit"
                  icon={false}
                  style={{ width: "100%", padding: "1rem" }}
                  onClick={() => {
                    if (
                      userLogin &&
                      userLogin.userInfo &&
                      userLogin.userInfo.data
                    ) {
                      history("/activate-kit");
                    } else {
                      history("/activate-your-kit");
                    }
                  }}
                />
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink
                  to={`#`}
                  onClick={() => {
                    window.open(`${REACT_APP_BASE_URL}/the-kit`, "_self");
                  }}
                  // to={`/the-kit`}
                  // style={({ isActive }) =>
                  // 	isActive
                  // 		? {
                  // 			color: '#D35959'
                  // 		}
                  // 		: {
                  // 			color: '#4B4B4B'
                  // 		}}
                  className="navigation__link"
                >
                  The Kit
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink
                  to={`#`}
                  onClick={() => {
                    window.open(`${REACT_APP_BASE_URL}/science`, "_self");
                  }}
                  // to={`/science`}
                  // style={({ isActive }) =>
                  // 	isActive
                  // 		? {
                  // 			color: '#D35959'
                  // 		}
                  // 		: {
                  // 			color: '#4B4B4B'
                  // 		}}
                  className="navigation__link"
                >
                  Science
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink
                  to={`#`}
                  onClick={() => {
                    window.open(`${REACT_APP_BASE_URL}/about`, "_self");
                  }}
                  // to={`/about`}
                  // style={({ isActive }) =>
                  // 	isActive
                  // 		? {
                  // 			color: '#D35959'
                  // 		}
                  // 		: {
                  // 			color: '#4B4B4B'
                  // 		}}
                  className="navigation__link"
                >
                  About
                </NavLink>
              </li>
              <li onClick={closeMobileMenu}>
                <NavLink
                  to={`#`}
                  onClick={() => {
                    window.open(`${REACT_APP_BASE_URL}/contact-us`, "_self");
                  }}
                  // to={`/the-kit`}
                  // style={({ isActive }) =>
                  // 	isActive
                  // 		? {
                  // 			color: '#D35959'
                  // 		}
                  // 		: {
                  // 			color: '#4B4B4B'
                  // 		}}
                  className="navigation__link only_mob"
                >
                  Contact Us
                </NavLink>
              </li>
              {/* <li onClick={closeMobileMenu}>
								<NavLink
									to={`/articles`}
									style={({ isActive }) =>
										isActive
											? {
												color: '#D35959'
											}
											: {
												color: '#4B4B4B'
											}
									}
									className="navigation__link"
								>
									Articles
								</NavLink>
							</li> */}
              <li
                className="mobile-menu"
                style={{
                  width: "100%",
                  borderTop: "1px solid #F0F0F0",
                  paddingTop: "calc(1.4rem - .5rem)",
                  marginTop: "13.1rem",
                }}
              >
                <div
                  className="navigation__link header-right-side"
                  style={{ width: "100%" }}
                >
                  {userLogin &&
                  userLogin.userInfo &&
                  userLogin.userInfo.data ? (
                    <Button
                      varient="secondary"
                      label="Sign Out"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "1rem",
                        color: "#ffffff",
                      }}
                      icon={false}
                      onClick={logOutAction}
                    />
                  ) : (
                    <Button
                      label="Sign In"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "1rem",
                        backgroundColor: "#D35959",
                        border: "1px solid #D35959",
                        color: "#ffffff",
                      }}
                      icon={false}
                      onClick={() => history(`/login`)}
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
          <ul className="navigation__links navigation__links--contact">
            <li>
              <div className="navigation__link header-right-side">
                <ButtonOutline
                  disabled={
                    getUserInfo &&
                    getUserInfo.userInfo &&
                    getUserInfo.userInfo.data &&
                    getUserInfo.userInfo.data.kitActivatedID &&
                    getUserInfo.userInfo.data.kitActivatedID !== "None"
                  }
                  varient="primary"
                  label="Activate Kit"
                  icon={false}
                  onClick={() => {
                    if (
                      userLogin &&
                      userLogin.userInfo &&
                      userLogin.userInfo.data
                    ) {
                      history("/activate-kit");
                    } else {
                      history("/activate-your-kit");
                    }
                  }}
                />

                <div className="icon-box">
                  <div className="menu-button-wrapper" htmlFor="">
                    <div className="icon-wrapper tooltip_menu">
                      {userLogin &&
                      userLogin.userInfo &&
                      userLogin.userInfo.data ? (
                        <Fragment>
                          <svg
                            className="tooltip_text_menu"
                            width="24"
                            height="26"
                            viewBox="0 0 24 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 0C13.364 0 14.6721 0.541835 15.6365 1.50631C16.601 2.47078 17.1429 3.77889 17.1429 5.14286V8.57143C17.1429 9.9354 16.601 11.2435 15.6365 12.208C14.6721 13.1725 13.364 13.7143 12 13.7143C10.636 13.7143 9.32792 13.1725 8.36345 12.208C7.39898 11.2435 6.85714 9.9354 6.85714 8.57143V5.14286C6.85714 3.77889 7.39898 2.47078 8.36345 1.50631C9.32792 0.541835 10.636 0 12 0ZM24 24V22.752C24 17.2886 17.6811 13.7143 12 13.7143C6.31886 13.7143 0 17.2886 0 22.752V24C0 24.4547 0.180612 24.8907 0.502103 25.2122C0.823593 25.5337 1.25963 25.7143 1.71429 25.7143H22.2857C22.7404 25.7143 23.1764 25.5337 23.4979 25.2122C23.8194 24.8907 24 24.4547 24 24Z"
                              fill="url(#paint0_linear_2413_569)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2413_569"
                                x1="12"
                                y1="0"
                                x2="12"
                                y2="33.5"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#77B778" />
                                <stop offset="0.755208" stopColor="#4B5F72" />
                              </linearGradient>
                            </defs>
                          </svg>
                          <div
                            className="tooltip_msg_menu"
                            onClick={logOutAction}
                          >
                            Sign Out
                          </div>
                        </Fragment>
                      ) : (
                        <svg className="user-icon">
                          <use xlinkHref="assets/sprite.svg#icon-user-outline" />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="icon-box" onClick={toggle_viewCart}>
                  <svg className="cart-icon" style={{ marginBottom: "0.5rem" }}>
                    <use xlinkHref="assets/sprite.svg#icon-cart-outline" />
                  </svg>
                  {!getCartCountByUser.loading && cartCount > 0 && (
                    <div className="cart_count_icon">
                      {getCartCountByUser.loading ? " " : cartCount}
                    </div>
                  )}
                </div> */}
              </div>
            </li>
          </ul>
          {/* <div className="mobile-menu">
            <div className="icon-box" onClick={toggle_viewCart}>
              <svg className="cart-icon" style={{ marginBottom: "0.5rem" }}>
                <use xlinkHref="assets/sprite.svg#icon-cart-outline" />
              </svg>
              {!getCartCountByUser.loading && cartCount > 0 && (
                <div className="cart_count_icon">
                  {getCartCountByUser.loading ? " " : cartCount}
                </div>
              )}
            </div>
          </div> */}
        </nav>
      </header>
    </div>
    // <hr width="95%;" color="#D1D1D1" size="1" />
  );
};

export default WebHeader;
