import React from 'react';
import NoDataStyles from './NoData.module.css';

const NoDataWeb = ({ icon = 'sad', title = 'No Data Found!', subTitle = 'We could not find any data.' ,style}) => {
	return (
		<div className={NoDataStyles.noDataContainer} style={style}> 
			<h3 className={NoDataStyles.noDataContainerText} >
				{title}
			</h3> 
		</div>
	);
};

export default NoDataWeb;
