import axios from "axios";

let api = process.env.REACT_APP_API_BASE_URL;
if (!api) api = "https://api.stresskase.com/api/v1";

// api utils

function headers() {
  const token = localStorage.getItem("userToken");
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
}

export const API = {
  get: (path, params) => {
    const config = { headers: headers() };
    if (params) config.params = params;
    return axios.get(api + path, config);
  },
};

// user routes

export async function getUserData() {
  const { data } = await API.get("/user/me");
  return data;
}

// questionnaire routes

export async function getRecommendations(kitID) {
  const { data } = await API.get("/questionnaire/result/kit-description", {
    kitID,
  });
  return data;
}

export async function getSupplementRecommendations(kitID) {
  const { data } = await API.get("/kit/supplement-recommendations", {
    kitID,
  });
  return data;
}

export async function getAllSupplements() {
  const { data } = await API.get("/kit/supplement-recommendations", {
    "get-all": true,
  });
  return data;
}

export async function addItemToCart({ itemId, quantity }) {
  const result = await axios.get("https://stresskase.com/cart", {
    params: {
      "add-to-cart": itemId,
      quantity,
    },
  });
  console.log("result");
  return result;
}
