import React from 'react';
import { formatter } from '../../utils/for';
import ProductCardStyles from './ProductCardStyles.module.css'; 


const ProductSquareCard = ({ id, data }) => {
	return (
		<div className={ProductCardStyles.productCard}>
			<div className={ProductCardStyles.column} style={{ backgroundColor: '#EEEEEE', borderRadius: '0.3rem' }}>
				{data.image ? (
					<img src={data.image.src} alt='product thumb' className={ProductCardStyles.productCardImage} />
				) : (
					<img src={'./assets/product_round.png'} alt='product thumb' className={ProductCardStyles.productCardImage} />
				)}
				<h1 className={ProductCardStyles.priceTag}>
					{data.variants[0] && formatter.format(data.variants[0].compare_at_price)}</h1>
			</div>
			<div className={ProductCardStyles.column_content}>
				<h1 className={ProductCardStyles.productHeading}>{data.title}</h1>

				{data.metaField &&
					data.metaField.length > 0 &&
					data.metaField.map(
						(item, i) =>
							item.key === 'sub_titlle' && <p key={i} className={ProductCardStyles.productHeading_description}>{item.value}</p>
					)}
				{/* {data.metaField && (
					<div className={ProductCardStyles.productHeading_description}>
						{data.metaField.length > 0 &&
							data.metaField.map(
								(item, i) => item.key === 'sub_titlle' && <LearnMore id={id} children={item.value} count={100} />
							)}
					</div>
				)} */}
			</div>
		</div>
	);
};

export default ProductSquareCard;
