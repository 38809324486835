import React from "react";
import "./TextBox.css";

const InputBox = ({
  type = "text",
  placeholder,
  value,
  onChange,
  errorMessage,
  label,
  icon,
  style,
  containerStyle,
  disabled,
  defaultValue,
  id,
  maxLength,
  readOnly,
}) => {
  const [inputType, setInputType] = React.useState(type);

  const handlePasswordView = () => {
    if (type === "password") {
      if (inputType === "text") setInputType("password");
      else setInputType("text");
    }
  };

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className="form_input" style={containerStyle}>
      {label && (
        <label
          htmlFor={label}
          className={
            errorMessage && errorMessage.length > 0
              ? "form_input_invalid_label"
              : "form_input__label"
          }
          data-testid="text-box-label"
        >
          {label}
        </label>
      )}
      <div
        className={
          errorMessage && errorMessage.length > 0
            ? "form_input_invalid_container"
            : "form_input__container"
        }
        style={style}
      >
        <input
          type={inputType}
          id={id}
          name={id}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          maxLength={maxLength}
          className="form_input__box"
          max={type === "date" ? getTodaysDate() : ""}
          data-testid="text-box"
          readOnly={readOnly}
        />
        {type === "password" && (
          <svg className="form_input__icon" onClick={handlePasswordView}>
            <use
              xlinkHref={`/assets/sprite.svg#icon-${
                inputType === "text" ? "visibility_off" : "visibility"
              }`}
            />
          </svg>
        )}
      </div>

      <div style={{ height: "1rem" }}>
        {errorMessage && <p className="form_input__error">{errorMessage}</p>}
      </div>
    </div>
  );
};

export const InputCustomBox = ({
  type = "text",
  placeholder,
  value,
  onChange,
  errorMessage,
  label,
  icon,
  style,
  containerStyle,
  disabled,
  defaultValue,
  id,
  maxLength,
}) => {
  const [inputType, setInputType] = React.useState(type);

  const handlePasswordView = () => {
    if (type === "password") {
      if (inputType === "text") setInputType("password");
      else setInputType("text");
    }
  };

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className="form_input" style={containerStyle}>
      {label && (
        <label
          htmlFor={label}
          className={
            errorMessage && errorMessage.length > 0
              ? "form_input_invalid_label"
              : "form_input__label"
          }
          data-testid="text-box-label"
        >
          {label}
        </label>
      )}
      <div
        className={
          errorMessage && errorMessage.length > 0
            ? "form_input_invalid_container"
            : "form_input__container"
        }
        style={style}
      >
        <input
          type={inputType}
          id={id}
          name={id}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          maxLength={maxLength}
          className="form_input__box"
          max={type === "date" ? getTodaysDate() : ""}
          data-testid="text-box"
        />
        {type === "password" && (
          <svg className="form_input__icon" onClick={handlePasswordView}>
            <use
              xlinkHref={`/assets/sprite.svg#icon-${
                inputType === "text" ? "visibility_off" : "visibility"
              }`}
            />
          </svg>
        )}
      </div>
      {errorMessage && <p className="form_input__error">{errorMessage}</p>}
    </div>
  );
};

export default InputBox;
