import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import InputBox, { InputCustomBox } from "../../components/formInputs/InputBox";
import { registerAction } from "../../redux/actions/userActions";
import "./RegisterScreen.css";
import { stateValidatorSchema } from "../../utils/validators";
import { Link, useNavigate } from "react-router-dom";
import InputsSections from "../../components/Modal/InputSections";
import { REGISTER_KIT_RESET } from "../../redux/constants/userConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import SelectBox from "../../components/formInputs/SelectBox";
import FormValidation from "../../utils/formValidation";
import Spinner from "../../components/Spinner/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const RegisterScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const registerKit = useSelector((state) => state.registerKit);
  const { loading } = registerKit;

  React.useEffect(() => {
    if (registerKit && registerKit.userInfo && registerKit.userInfo.data) {
      toast.success(registerKit.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: REGISTER_KIT_RESET });
      history("/login");
    } else if (
      registerKit &&
      registerKit.userInfo &&
      !registerKit.userInfo.data
    ) {
      toast.error(registerKit.userInfo.message, {
        icon: "✕",
      });
      dispatch({ type: REGISTER_KIT_RESET });
    }
  }, [registerKit, dispatch, history]);

  // Register form
  const userData = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    email: { value: "", error: "" },
    gender: { value: "", error: "" },
    genderAssigned: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
  };

  const registerHandler = (state) => {
    if (state.password === "" && state.password.trim() === "") {
      setErrors({ ...errors, confirmPassword: "Please enter your password" });
      setDisable(true);
    } else if (
      state.password !== state.confirmPassword &&
      state.password.trim() !== state.confirmPassword.trim()
    ) {
      setErrors({ ...errors, confirmPassword: "Password does not match" });
      setDisable(true);
    } else {
      dispatch(
        registerAction({
          firstname: state.firstName,
          lastname: state.lastName,
          email: state.email,
          gender: state.gender,
          genderAssignedBirth: state.genderAssigned,
          password: state.password,
          role: "customer",
        })
      );
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setDisable,
    setValues,
    setErrors,
  } = FormValidation(userData, stateValidatorSchema, registerHandler);

  const {
    firstName,
    lastName,
    email,
    gender,
    genderAssigned,
    password,
    confirmPassword,
  } = values;

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="login_back-btn">
        <BackButton label="< Back" onClick={() => history("/Login")} />
      </div>
      <div className="main-container" style={{ height: "110vh" }}>
        {loading && <Spinner />}
        <div className="register_container">
          <div className="register_container__form">
            <div>
              <h1 className="register_container__heading">Activate Your Kit</h1>
            </div>
            <Fragment>
              <InputsSections style={{ flexDirection: "row" }}>
                <InputCustomBox
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  errorMessage={
                    errors &&
                    errors.firstName &&
                    dirty.firstName &&
                    errors.firstName
                  }
                  value={firstName}
                  onChange={handleOnChange}
                  label={false}
                  icon="user"
                />

                <InputCustomBox
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  errorMessage={
                    errors &&
                    errors.lastName &&
                    dirty.lastName &&
                    errors.lastName
                  }
                  value={lastName}
                  onChange={handleOnChange}
                  label={false}
                  icon="user"
                />
              </InputsSections>
              <InputCustomBox
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                errorMessage={
                  errors && errors.email && dirty.email && errors.email
                }
                value={email}
                onChange={handleOnChange}
                label={false}
                icon="email"
              />

              <InputCustomBox
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                errorMessage={
                  errors && errors.password && dirty.password && errors.password
                }
                value={password}
                onChange={handleOnChange}
                label={false}
                icon="key"
              />

              <InputCustomBox
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                errorMessage={
                  errors &&
                  errors.confirmPassword &&
                  dirty.confirmPassword &&
                  errors.confirmPassword
                }
                value={confirmPassword}
                onChange={handleOnChange}
                label={false}
                icon="key"
              />

              <SelectBox
                errorMessage={
                  errors && errors.gender && dirty.gender && errors.gender
                }
                value={gender}
                onChange={handleOnChange}
                icon={false}
                label={false}
                id="gender"
                name="gender"
                containerStyle={{ width: "100%" }}
                textBoxStyle={{ width: "100%" }}
              >
                <option value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non-binary">Non-Binary</option>
              </SelectBox>

              <SelectBox
                errorMessage={
                  errors &&
                  errors.genderAssigned &&
                  dirty.genderAssigned &&
                  errors.genderAssigned
                }
                value={genderAssigned}
                onChange={handleOnChange}
                icon={false}
                label={false}
                id="genderAssigned"
                name="genderAssigned"
                containerStyle={{ width: "100%" }}
                textBoxStyle={{ width: "100%" }}
              >
                <option value="">Gender assigned at birth</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                {/* <option value='non-binary'>
									Non-Binary
								</option> */}
              </SelectBox>

              <Button
                disabled={disable}
                style={{ justifyContent: "center", width: "100%" }}
                onClick={handleOnSubmit}
                label="Finish"
                icon={false}
              />

              {/* <div className='register-container '>
								Already have an account?  &nbsp;
								<Link to='/login' className='register-text'>
									Sign In
								</Link>.
							</div> */}
            </Fragment>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="register__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="register_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="register__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            {/* {new Date(Date.now()).getFullYear()} */}
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default RegisterScreen;
