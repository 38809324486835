import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import { InputCustomBox } from "../../components/formInputs/InputBox";
import "./ForgotPasswordScreen.css";
import { useNavigate } from "react-router-dom";
import { forgotPasswordAction } from "../../redux/actions/userActions";
import { FORGOT_PASSWORD_RESET } from "../../redux/constants/userConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import FormValidation from "../../utils/formValidation";
import { stateValidatorSchema } from "../../utils/validators";
import Spinner from "../../components/Spinner/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const forgotPassword = useSelector((state) => state.forgotPassword);

  React.useEffect(() => {
    if (
      forgotPassword &&
      forgotPassword.userInfo &&
      forgotPassword.userInfo.message &&
      forgotPassword.status === 200
    ) {
      toast.success(forgotPassword.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: FORGOT_PASSWORD_RESET });
    } else if (
      forgotPassword &&
      forgotPassword.error &&
      forgotPassword.error.message
    ) {
      toast.error(forgotPassword.error.message, {
        icon: "✕",
      });
      dispatch({ type: FORGOT_PASSWORD_RESET });
    }
  }, [forgotPassword, dispatch]);

  // Fogot Password form
  const userSchema = {
    email: { value: "", error: "" },
  };

  const handleSubmit = (state) => {
    dispatch(forgotPasswordAction(state.email));
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  const { email } = values;

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="login_back-btn">
        <BackButton label="< Back" onClick={() => history("/login")} />
      </div>
      <div className="main-container" style={{ height: "90vh" }}>
        {forgotPassword.loading && <Spinner />}
        <div className="forgot_password_container">
          <div className="forgot_password_container__form">
            <div>
              <h1 className="forgot_password_container__heading">
                Forgot Password?
              </h1>

              <div className="forgot_password_description">
                Enter your email address and we'll send you the <br />{" "}
                instructions on how to reset your password.
              </div>
            </div>
            <Fragment>
              <InputCustomBox
                id="email"
                type="text"
                placeholder="Email"
                name="email"
                errorMessage={
                  errors && errors.email && dirty.email && errors.email
                }
                value={email}
                onChange={handleOnChange}
                label={false}
                icon="email"
              />

              <Button
                disabled={disable}
                style={{ justifyContent: "center", width: "100%" }}
                onClick={handleOnSubmit}
                label="Send Instructions"
                icon={false}
              />

              {/* <div className='forgot_password_container '>
									Already have an account?  &nbsp;
									<Link to='/login' className='forgot-password-text'>
										Sign In
									</Link>.
								</div> */}
            </Fragment>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="forgot_password__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="forgot_password_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="forgot_password__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordScreen;
