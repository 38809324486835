import React from "react";
import styles from "./CheckoutScreen.module.css";
import OrderConfirmDetail from "../../components/OrderHistoryDetailsCard/OrderConfirmDetail";
import InputsSections from "../../components/Modal/InputSections";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CHECKOUT_CART_RESET,
  GET_USER_BY_CART_RESET,
} from "../../redux/constants/productConstants";
import {
  getCartByUserAction,
  getCartCountByUserAction,
} from "../../redux/actions/productActions";
import { GET_ORDER_CONFIRM_DETAILS_RESET } from "../../redux/constants/orderConstants";

const { REACT_APP_BASE_URL } = process.env;

const OrderConfirm = ({ data, setProductList }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);

  return (
    <div className={styles.content_product_desc}>
      <div className={styles.order_confirmContainer}>
        <div>
          <svg className={styles.order_confirm_icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-yesMark`} />
          </svg>
        </div>
        <div>
          <h1 style={{ fontWeight: "500", marginTop: "2rem" }}>
            {" "}
            Order {data && data.order && data.order.name}{" "}
          </h1>
          <h1> Thank you for your order. </h1>
        </div>
      </div>
      <div
        style={{
          margin: "2rem 0rem 2rem 0rem",
          border: "1px solid #d9d9d9",
          borderRadius: "1rem",
          backgroundColor: "white",
          padding: "2rem",
        }}
      >
        <h1> Your order is confirmed </h1>
        <p>
          You'll receive a confirmation email and text with your order number
          shortly.
        </p>
      </div>

      <div className="orderHistory-card">
        <OrderConfirmDetail data={data} />
      </div>

      <InputsSections
        style={{ alignItems: "flex-end", justifyContent: "space-between" }}
      >
        <div>
          Need help? &nbsp;
          <a
            href="mailto:customerservice@stresskase.com"
            style={{ textDecoration: "none", color: "rgba(75, 95, 114, 1)" }}
            className="line"
          >
            Contact us
          </a>
        </div>
        <Button
          label="Continue shopping" //to shipping
          style={{ marginTop: "1rem" }}
          onClick={() => {
            setProductList([]);
            dispatch(getCartByUserAction());
            dispatch(getCartCountByUserAction());
            dispatch({ type: GET_USER_BY_CART_RESET });
            dispatch({ type: GET_ORDER_CONFIRM_DETAILS_RESET });
            dispatch({ type: CHECKOUT_CART_RESET });
            if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
              history("/");
            } else {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }
          }}
        />
      </InputsSections>
    </div>
  );
};

export default OrderConfirm;
