import React from "react";
import { subcribeNewsLetterAction } from "../../redux/actions/userActions";
import { SUBSCRIBE_NEWSLETTER_RESET } from "../../redux/constants/userConstants";
import FormValidation from "../../utils/formValidation";
import Button from "../formInputs/Button";
import InputBox from "../formInputs/InputBox";
import InputsSections from "../Modal/InputSections";
import "./Footer.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useDispatch, useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const Footer = () => {
  const dispatch = useDispatch();
  const subcribeNewsLetter = useSelector((state) => state.subcribeNewsLetter);

  React.useEffect(() => {
    if (
      subcribeNewsLetter &&
      subcribeNewsLetter.data &&
      subcribeNewsLetter.data.inlineMessage
    ) {
      toast.success(
        subcribeNewsLetter.data.inlineMessage.replace(/(<([^>]+)>)/gi, ""),
        {
          icon: "✔",
        }
      );
      dispatch({ type: SUBSCRIBE_NEWSLETTER_RESET });
    } else if (
      subcribeNewsLetter &&
      subcribeNewsLetter.error &&
      subcribeNewsLetter.error.message
    ) {
      toast.error(
        subcribeNewsLetter.error.inlineMessage.replace(/(<([^>]+)>)/gi, ""),
        {
          icon: "✕",
        }
      );
      dispatch({ type: SUBSCRIBE_NEWSLETTER_RESET });
    }
  }, [subcribeNewsLetter]);

  const stateSchema = {
    email: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    email: {
      required: true,
      validator: {
        func: (value) =>
          /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            value
          ),
        error: "Please enter a valid email address",
      },
    },
  };

  const onSubmitForm = (state) => {
    dispatch(subcribeNewsLetterAction(state.email));
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    FormValidation(stateSchema, stateValidatorSchema, onSubmitForm);

  const { email } = values;

  return (
    <div className="footer_content">
      <footer data-testid="Footer">
        <div className="row primary" style={{ padding: "0" }}>
          <div className="column about_footer">
            <img
              data-testid="stressKase_logo"
              src="./assets/logo.png"
              alt="Stresskase Logo"
              className="footer_logo"
              onClick={() => {
                window.open(`${REACT_APP_BASE_URL}/`, "_self");
              }}
            />
            <p className="footer_text">
              Sign up and be the first to know about new stresskase special
              offers and news.
            </p>
            <form onSubmit={handleOnSubmit}>
              <InputsSections
                style={{
                  gap: "1.3rem",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <InputBox
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  label={false}
                  icon={false} //'email'
                  value={email}
                  onChange={handleOnChange}
                  errorMessage={
                    errors && errors.email && dirty.email && errors.email
                  }
                  containerStyle={{ width: "unset" }}
                  style={{
                    width: "19.2rem",
                    height: "4.5rem",
                    border: "2px solid #4B4B4B",
                    borderRadius: "5px",
                  }}
                />
                <Button
                  disabled={disable}
                  style={{
                    justifyContent: "center",
                    width: "8.1rem",
                    height: "4.5rem",
                  }}
                  label="Submit"
                  icon={false}
                  onClick={handleOnSubmit}
                />
              </InputsSections>
            </form>
          </div>
          <div className="column links">
            <h3>Learn</h3>
            <ul>
              <li>
                <a href={`${REACT_APP_BASE_URL}/science`} target="_self">
                  <span> Science </span>
                </a>
              </li>
              {/* <li>
							<Link to='/articles' >
								<span> Articles </span>
							</Link>
						</li> */}
            </ul>
          </div>
          <div className="column links">
            <h3>About</h3>
            <ul>
              <li>
                <a href={`${REACT_APP_BASE_URL}/about`} target="_self">
                  <span> About Us </span>
                </a>
                {/* <Link to='/about'>
								<span> About Us </span>
							</Link> */}
              </li>
            </ul>
          </div>
          <div className="column links">
            <h3>Support</h3>
            <ul>
              <li>
                <a href={`${REACT_APP_BASE_URL}/contact-us`} target="_self">
                  <span> Contact Us </span>
                </a>
                {/* <Link to='/contact'>
								<span> Contact Us </span>
							</Link> */}
              </li>
              <li>
                <a href={`${REACT_APP_BASE_URL}/help-center`} target="_self">
                  <span> Help Center </span>
                </a>
                {/* <Link to='/help-center'>
								<span> Help Center </span>
							</Link> */}
              </li>
            </ul>
          </div>
        </div>

        <div className="row primary tab " style={{ padding: "0" }}>
          <div className="column about footer_social" style={{ padding: "0" }}>
            <InputsSections
              style={{ margin: "1px", gap: "3.5rem", flexDirection: "row" }}
            >
              <div className="footer__social--section">
                <a
                  href="https://m.facebook.com/stresskaselabs/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg className="social-icon">
                    <use xlinkHref={`/assets/sprite.svg#desktop-facebook`} />
                  </svg>
                  <span>Facebook</span>
                </a>
              </div>
              <div className="footer__social--section">
                <a
                  href="https://m.facebook.com/stresskaselabs/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg className="social-icon">
                    <use xlinkHref={`/assets/sprite.svg#desktop-instagram`} />
                  </svg>
                  <span>Instagram </span>
                </a>
              </div>
              <div className="footer__social--section">
                <a
                  href="https://www.linkedin.com/company/stresskase/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg className="social-icon">
                    <use xlinkHref={`/assets/sprite.svg#desktop-linkedIn`} />
                  </svg>
                  <span>LinkedIn</span>
                </a>
              </div>
              <div className="footer__social--section">
                <a
                  href="https://www.youtube.com/channel/UCDwtqKZnxZx59a-EXzcncAQ"
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg className="social-icon-youtube">
                    <use xlinkHref={`/assets/sprite.svg#desktop-youtube`} />
                  </svg>
                  <span> YouTube </span>
                </a>
              </div>
            </InputsSections>
          </div>

          <div className="footer_download footer_desktop">
            <h3 className="footer_download_text">
              Download the stresskase app today!
            </h3>
          </div>
          <div className="footer_download footer_mob">
            <h3 className="footer_download_text">
              Download the stresskase app today!
            </h3>
          </div>
          <div className="footer_download_text_btn_col">
            <a
              data-testid="apple_store-img"
              href="https://apps.apple.com/us/app/stresskase/id1672702851"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="./assets/apple_store.png"
                alt="Stresskase Logo"
                className="platform_logo"
              />
            </a>
            <a
              data-testid="play_store-img"
              href="https://play.google.com/store/apps/details?id=com.customer.stresskase"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="./assets/play_store.png"
                alt="Stresskase Logo"
                className="platform_logo"
              />
            </a>
          </div>
          <a
            data-testid="apple_store-img"
            className="footer_download_text_btn"
            href="https://apps.apple.com/us/app/stresskase/id1672702851"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="./assets/apple_store.png"
              alt="Stresskase Logo"
              className="platform_logo"
            />
          </a>
          <a
            data-testid="play_store-img"
            className="footer_download_text_btn"
            href="https://play.google.com/store/apps/details?id=com.customer.stresskase"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="./assets/play_store.png"
              alt="Stresskase Logo"
              className="platform_logo"
            />
          </a>
        </div>

        <div className="row" style={{ paddingTop: "3.56rem" }}>
          <div className="footer_bottom">
            <div className="footer_copyright">
              <p className="footer_copyright-text">
                &copy; All Rights Reserved By Stresskase{" "}
                {new Date(Date.now()).getFullYear()}.
                <span>
                  <a
                    href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                    target="_self"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                    target="_self"
                  >
                    Terms of Service
                  </a>
                </span>
              </p>
              <p className="footer_copyright-text footer_copyright-text_bottom">
                *These statements have not been evaluated by the FDA. These
                products are not intended to diagnose, treat, cure, or prevent
                any disease.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
