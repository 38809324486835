import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { BackButton } from "../../components/formInputs/Button";
import { InputCustomBox } from "../../components/formInputs/InputBox";
import "./ActivateKitScreen.css";
import { useNavigate } from "react-router-dom";
import { validateActiviteKitAction } from "../../redux/actions/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { REGISTER_KIT_RESET } from "../../redux/constants/userConstants";
import { stateValidatorSchema } from "../../utils/validators";
import FormValidation from "../../utils/formValidation";
import Spinner from "../../components/Spinner/Spinner";

const { REACT_APP_BASE_URL } = process.env;

const WithoutTokenScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const activiteKit = useSelector((state) => state.activiteKit);
  const { loading } = activiteKit;
  const [error, setErrorMsg] = useState(null);

  // Acitivate kit Password form
  const userSchema = {
    activiteKitId: { value: "", error: "" },
  };

  const handleSubmit = async (state) => {
    let data = await dispatch(validateActiviteKitAction(state.activiteKitId));
    if (data.data === "Valid") {
      localStorage.setItem("activiteKitId", state.activiteKitId);
      history("/register-kit");
    } else {
      setErrorMsg(data.message);
    }
  };

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    FormValidation(userSchema, stateValidatorSchema, handleSubmit);

  const { activiteKitId } = values;

  useEffect(() => {
    if (activiteKitId) {
      setErrorMsg(null);
    }
  }, [values]);
  useEffect(() => {
    if (activiteKit && activiteKit.userInfo && activiteKit.userInfo.data) {
      setErrorMsg(null);
      toast.success(activiteKit.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: REGISTER_KIT_RESET });
      history("/register-kit");
    } else if (
      activiteKit &&
      activiteKit.userInfo &&
      !activiteKit.userInfo.data
    ) {
      dispatch({ type: REGISTER_KIT_RESET });
      toast.error(activiteKit.userInfo.message, {
        icon: "✕",
      });
    }
  }, [activiteKit, dispatch, history]);

  return (
    <>
      <div className="login_back-btn">
        <BackButton label="< Back" onClick={() => history("/login")} />
      </div>
      <div className="main-container" style={{ height: "90vh" }}>
        {loading && <Spinner />}
        <div className="activate_kit_container">
          <div className="activate_kit_container__form">
            <div>
              <h1 className="activate_kit_container__heading">
                Activate Your Kit
              </h1>
            </div>
            <form onSubmit={handleOnSubmit} autocomplete="off" style={{ width: "100%" }}>
              <input autocomplete="false" name="hidden" type="text" style={{"display":"none"}}/>
              <InputCustomBox
                id="activiteKitId"
                type="text"
                name="activiteKitId"
                placeholder="Kit ID Number"
                value={activiteKitId}
                onChange={handleOnChange}
                errorMessage={
                  errors &&
                  errors.activiteKitId &&
                  dirty.activiteKitId &&
                  errors.activiteKitId
                }
                label={false}
                icon="activiteKitId"
              />

              {error && (
                <p
                  style={{ width: "100%", textAlign: "left", marginTop: "5px" }}
                  className="form_input__error"
                >
                  *{error}
                </p>
              )}

              <div className="activity-description">
                This ID number is found in your stresskase order email and also
                inside your kit.
              </div>

              <Button
                disabled={disable}
                style={{ justifyContent: "center", width: "100%" }}
                label="Next"
                icon={false}
                type="submit"
              />

              {/* <div className='activity-container '>
								Already have an account?&nbsp;
								<Link to='/login' className='activate_kit-text'>
									Sign In
								</Link>.
							</div> */}
            </form>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="activate_kit__copyright">
          <img
            src="./assets/logo.png"
            alt="Stresskase Logo"
            className="activate_kit_container__logo"
            onClick={() => {
              window.open(`${REACT_APP_BASE_URL}/`, "_self");
            }}
          />
          <p className="activate_kit__copyright-text">
            &copy; All Rights Reserved By Stresskase 2021.
            {/* {new Date(Date.now()).getFullYear()}. */}
            <span>
              <a
                href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                rel="noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default WithoutTokenScreen;
