import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Content from "../../components/Content";
import Spinner from "../../components/Spinner/Spinner";
import {
  getTestResultAction,
  getTestResultPDFURLAction,
} from "../../redux/actions/resultActions";
import "./TestResultScreen.css";
import { RangeInput } from "../../components/RangeInput/range";
import ReactHtmlParser from "react-html-parser";
import CanvasJSReact from "../../assets/canvasjs.react";
import Button, { ButtonPrefix } from "../../components/formInputs/Button";
import { months } from "../../utils/validators";
import { getMonthListAction } from "../../redux/actions/questionnaireActions";
import NoData from "../../components/NoData";
import ShareModal from "../../components/ShareModal";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const TestResultScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const getTestResult = useSelector((state) => state.getTestResult);
  const getUserInfo = useSelector((state) => state.getUserInfo);
  const getMonthList = useSelector((state) => state.getMonthList);
  const [testLineChartResult, setTestLineChartResult] = React.useState([]);
  const [monthList, setMonthList] = React.useState([]);
  const [shareModal, setShareModal] = React.useState(false);
  const [testResultPDFURL, setTestResultPDFURL] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.isTestResult
    ) {
      dispatch(getMonthListAction());
      dispatch(getTestResultAction(getUserInfo.userInfo.data.kitActivatedID));
    } else if (
      getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      !getUserInfo.userInfo.data.isTestResult
    ) {
      history("/");
    }
  }, [getUserInfo, history, dispatch]);

  React.useEffect(() => {
    if (
      getTestResult &&
      getTestResult.userInfo &&
      getTestResult.userInfo.data &&
      getTestResult.userInfo.data.tests &&
      getTestResult.userInfo.data.tests.length > 0
    ) {
      let arr = [{ label: "", y: [0, 1] }];
      let arr2 = [{ label: "", y: [0, 1] }];

      getTestResult.userInfo.data.tests.forEach((item, index) => {
        if (item.name === "Cortisol") {
          arr.push({
            label: item.rangeMinimum + " - " + item.rangeMaximum,
            y: [item.rangeMinimum, item.rangeMaximum],
          });
          arr2.push({
            label: item.rangeMinimum + " - " + item.rangeMaximum,
            y: JSON.parse(item.result),
          });
        }
      });

      setTestLineChartResult([...arr2]);
      // setTestResult([...arr]);
    }
  }, [userLogin, getUserInfo, dispatch]);

  React.useEffect(() => {
    if (
      getMonthList &&
      getMonthList.data &&
      getMonthList.data.data &&
      getMonthList.data.data.length > 0
    ) {
      getMonthList.data.data.forEach((item) => {
        item.selected = false;
        if (
          item.month === new Date().getMonth() &&
          item.year === new Date().getFullYear()
        ) {
          item.selected = true;
        }
      });
      setMonthList(getMonthList.data.data);
    }
  }, [getMonthList]);

  const extractString = (name) => {
    var extracted = name.split(" ");
    switch (extracted[extracted.length - 1]) {
      case "(morning)":
        return "Pre-Awakening Optimal Range:";
      case "(noon)":
        return "Mid-Morning Optimal Range:";
      case "(evening)":
        return "Afternoon Optimal Range:";
      case "(night)":
        return "Evening Optimal Range:";
      default:
        return "Pre-Awakening Optimal Range:";
    }
  };

  const onChangeRange = () => {
    return;
  };

  const getRangeLabel = (item) => {
    if (item.resultLevel.toLowerCase() === "ok") {
      return (
        <span>
          {" "}
          in the <b> normal</b> range for your age
        </span>
      );
    } else if (item.resultLevel === "L") {
      return (
        <span>
          {" "}
          <b>below</b> the normal range for your age
        </span>
      );
    }

    return (
      <span>
        {" "}
        <b>above</b> the normal range for your age
      </span>
    );
  };

  const optionsResult = {
    backgroundColor: "#BF6A6A",
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: false,
    axisY: {
      gridThickness: 0,
      includeZero: true,
      title: "ng / ml",
    },
    axisX: {
      gridThickness: 0,
      minimum: 0,
    },
    data: [
      {
        type: "rangeArea",
        color: "#a8dbdc",
        lineColor: "#edc477",
        markerType: "none",
        lineThickness: 6,
        fillOpacity: 0.8,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: [3.7, 9.5] },
          { label: "1.2 - 3.0", y: [1.2, 3.0] },
          { label: "0.6 - 1.9", y: [0.6, 1.9] },
          { label: "0.4 - 1", y: [0.4, 1.0] },
        ],
      },
      {
        type: "scatter",
        showInLegend: false,
        color: "#FFF",
        markerColor: "#000",
        // lineColor: "#000",
        lineThickness: 3,
        markerSize: 10,
        dataPoints: testLineChartResult,
      },
    ],
  };

  const optionsOptimalResult = {
    backgroundColor: "#BF6A6A",
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: false,
    axisY: {
      gridThickness: 0,
      includeZero: true,
      title: "ng / ml",
    },
    axisX: {
      gridThickness: 0,
      minimum: 0,
    },
    data: [
      {
        type: "rangeArea",
        color: "#a8dbdc",
        lineColor: "#edc477",
        markerType: "none",
        lineThickness: 6,
        fillOpacity: 0.8,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: [3.7, 9.5] },
          { label: "1.2 - 3.0", y: [1.2, 3.0] },
          { label: "0.6 - 1.9", y: [0.6, 1.9] },
          { label: "0.4 - 1", y: [0.4, 1.0] },
        ],
      },
      {
        type: "scatter",
        showInLegend: false,
        color: "#FFF",
        markerColor: "#000",
        lineThickness: 3,
        markerSize: 10,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: 6.6 },
          { label: "1.2 - 3.0", y: 2.1 },
          { label: "0.6 - 1.9", y: 1.25 },
          { label: "0.4 - 1", y: 0.7 },
        ],
      },
    ],
  };

  const showFromMonthPicker = async (e, date) => {
    e.preventDefault();
    let newFormValues = [...monthList];
    newFormValues.forEach((item) => (item.selected = false));
    date.selected = !date.selected;
    setMonthList(newFormValues);
    await dispatch(
      getTestResultAction(
        getUserInfo.userInfo.data.kitActivatedID,
        date.month,
        date.year
      )
    );
  };

  const downloadHandler = async () => {
    if (testResultPDFURL) {
      fetch(testResultPDFURL)
        .then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download =
              testResultPDFURL.split("/")[
                testResultPDFURL.split("/").length - 1
              ];
            alink.click();
          });
        })
        .catch((error) => {
          window.open(testResultPDFURL, "_blank");
        });
    } else {
      setLoading(true);
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(getUserInfo.userInfo.data.kitActivatedID)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(
          getTestResultPDFURL &&
            getTestResultPDFURL.data &&
            getTestResultPDFURL.data.url
        );
        fetch(getTestResultPDFURL.data.url)
          .then((response) => {
            response.blob().then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download =
                getTestResultPDFURL.data.url.split("/")[
                  getTestResultPDFURL.data.url.split("/").length - 1
                ];
              alink.click();
            });
          })
          .catch((error) => {
            window.open(getTestResultPDFURL.data.url, "_blank");
          });
        setLoading(false);
      }
    }
  };

  // const getRanges = (R, min, max) => {

  //   const absMax = min;
  //   const absMin;
  // }

  const printHandler = async () => {
    if (testResultPDFURL) {
      window.open(testResultPDFURL, "PRINT", "fullscreen=yes");
    } else {
      setLoading(true);
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(getUserInfo.userInfo.data.kitActivatedID)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(getTestResultPDFURL.data.url);
        window.open(getTestResultPDFURL.data.url, "PRINT", "fullscreen=yes");
        setLoading(false);
      }
    }
  };

  const shareHandler = async () => {
    setShareModal(false);
  };

  const shareOpenHandler = async () => {
    if (testResultPDFURL) {
      setTestResultPDFURL(testResultPDFURL);
      setShareModal(true);
    } else {
      setLoading(true);
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(getUserInfo.userInfo.data.kitActivatedID)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(getTestResultPDFURL.data.url);
        setShareModal(true);
        setLoading(false);
      }
    }
  };

  return (
    <Content
      currentMenu="testResults"
      headerTitle="Test Results"
      navigationRoute={[
        {
          path: "/",
          label: "Dashboard",
          routes: [
            {
              path: "/test-results",
              label: "Test Results",
            },
          ],
        },
      ]}
      addBtn={false}
      addBtnText="Add New User"
      addBtnIcon="plus"
      addBtnClick={() => {}}
      search={true}
      searchPlaceholder="Search Users..."
      searchIcon="search"
      searchvalue={"search"}
      searchOnChange={""}
    >
      {(getTestResult.loading || loading) && <Spinner />}
      {!getTestResult.loading &&
        getTestResult &&
        getTestResult.userInfo &&
        getTestResult.userInfo.data &&
        getTestResult.userInfo.data.tests &&
        getTestResult.userInfo.data.tests.length > 0 && (
          <div className="test_result-container">
            <div className="test_result-heading">
              <div className="test_result-container-top">
                <div></div>
                <div className="test_result-actionBtn">
                  <ButtonPrefix
                    style={{
                      backgroundColor: "#fffafa00",
                      border: "none",
                      color: "#4B4B4B",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "21px",
                      justifyContent: "center",
                      padding: "0.5rem",
                    }}
                    icon="printer"
                    onClick={printHandler}
                    label={"Print"}
                  />

                  <ButtonPrefix
                    style={{
                      backgroundColor: "#fffafa00",
                      border: "none",
                      color: "#4B4B4B",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "21px",
                      justifyContent: "center",
                      padding: "0.5rem",
                    }}
                    icon="share"
                    onClick={shareOpenHandler}
                    label={"Share"}
                  />

                  <ButtonPrefix
                    style={{
                      backgroundColor: "#fffafa00",
                      border: "none",
                      color: "#4B4B4B",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "21px",
                      justifyContent: "center",
                      padding: "0.5rem",
                    }}
                    icon="download"
                    onClick={downloadHandler}
                    label={"Download"}
                  />
                </div>
              </div>

              <div className="test_result_top_heading">
                <h1 className="test_result-heading">Test Results</h1>
                <p className="test_result-subheading">
                  Below you will find the results from your most recent test.
                </p>
              </div>
            </div>

            <div className="test_result-content">
              <div>
                <h1 className="test_result-heading">
                  Cortisol Profile Results
                </h1>
                <p className="test_result-subheading">
                  Your cortisol profile results will give you a general look at
                  how your cortisol hormones are performing.
                </p>
              </div>

              <div className="test_result_cortisol_graph">
                <div className="test_result_graph_item">
                  <h1> Your Results </h1>
                  <CanvasJSChart
                    options={optionsResult}
                    // onRef={ref => this.chart = ref}
                  />
                </div>
                <div className="test_result_graph_item">
                  <h1> Optimal Results </h1>
                  <CanvasJSChart
                    options={optionsOptimalResult}
                    // onRef={ref => this.chart = ref}
                  />
                </div>
              </div>

              <div className="cortisol_container">
                <h1 className="test_result-heading">Cortisol Levels (ng/ml)</h1>
                <p className="test_result-subheading">
                  Your cortisol results are divided into four intervals;
                  pre-awakening, mid-morning, afternoon and evening. See how
                  your cortisol levels change throught the day to better
                  understand your stress and feel empowered to make changes.
                </p>

                <div className="test_result_cortisol_card">
                  {getTestResult &&
                    getTestResult.userInfo &&
                    getTestResult.userInfo.data &&
                    getTestResult.userInfo.data.tests &&
                    getTestResult.userInfo.data.tests.length > 0 &&
                    getTestResult.userInfo.data.tests.map(
                      (item, index) =>
                        item.name === "Cortisol" && (
                          <div key={index} className="test_result_item">
                            <div>
                              <RangeInput
                                min={item.rangeMinimum}
                                max={item.rangeMaximum}
                                values={[item.result]}
                                onChange={onChangeRange}
                              />
                            </div>
                            <h1 className="test_result_cortisol_card-heading">
                              {" "}
                              {extractString(item.range)}{" "}
                              {item.range.split(" ")[0]}{" "}
                            </h1>
                            <h3 className="test_result_cortisol_card-description">
                              You’re
                              {getRangeLabel(item)}
                            </h3>
                          </div>
                        )
                    )}
                </div>
              </div>

              <div className="dheas_container">
                <h1 className="test_result-heading">
                  DHEAS Daily Level (ng/ml)
                </h1>
                <p className="test_result-subheading">
                  Your dehydroepiandrosterone (DHEAS) daily level will give you
                  a general overview at how this key stress hormone is
                  performing throughout the day.
                </p>
                <div
                  className="test_result_cortisol_card"
                  style={{ marginTop: "3.4rem", marginBottom: "0" }}
                >
                  {getTestResult &&
                    getTestResult.userInfo &&
                    getTestResult.userInfo.data &&
                    getTestResult.userInfo.data.tests &&
                    getTestResult.userInfo.data.tests.length > 0 &&
                    getTestResult.userInfo.data.tests.map(
                      (item, index) =>
                        item.name === "DHEAS" && (
                          <div key={index} className="test_result_item">
                            <div>
                              <RangeInput
                                min={item.rangeMinimum}
                                max={item.rangeMaximum}
                                values={[item.result]}
                                onChange={onChangeRange}
                              />
                            </div>
                            <h1 className="test_result_cortisol_card-heading">
                              Daily Optimal Range: {item.range.split(" ")[0]}{" "}
                              {
                                item.range.split(" ")[
                                  item.range.split(" ").length - 2
                                ]
                              }{" "}
                              {
                                item.range.split(" ")[
                                  item.range.split(" ").length - 1
                                ]
                              }{" "}
                            </h1>
                            <h3 className="test_result_cortisol_card-description">
                              You’re
                              {getRangeLabel(item)}
                            </h3>
                          </div>
                        )
                    )}
                </div>
              </div>

              <div className="biological_container">
                <h1 className="test_result-heading">
                  Your Biological Results Overview
                </h1>
                <p className="test_result-subheading">
                  Your biological response overview indicated below will explain
                  how your biology is handling your specific stress load.
                </p>

                <p
                  className="test_result-subheading"
                  style={{ marginTop: "calc(2.1rem - .5rem)" }}
                >
                  {getTestResult &&
                    getTestResult.userInfo &&
                    getTestResult.userInfo.data &&
                    "Your " +
                      getTestResult.userInfo.data.comments
                        .split("\r\n")[2]
                        .split("For additional")[0]}
                </p>

                <p
                  className="test_result-subheading"
                  style={{ marginTop: "calc(2.1rem - .5rem)" }}
                >
                  {getTestResult &&
                    getTestResult.userInfo &&
                    getTestResult.userInfo.data &&
                    "Your " +
                      getTestResult.userInfo.data.comments.split("\r\n")[0]}
                </p>
              </div>
              <div className="recommended_reads">
                <svg className="test_result-icon">
                  <use xlinkHref={`./assets/sprite.svg#icon-open-book`} />
                </svg>
                <div>
                  <h1 className="test_result-h1">
                    Our Stresskase Medical Staff Recommended Reads:
                  </h1>

                  <p className="test_result-desc medical_staff_details">
                    {getTestResult &&
                      getTestResult.userInfo &&
                      getTestResult.userInfo.data &&
                      ReactHtmlParser(
                        getTestResult.userInfo.data.comments
                          .split("\r\n")[2]
                          .split(":")
                          [
                            getTestResult.userInfo.data.comments
                              .split("\r\n")[2]
                              .split(":").length - 1
                          ].replaceAll('"The', "<br/> <b> The")
                          .replaceAll(
                            "Adrenal Fatigue",
                            "<b>Adrenal Fatigue</b>"
                          )
                          .replaceAll('"', "</b>")
                          .replaceAll(";", "")
                      )}
                  </p>
                </div>
              </div>
            </div>

            <div className="test_result-section-btn">
              {monthList && monthList.length > 1 && (
                <div
                  className="test_result-heading"
                  style={{ marginTop: "calc(6.2rem - 2rem)" }}
                >
                  <h1 className="test_result-heading">Your Archived Results</h1>
                  <div className="MonthYearPicker">
                    {monthList.map((item, i) => (
                      <div key={i}>
                        <button
                          className="filter-btn"
                          disabled={item.selected}
                          onClick={(event) => {
                            showFromMonthPicker(event, item);
                          }}
                        >
                          {months[item.month - 1]} {item.year}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <Button
                style={{
                  marginTop: "calc(5.9rem - 2rem)",
                  backgroundColor: "#fffafa00",
                  border: "none",
                  color: "#4B4B4B",
                  fontWeight: "500",
                  fontSize: "2.2rem",
                  justifyContent: "flex-end",
                  padding: "0rem",
                  width: "100%",
                }}
                label=" Recommendations > "
                onClick={() => history("/recommendations-supplements")}
              />
            </div>
          </div>
        )}

      <ShareModal
        data={{
          getTestResultPDFURL: testResultPDFURL ? testResultPDFURL : null,
          showAddModal: shareModal,
          handleCancelModalClose: shareHandler,
        }}
      />
    </Content>
  );
};

export default TestResultScreen;
