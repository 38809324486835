import {
	LOGOUT_USER_FAIL,
	LOGOUT_USER_REQUEST,
	LOGOUT_USER_RESET,
	LOGOUT_USER_SUCCESS,

	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,

	GET_USER_BY_ID_REQUEST,
	GET_USER_BY_ID_SUCCESS,
	GET_USER_BY_ID_FAIL,
	GET_USER_BY_ID_RESET,

	POST_ACTIVATION_KITS_RESET,
	POST_ACTIVATION_KITS_FAIL,
	POST_ACTIVATION_KITS_SUCCESS,
	POST_ACTIVATION_KITS_REQUEST,

	REGISTER_KIT_REQUEST,
	REGISTER_KIT_SUCCESS,
	REGISTER_KIT_FAIL,
	REGISTER_KIT_RESET,

	CHANGE_PWD_REQUEST,
	CHANGE_PWD_SUCCESS,
	CHANGE_PWD_FAIL,
	CHANGE_PWD_RESET,
	GET_USER_ADDRESS_RESET,
	GET_USER_ADDRESS_FAIL,
	GET_USER_ADDRESS_SUCCESS,
	GET_USER_ADDRESS_REQUEST,
	ADD_USER_ADDRESS_REQUEST,
	ADD_USER_ADDRESS_RESET,
	ADD_USER_ADDRESS_FAIL,
	ADD_USER_ADDRESS_SUCCESS,
	GET_ACTIVATION_KITS_STATUS_REQUEST,
	GET_ACTIVATION_KITS_STATUS_SUCCESS,
	GET_ACTIVATION_KITS_STATUS_FAIL,
	GET_ACTIVATION_KITS_STATUS_RESET,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	UPDATE_USER_RESET,
	UPDATE_USER_ADDRESS_REQUEST,
	UPDATE_USER_ADDRESS_SUCCESS,
	UPDATE_USER_ADDRESS_FAIL,
	UPDATE_USER_ADDRESS_RESET,
	DELETE_USER_ADDRESS_REQUEST,
	DELETE_USER_ADDRESS_SUCCESS,
	DELETE_USER_ADDRESS_FAIL,
	DELETE_USER_ADDRESS_RESET,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	RESET_PASSWORD_RESET,
	GET_BILLING_ADDRESS_REQUEST,
	GET_BILLING_ADDRESS_SUCCESS,
	GET_BILLING_ADDRESS_FAIL,
	GET_BILLING_ADDRESS_RESET,
	ADD_BILLING_ADDRESS_REQUEST,
	ADD_BILLING_ADDRESS_SUCCESS,
	ADD_BILLING_ADDRESS_FAIL,
	ADD_BILLING_ADDRESS_RESET,
	UPDATE_BILLING_ADDRESS_REQUEST,
	UPDATE_BILLING_ADDRESS_SUCCESS,
	UPDATE_BILLING_ADDRESS_FAIL,
	UPDATE_BILLING_ADDRESS_RESET,
	DELETE_BILLING_ADDRESS_REQUEST,
	DELETE_BILLING_ADDRESS_SUCCESS,
	DELETE_BILLING_ADDRESS_FAIL,
	DELETE_BILLING_ADDRESS_RESET,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_RESET,
	FORGOT_PASSWORD_FAIL,
	FORGOT_PASSWORD_SUCCESS,
	GET_NOTIFICATIONS_REQUEST,
	GET_NOTIFICATIONS_SUCCESS,
	GET_NOTIFICATIONS_FAIL,
	GET_NOTIFICATIONS_RESET,
	SUBSCRIBE_NEWSLETTER_REQUEST,
	SUBSCRIBE_NEWSLETTER_SUCCESS,
	SUBSCRIBE_NEWSLETTER_FAIL,
	SUBSCRIBE_NEWSLETTER_RESET
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };

		case USER_LOGIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };

		case USER_LOGOUT:
			return {};

		default:
			return state;
	}
};


export const forgotPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD_REQUEST:
			return { loading: true };

		case FORGOT_PASSWORD_SUCCESS:
			return { userInfo: action.payload, loading: false, status: action.status };

		case FORGOT_PASSWORD_FAIL:
			return { loading: false, error: action.payload };

		case FORGOT_PASSWORD_RESET:
			return {};

		default:
			return state;
	}
};



export const getUserByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_BY_ID_REQUEST:
			return { loading: true };

		case GET_USER_BY_ID_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_USER_BY_ID_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_BY_ID_RESET:
			return {};

		default:
			return state;
	}
};


export const getActiviteKitReducer = (state = {}, action) => {
	switch (action.type) {
		case POST_ACTIVATION_KITS_REQUEST:
			return { loading: true };

		case POST_ACTIVATION_KITS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case POST_ACTIVATION_KITS_FAIL:
			return { loading: false, error: action.payload };

		case POST_ACTIVATION_KITS_RESET:
			return {};

		default:
			return state;
	}
};

export const getActiviteKitStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ACTIVATION_KITS_STATUS_REQUEST:
			return { loading: true };

		case GET_ACTIVATION_KITS_STATUS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ACTIVATION_KITS_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case GET_ACTIVATION_KITS_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const logOutUserReducer = (state = {}, action) => {
	switch (action.type) {
		case LOGOUT_USER_REQUEST:
			return { loading: true };

		case LOGOUT_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case LOGOUT_USER_FAIL:
			return { loading: false, error: action.payload };

		case LOGOUT_USER_RESET:
			return {};

		default:
			return state;
	}
};


export const registerKitReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_KIT_REQUEST:
			return { loading: true };

		case REGISTER_KIT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case REGISTER_KIT_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_KIT_RESET:
			return {};

		default:
			return state;
	}
};


export const changePwdReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_PWD_REQUEST:
			return { loading: true };

		case CHANGE_PWD_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CHANGE_PWD_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_PWD_RESET:
			return {};

		default:
			return state;
	}
};



export const resetPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case RESET_PASSWORD_REQUEST:
			return { loading: true };

		case RESET_PASSWORD_SUCCESS:
			return { userInfo: action.payload, loading: false, status: action.status };

		case RESET_PASSWORD_FAIL:
			return { loading: false, error: action.payload };

		case RESET_PASSWORD_RESET:
			return {};

		default:
			return state;
	}
};



export const getUserAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_ADDRESS_REQUEST:
			return { loading: true };

		case GET_USER_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_USER_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};


export const addUserAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_USER_ADDRESS_REQUEST:
			return { loading: true };

		case ADD_USER_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case ADD_USER_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case ADD_USER_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};



export const updateUserAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_USER_ADDRESS_REQUEST:
			return { loading: true };

		case UPDATE_USER_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_USER_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_USER_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};


export const removeUserAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_USER_ADDRESS_REQUEST:
			return { loading: true };

		case DELETE_USER_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_USER_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_USER_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};


export const updateUserReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_USER_REQUEST:
			return { loading: true };

		case UPDATE_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_USER_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_USER_RESET:
			return {};

		default:
			return state;
	}
};



export const getBillingAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_BILLING_ADDRESS_REQUEST:
			return { loading: true };

		case GET_BILLING_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_BILLING_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case GET_BILLING_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};


export const addBillingAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_BILLING_ADDRESS_REQUEST:
			return { loading: true };

		case ADD_BILLING_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case ADD_BILLING_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case ADD_BILLING_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};



export const updateBillingAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_BILLING_ADDRESS_REQUEST:
			return { loading: true };

		case UPDATE_BILLING_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_BILLING_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_BILLING_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};


export const removeBillingAddressReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_BILLING_ADDRESS_REQUEST:
			return { loading: true };

		case DELETE_BILLING_ADDRESS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DELETE_BILLING_ADDRESS_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_BILLING_ADDRESS_RESET:
			return {};

		default:
			return state;
	}
};



export const getNotificationsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_NOTIFICATIONS_REQUEST:
			return { loading: true };

		case GET_NOTIFICATIONS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_NOTIFICATIONS_FAIL:
			return { loading: false, error: action.payload };

		case GET_NOTIFICATIONS_RESET:
			return {};

		default:
			return state;
	}
};


export const subcribeNewsLetterReducer = (state = {}, action) => {
	switch (action.type) {
		case SUBSCRIBE_NEWSLETTER_REQUEST:
			return { loading: true };

		case SUBSCRIBE_NEWSLETTER_SUCCESS:
			return { data: action.payload, loading: false };

		case SUBSCRIBE_NEWSLETTER_FAIL:
			return { loading: false, error: action.payload };

		case SUBSCRIBE_NEWSLETTER_RESET:
			return {};

		default:
			return state;
	}
};



