import React from "react";
import Content from "../../components/Content";
import "./ContactUsScreen.css";

const { REACT_APP_BASE_URL } = process.env;

const ContactUsScreen = () => {
  // const dispatch = useDispatch();
  // const userLogin = useSelector((state) => state.userLogin);
  // const { loading, error } = userLogin;

  return (
    <Content
      currentMenu="contactus"
      headerTitle="Contact Us"
      navigationRoute={[
        {
          path: "/",
          label: "Dashboard",
          routes: [
            {
              path: "/contact-us",
              label: "Contact Us",
            },
          ],
        },
      ]}
      addBtn={false}
      addBtnText="Add New User"
      addBtnIcon="plus"
      addBtnClick={() => {}}
      search={true}
      searchPlaceholder="Search Users..."
      searchIcon="search"
      searchvalue={"search"}
      searchOnChange={""}
    >
      <div className="contactus-container" style={{ minHeight: "60vh" }}>
        <div className="contactus-heading">
          <h1 className="contactus_dashboard-heading1">Contact Us</h1>
          <p>
            Protip: Before contacting us, visit our{" "}
            <a
              href={`${REACT_APP_BASE_URL}/help-center`}
              target="_blank"
              className="subscriptions-link"
            >
              Help Center
            </a>{" "}
            to see if your question can be answered.
          </p>
        </div>
        <div className="contact_web-row">
          <div className="contact_web-column">
            <svg className="contactus_icon contact_us_email_icon">
              <use xlinkHref={`/assets/sprite.svg#icon-mail`} />
            </svg>
            For customer support:
            <br />
            <a
              href="mailto:customerservice@stresskase.com"
              style={{ textDecoration: "none" }}
              className="line"
            >
              customerservice@stresskase.com
            </a>
          </div>
          <div className="contact_web-column">
            <svg className="contactus_icon contact_us_message_icon">
              <use xlinkHref={`/assets/sprite.svg#icon-message`} />
            </svg>
            For press related inquiries:
            <br />
            <a
              href="mailto:press@stresskase.com"
              style={{ textDecoration: "none" }}
              className="line"
            >
              press@stresskase.com
            </a>
          </div>
          <div className="contact_web-column">
            <svg className="contactus_icon contact_us_contact_icon">
              <use xlinkHref={`/assets/sprite.svg#icon-connect`} />
            </svg>
            For business related inquiries: <br />
            <a
              href="mailto:letstalk@stresskase.com"
              style={{ textDecoration: "none" }}
              className="line"
            >
              letstalk@stresskase.com
            </a>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ContactUsScreen;
