import React from 'react';
import Modal from '../../components/Modal';
import ModalForm from '../../components/Modal/ModalForm';
import InputBox from '../../components/formInputs/InputBox';
import SelectBox from '../../components/formInputs/SelectBox';
import InputsSections from '../../components/Modal/InputSections';
import CheckBox from '../../components/formInputs/CheckBox';
import InputsSection from '../../components/Modal/InputsSection';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import Button from '../../components/formInputs/Button';
import { stateValidatorSchema } from '../../utils/validators';
import FormValidation from '../../utils/formValidation';
import { addPaymentMethodAction } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { countryObj } from '../../utils/for';

const CreateEditPayment = ({ data }) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();

	const {
		showAddModal,
		handleAddModalClose,
		stateList,
		newPaymentMethodSchema
	} = data;



	const addNewPaymentMethodHandler = async (state) => {
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement),
			billing_details: {
				address: {
					city: state.city,
					country: countryObj.iso2,
					line1: state.address,
					line2: state.appartment,
					postal_code: state.zip,
					state: state.state
				},
				name: state.nameOnCard,
				// email: null,
				// phone: null
			},
		})

		if (!error) {
			let paymentData = {}
			const { id } = paymentMethod;
			paymentData.id = id;
			paymentData.isDefault = state.isPrimary;

			if (paymentData.id) {
				await dispatch(addPaymentMethodAction(paymentData));
			}
		}
	};

	const {
		handleOnChange,
		handleOnSubmit,
		errors,
		dirty,
		disable,
		setValues,
		values: newPaymentMethod,
		setValues: setNewPaymentMethodValues,
		setErrors: setNewPaymentMethodErrors,
		setDirty: setNewPaymentMethodDirty
	} = FormValidation(newPaymentMethodSchema, stateValidatorSchema, addNewPaymentMethodHandler);

	const {
		firstName,
		lastName,
		address,
		appartment,
		city,
		state,
		zip,
		isPrimary,
		nameOnCard,
	} = newPaymentMethod;

	const clearData = () => {
		setNewPaymentMethodValues({
			firstName: '',
			lastName: '',
			address: '',
			appartment: '',
			city: '',
			state: '',
			zip: '',
			isPrimary: '',
			nameOnCard: '',
		})
		setNewPaymentMethodErrors({
			firstName: "*This is required field",
			lastName: "*This is required field",
			address: "*This is required field",
			appartment: "*This is required field",
			city: "*This is required field",
			state: "*This is required field",
			zip: "*This is required field",
			isPrimary: "*This is required field",
			nameOnCard: "*This is required field",
		})
		setNewPaymentMethodDirty({
			firstName: false,
			lastName: false,
			address: false,
			appartment: false,
			city: false,
			state: false,
			zip: false,
			isPrimary: false,
			nameOnCard: false,
		})
	}

	return (
		<Modal show={showAddModal}>
			<div className='modal__heading--container'>
				<h2 className='modal__heading2'>Add Payment Method</h2>
				<button onClick={() => {
					clearData()
					handleAddModalClose()
				}} className='modal__heading--btn'>
					<svg className='modal__heading--icon modal__heading-close-icon'>
						<use xlinkHref={`/assets/sprite.svg#icon-close`} />
					</svg>
				</button>
			</div>
			<ModalForm onSubmit={handleOnSubmit} style={{ marginBottom: '1rem' }}>
				<div className="modal_container">
					<div >
						{/* <div className="account_form--heading3">
						Accepted Cards: <img src='./assets/accept_card.png' width='80vh' alt='stresskase payment' />
					</div> */}
						<div className='payment-card-container'>
							<div >
								<label className='payment-card-label'>
									Card Number
								</label>
								<div className='payment-card-number'>
									<CardNumberElement
										options={{
											showIcon: true,
											style: {
												base: {
													iconColor: '#4B4B4B',
													color: '#4B4B4B',
													fontFamily: 'inherit',
													fontSize: '14px',
													fontWeight: '500',
													fontSmoothing: 'antialiased',
													':-webkit-autofill': {
														color: '#4B4B4B'
													},
													'::placeholder': {
														color: '#4B4B4B',
														fontWeight: '500',
														fontFamily: 'inherit',
													}
												}
											}
										}}
									/>
								</div>
							</div>
							<div>
								<label className='payment-card-label'>
									Security Code
								</label>
								<div className='payment-card-cvv'>
									<CardCvcElement
										options={{
											showIcon: true,
											style: {
												base: {
													iconColor: '#4B4B4B',
													color: '#4B4B4B',
													fontFamily: 'inherit',
													fontSize: '14px',
													fontWeight: '500',
													fontSmoothing: 'antialiased',
													':-webkit-autofill': {
														color: '#4B4B4B'
													},
													'::placeholder': {
														color: '#4B4B4B',
														fontWeight: '500',
														fontFamily: 'inherit',
													}
												}
											}
										}}
									/>
								</div>
							</div>
							<div>
								<label className='payment-card-label'>
									Expiration Date
								</label>
								<div className='payment-card-month'>
									<CardExpiryElement
										options={{
											showIcon: true,
											style: {
												base: {
													iconColor: '#4B4B4B',
													color: '#4B4B4B',
													fontFamily: 'inherit',
													fontSize: '14px',
													fontWeight: '500',
													fontSmoothing: 'antialiased',
													':-webkit-autofill': {
														color: '#4B4B4B'
													},
													'::placeholder': {
														color: '#4B4B4B',
														fontWeight: '500',
														fontFamily: 'inherit',
													}
												}
											}
										}}
									/>
								</div>
							</div>
						</div>
						<InputsSections>
							<InputBox
								id="nameOnCard"
								type='text'
								placeholder='Ex. John Doe'
								errorMessage={errors && errors.nameOnCard && dirty.nameOnCard && errors.nameOnCard}
								value={nameOnCard}
								onChange={handleOnChange}
								label='Name on Card'
								icon='user'
							/>
						</InputsSections>
					</div>
					<h1 className='account_form--heading'>Billing Address</h1>
					<InputsSections style={{ flexDirection: 'row' }}>
						<InputBox
							id="firstName"
							type='text'
							placeholder='Ex. John'
							errorMessage={errors && errors.firstName && dirty.firstName && errors.firstName}
							value={firstName}
							onChange={handleOnChange}
							label='First Name'
							icon='user'
						/>
						<InputBox
							id="lastName"
							type='text'
							placeholder='Ex. Doe'
							errorMessage={errors && errors.lastName && dirty.lastName && errors.lastName}
							value={lastName}
							onChange={handleOnChange}
							label='Last Name'
							icon='user'
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="address"
							name="address"
							type='text'
							placeholder='Ex. 123 Stress Free Lane'
							errorMessage={errors && errors.address && dirty.address && errors.address}
							value={address}
							onChange={handleOnChange}
							label='Street Address'
							icon='pin'
							maxLength="150"
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="appartment"
							name="appartment"
							type='text'
							placeholder='Ex. Apt 456'
							errorMessage={errors && errors.appartment && dirty.appartment && errors.appartment}
							value={appartment}
							onChange={handleOnChange}
							label='C/O , Apt, Suite, Building, Floor'
							icon='pin'
							maxLength="150"
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="city"
							type='text'
							errorMessage={errors && errors.city && dirty.city && errors.city}
							value={city}
							onChange={handleOnChange}
							name='city'
							label='City'
							placeholder='Ex. San Francisco'
							icon={false}
						/>

						<SelectBox
							errorMessage={errors && errors.state && dirty.state && errors.state}
							value={state}
							onChange={handleOnChange}
							icon={false}
							label='State'
							name='state'
							containerStyle={{ width: '100%' }}
							textBoxStyle={{ width: '100%' }}
						>
							<option value='' >Select State</option>
							{
								stateList &&
								stateList.length > 0 &&
								stateList.map((item, index) => (
									<option value={item.name} key={index + 1}>
										{item.name}
									</option>
								))}
						</SelectBox>

						<InputBox
							id="zip"
							type='text'
							placeholder='Ex. 92101'
							name='zip'
							errorMessage={errors && errors.zip && dirty.zip && errors.zip}
							value={zip}
							onChange={handleOnChange}
							label='Postal Code'
							maxLength="6"
							icon={false}
						/>
					</InputsSections>
				</div>

				<div className="modal_container_footer">
					<InputsSection>
						<CheckBox
							id="default"
							type='checkbox'
							name='isPrimary'
							style={{ gap: '1.8rem' }}
							checked={isPrimary}
							onChange={(event) => setValues({ ...newPaymentMethod, isPrimary: !isPrimary })}
							errorMessage={false}
							label='Set as default payment method'
							icon='key'
						/>
					</InputsSection>
					<Button
						disabled={disable}
						style={{
							justifyContent: 'center',
							width: '100%'
						}}
						label='Save'
						onClick={handleOnSubmit}
					/>
				</div>
			</ModalForm>
		</Modal>
	);
};

export default CreateEditPayment;


