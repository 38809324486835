import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Content from "../../components/Content";
import Button, { ButtonPrefix } from "../../components/formInputs/Button";
import ProductCard from "../../components/ProductCard";
import { getRecommendationByProductAction } from "../../redux/actions/productActions";
import "./RecommendationsScreen.css";
import "react-toastify/dist/ReactToastify.min.css";
import { SUPPLEMENTS_COLLECTION_BY_ID_PROD } from "../../redux/actions/ip";
import { getTestResultPDFURLAction } from "../../redux/actions/resultActions";
import ShareModal from "../../components/ShareModal";
import {
  getRecommendations,
  getUserData,
  getSupplementRecommendations,
  addItemToCart,
} from "../../utils/api";

export default function RecommendationsScreen(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const getTestResult = useSelector((state) => state.getTestResult);

  const [productList, setProductList] = useState([]);
  const [testResultPDFURL, setTestResultPDFURL] = useState(null);
  const [shareModal, setShareModal] = useState(false);

  const [kitId, setKitId] = useState(null);
  const [recs, setRecs] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const userData = await getUserData();
    const { kitActivatedID } = userData;
    setKitId(kitActivatedID);
    const recData = await getRecommendations(kitActivatedID);
    setRecs(recData.data);
    const supplementRecData = await getSupplementRecommendations(
      kitActivatedID
    );
    setProductList(supplementRecData.data);
  }

  // useEffect(() => {

  //   setProductList([restUp, onTheGo, getGoing, chillOut]);

  // }, []);

  React.useEffect(() => {
    if (
      getTestResult &&
      getTestResult.userInfo &&
      getTestResult.userInfo.data &&
      getTestResult.userInfo.data.tests &&
      getTestResult.userInfo.data.tests.length > 0
    ) {
      let cortisol_range = {
        post_awakening: 0,
        mid_morning: 0,
        afternoon: 0,
        evening: 0,
      };

      getTestResult.userInfo.data.tests.forEach((item, index) => {
        if (item.name === "Cortisol") {
          var extracted = item.range.split(" ");
          if (extracted[extracted.length - 1] === "(morning)") {
            cortisol_range.post_awakening = parseFloat(item.result);
          } else if (extracted[extracted.length - 1] === "(noon)") {
            cortisol_range.mid_morning = parseFloat(item.result);
          } else if (extracted[extracted.length - 1] === "(evening)") {
            cortisol_range.afternoon = parseFloat(item.result);
          } else if (extracted[extracted.length - 1] === "(night)") {
            cortisol_range.evening = parseFloat(item.result);
          }
        }
      });
      dispatch(
        getRecommendationByProductAction(SUPPLEMENTS_COLLECTION_BY_ID_PROD, {
          cortisol_range: cortisol_range,
        })
      );
    }
  }, [getTestResult, dispatch]);

  const onQtyChange = (qty, id) => {
    setProductList((x) => {
      const itemIndex = x.findIndex((i) => i.id === id);
      x[itemIndex].quantity = qty;
      return x;
    });
  };

  const downloadHandler = async () => {
    if (testResultPDFURL) {
      fetch(testResultPDFURL)
        .then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download =
              testResultPDFURL.split("/")[
                testResultPDFURL.split("/").length - 1
              ];
            alink.click();
          });
        })
        .catch((error) => {
          window.open(testResultPDFURL, "_blank");
        });
    } else {
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(kitId)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(
          getTestResultPDFURL &&
            getTestResultPDFURL.data &&
            getTestResultPDFURL.data.url
        );
        fetch(getTestResultPDFURL.data.url)
          .then((response) => {
            response.blob().then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download =
                getTestResultPDFURL.data.url.split("/")[
                  getTestResultPDFURL.data.url.split("/").length - 1
                ];
              alink.click();
            });
          })
          .catch((error) => {
            window.open(getTestResultPDFURL.data.url, "_blank");
          });
      }
    }
  };

  const printHandler = async () => {
    if (testResultPDFURL) {
      window.open(testResultPDFURL, "PRINT", "fullscreen=yes");
    } else {
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(kitId)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(getTestResultPDFURL.data.url);
        window.open(getTestResultPDFURL.data.url, "PRINT", "fullscreen=yes");
      }
    }
  };

  const shareHandler = async () => {
    setShareModal(false);
  };

  const shareOpenHandler = async () => {
    if (testResultPDFURL) {
      setTestResultPDFURL(testResultPDFURL);
      setShareModal(true);
    } else {
      const getTestResultPDFURL = await dispatch(
        getTestResultPDFURLAction(kitId)
      );
      if (
        getTestResultPDFURL &&
        getTestResultPDFURL.data &&
        getTestResultPDFURL.data.url
      ) {
        setTestResultPDFURL(getTestResultPDFURL.data.url);
        setShareModal(true);
      }
    }
  };

  const handleAddAllToCart = () => {
    const productIds = [];
    const quantities = [];
    productList.forEach((item) => {
      productIds.push(item.id);
      quantities.push(item.quantity || 1);
    });

    const quantityString = productList.length === 1 ? "quantity" : "quantities";
    window.location.href = `https://stresskase.com/cart/?add-to-cart=${productIds.join()}&${quantityString}=${quantities.join()}`;
  };

  return (
    <Content
      currentMenu="RecommendationsSupplements"
      headerTitle="Recommendations + Supplements"
      navigationRoute={[
        {
          path: "/",
          label: "Dashboard",
          routes: [
            {
              path: "/recommendations-supplements",
              label: "Recommendations + Supplements",
            },
          ],
        },
      ]}
      addBtn={false}
      addBtnText="Add New User"
      addBtnIcon="plus"
      addBtnClick={() => {}}
      search={true}
      searchPlaceholder="Search Users..."
      searchIcon="search"
      searchvalue={"search"}
      searchOnChange={""}
    >
      <div className="recommendations-container">
        {renderActions()}
        {renderVideo()}
        {renderRecommendations()}
        {renderSupplements()}
      </div>

      <ShareModal
        data={{
          getTestResultPDFURL: testResultPDFURL ? testResultPDFURL : null,
          showAddModal: shareModal,
          handleCancelModalClose: shareHandler,
        }}
      />
    </Content>
  );

  function renderActions() {
    return (
      <div className="recommendations-container-top">
        <div></div>
        <div className="recommendations-actionBtn">
          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="printer"
            onClick={printHandler}
            label={"Print"}
          />

          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="share"
            onClick={shareOpenHandler}
            label={"Share"}
          />

          <ButtonPrefix
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            icon="download"
            onClick={downloadHandler}
            label={"Download"}
          />
        </div>
      </div>
    );
  }

  function renderVideo() {
    return (
      <div style={{ marginBottom: 40, width: "100%" }}>
        <div className="product-content-reverse">
          <iframe
            height="500"
            src="https://player.vimeo.com/video/647933833"
            title="Stresskase video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    );
  }

  function renderRecommendations() {
    if (!recs) return;
    const shortDesc = recs.shortDescription.split(".");
    return (
      <div style={{ marginBottom: 40 }}>
        <h1 className="recommendations-heading1">
          Your Lifestyle Recommendations
        </h1>
        <div
          className="recommendation-section"
          style={{ background: "#fff6e2" }}
        >
          <div className="recommendation-section-title">Overview:</div>
          <div>1. How do we determine your Wellness & Lifestyle plan?</div>
          <div>
            2. Your Wellness & Lifestyle Plan has three components - Physical
            Activity, Dietary Advice and Supplements.
          </div>
          <div>3. Importance of Adherence, how to create your success.</div>
          <div>
            4. We will have regular follow-up to help you on your journey.
          </div>
        </div>
        <div className="recommendation-section">
          <div className="recommendation-subsection">
            Your Wellness & Lifestyle plan has been determined by the biological
            samples and answers to the questionnaire you provided us. The{" "}
            <span style={{ fontWeight: 500 }}>supplements, dietary advice</span>{" "}
            and{" "}
            <span style={{ fontWeight: 500 }}>
              physical activity recommendations
            </span>{" "}
            will work in concert to specifically address the biochemical and
            emotional imbalances that lead to your symptoms.
          </div>
          <div className="recommendation-subsection">
            <div className="recommendation-section-title">
              General Assessment & Symptoms:
            </div>
            {shortDesc.map((line, i) => {
              const last = i === shortDesc.length - 1;
              let str = line;
              if (!last) str += ".";
              return <div key={i}>{str}</div>;
            })}
            <ul className="recommend_ul">
              {recs.symptoms.map((item, i) => (
                <li className="product-content-description-subheading2" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>

          <div className="recommendation-subsection">
            <div className="recommendation-section-title">
              Physical Activity Recommendations:
            </div>
            <ul className="recommend_ul">
              {recs.Physical.map((item, i) => (
                <li className="product-content-description-subheading2" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className="recommendation-section-title">Dietary Advice:</div>
            <ul className="recommend_ul">
              {recs.Dietary.map((item, i) => (
                <li className="product-content-description-subheading2" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="recommendation-section"
          style={{ background: "#e0eaf2" }}
        >
          <div className="recommendation-section-title">Success Factors:</div>
          <ul className="recommendation-ul">
            <li>Changing behavior is difficult</li>
            <li>
              The way to deal with implementing change is to set yourself small
              achievable goals.
            </li>
            <li>
              As opposed to medication, behavioral change provides you with a
              set of coping mechanisms that will last a lifetime and can be
              revisited from time to time.
            </li>
            <li>
              Behavioral change will come with ups and downs, do not get
              discouraged by this
            </li>
          </ul>
        </div>
        <div className="recommendations-section-btn">
          <Button
            style={{
              backgroundColor: "#fffafa00",
              border: "none",
              color: "#4B4B4B",
              justifyContent: "center",
              padding: "0.5rem",
            }}
            label="Read More on Stress Managment >"
            onClick={() => history("/about-stress-management")}
          />
        </div>
      </div>
    );
  }

  function renderSupplements() {
    if (!productList || !productList[0]) return;
    return (
      <>
        <div
          className="recommendations-heading"
          style={{ marginTop: "calc(6.1rem - 4.9rem)" }}
        >
          <h1 className="recommendations-heading1">Suggested Supplements</h1>
          {/* <p
            className="recommendations-subheading2"
            style={{ marginTop: ".5rem" }}
          >
            Our Supplements utilize superior raw materials and evidence-based
            formulas to address specific cortisol irregularities brought on by
            stress. At stresskase, we are committed to quality and efficacy and
            believe that better ingredients means better outcomes.
            <br />
            <br />
            Based on your unique cortisol profile, we recommend the following
            supplements and dosage:
          </p> */}
          <div
            className="recommendation-section"
            style={{ background: "#deeddf" }}
          >
            <div style={{ marginBottom: 20 }}>
              Our Supplement Recommendations are based on your unique cortisol
              profile and are to address any irregularities in an effort to
              support you throughout your day.
            </div>
            <div style={{ fontWeight: 500 }}>
              We recommend the following supplements and dosages:
            </div>
          </div>
        </div>
        <div className="product-content" style={{ marginTop: 40 }}>
          {productList.map((product, i) => (
            <ProductCard
              key={i}
              index={i}
              data={product}
              handleSubmit={() => {
                // todo
              }}
              onQtyChange={onQtyChange}
            />
          ))}
          <div className="cart-section-btn">
            <Button
              style={{
                justifyContent: "center",
              }}
              label="Add All To Cart"
              onClick={handleAddAllToCart}
            />
          </div>
          <div
            className="recommendations-section-btn"
            style={{ marginTop: 30 }}
          >
            <Button
              style={{
                backgroundColor: "#fffafa00",
                border: "none",
                color: "#4B4B4B",
                justifyContent: "center",
                padding: "0.5rem",
              }}
              label="Shop All Stresskase Supplements >"
              onClick={() => history("/supplement-collections")}
            />
          </div>
        </div>
      </>
    );
  }
}
