
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatter } from '../../utils/for';
import OrderHistoryCardStyles from './OrderHistoryCardStyles.module.css';

const OrderHistoryCard = ({ data }) => {
	const history = useNavigate(); 

	return (
		<div className='order-history-card-box'>
			<div className={OrderHistoryCardStyles.orderHistoryAddressContainer}>
				<div className={OrderHistoryCardStyles.column}>
					<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading1}>
						Order Number: {data.order.order_number}
					</h1>
				</div>
				<div className={OrderHistoryCardStyles.column}>

				</div>
				<div className={OrderHistoryCardStyles.column} onClick={() => history(`/order-details/${data.order.order_number}`)}>
					<p className='orderHistory-link' style={{ fontWeight: "400" }}>
						View Details
					</p>
				</div>
			</div>

			<div className={OrderHistoryCardStyles.orderHistoryCardContainer}>
				<div className={OrderHistoryCardStyles.columnImageThumb}>
					{data.order && data.order.line_items[0] && <img src={data.order.line_items[0].image.src} alt="Order History thumb" className={OrderHistoryCardStyles.orderCardImage} />}

					<div className={OrderHistoryCardStyles.orderHistoryCardForMobile}>
						<div className={OrderHistoryCardStyles.column}>
							<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
								<span > Total Items:</span>   {data.order.line_items.reduce((acc, item) => acc + item.quantity, 0)}
							</h1>
						</div>
						<div className={OrderHistoryCardStyles.column}>

						</div>
						<div className={OrderHistoryCardStyles.column}>
							<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
								<span >	Order Total: </span>  {formatter.format(data.order.total_price)}
							</h1>
							<div className={OrderHistoryCardStyles.column} onClick={() => history(`/order-details/${data.order.order_number}`)}>
								<p className='orderHistory-link2' >
									View Details
								</p>
							</div>
						</div>
					</div>
				</div>
				<div style={{ width: '100%' }} className={OrderHistoryCardStyles.column}>
					<div className={OrderHistoryCardStyles.orderHistoryCard}>
						<div className={OrderHistoryCardStyles.column}>
							<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
								<span>Total Items: </span> {data.order.line_items.reduce((acc, item) => acc + item.quantity, 0)}
							</h1>
						</div>
						<div className={OrderHistoryCardStyles.column}>

						</div>
						<div className={OrderHistoryCardStyles.column}>
							<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
								<span >	Order Total: </span>{formatter.format(data.order.total_price)}
							</h1>
						</div>
					</div>
					<div className={OrderHistoryCardStyles.orderHistoryCardShippingDetails}>

						<div className='order-history-card-product-detail'>
							<div className='order-history-card-style order-history-card-product-detail-date'>
								<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
									<span > Date: </span> <br />
									{
										new Date(data.order.processed_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit'
										})
									}
								</h1>
							</div>
							<div className='order-history-card-style order-history-card-product-detail-ship'>
								<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
									<span>Shipped To: </span> <br />
									{data.order.customer && data.order.customer.default_address && data.order.customer.default_address.name}

									{/* {data.order.shipping_address.first_name} */}
								</h1>
							</div>
							<div className='order-history-card-style order-history-card-product-detail-track'>
								<h1 className={OrderHistoryCardStyles.orderHistoryCardHeading2}>
									<span >Shipping Method: </span>
									{data.zrtFulfillmentDetails?.responseObjectFullfillment?.carrier || 'N/A'}<br />
									<span > Tracking No:</span>
									{data.zrtFulfillmentDetails?.responseObjectFullfillment?.trackingNumber || 'N/A'}<br />
								</h1>
							</div>
						</div>
						{/* <p className={OrderHistoryCardStyles.column}>
							Shipped To: <br />
							{data.order.shipping_address.first_name} {data.order.shipping_address.first_name}
						</p>
						<p className={OrderHistoryCardStyles.column}>
							Date: <br />
							
							{
								new Date(data.order.processed_at).toLocaleDateString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit'
								})
							}
							{/* {{new Date(data.order.processed_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit'
										})}} */}

						{/* </p>
						<p className={OrderHistoryCardStyles.column}>
							Shipping Method: {data.ShippingMethod} <br />
							Tracking No: {data.TrackingNo} <br />
						</p> */}
					</div>
				</div>
			</div>

		</div>

	);
};

export default OrderHistoryCard;