import React from 'react';
import Modal from '../../components/Modal';
import ModalForm from '../../components/Modal/ModalForm';
import InputBox from '../../components/formInputs/InputBox';
import SelectBox from '../../components/formInputs/SelectBox';
import InputsSections from '../../components/Modal/InputSections';
import Button from '../../components/formInputs/Button';
import { inputDateMasking } from '../../utils/phonemask';
const CreateEditPayment = ({ data }) => {
	const {
		showAddModal,
		handleAddModalClose,
		stateList,
		errors,
		dirty,
		disable,
		// setValues,
		handleOnChange,
		newPaymentMethod,
		handleOnSubmit,
	} = data;

	const {
		firstName,
		lastName,
		address,
		appartment,
		city,
		state,
		zip,
		nameOnCard,
		cardNo,
		expirationDate
	} = newPaymentMethod;

	return (
		<Modal show={showAddModal}>
			<div className='modal__heading--container'>
				<h2 className='modal__heading2'>Edit Payment Method</h2>
				<button onClick={() => {
					handleAddModalClose()
				}} className='modal__heading--btn'>
					<svg className='modal__heading--icon modal__heading-close-icon'>
						<use xlinkHref={`/assets/sprite.svg#icon-close`} />
					</svg>
				</button>
			</div>
			<ModalForm onSubmit={handleOnSubmit} style={{ marginBottom: '1rem' }}>
				<div className="modal_container">
					<div >
						<InputsSections>
							<InputBox
								id="cardNumber"
								type='text'
								placeholder='0001 9870 8765 6751'
								value={'XXXX XXXX XXXX ' + cardNo}
								onChange={handleOnChange}
								label='Card Number'
								icon='user'
								disabled={true}
							/>
							<InputBox
								id="expirationDate"
								type='text'
								placeholder='MM/YYYY'
								value={inputDateMasking(expirationDate)}
								onChange={handleOnChange}
								errorMessage={errors && errors.expirationDate && dirty.expirationDate && errors.expirationDate}
								label='Expiration Date'
								icon='user'
							/>
						</InputsSections>
						<InputsSections>
							<InputBox
								id="nameOnCard"
								type='text'
								placeholder='Ex. John Doe'
								errorMessage={errors && errors.nameOnCard && dirty.nameOnCard && errors.nameOnCard}
								value={nameOnCard}
								onChange={handleOnChange}
								label='Name on Card'
								icon='user'
							/>
						</InputsSections>
					</div>
					<h1 className='account_form--heading'>Billing Address</h1>
					<InputsSections style={{ flexDirection: 'row' }}>
						<InputBox
							id="firstName"
							type='text'
							placeholder='Ex. John'
							errorMessage={errors && errors.firstName && dirty.firstName && errors.firstName}
							value={firstName}
							onChange={handleOnChange}
							label='First Name'
							icon='user'
						/>
						<InputBox
							id="lastName"
							type='text'
							placeholder='Ex. Doe'
							errorMessage={errors && errors.lastName && dirty.lastName && errors.lastName}
							value={lastName}
							onChange={handleOnChange}
							label='Last Name'
							icon='user'
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="address"
							name="address"
							type='text'
							placeholder='Ex. 123 Stress Free Lane'
							errorMessage={errors && errors.address && dirty.address && errors.address}
							value={address}
							onChange={handleOnChange}
							label='Street Address'
							icon='pin'
							maxLength="150"
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="appartment"
							name="appartment"
							type='text'
							placeholder='Ex. Apt 456'
							errorMessage={errors && errors.appartment && dirty.appartment && errors.appartment}
							value={appartment}
							onChange={handleOnChange}
							label='C/O , Apt, Suite, Building, Floor'
							icon='pin'
							maxLength="150"
						/>
					</InputsSections>
					<InputsSections>
						<InputBox
							id="city"
							type='text'
							errorMessage={errors && errors.city && dirty.city && errors.city}
							value={city}
							onChange={handleOnChange}
							name='city'
							label='City'
							placeholder='Ex. San Francisco'
							icon={false}
						/>

						<SelectBox
							errorMessage={errors && errors.state && dirty.state && errors.state}
							value={state}
							onChange={handleOnChange}
							icon={false}
							label='State'
							name='state'
							containerStyle={{ width: '100%' }}
							textBoxStyle={{ width: '100%' }}
						>
							<option value='' >Select State</option>
							{
								stateList &&
								stateList.length > 0 &&
								stateList.map((item, index) => (
									<option value={item.name} key={index + 1}>
										{item.name}
									</option>
								))}
						</SelectBox>

						<InputBox
							id="zip"
							type='text'
							placeholder='Ex. 92101'
							name='zip'
							errorMessage={errors && errors.zip && dirty.zip && errors.zip}
							value={zip}
							onChange={handleOnChange}
							label='Postal Code'
							maxLength="6"
							icon={false}
						/>
					</InputsSections>
				</div>

				<div className="modal_container_footer">
					<Button
						disabled={disable}
						style={{
							justifyContent: 'center',
							width: '100%'
						}}
						label='Update'
						onClick={handleOnSubmit}
					/>
				</div>
			</ModalForm>
		</Modal>
	);
};

export default CreateEditPayment;


{/* <InputsSection>
						<CheckBox
							id="default"
							type='checkbox'
							name='isPrimary'
							style={{ gap: '1.8rem' }}
							checked={isPrimary}
							onChange={(event) => setValues({ ...newPaymentMethod, isPrimary: !isPrimary })}
							errorMessage={false}
							label='Set as default payment method'
							icon='key'
						/>
					</InputsSection> */}