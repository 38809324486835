import React from 'react';
import CheckBox from './CheckBox.module.css';

const InputBox = ({ placeholder, checked, onChange, errorMessage, label, disabled, defaultValue, id, style }) => {

	return (
		<div className={CheckBox.form_input} data-testid='switch-box' style={style}>
			<input
				type='checkbox'
				id={id}
				name={id}
				disabled={disabled ? disabled : false}
				placeholder={placeholder}
				checked={checked}
				onChange={onChange}
				className={CheckBox.form_input_checkbox}
			/>
			{label && (
				<label htmlFor={id} className='form_input__label' style={{paddingTop: '10px',marginLeft: '5px', fontWeight: '400'}}>
					{label}
				</label>
			)}
			<div style={{ height: '1rem' }}>
				{errorMessage && <p className={CheckBox.form_input__error}>{errorMessage}</p>}
			</div>
		</div>
	);
};

export default InputBox;
