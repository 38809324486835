import React from 'react'; 

const Spinner = () => {
	return (
		<div className="spinner_style">
			<img
				src={'./assets/loaing_circle.gif'}
				style={{
					width: '15rem',
					position: 'absolute',
					// top: '60%',
					// left: '50%',
					// transform: 'translate(-50%, -50%)',
					// backdropFilter:' blur(1px)'
				}}
				alt='Loading...'
			/> 
		</div>
	);
};

export default Spinner;
