import {
    CANCEL_SUBSCRIPTION_FAIL,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_RESET,
    CANCEL_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_FAIL,
    UPDATE_SUBSCRIPTION_REQUEST,
    UPDATE_SUBSCRIPTION_RESET,
    UPDATE_SUBSCRIPTION_SUCCESS
} from "../constants/subscriptionConstants";



export const cancelSubscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case CANCEL_SUBSCRIPTION_REQUEST:
            return { loading: true };

        case CANCEL_SUBSCRIPTION_SUCCESS:
            return { data: action.payload, loading: false };

        case CANCEL_SUBSCRIPTION_FAIL:
            return { loading: false, error: action.payload };

        case CANCEL_SUBSCRIPTION_RESET:
            return {};

        default:
            return state;
    }
};



export const updateSubscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_REQUEST:
            return { loading: true };

        case UPDATE_SUBSCRIPTION_SUCCESS:
            return { data: action.payload, loading: false };

        case UPDATE_SUBSCRIPTION_FAIL:
            return { loading: false, error: action.payload };

        case UPDATE_SUBSCRIPTION_RESET:
            return {};

        default:
            return state;
    }
};
