import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import "./ShareModal.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ModalForm from '../../components/Modal/ModalForm';
import {
    // FacebookShareButton,
    // TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton
} from "react-share";
import {
    // FacebookIcon,
    // TwitterIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";

const ShareModal = ({ data }) => {
    const textAreaRef = React.useRef(null);
    const {
        getTestResultPDFURL,
        showAddModal,
        handleCancelModalClose
    } = data;


    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        e.preventDefault();
        toast.success('Link copied', {
            icon: "✔"
        });
    };

    return (
        <Modal show={showAddModal}>
            <ModalHeading heading={'Share a link'} onClose={handleCancelModalClose} />
            <ModalForm style={{ marginBottom: '1rem' }}>
                <div className="shareModalCss">
                    <div className='form_input' style={{ width: '100%' }}>
                        <label className='form_input__label'>
                            Share "{getTestResultPDFURL && getTestResultPDFURL.split('/')[getTestResultPDFURL && getTestResultPDFURL.split('/').length - 1]}"
                        </label>
                        <div className='form_input__container'>
                            <textarea
                                ref={textAreaRef}
                                value={getTestResultPDFURL && getTestResultPDFURL}
                                className='form_input__box'
                            />
                        </div>
                    </div>
                    {
                        document.queryCommandSupported('copy') &&
                        <div>
                            <button onClick={copyToClipboard} className='copy-btn'>
                                <svg className='navigation__icon'>
                                    <use xlinkHref={`/assets/sprite.svg#icon-copy`} />
                                </svg> Copy Link
                            </button>
                        </div>
                    }
                </div>

                <div className="shareModalCss" style={{ gap: '5px', alignItems: 'flex-start', justifyContent: 'flex-start', margin: '1rem' }}>
                    {/* <FacebookShareButton
                        url={getTestResultPDFURL && getTestResultPDFURL}
                        quote={"Test Results"}
                        hashtag={"#stresskase"}>
                        <FacebookIcon size={32} round={false} />
                    </FacebookShareButton>

                    <TwitterShareButton
                        quote={"Test Results"}
                        url={getTestResultPDFURL && getTestResultPDFURL}
                        hashtag={"#stresskase"}>
                        <TwitterIcon size={32} round={false} />
                    </TwitterShareButton> */}

                    <WhatsappShareButton
                        quote={"Test Results"}
                        url={getTestResultPDFURL && getTestResultPDFURL}
                        hashtag={"#stresskase"}>
                        <WhatsappIcon size={32} round={false} />
                    </WhatsappShareButton>

                    <EmailShareButton
                        quote={"Test Results"}
                        url={getTestResultPDFURL && getTestResultPDFURL}
                        hashtag={"#stresskase"}>
                        <EmailIcon size={32} round={false} />
                    </EmailShareButton>
                </div>
            </ModalForm>
        </Modal>
    );
};

export default ShareModal; 