import axios from 'axios';
import { CANCEL_SUBSCRIPTION_FAIL, CANCEL_SUBSCRIPTION_REQUEST, CANCEL_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION_FAIL, UPDATE_SUBSCRIPTION_REQUEST, UPDATE_SUBSCRIPTION_SUCCESS } from '../constants/subscriptionConstants';
import { BASE_URL } from './ip';
import { logout } from './userActions';

export const getSubscriptionsAction = (id) => async (dispatch, getState) => {
	try {
		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/subscription`, config);

		return data;
	} catch (err) {
		if (err && err.response && err.response.status === 401) {
			dispatch(logout());
		}
		return err.response && err.response.data ? err.response.data : err.message;
	}
};



export const cancelSubscriptionsAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.put(`${BASE_URL}/subscription/cancel`, formData, config);

		dispatch({
			type: CANCEL_SUBSCRIPTION_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: CANCEL_SUBSCRIPTION_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};



export const updateSubscriptionsAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_SUBSCRIPTION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.put(`${BASE_URL}/subscription`, formData, config);

		dispatch({
			type: UPDATE_SUBSCRIPTION_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: UPDATE_SUBSCRIPTION_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


