import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const ActivitySkeleton = () => {
	return (
		<>
			<div className={[SkeletonStyles.textBoxSkeleton1, SkeletonStyles.skeleton].join(' ')} /> 
		</>)
};

export default ActivitySkeleton;