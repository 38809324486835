import React, { Fragment } from 'react'
import WebsiteContent from '../../components/Content/WebsiteContent'
import { SelectInput } from './SelectBox'
import Button from '../../components/formInputs/Button'
import NoData from '../../components/NoData'
import { Link, useNavigate } from 'react-router-dom'
import CartCard from './CartCard/CartCard'
import {
	addCartByUserAction,
	deleteItemCartAction,
	getCartByUserAction,
	getCartCountByUserAction
} from '../../redux/actions/productActions'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
	ADD_USER_BY_CART_RESET,
	CHECKOUT_CART_RESET,
	GET_USER_BY_CART_COUNT_RESET,
	GET_USER_BY_CART_RESET
} from '../../redux/constants/productConstants'
import { encryptData, formatter } from '../../utils/for';
import { validatePromoCodeAction } from '../../redux/actions/userActions'
import SignInRegister from '../SignInRegisterModal/SignInRegister'
import PromoCodeAccordion from '../../components/PromoCodeAccordion/PromoCodeAccordion'
import Spinner from '../../components/Spinner/Spinner';
import { PRODUCT_TYPE } from '../../redux/actions/ip';
import FormValidation from '../../utils/formValidation';
import { stateValidatorSchema } from '../../utils/validators';
import CartScreenStyles from './CartScreen.module.css';

const CartScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [totalPrice, setTotalPrice] = React.useState(0);
	const [totalItems, setTotalItems] = React.useState(0);
	const [markedPrice, setMarkedPrice] = React.useState(0);
	const getCartCountByUser = useSelector((state) => state.getCartCountByUser);
	const cartProducts = useSelector((state) => state.cartProducts.cart);
	const userLogin = useSelector((state) => state.userLogin);
	const getCartByUser = useSelector((state) => state.getCartByUser);
	const addCartByUser = useSelector((state) => state.addCartByUser);
	const [productList, setProductList] = React.useState([]);
	const [showSignInModal, setShowSignInModal] = React.useState(false);
	const [promoCodeDetail, setPromocCodeDetail] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [cartCount, setCartCount] = React.useState(0);

	const stateSchema = {
		promoCode: { value: '', error: '' }
	};

	const onSubmitForm = async (state) => {
		let data = await dispatch(validatePromoCodeAction(state.promoCode));
		if (data.data) {
			setPromocCodeDetail(data.data);
			localStorage.setItem('promoCodeDetail', JSON.stringify(data.data))
			toast.success(data.message, {
				icon: "✔"
			});
		} else {
			setPromocCodeDetail({});
			localStorage.removeItem('promoCodeDetail');
			toast.error(data.message, {
				icon: "✕"
			});
		}
	}

	const { values, errors, dirty, handleOnChange, handleOnSubmit, disable, setValues, setDirty } = FormValidation(stateSchema, stateValidatorSchema, onSubmitForm);


	React.useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
				dispatch(getCartByUserAction());
				dispatch(getCartCountByUserAction());
				localStorage.removeItem('checkOutList');
				if (localStorage.getItem('promoCodeDetail')) {
					setPromocCodeDetail(JSON.parse(localStorage.getItem('promoCodeDetail')));
					setValues({ ...values, promoCode: JSON.parse(localStorage.getItem('promoCodeDetail')).title });
					setDirty({ ...dirty, promoCode: false });
				}
			}
		},
		[userLogin, dispatch]
	);


	React.useEffect(
		() => {
			setLoading(true);
			if (getCartByUser &&
				getCartByUser.data &&
				getCartByUser.data.data &&
				getCartByUser.data.data.product &&
				getCartByUser.data.data.product.length > 0) {
				getCartByUser.data.data.product.forEach((el) => {
					if (el.product) {
						el.img = el.product.image.src;
						el.quantity = el.quantity;
						el.frequency = el.frequency;
						el.title = el.product.title;
						el.body_html = el.product.body_html;
						el.price = JSON.parse(el.product.variants[0].price);
					}
				});

				setProductList(getCartByUser.data.data.product)
			} else if (cartProducts && cartProducts.length > 0) {
				cartProducts.forEach((el) => {
					if (el) {
						el.img = el.image && el.image.src;
						el.price = el.variants && el.variants.length > 0 && JSON.parse(el.variants[0].price);
					}
				});

				setProductList(cartProducts)
			}

			setTimeout(() => {
				setLoading(false);
			}, 2500);
		},
		[getCartByUser, cartProducts]
	);


	React.useEffect(
		() => {
			if (addCartByUser && addCartByUser.data && addCartByUser.data.data) {
				toast.success(addCartByUser.data.message, {
					icon: "✔"
				});
				setProductList([])
				dispatch(getCartByUserAction());
				dispatch(getCartCountByUserAction());
				dispatch({ type: GET_USER_BY_CART_RESET });
				dispatch({ type: GET_USER_BY_CART_COUNT_RESET });
				dispatch({ type: CHECKOUT_CART_RESET });
				dispatch({ type: ADD_USER_BY_CART_RESET });
			} else if (addCartByUser && addCartByUser.data && !addCartByUser.data.data) {
				dispatch({ type: ADD_USER_BY_CART_RESET });
				toast.error(addCartByUser.data.message, {
					icon: "✕"
				});
			}
		},
		[addCartByUser, dispatch]
	);




	React.useEffect(() => {
		let items = 0;
		let price = 0;
		let MRP = 0;
		let disc = 0;
		productList && productList.forEach((item) => {
			items += item.quantity;
			price += item.quantity * item.price;
			MRP += item.quantity * item.price;
			disc = MRP - price
		});

		setTotalItems(items)
		setMarkedPrice(MRP)

		if (promoCodeDetail && promoCodeDetail.value_type === 'percentage') {
			setTotalPrice(price - Math.abs(percentage(markedPrice, promoCodeDetail.value)))
		} else if (promoCodeDetail && promoCodeDetail.value_type === 'fixed_amount') {
			setTotalPrice(price - Math.abs(promoCodeDetail.value))
		} else setTotalPrice(price)

	}, [promoCodeDetail, productList])


	const onQtyChange = (event, item) => {
		let newFormValues = [...productList];
		item.quantity = event.target.value;
		setProductList([...newFormValues]);
		updateCartHandler(newFormValues);
	}


	const onFrequncyChange = (event, item) => {
		let newFormValues = [...productList];
		item.frequency = event.target.value;
		setProductList([...newFormValues]);
		updateCartHandler(newFormValues);
	}


	const updateCartHandler = (data) => {
		let products = [];
		if (data &&
			data.length > 0) {
			data.forEach((item) => {
				products.push({
					"variant_id": item.variant_id,
					"quantity": item.quantity,
					"product_id": item.product_id,
					"frequency": item.frequency !== undefined ? item.frequency : 'once',
					"collectionId": item.collectionId,
					"product_type": item.product_type
				})
			})
		}

		const arrayUniqueByKey = [...new Map(products.map(item => [item["product_id"], item])).values()];

		dispatch(addCartByUserAction({
			products: encryptData(arrayUniqueByKey)
		}));
	}

	const gPayItemlist = async () => {
		let Items = [];
		await productList.forEach((product) => {
			Items.push({
				"label": product.title,
				"type": 'LINE_ITEM',
				"price": product.price.toString()
			})
		});
		localStorage.setItem('checkOutList', JSON.stringify(Items));
	}

	const checkOutHandler = async () => {
		if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
			await gPayItemlist();
			history('/checkout');
		} else {
			setShowSignInModal(true);
		}
	}


	const handleAddModalClose = () => {
		setShowSignInModal(false)
	}

	const percentage = (percent, total) => {
		return ((percent / 100) * total).toFixed(2)
	}

	React.useEffect(() => {
		if (getCartCountByUser && getCartCountByUser.data && getCartCountByUser.data.data) {
			setCartCount(getCartCountByUser.data.data)
		} else {
			setCartCount(0);
		}
	}, [getCartCountByUser]);


	React.useEffect(() => {
		if (cartProducts && cartProducts.length > 0) {
			setCartCount(cartProducts.length)
		} else {
			setCartCount(0)
		}
	}, [cartProducts])

	return (
		<WebsiteContent style={{ gap: '0px', marginBottom: '-52px' }}>
			{
				!showSignInModal ?
					<>
						<div className={CartScreenStyles.CartHeader}>
							<h1>{`Your Cart (${getCartCountByUser.loading ? ' ' : cartCount})`}</h1>
							<hr width="90%;" color="#F2F2F2" size="1" />
						</div>

						<div className={CartScreenStyles.cart_section}>
							<div className={CartScreenStyles.cart_content_right}>
								<div className={CartScreenStyles.cart_Item_heading}>
									<div>
										Product
									</div>
									<div>
										Price
									</div>
									<div>
										Quantity
									</div>
									<div>
										Frequency
									</div>
									<div>
										Total
									</div>
									<div className={CartScreenStyles.cart_action}>
									</div>
								</div>
								{getCartByUser.loading || getCartCountByUser.loading || loading ? (
									<Fragment>
										<Spinner />
									</Fragment>
								) :
									productList &&
										productList.length > 0 ? (
										productList.map((product, i) => (
											<div className={CartScreenStyles.cart_Item} key={i}>
												<div className={CartScreenStyles.cart_name}>
													<img src={product.img} alt="thumb" className={CartScreenStyles.cart_thumb} />
													<p>
														{product && product.title && product.title.slice(0, 15)}
													</p>
												</div>
												<div className={CartScreenStyles.cart_price}>
													{formatter.format(product.price)}
												</div>
												<div className={CartScreenStyles.cart_qty}>
													<SelectInput
														value={product.quantity}
														onChange={(event) => {
															onQtyChange(event, product);
														}}
														icon={false}
														label={false}
														name='quantity'
														containerStyle={{ width: '60px' }}
													>
														{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((type, index) => (
															<option value={type} key={index + 1}>
																{type}
															</option>
														))}
													</SelectInput>
												</div>

												{product &&
													product.product_type === PRODUCT_TYPE ?
													<div className={CartScreenStyles.cart_frequnecy}>
														N/A
													</div> :
													<div className={CartScreenStyles.cart_frequnecy}>
														<SelectInput
															value={product.frequency}
															onChange={(event) => {
																onFrequncyChange(event, product);
															}}
															icon={false}
															label={false}
															name='frequency'
															containerStyle={{ width: '130px' }}
														>
															<option value='once'>
																Once
															</option>
															<option value='monthly'>
																Monthly
															</option>
														</SelectInput>
													</div>
												}

												<div className={CartScreenStyles.cart_total}>
													{formatter.format(product.price * product.quantity)}
												</div>
												<div className={CartScreenStyles.cart_action}>
													<Button
														style={{
															backgroundColor: '#fffafa00',
															border: 'none',
															color: '#4B4B4B',
															fontWeight: '400',
															fontSize: '1.8rem',
															justifyContent: 'center',
															padding: '0.5rem',
															textDecoration: 'underline'
														}}
														label="Remove"
														onClick={() => {
															if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
																let productListtmp = [...productList]
																productListtmp.splice(i, 1)
																setProductList([...productListtmp])
																updateCartHandler(productListtmp)
															} else {
																dispatch(deleteItemCartAction(product.id))
																let productListtmp = [...productList]
																productListtmp.splice(i, 1)
																setProductList([...productListtmp])
															}
														}}
													/>
												</div>
											</div>
										))) : (
										<div className={CartScreenStyles.shop_now_content}>
											<NoData
												title='Your cart is currently empty.'
												height='10vh'
											/>
											<button
												className={CartScreenStyles.shopnow_button}
												onClick={() => {
													window.open('https://app.stresskase.com/#/', "_self")
												}}
											>
												Shop Now
											</button>
										</div>
									)}
							</div>

							<div className={CartScreenStyles.cart_content_right_mob}>
								{getCartByUser.loading || getCartCountByUser.loading || loading ? (
									<Fragment>
										<Spinner />
									</Fragment>
								) :
									productList &&
										productList.length > 0 ? (
										productList.map((product, i) =>
											<CartCard
												data={product}
												key={i}
												handleSubmit={() => {
													let productListtmp = [...productList]
													productListtmp.splice(i, 1)
													setProductList([...productListtmp])
													updateCartHandler(productListtmp)
												}}
												onQtyChange={onQtyChange}
												onFrequncyChange={onFrequncyChange}
											/>
										)) : (
										<div className={CartScreenStyles.shop_now_content}>
											<NoData
												title='Your cart is currently empty.'
												height='10vh'
											/>
											<button
												className={CartScreenStyles.shopnow_button}
												onClick={() => {
													window.open('https://app.stresskase.com/#/', "_self")
												}}
											>
												Shop Now
											</button>
										</div>
									)}
							</div>

							<div className={CartScreenStyles.cart_content_left}>
								{productList &&
									productList &&
									productList.length > 0 &&
									<div className={CartScreenStyles.order_summary_container}>
										<h1 className={CartScreenStyles.order_summary_heading}>
											Order Summary
										</h1>

										<div className={CartScreenStyles.summary_items}>
											<div className={[CartScreenStyles.summary_content, CartScreenStyles.summary_content_border].join(' ')}>
												<p>Items Subtotal </p>
												<p>{formatter.format(markedPrice)}</p>
											</div>
											{/* <div  className={CartScreenStyles.summary_content}>
												<p>
													Discount
													{promoCodeDetail &&
														promoCodeDetail.value_type &&
														promoCodeDetail.value_type === 'percentage' &&
														' (' + promoCodeDetail.value + '% )'
													}
												</p>
												<p>${
													promoCodeDetail &&
														promoCodeDetail.value_type &&
														promoCodeDetail.value_type === 'percentage' ?
														percentage(markedPrice, promoCodeDetail.value)
														:
														promoCodeDetail &&
															promoCodeDetail.value_type &&
															promoCodeDetail.value_type === 'fixed_amount' ?
															promoCodeDetail.value / 100 : 0
												}</p>
											</div> */}
										</div>
										<div className={[CartScreenStyles.promo_code, CartScreenStyles.summary_content_border, 'promo_content'].join(' ')}>
											<PromoCodeAccordion title={"Promo Code?"}
												style={{ marginBottom: '10rem', display: 'flex' }}
												data={{
													values,
													errors,
													dirty,
													handleOnChange,
													handleOnSubmit,
													disable,
													setValues,
													onSubmitForm
												}}
											/>
										</div>

										<div className={CartScreenStyles.total_price}>
											<h1 className={CartScreenStyles.order_summary_heading}>
												Order Total
											</h1>
											<h1 className={CartScreenStyles.order_summary_heading}>
												{formatter.format(totalPrice)}
											</h1>
										</div>
										<div className={CartScreenStyles.Checkout_btn}>
											<Button
												style={{ justifyContent: 'center', width: '100%', height: '50px' }}
												label='Checkout'
												icon={false}
												onClick={checkOutHandler}
											/>

											<p className={CartScreenStyles.footer_copyright_text}>
												By completing your purchase you agree to our <Link to='/terms-service' > Terms of Service </Link>.
											</p>
										</div>
										{/* <div className='button--checkout'></div> */}
									</div>}
							</div>
						</div>
					</>
					:
					<SignInRegister data={{
						showAddModal: showSignInModal,
						setShowModal: setShowSignInModal,
						handleAddModalClose: handleAddModalClose,
					}} />}
		</WebsiteContent>
	)
}

export default CartScreen

