export const validateOnlyChacters = (name) => {
	const re = /^[a-zA-Z \s]+$/;
	return re.test(name);
};

export const validatePassword = (password) => {
	const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
	return re.test(password);
};

export const validateEmail = (email) => {
	const re = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	return re.test(email);
};

export const validatePhone = (phone) => {
	const re = /^[0-9]{10}$/;
	// const re = /^[0-9]{5,12}$/;
	return re.test(phone);
};

export const validatePrice = (phone) => {
	const re = /^[0-9]{1,3}(\.\d{1,3})?%?$/;
	// const re = /^[0-9]{5,12}$/;
	return re.test(phone);
};

export const validateMonth = (phone) => {
	const re = /^[1-9]{1,2}$/;
	// const re = /^[0-9]{5,12}$/;
	return re.test(phone);
};

export const months = [
	"Jan.",
	"Feb.",
	"Mar.",
	"Apr.",
	"May",
	"Jun.",
	"Jul.",
	"Aug.",
	"Sep.",
	"Oct.",
	"Nov.",
	"Dec."
];

export const stateValidatorSchema = {
	email: {
		required: true,
		validator: {
			func: value => /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value),
			error: "Please enter a valid email address"
		}
	},
	password: {
		required: true,
		validator: {
			func: value => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/.test(value),
			error: "Please enter a valid password. Ex.John@doe123-@#$_&"
		}
	},
	confirmPassword: {
		required: true,
		validator: {
			func: value => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/.test(value),
			error: "Please enter a valid confirm password. Ex. John@doe123-@#$_&"
		}
	},
	emailLogin: {
		required: true,
		validator: {
			func: value => /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value),
			error: "Please enter a valid email."
		}
	},
	passwordLogin: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?&/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid password."
		}
	},
	emailForgotPwd: {
		required: true,
		validator: {
			func: value => /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value),
			error: "Please enter a valid password. Ex.John@doe123-@#$_&"
		}
	},
	phone: {
		required: true,
		validator: {
			func: value => /^[0-9-]{12}$/.test(value),
			error: "Please enter a valid phone"
		}
	},
	name: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z ]+$/.test(value),
			error: "Please enter a valid name"
		}
	},
	nameOnCard: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z ]+$/.test(value),
			error: "Please enter a valid name on card"
		}
	},
	firstName: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z]+$/.test(value),
			error: "Please enter a valid first name"
		}
	},
	lastName: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z]+$/.test(value),
			error: "Please enter a valid last name"
		}
	},
	gender: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z-]+$/.test(value),
			error: "Please select a valid gender"
		}
	},
	genderAssigned: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z-]+$/.test(value),
			error: "Please select a valid gender assigned at birth"
		}
	},
	validateOnlyChacters: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z \s]+$/.test(value),
			error: "Please enter a valid characters"
		}
	},
	address: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?&/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid address"
		}
	},
	appartment: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid appartment"
		}
	},
	country: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z -\s]+$/.test(value),
			error: "Please enter a valid country"
		}
	},
	city: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z -\s]+$/.test(value),
			error: "Please enter a valid city"
		}
	},
	company: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z0-9 -@.\s]+$/.test(value),
			error: "Please enter a valid company name"
		}
	},
	state: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z \s]+$/.test(value),
			error: "Please select a valid state"
		}
	},
	zip: {
		required: true,
		validator: {
			func: value => /^[0-9\s]+$/.test(value),
			error: "Please enter a valid postal code"
		}
	},
	country_code: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z0-9 \s]+$/.test(value),
			error: ""//"Please enter a valid country code"
		}
	},
	state_code: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z0-9 \s]+$/.test(value),
			error: ""//"Please enter a valid state code"
		}
	},
	isPrimary: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z0-9\s]+$/.test(value),
			error: "Please enter a valid isPrimary"
		}
	},
	isSame: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z0-9\s]+$/.test(value),
			error: "Please enter a valid shipping address same"
		}
	},
	dob: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z0-9 /\s]+$/.test(value),
			// Please select date of birth
			error: ""
		}
	},
	activiteKitId: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z0-9-\s]+$/.test(value),
			error: "Please enter kit id"
		}
	},
	promoCode: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z0-9]+$/.test(value),
			error: 'Please enter a valid promo code'
		}
	},
	firstNameShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z]+$/.test(value),
			error: "Please enter a valid first name"
		}
	},
	lastNameShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z]+$/.test(value),
			error: "Please enter a valid last name"
		}
	},
	addressShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?&/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid address"
		}
	},
	appartmentShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid appartment"
		}
	},
	countryShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z -\s]+$/.test(value),
			error: "Please enter a valid country"
		}
	},
	cityShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z -\s]+$/.test(value),
			error: "Please enter a valid city"
		}
	},
	company: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z0-9 -@.\s]+$/.test(value),
			error: "Please enter a valid company name"
		}
	},
	stateShippingAddr: {
		required: true,
		validator: {
			func: value => /^[a-zA-Z \s]+$/.test(value),
			error: "Please select a valid state"
		}
	},
	zipShippingAddr: {
		required: true,
		validator: {
			func: value => /^[0-9\s]+$/.test(value),
			error: "Please enter a valid postal code"
		}
	},
	cardNo: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?&/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid card number"
		}
	},
	expirationDate: {
		required: false,
		validator: {
			func: value => /^[a-zA-Z$@$!%*?&/\. - 0-9\s]+$/.test(value),
			error: "Please enter a valid expiration date."
		}
	},
};