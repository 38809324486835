import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./CheckoutScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdressAction,
  getAdressAction,
  getPaymentMethodAction,
  getStateByCountryAction,
  getUserByIdAction,
  updateAdressAction,
} from "../../redux/actions/userActions";
import {
  convertDate,
  inputDateMasking,
  unMasking,
} from "../../utils/phonemask";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  // PAYPAL_CLIENT_KEY,
  STRIPE_PUBLISHABLE_KEY,
} from "../../redux/actions/ip";
import StripePayment from "./StripePayment";
import {
  checkOutAction,
  getCartByUserAction,
} from "../../redux/actions/productActions";
import GooglePayButton from "@google-pay/button-react";
import jwt_decode from "jwt-decode";
import AddresCard, { PaymentCard } from "../../components/AddresCard";
import Button from "./Button";
import AddressForm from "./AddressForm";
import AddressFormModal from "./AddressFormModal";
import { stateValidatorSchema } from "../../utils/validators";
import FormValidation from "../../utils/formValidation";
import InputsSections from "../../components/Modal/InputSections";
import Spinner from "../../components/Spinner/Spinner";
import {
  ADD_BILLING_ADDRESS_RESET,
  ADD_USER_ADDRESS_RESET,
  UPDATE_USER_ADDRESS_RESET,
} from "../../redux/constants/userConstants";
import { CHECKOUT_CART_RESET } from "../../redux/constants/productConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import OrderConfirm from "./OrderConfirm";
import NoData from "../../components/NoData";
import { getOrderConfirmDetailAction } from "../../redux/actions/orderActions";
import { GET_ORDER_CONFIRM_DETAILS_RESET } from "../../redux/constants/orderConstants";
import { countryObj, formatter } from "../../utils/for";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const { REACT_APP_BASE_URL } = process.env;

const CheckoutScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const getPaymentMethod = useSelector((state) => state.getPaymentMethod);
  const cartProducts = useSelector((state) => state.cartProducts.cart);
  const getCartByUser = useSelector((state) => state.getCartByUser);
  const addUserAddress = useSelector((state) => state.addUserAddress);
  const updateUserAddress = useSelector((state) => state.updateUserAddress);

  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [markedPrice, setMarkedPrice] = React.useState(0);
  const [promoCodeDetail, setPromocCodeDetail] = React.useState({});
  const [shippingAddress, setShippingAddress] = React.useState([]);
  const [showAddNewAddressModal, setShowAddNewAddressModal] =
    React.useState(false);
  const [formType, setFormType] = React.useState("addrForm");
  const [shippingData, setShippingData] = React.useState(shippingAddress[0]);
  const [paymentData, setPaymentData] = React.useState(null);
  const [displayItemsArr, setDisplayItemsArr] = React.useState(null);

  const checkOut = useSelector((state) => state.checkOut);
  const orderConfirm = useSelector((state) => state.orderConfirm);
  const getUserAddress = useSelector((state) => state.getUserAddress);
  const addPaymentMethod = useSelector((state) => state.addPaymentMethod);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      getStateListHandler();
      dispatch(getAdressAction());
      dispatch(getCartByUserAction());
      dispatch(getUserByIdAction(decoded._id));
      dispatch(getPaymentMethodAction());

      if (localStorage.getItem("promoCodeDetail")) {
        setPromocCodeDetail(
          JSON.parse(localStorage.getItem("promoCodeDetail"))
        );
      }
      if (localStorage.getItem("checkOutList")) {
        setDisplayItemsArr(JSON.parse(localStorage.getItem("checkOutList")));
      }
    }
  }, [userLogin, dispatch]);

  React.useEffect(() => {
    if (
      getUserAddress &&
      getUserAddress.userInfo &&
      getUserAddress.userInfo.data &&
      getUserAddress.userInfo.data.length > 0
    ) {
      let shippingAddressList = [];
      getUserAddress.userInfo.data.forEach((item, index) => {
        shippingAddressList.push({
          _id: item._id,
          firstName: item.firstname,
          lastName: item.lastname,
          address: item.streetaddress,
          appartment: item.building,
          city: item.city,
          state: item.state,
          zip: item.postalcode,
          phone: item.phone,
          company: item.company,
          shopifyAddressID: item.shopifyAddressID,
          // isPrimary: item.isPrimary, // ? item.isPrimary : false
          customerID: item.customerID,
          country: item.country,
          country_code: item.country_code,
          state_code: item.state_code,
        });
      });
      setShippingAddress([...shippingAddressList]);
    }
  }, [getUserAddress]);

  React.useEffect(() => {
    if (
      getCartByUser &&
      getCartByUser.data &&
      getCartByUser.data.data &&
      getCartByUser.data.data.product &&
      getCartByUser.data.data.product.length > 0
    ) {
      getCartByUser.data.data.product.forEach((el) => {
        if (el.product) {
          el.img = el.product.image.src;
          el.quantity = el.quantity;
          el.frequency = el.frequency;
          el.title = el.product.title;
          el.body_html = el.product.body_html;
          el.price = JSON.parse(el.product.variants[0].price);
        }
      });

      setProductList(getCartByUser.data.data.product);
    } else if (cartProducts && cartProducts.length > 0) {
      cartProducts.forEach((el) => {
        if (el) {
          el.img = el.image && el.image.src;
          el.price =
            el.variants &&
            el.variants.length > 0 &&
            JSON.parse(el.variants[0].price);
        }
      });

      setProductList(cartProducts);
    }
  }, [getCartByUser, cartProducts]);

  React.useEffect(() => {
    let items = 0;
    let price = 0;
    let MRP = 0;
    let disc = 0;

    productList &&
      productList.forEach((item) => {
        items += item.quantity;
        price += item.quantity * item.price;
        MRP += item.quantity * item.price;
        disc = MRP - price;
      });

    setTotalItems(items);
    setMarkedPrice(MRP);
    if (promoCodeDetail && promoCodeDetail.value_type === "percentage") {
      setTotalPrice(
        price - Math.abs(percentage(markedPrice, promoCodeDetail.value))
      );
    } else if (
      promoCodeDetail &&
      promoCodeDetail.value_type === "fixed_amount"
    ) {
      setTotalPrice(price - Math.abs(promoCodeDetail.value));
    } else setTotalPrice(price);
  }, [productList, promoCodeDetail]);

  const percentage = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };

  React.useEffect(() => {
    if (
      addPaymentMethod &&
      addPaymentMethod.userInfo &&
      addPaymentMethod.userInfo.data
    ) {
      dispatch(getPaymentMethodAction());
      toast.success(addPaymentMethod.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: ADD_BILLING_ADDRESS_RESET });
    } else if (
      addPaymentMethod &&
      addPaymentMethod.error &&
      addPaymentMethod.error.message
    ) {
      toast.error(addPaymentMethod.error.message, {
        icon: "✕",
      });
      dispatch({ type: ADD_BILLING_ADDRESS_RESET });
    }
  }, [addPaymentMethod, dispatch]);

  React.useEffect(() => {
    if (checkOut && checkOut.data && checkOut.data.data) {
      dispatch(getOrderConfirmDetailAction(checkOut.data.data._id));
    } else if (checkOut && checkOut.error && checkOut.error.message) {
      toast.error(checkOut.error.message, {
        icon: "✕",
      });
      dispatch({ type: CHECKOUT_CART_RESET });
    }
  }, [checkOut, dispatch]);

  React.useEffect(() => {
    if (orderConfirm && orderConfirm.data && orderConfirm.data.data) {
      if (orderConfirm.data.data.isPaid) {
        localStorage.removeItem("promoCodeDetail");
        toast.success(orderConfirm.data.message, {
          icon: "✔",
        });
        setFormType("OrderConfirm");
      } else {
        toast.success("Please try again after sometime", {
          icon: "✔",
        });
      }
    } else if (
      orderConfirm &&
      orderConfirm.error &&
      orderConfirm.error.message
    ) {
      toast.error(orderConfirm.error.message, {
        icon: "✕",
      });
      dispatch({ type: GET_ORDER_CONFIRM_DETAILS_RESET });
      dispatch({ type: CHECKOUT_CART_RESET });
    } else if (orderConfirm && orderConfirm.data && orderConfirm.data.message) {
      toast.success(orderConfirm.data.message, {
        icon: "✔",
      });
      dispatch({ type: GET_ORDER_CONFIRM_DETAILS_RESET });
      dispatch({ type: CHECKOUT_CART_RESET });
    }
  }, [orderConfirm, dispatch]);

  // User Add Shipping Address
  React.useEffect(() => {
    if (
      updateUserAddress &&
      updateUserAddress.userInfo &&
      updateUserAddress.userInfo.data
    ) {
      // toast.success(updateUserAddress.userInfo.message, {
      // 	icon: "✔"
      // });
      dispatch(getAdressAction());
      clearData();
      dispatch({ type: UPDATE_USER_ADDRESS_RESET });
    } else if (
      updateUserAddress &&
      updateUserAddress.error &&
      !updateUserAddress.error.message
    ) {
      dispatch({ type: UPDATE_USER_ADDRESS_RESET });
      // toast.error(updateUserAddress.error.message, {
      // 	icon: "✕"
      // });
    }
  }, [updateUserAddress, dispatch]);

  React.useEffect(() => {
    if (
      addUserAddress &&
      addUserAddress.userInfo &&
      addUserAddress.userInfo.data
    ) {
      clearData();
      dispatch(getAdressAction());
      dispatch({ type: ADD_USER_ADDRESS_RESET });
    } else if (
      addUserAddress &&
      addUserAddress.error &&
      addUserAddress.error.message
    ) {
      dispatch({ type: ADD_USER_ADDRESS_RESET });
      // toast.error(addUserAddress.error.message, {
      // 	icon: "✕"
      // });
    }
  }, [addUserAddress, dispatch]);

  const newShippingAddrSchema = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    address: { value: "", error: "" },
    appartment: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    zip: { value: "", error: "" },
    phone: { value: "", error: "" },
    company: { value: "", error: "" },
    isPrimary: { value: false, error: "" },
  };

  const addNewShippingAddrHandler = (shippingNewAddress) => {
    if (shippingNewAddress._id) {
      dispatch(
        updateAdressAction(shippingNewAddress._id, {
          firstname: shippingNewAddress.firstName,
          lastname: shippingNewAddress.lastName,
          streetaddress: shippingNewAddress.address,
          building: shippingNewAddress.appartment,
          city: shippingNewAddress.city,
          state: shippingNewAddress.state,
          postalcode: shippingNewAddress.zip,
          phone: unMasking(shippingNewAddress.phone),
          company: shippingNewAddress.company,
          isPrimary: shippingNewAddress.isPrimary,
          country: countryObj.name,
          country_code: countryObj.iso2,
          state_code: stateList.find((o) => o.name === shippingNewAddress.state)
            .state_code,
        })
      );
    } else {
      dispatch(
        addAdressAction({
          firstname: shippingNewAddress.firstName,
          lastname: shippingNewAddress.lastName,
          streetaddress: shippingNewAddress.address,
          building: shippingNewAddress.appartment,
          city: shippingNewAddress.city,
          state: shippingNewAddress.state,
          postalcode: shippingNewAddress.zip,
          phone: unMasking(shippingNewAddress.phone),
          company: shippingNewAddress.company,
          isPrimary: shippingNewAddress.isPrimary,
          country: countryObj.name,
          country_code: countryObj.iso2,
          state_code: stateList.find((o) => o.name === shippingNewAddress.state)
            .state_code,
        })
      );
    }
  };

  const {
    values: newShippingAddrValues,
    errors: newShippingAddrErrors,
    dirty: newShippingAddrDirty,
    handleOnChange: newShippingAddrHandleOnChange,
    handleOnSubmit: newShippingAddrHandleOnSubmit,
    disable: newShippingAddrDisable,
    setValues: setShippingAddrValues,
    setErrors: setShippingAddrErrors,
    setDirty: setShippingAddrDirty,
  } = FormValidation(
    newShippingAddrSchema,
    stateValidatorSchema,
    addNewShippingAddrHandler
  );

  const handleAddAddressModalClose = () => {
    clearData();
    setShowAddNewAddressModal(false);
  };

  const editHandler = (data) => {
    setShippingAddrValues({ shippingAddress, ...data });
    setShippingAddrErrors({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      company: "",
    });
    setShippingAddrDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      phone: false,
      company: false,
    });
    setShowAddNewAddressModal(true);
  };

  const clearData = () => {
    setShippingAddrValues({
      firstName: "",
      lastName: "",
      address: "",
      appartment: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      company: "",
    });
    setShippingAddrErrors({
      firstName: "*This is required field",
      lastName: "*This is required field",
      address: "*This is required field",
      appartment: "*This is required field",
      city: "*This is required field",
      state: "*This is required field",
      zip: "*This is required field",
      phone: "*This is required field",
      company: "*This is required field",
    });
    setShippingAddrDirty({
      firstName: false,
      lastName: false,
      address: false,
      appartment: false,
      city: false,
      state: false,
      zip: false,
      phone: false,
      company: false,
    });

    setShowAddNewAddressModal(false);
  };

  // Add Payment Method
  const newPaymentMethodSchema = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    address: { value: "", error: "" },
    appartment: { value: "", error: "" },
    city: { value: "", error: "" },
    state: { value: "", error: "" },
    zip: { value: "", error: "" },

    // phone: { value: "", error: "" },
    // company: { value: "", error: "" },
    // isSame: { value: false, error: "" },
    // firstNameShippingAddr: { value: "", error: "" },
    // lastNameShippingAddr: { value: "", error: "" },
    // addressShippingAddr: { value: "", error: "" },
    // appartmentShippingAddr: { value: "", error: "" },
    // cityShippingAddr: { value: "", error: "" },
    // stateShippingAddr: { value: "", error: "" },
    // zipShippingAddr: { value: "", error: "" },
    // // isPrimaryShippingAddr: { value: false, error: "" },
    // countryShippingAddr: { value: "", error: "" }
  };

  React.useEffect(() => {
    if (
      getPaymentMethod &&
      getPaymentMethod.userInfo &&
      getPaymentMethod.userInfo.data &&
      getPaymentMethod.userInfo.data.data &&
      getPaymentMethod.userInfo.data.data.length > 0
    ) {
      let PaymentList = [];
      let paymentProfiles = getPaymentMethod.userInfo.data.data;
      setPaymentData(paymentProfiles[0]);
      paymentProfiles.forEach((item, index) => {
        let address = item.billing_details.address;
        PaymentList.push({
          id: item.id,
          cardNo: item.card.last4,
          expirationDate: inputDateMasking(
            convertDate(item.card.exp_month, item.card.exp_year)
          ), // inputDateMasking(item.card.exp_month + '/' + item.card.exp_year),
          expirationDateError: "",
          nameOnCard:
            item.billing_details &&
            item.billing_details.name &&
            item.billing_details.name,
          nameOnCardError: "",
          brand: item.card.brand,
          firstName:
            item.billing_details &&
            item.billing_details.name &&
            item.billing_details.name.split(" ")[0],
          firstNameError: "",
          lastName:
            item.billing_details &&
            item.billing_details.name &&
            item.billing_details.name.split(" ")[1],
          lastNameError: "",
          address: address.line1,
          addressError: "",
          appartment: address.line2,
          appartmentError: "",
          city: address.city,
          cityError: "",
          country: countryObj.name,
          state: address.state,
          stateError: "",
          zip: address.postal_code,
          zipError: "",
          isPrimary: item.defaultPaymentProfile
            ? item.defaultPaymentProfile
            : false,
          isPaymentValid: false,
          payment_method_type: "stripe",
        });
      });

      setPaymentMethods([...PaymentList]);
    }
  }, [getPaymentMethod]);

  const getStateListHandler = async () => {
    const data = await dispatch(getStateByCountryAction());
    setStateList(data.data);
  };

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    emailRequired: true,
    callbackIntents: ["PAYMENT_AUTHORIZATION"],
    shippingAddressRequired: true,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"], //"MAESTRO" "DISCOVER", "ELECTRON", "ELO", "ELO_DEBIT", "INTERAC",
          billingAddressRequired: true,
          billingAddressParameters: {
            format: "FULL",
            phoneNumberRequired: true,
          },
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "stripe",
            "stripe:version": "2022-08-01", // "2019-12-03",
            "stripe:publishableKey": STRIPE_PUBLISHABLE_KEY, //'pk_test_HLKSK1hyuVsxEIZxIsEivNEj00RsqCdrBq',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: STRIPE_PUBLISHABLE_KEY, //'pk_test_HLKSK1hyuVsxEIZxIsEivNEj00RsqCdrBq',
      merchantName: "stripe",
    },
    // shippingAddress: {},
    transactionInfo: {
      displayItems: displayItemsArr,
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: totalPrice.toString(),
      checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE",
      currencyCode: "USD",
      countryCode: "US",
    },
  };

  const orderHandler = () => {
    let formData = {
      cart:
        getCartByUser &&
        getCartByUser.data &&
        getCartByUser.data.data &&
        getCartByUser.data.data._id,
      shopifyAddressID: shippingData && shippingData.shopifyAddressID,
      promotion_code:
        promoCodeDetail && promoCodeDetail.id && promoCodeDetail.id.toString(),
    };

    if (paymentData.payment_method_type === "google_pay") {
      formData.payment_method_type = "google_pay";
      formData.token_id =
        paymentData &&
        paymentData.paymentMethodData &&
        paymentData.paymentMethodData.tokenizationData.id;
    } else if (paymentData.payment_method_type === "paypal") {
      formData.payment_method_type = "paypal";
      formData.transaction_id = paymentData && paymentData.id;
    } else if (paymentData.payment_method_type === "stripe") {
      formData.payment_method_type = "stripe";
      formData.payment_method = paymentData && paymentData.id;
    } else if (paymentData.type === "card") {
      formData.payment_method_type = "stripe";
      formData.payment_method = paymentData && paymentData.id;
    }

    dispatch(checkOutAction(formData));
  };

  const orderExpressHandler = (paymentObj) => {
    let formData = {
      cart:
        getCartByUser &&
        getCartByUser.data &&
        getCartByUser.data.data &&
        getCartByUser.data.data._id,
      shopifyAddressID: shippingData && shippingData.shopifyAddressID,
      promotion_code:
        promoCodeDetail && promoCodeDetail.id && promoCodeDetail.id.toString(),
    };

    if (paymentObj.payment_method_type === "google_pay") {
      formData.payment_method_type = "google_pay";
      let tokenObj =
        paymentObj &&
        paymentObj.paymentMethodData &&
        paymentObj.paymentMethodData.tokenizationData &&
        JSON.parse(paymentObj.paymentMethodData.tokenizationData.token);
      formData.token_id = tokenObj.id;
    } else if (paymentObj.payment_method_type === "paypal") {
      formData.payment_method_type = "paypal";
      formData.transaction_id = paymentObj && paymentObj.id;
    } else if (paymentObj.payment_method_type === "stripe") {
      formData.payment_method_type = "stripe";
      formData.payment_method = paymentObj && paymentObj.id;
    } else if (paymentObj.type === "card") {
      formData.payment_method_type = "stripe";
      formData.payment_method = paymentObj && paymentObj.id;
    }

    dispatch(checkOutAction(formData));
  };

  return (
    <div className={styles.content}>
      <div className={styles.content_insert}>
        <img
          src="/assets/logo.png"
          alt="Logo"
          className={styles.logo}
          onClick={() => {
            window.open(`${REACT_APP_BASE_URL}/`, "_self");
          }}
        />
        {checkOut.loading && <Spinner />}

        {formType !== "OrderConfirm" && (
          <div className={styles.breadcrumb}>
            <Link to="/cart">Cart</Link>
            <span>{">"}</span>
            {formType === "paymentForm" ? (
              <>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFormType("addrForm");
                  }}
                >
                  Shipping
                </span>
                <span>{">"}</span>
                <p>Payment</p>
              </>
            ) : (
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFormType("addrForm");
                }}
              >
                Shipping
              </span>
            )}
          </div>
        )}

        {formType === "OrderConfirm" ? (
          <OrderConfirm
            setProductList={setProductList}
            data={orderConfirm && orderConfirm.data && orderConfirm.data.data}
          />
        ) : formType === "paymentForm" ? (
          <div>
            <div className={styles.express_checkout}>
              <p className={styles.express_checkout__title}>Express Checkout</p>
              <div className={styles.express_checkout__content}>
                {/* <Link to='#' className={styles.express_checkout__shopPay}>
										<svg>
											<use xlinkHref={`/assets/sprite.svg#icon-shop_pay`} />
										</svg>
									</Link>
									 */}

                <GooglePayButton
                  environment="TEST"
                  buttonColor={"black"}
                  buttonType={"plain"}
                  buttonSizeMode={"fill"}
                  onPaymentAuthorized={(paymentData) => {
                    // paymentRequest.callbackIntents must include "PAYMENT_AUTHORIZATION" to be able to use this callback
                    return { transactionState: "SUCCESS" };
                  }}
                  paymentRequest={paymentRequest}
                  onLoadPaymentData={(onLoadPaymentData) => {
                    onLoadPaymentData.payment_method_type = "google_pay";
                    setPaymentData(onLoadPaymentData);
                    orderExpressHandler(onLoadPaymentData);
                  }}
                  onError={(error) => {
                    console.log("load onError", error);
                  }}
                  // existingPaymentMethodRequired="false"
                  // onCancel={err => {
                  // 	console.log("load onCancel", err);
                  // }}
                  // onReadyToPayChange={res => {
                  // 	console.log("load res", res);
                  // }}
                  // onClick={res => {
                  // 	console.log("load res", res);
                  // }}
                  style={{ width: "25rem", height: 50 }}
                />
              </div>
            </div>

            <div className={styles.card_checkout}>
              <p className={styles.card_checkout__intro}>
                <span>or continue below to pay with a credit card</span>
              </p>
              {paymentMethods && paymentMethods.length > 0 ? (
                <>
                  <h1 className="account_form--heading">
                    Your Saved Payment Method
                  </h1>

                  {paymentMethods.map((item, index) => (
                    <div
                      key={index + 1}
                      className={
                        paymentData && item.id === paymentData.id
                          ? styles.paymentCardActive
                          : styles.paymentCard
                      }
                      onClick={() => {
                        item.payment_method_type = "stripe";
                        setPaymentData(item);
                      }}
                    >
                      <PaymentCard
                        data={item}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                      />
                    </div>
                  ))}

                  {!paymentData && (
                    <>
                      <h1 className="account_form--heading">
                        Add New Payment Method
                      </h1>

                      <Elements stripe={stripePromise}>
                        <StripePayment
                          data={{
                            stateList,
                            newPaymentMethodSchema,
                          }}
                        />
                      </Elements>
                    </>
                  )}

                  {paymentData && (
                    <InputsSections
                      style={{
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        label="Add Payment Method" //to shipping
                        style={{ marginTop: "1rem" }}
                        onClick={() => {
                          setPaymentData(null);
                        }}
                      />
                      <Button
                        label="Pay Now" //to shipping
                        style={{ marginTop: "1rem" }}
                        disabled={!paymentData}
                        onClick={orderHandler}
                      />
                    </InputsSections>
                  )}
                </>
              ) : (
                <Elements stripe={stripePromise}>
                  <StripePayment
                    data={{
                      stateList,
                      newPaymentMethodSchema,
                    }}
                  />
                </Elements>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.addressContainer}>
            <h1 className="account_form--heading">Shipping Address</h1>
            {shippingAddress && shippingAddress.length > 0 ? (
              shippingAddress.map((item, index) => (
                <div
                  key={index + 1}
                  className={
                    shippingData && item._id === shippingData._id
                      ? styles.addressCardActive
                      : styles.addressCard
                  }
                >
                  <AddresCard
                    data={item}
                    shippingData={shippingData}
                    setShippingData={setShippingData}
                    editHandler={editHandler}
                  />
                </div>
              ))
            ) : getUserAddress.loading ? (
              <Spinner />
            ) : (
              <AddressForm
                data={{
                  handleOnChange: newShippingAddrHandleOnChange,
                  handleSubmit: newShippingAddrHandleOnSubmit,
                  shippingAddress: newShippingAddrValues,
                  setValues: setShippingAddrValues,
                  errors: newShippingAddrErrors,
                  dirty: newShippingAddrDirty,
                  disable: newShippingAddrDisable,
                  stateList,
                }}
              />
            )}

            <div className={styles.addressContainer}>
              <AddressFormModal
                data={{
                  showAddModal: showAddNewAddressModal,
                  handleAddModalClose: handleAddAddressModalClose,
                  handleOnChange: newShippingAddrHandleOnChange,
                  handleSubmit: newShippingAddrHandleOnSubmit,
                  shippingAddress: newShippingAddrValues,
                  setValues: setShippingAddrValues,
                  errors: newShippingAddrErrors,
                  dirty: newShippingAddrDirty,
                  disable: newShippingAddrDisable,
                  stateList,
                }}
              />

              {shippingAddress && shippingAddress.length > 0 && (
                <InputsSections
                  style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
                >
                  <Button
                    label="Continue" //to shipping
                    style={{ marginTop: "1rem" }}
                    disabled={!shippingData}
                    onClick={() => {
                      if (productList && productList.length > 0) {
                        setFormType("paymentForm");
                      } else history("/cart");
                    }}
                  />
                </InputsSections>
              )}
            </div>
          </div>
        )}

        <div className={styles.footer_links}>
          {/* <Link to='/'>Refund Policy</Link> */}
          <a
            href={`${REACT_APP_BASE_URL}/privacy-policy/`}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            href={`${REACT_APP_BASE_URL}/terms-of-service/`}
            rel="noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
          {/* <Link to='/privacy-policy'>Privacy Policy</Link>
					<Link to='/terms-service'>Terms of Service</Link> */}
        </div>
      </div>

      <div className={styles.content_product}>
        {getCartByUser.loading ? (
          <Spinner />
        ) : productList && productList.length > 0 ? (
          productList.map((product, i) => (
            <div key={i} className={styles.content_product_data}>
              <div className={styles.content_product_img_desc}>
                <div className={styles.content_product_img}>
                  <img src={product.img} alt="product" />
                  <p>{product.quantity}</p>
                </div>

                <div className={styles.content_product_desc}>
                  <h1>{product.title && product.title.slice(0, 15)}</h1>
                </div>
              </div>

              <p className={styles.content_product_price}>
                {" "}
                {formatter.format(product.price)}
              </p>
            </div>
          ))
        ) : (
          <div className="shop_now_content">
            <NoData
              title="Your cart is currently empty."
              subTitle={" "}
              height="20vh"
            />
            <Button
              varient="secondary"
              style={{
                padding: "1rem",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
              onClick={() => {
                window.open(
                  "https://app.stresskase.com/#/recommendations-supplements",
                  "_self"
                );
                // history('/test-kit-pdp')
              }}
              label="Continue Shopping"
              icon={false}
            />
          </div>
        )}
        <br />
        <br />
        {productList && productList.length > 0 && (
          <>
            <div className={styles.content_product_data}>
              <div className={styles.content_product_img_desc}>
                <p>
                  Discount
                  {promoCodeDetail &&
                    promoCodeDetail.value_type &&
                    promoCodeDetail.value_type === "percentage" &&
                    " (" + promoCodeDetail.value + "% )"}
                </p>
              </div>

              <p className={styles.content_product_price}>
                $
                {promoCodeDetail &&
                promoCodeDetail.value_type &&
                promoCodeDetail.value_type === "percentage"
                  ? percentage(markedPrice, promoCodeDetail.value)
                  : promoCodeDetail &&
                    promoCodeDetail.value_type &&
                    promoCodeDetail.value_type === "fixed_amount"
                  ? promoCodeDetail.value / 100
                  : 0}
              </p>
            </div>

            <div
              className={[styles.content_product_calc, styles.borderTop].join(
                " "
              )}
            >
              <p>Subtotal</p>
              <p>{formatter.format(markedPrice)}</p>
            </div>

            <div
              className={[styles.content_product_calc, styles.borderTop].join(
                " "
              )}
            >
              <p>Total</p>
              <p>
                USD <span>{formatter.format(totalPrice)}</span>
              </p>
            </div>
          </>
        )}
      </div>
      <div className={styles.footer_links_mob}>
        {/* <Link to='/'>Refund Policy</Link> */}
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-service">Terms of Service</Link>
      </div>
    </div>
  );
};

export default CheckoutScreen;
