import {
    GET_TEST_RESULTS_FAIL,
    GET_TEST_RESULTS_PDF_URL_FAIL,
    GET_TEST_RESULTS_PDF_URL_REQUEST,
    GET_TEST_RESULTS_PDF_URL_RESET,
    GET_TEST_RESULTS_PDF_URL_SUCCESS,
    GET_TEST_RESULTS_REQUEST,
    GET_TEST_RESULTS_RESET,
    GET_TEST_RESULTS_SUCCESS
} from "../constants/resultConstants";

export const getTestResultReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TEST_RESULTS_REQUEST:
            return { loading: true };

        case GET_TEST_RESULTS_SUCCESS:
            return { userInfo: action.payload, loading: false };

        case GET_TEST_RESULTS_FAIL:
            return { loading: false, error: action.payload };

        case GET_TEST_RESULTS_RESET:
            return {};

        default:
            return state;
    }
};


export const getTestResultPDFURLReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TEST_RESULTS_PDF_URL_REQUEST:
            return { loading: true };

        case GET_TEST_RESULTS_PDF_URL_SUCCESS:
            return { userInfo: action.payload, loading: false };

        case GET_TEST_RESULTS_PDF_URL_FAIL:
            return { loading: false, error: action.payload };

        case GET_TEST_RESULTS_PDF_URL_RESET:
            return {};

        default:
            return state;
    }
};
