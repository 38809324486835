import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getUserByIdAction } from "../../redux/actions/userActions";

const { REACT_APP_BASE_URL } = process.env;

export default function CustomerSidebar({ current }) {
  const dispatch = useDispatch();
  const getUserInfo = useSelector((state) => state.getUserInfo);
  const userLogin = useSelector((state) => state.userLogin);

  React.useEffect(() => {
    if (
      getUserInfo &&
      !getUserInfo.userInfo &&
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.data
    ) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      dispatch(getUserByIdAction(decoded._id));
    }
  }, [getUserInfo, userLogin, dispatch]);

  return (
    <Fragment>
      <div className={`${current === "questionnaire" ? "currentNav" : ""}`}>
        <li className="navigation__list--itemHover">
          <Link to="/questionnaire" className="navigation__list--link">
            <span>Questionnaire</span>
          </Link>
        </li>
      </div>

      {getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.isTestResult ? (
        <div className={`${current === "testResults" ? "currentNav" : ""}`}>
          <li className="navigation__list--itemHover">
            <Link to="/test-results" className="navigation__list--link">
              <span>Test Results</span>
            </Link>
          </li>
        </div>
      ) : (
        <div className={`${current === "testResults" ? "currentNav" : ""}`}>
          <li className="navigation__list--itemHover">
            <Link
              to="#"
              className="navigation__list--link navigation__list--link-disabled"
            >
              <span>Test Results</span>
            </Link>
          </li>
        </div>
      )}

      {getUserInfo &&
      getUserInfo.userInfo &&
      getUserInfo.userInfo.data &&
      getUserInfo.userInfo.data.isRecommendationProduct ? (
        <div
          className={`${
            current === "RecommendationsSupplements" ? "currentNav" : ""
          }`}
        >
          <li className="navigation__list--itemHover">
            <Link
              to="/recommendations-supplements"
              className="navigation__list--link"
            >
              <span>Recommendations </span>
            </Link>
          </li>
        </div>
      ) : (
        <div
          className={`${
            current === "RecommendationsSupplements" ? "currentNav" : ""
          }`}
        >
          <li className="navigation__list--itemHover">
            <Link
              to="#"
              className="navigation__list--link navigation__list--link-disabled"
            >
              <span>Recommendations </span>
            </Link>
          </li>
        </div>
      )}

      <div
          className={`${
            current === "SupplementCollections" ? "currentNav" : ""
          }`}
        >
          <li className="navigation__list--itemHover">
            <Link
              to="/supplement-collections"
              className="navigation__list--link"
            >
              <span>Supplements </span>
            </Link>
            <div className="recommendations-supplements-bottom" />
          </li>
        </div>

      <div className={`${current === "account" ? "currentNav" : ""}`}>
        <li className="navigation__list--itemHover">
          <Link to="/account" className="navigation__list--link">
            <span>Account</span>
          </Link>
        </li>
      </div>

      <div className={`${current === "OrderHistory" ? "currentNav" : ""}`}>
        <li className="navigation__list--itemHover">
          <Link to="/order-history" className="navigation__list--link">
            <span>Order History</span>
          </Link>
        </li>
      </div>

      <div className={`${current === "contactus" ? "currentNav" : ""}`}>
        <li className="navigation__list--itemHover">
          <a
            href={`${REACT_APP_BASE_URL}/contact-us`}
            target="_self"
            className="navigation__list--link"
          >
            <span>Contact Us</span>
          </a>
        </li>
      </div>
    </Fragment>
  );
}
