import React from 'react'
import WebsiteContent from '../../components/Content/WebsiteContent'
import Jumbotron from '../../components/Jumbotron'
import "./SubscriptionsPolicyScreen.css"

const SubscriptionsPolicyScreen = () => {
  return (
    <WebsiteContent>
      <div className='subscriptions_policy_content'>
        <Jumbotron
          title="STRESSKASE SUBSCRIPTION POLICY"
          text="Date: March 1st, 2022"
        />
        <div className='subscriptions_padding'>
          <p>
            It is best to make any changes to your subscriptions 7 days prior to the ship date to ensure your changes have time to take effect. Subscription discounts do not apply to the initial product purchase -  the discount applies to refill orders only.
          </p>
          <p>
            <br/>
            You're welcome to cancel your subscription at any time given you meet the following requirements:
          </p>
          <ul style={{ margin: '2.5rem' }}>
            <li>
              We are given 48 hours notice (to ensure we're able to process your cancellation request in a timely fashion)
            </li>
          </ul>
          <p>
            To make changes to your subscription payment method or shippping address, go to the Subscriptions tab, click Modify, Edit, and update your payment and shipping information for each active subscription.
          </p>
        </div>
      </div>
    </WebsiteContent>
  )
}

export default SubscriptionsPolicyScreen