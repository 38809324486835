import React from 'react'
import "./Jumbotron.css"

const Jumbotron = ({ title, text }) => {
    return (
        <div data-testid="Jumbotron" className='jumbotron'>
            <h1>{title}</h1>
            <span dangerouslySetInnerHTML={{ __html: text }}/>
        </div>
    )
}

export default Jumbotron