import axios from "axios";
import { BASE_URL } from "./ip";
import {
  GET_TEST_RESULTS_FAIL,
  GET_TEST_RESULTS_PDF_URL_FAIL,
  GET_TEST_RESULTS_PDF_URL_REQUEST,
  GET_TEST_RESULTS_PDF_URL_SUCCESS,
  GET_TEST_RESULTS_REQUEST,
  GET_TEST_RESULTS_SUCCESS,
} from "../constants/resultConstants";
import { logout } from "./userActions";

export const getTestResultAction =
  (id, month, year) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TEST_RESULTS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      let params = null;
      if (id && month && year) {
        params = `?year=${year}&month=${month}`;
      } else {
        params = `?id=${id}`;
      }

      const { data } = await axios.get(
        `${BASE_URL}/zrt/result${params}`,
        config
      );

      dispatch({
        type: GET_TEST_RESULTS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: GET_TEST_RESULTS_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const getTestResultPDFAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEST_RESULTS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/zrt/result/download?kitId=${id}`,
      config
    );

    dispatch({
      type: GET_TEST_RESULTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_TEST_RESULTS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

// export const getTestResultPDFURLAction = (id) => async (dispatch) => {
//     try {
//         dispatch({ type: GET_TEST_RESULTS_PDF_URL_REQUEST });

//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         };

//         const { data } = await axios.get(`${BASE_URL}/zrt/result/download-link?kitId=${id}`, config);

//         dispatch({
//             type: GET_TEST_RESULTS_PDF_URL_SUCCESS,
//             payload: data
//         });
//     } catch (err) {
//         if (err.response?.status === 401) {
//             dispatch(logout());
//         }
//         dispatch({
//             type: GET_TEST_RESULTS_PDF_URL_FAIL,
//             payload: err.response && err.response.data ? err.response.data : err.message
//         });
//     }
// };

export const getTestResultPDFURLAction = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/zrt/result/download-link?kitId=${id}`,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};
