import React from 'react';
import styles from './SelectBox.module.css';

const SelectBox = ({
	disabled = false,
	children,
	icon,
	name,
	label,
	value,
	onChange,
	errorMessage,
	style,
	textBoxStyle,
	containerStyle
}) => {
	return (
		<div style={textBoxStyle}> 
			<div  className={styles.textBox} style={textBoxStyle}>
				<div
					className={errorMessage && errorMessage.length > 0 ? styles.textBox_invalid_input : styles.textBox__input}
					style={containerStyle}>
					{label && (
						<label
							htmlFor={label}
							className={value ? styles.label : [styles.label, styles.labelHide].join(' ')}>
							{label}
						</label>
					)}
					<select
						disabled={disabled}
						value={value}
						name={name}
						id={name}
						className={styles.textBox__inputbox}
						onChange={onChange}
						style={style}>
						{children}
					</select>
				</div>
			</div>
			<div style={{ height: '2rem' }}>
				{errorMessage && (<p className='form_input__error'>{errorMessage}</p>)}
			</div>
		</div>
	);
};


export default SelectBox;


