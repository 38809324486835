import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import OrderHistoryCard from '../../components/OrderHistoryCard';
import Spinner from '../../components/Spinner/Spinner';
import { getOrderDetailsAction } from '../../redux/actions/orderActions';
import './OrderHistoryScreen.css';
import Pagination from '../../components/Pagination';

const OrderHistoryScreen = () => {
	const dispatch = useDispatch();
	const orderHistory = useSelector((state) => state.orderHistory);
	const [orderHistoryList, setOrderHistoryList] = useState([]);
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 3

	useEffect(() => {
		dispatch(getOrderDetailsAction(pageNumber, pageLimit));
	}, [dispatch])


	useEffect(() => {
		if (orderHistory && orderHistory.data && orderHistory.data.data && orderHistory.data.data && orderHistory.data.data.orderList.length > 0) {
			orderHistory.data.data.orderList.map(async (item) => {
				item.image = item.order && item.order.line_items[0] && item.order.line_items[0].image.src;
			});
			setOrderHistoryList(orderHistory.data.data.orderList);
			setTotalPageSize(orderHistory.data.data.totalOrderCount);
		}
	}, [orderHistory]);

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getOrderDetailsAction(selectedPage + 1, pageLimit));
		setPageNumber(selectedPage + 1);
	};


	return (
		<Content
			currentMenu='OrderHistory'
			headerTitle='Order History'
			navigationRoute={[
				{
					path: "/",
					label: "Dashboard",
					routes: [
						{
							path: "/order-history",
							label: "Order History",
						},
					]
				}
			]}
			addBtn={false}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => { }}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={'search'}
			searchOnChange={''}>
			<div className='orderHistory-container'>
				<h6 className='orderHistory-heading'>
					Order History
					<p >
						View previous orders here.
					</p>
				</h6>

				<div className='orderHistory-content' >
					{orderHistory.loading && <Spinner />}
					{
						orderHistoryList &&
							orderHistoryList.length > 0 ?
							orderHistoryList.map((item, i) =>
								<div className='orderHistory-card' key={i}>
									<OrderHistoryCard data={item} />
								</div>
							) : (
								<></>
							)
					}

					{orderHistoryList && orderHistoryList.length > 0 && totalPageSize > 1 && (
						<div className='tableContainer--paginater'>
							<Pagination
								list={orderHistoryList}
								onPageChange={handlePageChange}
								rowsPerPage={pageLimit}
								totalPageSize={totalPageSize}
								pageNumber={pageNumber}
							/>
						</div>
					)}
				</div>
			</div>
		</Content>
	);
};

export default OrderHistoryScreen;
