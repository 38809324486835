import React from 'react';
import { inputPhoneMasking } from '../../utils/phonemask';
import { RadioButtonsForCard } from '../formInputs/RadioButtons/RadioButtons';
import AddresCardStyles from './AddresCardStyles.module.css';

const AddresCard = ({ data, shippingData, setShippingData, editHandler }) => {
	return (
		<div style={{ width: '100%' }}>
			<div className={AddresCardStyles.AddresCardContainer}>
				<div className={AddresCardStyles.column}>
					<div className={AddresCardStyles.subscriptionsAddressColumn}>
						<RadioButtonsForCard
							label={data.firstName + ' ' + data.lastName}
							placeholder={false}
							value={data && data._id}
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: '2rem',
								fontSize: '1.8rem',
								width: 'max-content'
							}}
							checked={shippingData && shippingData._id}
							onChange={() => setShippingData(data)}
						/>
						<h1
							className={AddresCardStyles.subscriptionsHeading1}
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => editHandler(data)}>
							Edit
						</h1>
					</div>
					<div style={{ paddingLeft: '3.5rem', width: '100%' }} className={AddresCardStyles.subscriptionsHeading2} onClick={() => setShippingData(data)}>
						{data.address}, <br /> {data.appartment}, {data.state_code}, {data.zip}
						<h1 className={AddresCardStyles.subscriptionsHeading2}> <span style={{ fontWeight: '500' }}><br />Phone: </span>{inputPhoneMasking(data.phone)} </h1>
					</div>
				</div>
			</div>
		</div>
	);
};


export const PaymentCard = ({ data, paymentData, setPaymentData }) => { 
	return (
		<div style={{ width: '100%' }}>
			<div className={AddresCardStyles.AddresCardContainer}>
				<div className={AddresCardStyles.column}>
					<div className={AddresCardStyles.paymentCardSubscriptionsColumn}>
						<RadioButtonsForCard
							label={'XXXX XXXX XXXX ' + data.cardNo}
							placeholder={false}
							value={data.id}
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: '2rem',
								fontSize: '1.8rem',
								width: 'max-content'
							}}
							icon={data.brand}
							checked={paymentData && paymentData.id}
							onChange={() => {
								data.payment_method_type = "stripe";
								setPaymentData(data)
							}}
						/>
						<h1 className={AddresCardStyles.paymentCardHeading2}>
							<span>Name on Card: </span>
							<span>{data.nameOnCard}</span>
						</h1>

						<h1 className={AddresCardStyles.paymentCardHeading2}>
							<span>Expires on: </span>
							<span>
								{
									data.expirationDate &&
										data.expirationDate.length < 6 ?
										data.expirationDate.slice(0, data.expirationDate.length - 4) + '/' + data.expirationDate.slice(data.expirationDate.length - 4, 6)
										:
										data.expirationDate
								}
							</span>
						</h1>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddresCard;
