import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import ForgotPasswordWebScreen from '../screens/ForgotPasswordScreen/ForgotPasswordWeb';

// Portal only
import LoginScreen from '../screens/LoginScreen';
import HomeScreen from '../screens/HomeScreen';
import ActivateKitScreen from '../screens/ActivateKitScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import RegisterScreen from '../screens/RegisterScreen';
import NotificationScreen from '../screens/NotificationScreen';
import RecommendationsScreen from '../screens/RecommendationsScreen';
import TestResultScreen from '../screens/TestResultScreen';
import SupplementCollectionsScreen from '../screens/SupplementCollectionsScreen';
import AccountScreen from '../screens/AccountScreen';
import SubscriptionsScreen from '../screens/SubscriptionsScreen';
import OrderHistoryScreen from '../screens/OrderHistoryScreen';
import OrderDetailsScreen from '../screens/OrderHistoryScreen/OrderDetails';
import ContactUsScreen from '../screens/ContactUsScreen';
import SubscriptionsPolicyScreen from '../screens/SubscriptionsPolicyScreen';
import WithoutTokenScreen from '../screens/ActivateKitScreen/WithoutToken';
import RegisterWithKitScreen from '../screens/RegisterScreen/RegisterWithKit';
import ASMScreen from '../screens/RecommendationsScreen/ASMScreen';
import TestResultPDF from '../screens/TestResultPDF';
import CartScreen from '../screens/CartScreen';
import CheckoutScreen from '../screens/CheckoutScreen';


// import TestKitPDPScreen from '../screens/TestKitPDPScreen';
// import ForgotPwdCheckOut from '../screens/ForgotPasswordScreen/ForgotPwdCheckOut';


// website only 
import WebCheckoutScreen from '../screens/WebCheckoutScreen';
// import WebsiteCartScreen from '../screens/WebsiteCartScreen';
import SignInRegisterWebMain from '../screens/SignInRegisterWebModal/SignInRegisterWebMain';
// import MiniCart from '../screens/WebsiteCartScreen/miniCart';


const Navigation = () => {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				{/* Portal Routes */}
				<Route path='/login' element={<LoginScreen />} />
				<Route path='/' element={<NotificationScreen />} />
				<Route path='/questionnaire' element={<HomeScreen />} />
				<Route path='/activate-your-kit' element={<WithoutTokenScreen />} />
				<Route path='/activate-kit' element={<ActivateKitScreen />} />
				<Route path='/forgot-password' element={<ForgotPasswordScreen />} />
				<Route path='/register' element={<RegisterScreen />} />
				<Route path='/register-kit' element={<RegisterWithKitScreen />} />
				<Route path='/recommendations-supplements' element={<RecommendationsScreen />} />
				<Route path='/about-stress-management' element={<ASMScreen />} />
				<Route path='/supplement-collections' element={<SupplementCollectionsScreen />} />
				<Route path='/test-results' element={<TestResultScreen />} />
				<Route path='/account' element={<AccountScreen />} />
				<Route path='/subscriptions' element={<SubscriptionsScreen />} />
				<Route path='/order-history' element={<OrderHistoryScreen />} />
				<Route path='/order-details/:id' element={<OrderDetailsScreen />} />
				<Route path='/contact-us' element={<ContactUsScreen />} />
				<Route path='/checkout' element={<CheckoutScreen />} />
				<Route path='/subscription-policy' element={<SubscriptionsPolicyScreen />} />
				<Route path='/reset-password/:token' element={<ResetPasswordScreen />} />
				<Route path='/request-password-reset' element={<ForgotPasswordWebScreen />} />
				<Route path='/pdf-generator/:token' element={<TestResultPDF />} />
				<Route path='/cart' element={<CartScreen />} />
				<Route path='/register-page' element={<SignInRegisterWebMain />} />
				<Route path='/web-checkout' element={<WebCheckoutScreen />} />

				{/* <Route path='/request-password-reset' element={<ForgotPasswordWebScreen />} /> 
				<Route path='/subscriptions-new' element={<SubscriptionsScreenNew />} />  */}


				{/* Website Routes */}
				{/* <Route path='/test-kit-PDP' element={<TestKitPDPScreen />} /> */}
				{/* <Route path='/cart' element={<WebsiteCartScreen />} />
				<Route path='/mini-cart' element={<MiniCart />} />
				<Route path='/checkout' element={<CheckoutScreen />} />
				<Route path='/register-page' element={<SignInRegisterWebMain />} />
				<Route path='/reset-password/:token' element={<ResetPasswordScreen />} />
				<Route path='/request-password-reset' element={<ForgotPasswordWebScreen />} /> */}

			</Routes>
		</Router>
	);
};

export default Navigation;