import styled from 'styled-components';

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: #ccc;
`;

export const RangeColor = styled.div`
  height: 10px !important;
  z-index: 1;
  border-radius: 10px; 
  background:linear-gradient(90deg, rgba(211,89,89,1) 0%, rgba(215,100,91,1) 0%, rgba(255,207,116,1) 27%, rgba(255,255,255,1) 27%, rgba(255,255,255,1) 28%, rgba(122,175,156,1) 28%, rgba(168,219,220,1) 50%, rgba(115,168,146,1) 72%, rgba(255,255,255,1) 72%, rgba(255,255,255,1) 73%, rgba(255,207,116,1) 73%, rgba(211,89,89,1) 100%, rgba(211,89,89,1) 100%);
  width:100%;
  padding: ${(props) => props.pad}px 0;
`;
 //width:${(props) => props.w};
 //     background: ${(props) => props.bgColor};