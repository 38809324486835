import React from 'react';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
	return (
		<HelmetProvider>
			<Navigation />
			<ToastContainer
				autoClose={5000}
				position='bottom-right'
				theme={'colored'}
				hideProgressBar={true}
				closeOnClick={false}
				closeButton={false}
			/>
		</HelmetProvider>
	);
};

export default App;
