import React from 'react';
import { PRODUCT_TYPE } from '../../../redux/actions/ip';
import Button from '../../../components/formInputs/Button';
import { SelectInput } from '../SelectBox';
import ProductCardStyles from './ProductCardStyles.module.css';
import { formatter } from '../../../utils/for';

const CartCard = ({ data, onQtyChange, onFrequncyChange, handleSubmit }) => {
	return (
		<div className={ProductCardStyles.productCardContainer}>
			<div className={ProductCardStyles.columnImageThumb}>
				{data.img ? <img src={data.img} alt="thumb" className={ProductCardStyles.productCardImage} /> :
					<img src={'./assets/product_round.png'} alt="thumb" className={ProductCardStyles.productCardImage} />
				}
				<div className={ProductCardStyles.productHeading}>

					<h1 className={ProductCardStyles.product_cart_heading2}> {data.title}
						{/* <Button
						style={{
							backgroundColor: '#fffafa00',
							border: 'none',
							color: '#000',
							fontWeight: '500',
							fontSize: '1.5rem',
							justifyContent: 'center',
							padding: '0rem',
							textDecoration: 'underline'
						}}
						label={false}
						icon={'close'}
						onClick={handleSubmit}
					/> */}
					</h1>
					<p className={ProductCardStyles.cart_price}>
						<span>	{formatter.format(data.price * data.quantity)}</span><br />
					</p>
					<Button
						style={{
							backgroundColor: '#fffafa00',
							border: 'none',
							color: '#4B4B4B',
							fontWeight: '400',
							fontSize: '18px',
							justifyContent: 'center',
							padding: '0.5rem',
							textDecoration: 'underline',
							fontFamily: 'FuturaPTBook,sans-serif',
							marginRight: '1rem',
							lineHeight: '23px'
						}}
						label="Remove"
						onClick={handleSubmit}
					/>
					<div className={ProductCardStyles.productInputMobileContainerCart}>
						<div className={ProductCardStyles.gridLayout}>
							{data &&
								data.product_type === PRODUCT_TYPE ?
								<div className={ProductCardStyles.qty_label}>
									Quantity
								</div>
								:
								<SelectInput
									value={data.quantity}
									onChange={(event) => {
										onQtyChange(event, data);
									}}
									icon={false}
									label={false}
									name='quantity'
									containerStyle={{ width: '60px' }}
								// containerStyle={{ width: '100%', minWidth: '10vw' }}
								// textBoxStyle={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
								>
									{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((type, index) => (
										<option value={type} key={index + 1}>
											{type}
										</option>
									))
									}
								</SelectInput>
							}
						</div>

						{data &&
							data.product_type === PRODUCT_TYPE ?
							<SelectInput
								value={data.quantity}
								onChange={(event) => {
									onQtyChange(event, data);
								}}
								icon={false}
								label={false}
								name='quantity'
								containerStyle={{ width: '60px' }}
							// containerStyle={{ width: '100%', minWidth: '10vw' }}
							// textBoxStyle={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
							>
								<option value='1'>
									1
								</option>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((type, index) => (
									<option value={type} key={index + 1}>
										{type}
									</option>
								))
								}
							</SelectInput>
							:
							<div className={ProductCardStyles.gridLayout}>
								<SelectInput
									value={data.frequency}
									onChange={(event) => {
										onFrequncyChange(event, data);
									}}
									icon={false}
									label={false}
									name='frequency'
								// containerStyle={{ width: '100%' }}
								>
									<option value='once'>
										Once
									</option>
									<option value='monthly'>
										Monthly
									</option>
								</SelectInput>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartCard;





