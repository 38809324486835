import {
	GET_PRODUCT_LIST_REQUEST,
	GET_PRODUCT_LIST_SUCCESS,
	GET_PRODUCT_LIST_FAIL,
	GET_PRODUCT_LIST_RESET,

	GET_COLLECTION_LIST_REQUEST,
	GET_COLLECTION_LIST_SUCCESS,
	GET_COLLECTION_LIST_FAIL,
	GET_COLLECTION_LIST_RESET,

	GET_COLLECTION_BY_PRODUCT_REQUEST,
	GET_COLLECTION_BY_PRODUCT_SUCCESS,
	GET_COLLECTION_BY_PRODUCT_FAIL,
	GET_COLLECTION_BY_PRODUCT_RESET,

	GET_USER_BY_CART_SUCCESS,
	GET_USER_BY_CART_FAIL,
	GET_USER_BY_CART_REQUEST,
	GET_USER_BY_CART_RESET,

	GET_RECOMMENDATION_BY_PRODUCT_REQUEST,
	GET_RECOMMENDATION_BY_PRODUCT_SUCCESS,
	GET_RECOMMENDATION_BY_PRODUCT_FAIL,
	GET_RECOMMENDATION_BY_PRODUCT_RESET,

	ADD_USER_BY_CART_SUCCESS,
	ADD_USER_BY_CART_FAIL,
	ADD_USER_BY_CART_REQUEST,
	ADD_USER_BY_CART_RESET,

	GET_USER_BY_CART_COUNT_REQUEST,
	GET_USER_BY_CART_COUNT_SUCCESS,
	GET_USER_BY_CART_COUNT_FAIL,
	GET_USER_BY_CART_COUNT_RESET,

	CHECKOUT_CART_REQUEST,
	CHECKOUT_CART_SUCCESS,
	CHECKOUT_CART_FAIL,
	CHECKOUT_CART_RESET,
	
	GET_PRODUCT_LIST_CART,
	ADD_ITEM_IN_CART,
	ADJUST_ITEM_IN_CART,
	DELETE_ITEM_IN_CART,
	DELETE_ALL_ITEM_IN_CART,
	LOAD_ITEM_IN_CART,
	CART_RESET,
} from '../constants/productConstants';

export const getProductReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PRODUCT_LIST_REQUEST:
			return { loading: true };

		case GET_PRODUCT_LIST_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_PRODUCT_LIST_FAIL:
			return { loading: false, error: action.payload };

		case GET_PRODUCT_LIST_RESET:
			return {};

		default:
			return state;
	}
};


export const getCollectionReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_COLLECTION_LIST_REQUEST:
			return { loading: true };

		case GET_COLLECTION_LIST_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_COLLECTION_LIST_FAIL:
			return { loading: false, error: action.payload };

		case GET_COLLECTION_LIST_RESET:
			return {};

		default:
			return state;
	}
};




export const getCollectionByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_COLLECTION_BY_PRODUCT_REQUEST:
			return { loading: true };

		case GET_COLLECTION_BY_PRODUCT_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_COLLECTION_BY_PRODUCT_FAIL:
			return { loading: false, error: action.payload };

		case GET_COLLECTION_BY_PRODUCT_RESET:
			return {};

		default:
			return state;
	}
};


export const getCartByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_BY_CART_REQUEST:
			return { loading: true };

		case GET_USER_BY_CART_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_USER_BY_CART_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_BY_CART_RESET:
			return {};

		default:
			return state;
	}
};


export const addCartByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_USER_BY_CART_REQUEST:
			return { loading: true };

		case ADD_USER_BY_CART_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_USER_BY_CART_FAIL:
			return { loading: false, error: action.payload };

		case ADD_USER_BY_CART_RESET:
			return {};

		default:
			return state;
	}
};


export const getCartCountByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_BY_CART_COUNT_REQUEST:
			return { loading: true };

		case GET_USER_BY_CART_COUNT_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_USER_BY_CART_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case GET_USER_BY_CART_COUNT_RESET:
			return {};

		default:
			return state;
	}
};
 
export const getRecommendationByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_RECOMMENDATION_BY_PRODUCT_REQUEST:
			return { loading: true };

		case GET_RECOMMENDATION_BY_PRODUCT_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_RECOMMENDATION_BY_PRODUCT_FAIL:
			return { loading: false, error: action.payload };

		case GET_RECOMMENDATION_BY_PRODUCT_RESET:
			return {};

		default:
			return state;
	}
};



export const checkOutReducer = (state = {}, action) => {
	switch (action.type) {
		case CHECKOUT_CART_REQUEST:
			return { loading: true };

		case CHECKOUT_CART_SUCCESS:
			return { data: action.payload, loading: false };

		case CHECKOUT_CART_FAIL:
			return { loading: false, error: action.payload };

		case CHECKOUT_CART_RESET:
			return {};

		default:
			return state;
	}
};

const INITIAL_STATE = {
	products: [],
	cart: [],
	currentItem: null
}

export const cartProductReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCT_LIST_CART:
			return {
				...state,
				products: action.payload
			}

		case ADD_ITEM_IN_CART: 
			const item = state.products.find(prod => prod.id === action.payload.id);
			const inCart = state.cart.find(item => item.id === action.payload.id ? true : false)
			const addedData = inCart ? state.cart.map(item => item.id === action.payload.id ? { ...item, quantity: item.quantity + 1 } : item) : [...state.cart, { ...item, quantity: 1 }]
			localStorage.setItem('cart', JSON.stringify(addedData))
			return {
				...state,
				cart: addedData,
			};

		case ADJUST_ITEM_IN_CART: 
			const adjustedData = state.cart.map(item => item.id === action.payload.id ? { ...item, quantity: +action.payload.quantity, frequency:action.payload.frequency } : item);
			localStorage.setItem('cart', JSON.stringify(adjustedData))
			return {
				...state,
				cart: adjustedData
			};

		case DELETE_ITEM_IN_CART:
			const deletedData = state.cart.filter(item => item.id !== action.payload.id)
			localStorage.setItem('cart', JSON.stringify(deletedData))
			return {
				...state,
				cart: deletedData
			};

		case DELETE_ALL_ITEM_IN_CART:
			localStorage.removeItem('cart')
			return {
				...state,
				cart: []
			};

		case LOAD_ITEM_IN_CART:
			return {
				...state,
				currentItem: action.payload,
			};

		case CART_RESET:
			return {
				...state,
				cart: [],
			};

		default:
			return state;
	}
}; 