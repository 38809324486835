import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TestResultPDFStyles from "./TestResultPDF.module.css";
import { RangeInput } from "../../components/RangeInput/range";
import ReactHtmlParser from "react-html-parser";
import CanvasJSReact from "../../assets/canvasjs.react";
import { getTestResultPDFAction } from "../../redux/actions/resultActions";
import moment from "moment";
import logo from "./logo.png";
import "./index.css";
import ProductCard from "../../components/ProductCard";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function TestResultPDF(props) {
  const dispatch = useDispatch();
  const { token: kitId } = useParams();
  const getTestResult = useSelector((state) => state.getTestResult);
  const [testLineChartResult, setTestLineChartResult] = useState([]);
  const [getKitByDescription, setKitByDescription] = useState({});

  useEffect(() => {
    if (!kitId) return;
    dispatch(getTestResultPDFAction(kitId));
  }, [kitId]);

  useEffect(() => {
    if (
      getTestResult &&
      getTestResult.userInfo &&
      getTestResult.userInfo.data
    ) {
      setKitByDescription(getTestResult.userInfo.data.dynamicDescriptions);
      if (
        getTestResult.userInfo.data.testResult &&
        getTestResult.userInfo.data.testResult.tests &&
        getTestResult.userInfo.data.testResult.tests.length > 0
      ) {
        let arr = [{ label: "", y: [0, 1] }];
        let arr2 = [{ label: "", y: [0, 1] }];
        getTestResult.userInfo.data.testResult.tests.forEach((item, index) => {
          if (item.name === "Cortisol") {
            arr.push({
              label: item.rangeMinimum + " - " + item.rangeMaximum,
              y: [item.rangeMinimum, item.rangeMaximum],
            });
            arr2.push({
              label: item.rangeMinimum + " - " + item.rangeMaximum,
              y: JSON.parse(item.result),
            });
          }
        });

        setTestLineChartResult([...arr2]);
      }
    }
  }, [getTestResult, dispatch]);

  const extractString = (name) => {
    var extracted = name.split(" ");
    switch (extracted[extracted.length - 1]) {
      case "(morning)":
        return "Pre-Awakening Optimal Range:";
      case "(noon)":
        return "Mid-Morning Optimal Range:";
      case "(evening)":
        return "Afternoon Optimal Range:";
      case "(night)":
        return "Evening Optimal Range:";
      default:
        return "Pre-Awakening Optimal Range:";
    }
  };

  const getRangeLabel = (item) => {
    if (item.resultLevel.toLowerCase() === "ok") {
      return (
        <span>
          {" "}
          in the <b> normal</b> range for your age
        </span>
      );
    } else if (item.resultLevel === "L") {
      return (
        <span>
          {" "}
          <b>below</b> the normal range for your age
        </span>
      );
    }

    return (
      <span>
        {" "}
        <b>above</b> the normal range for your age
      </span>
    );
  };

  const optionsResult = {
    backgroundColor: "#BF6A6A",
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: false,
    height: 260,
    axisY: {
      gridThickness: 0,
      includeZero: true,
      title: "ng / ml",
    },
    axisX: {
      gridThickness: 0,
      minimum: 0,
    },
    data: [
      {
        type: "rangeArea",
        color: "#a8dbdc",
        lineColor: "#edc477",
        markerType: "none",
        lineThickness: 6,
        fillOpacity: 0.8,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: [3.7, 9.5] },
          { label: "1.2 - 3.0", y: [1.2, 3.0] },
          { label: "0.6 - 1.9", y: [0.6, 1.9] },
          { label: "0.4 - 1", y: [0.4, 1.0] },
        ],
      },
      {
        type: "scatter",
        showInLegend: false,
        color: "#FFF",
        markerColor: "#000",
        // lineColor: "#000",
        lineThickness: 3,
        markerSize: 10,
        dataPoints: testLineChartResult,
      },
    ],
  };

  const optionsOptimalResult = {
    backgroundColor: "#BF6A6A",
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: false,
    height: 260,
    axisY: {
      gridThickness: 0,
      includeZero: true,
      title: "ng / ml",
    },
    axisX: {
      gridThickness: 0,
      minimum: 0,
    },
    data: [
      {
        type: "rangeArea",
        color: "#a8dbdc",
        lineColor: "#edc477",
        markerType: "none",
        lineThickness: 6,
        fillOpacity: 0.8,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: [3.7, 9.5] },
          { label: "1.2 - 3.0", y: [1.2, 3.0] },
          { label: "0.6 - 1.9", y: [0.6, 1.9] },
          { label: "0.4 - 1", y: [0.4, 1.0] },
        ],
      },
      {
        type: "scatter",
        showInLegend: false,
        color: "#FFF",
        markerColor: "#000",
        lineThickness: 3,
        markerSize: 10,
        dataPoints: [
          { label: "", y: [0, 1] },
          { label: "3.7 - 9.5", y: 6.6 },
          { label: "1.2 - 3.0", y: 2.1 },
          { label: "0.6 - 1.9", y: 1.25 },
          { label: "0.4 - 1", y: 0.7 },
        ],
      },
    ],
  };

  const ready =
    !getTestResult.loading &&
    getTestResult &&
    getTestResult.userInfo &&
    getTestResult.userInfo.data &&
    getTestResult.userInfo.data.testResult &&
    getTestResult.userInfo.data.testResult.tests &&
    getTestResult.userInfo.data.testResult.tests.length > 0;

  if (!ready) return;

  return (
    <div>
      {renderCortisolLevels()}
      {renderDHEASLevels()}
      {renderRecommendations()}
      {renderSupplements()}
      {renderLifestyle1()}
      {renderLifestyle2()}
    </div>
  );

  function renderHeader() {
    return (
      <>
        <div className="pdf-logo-wrapper">
          <img src={logo} alt="Stresskase Logo" className="pdf-logo" />
        </div>
        <div className={TestResultPDFStyles.testResultSectionBtn}>
          <div style={{ fontSize: "16px" }}>
            Test Results for:{" "}
            <span style={{ fontWeight: "500" }}>
              {" "}
              {getTestResult.userInfo.data.customerName}
            </span>{" "}
          </div>
          <div style={{ fontSize: "16px" }}>
            Date of Test Results:{" "}
            <span style={{ fontWeight: "500" }}>
              {getTestResult.userInfo.data.resultDate !== "N/A"
                ? moment(getTestResult.userInfo.data.resultDate).format(
                    "MM/DD/YYYY"
                  )
                : moment(new Date()).format("MM/DD/YYYY")}{" "}
            </span>
          </div>
        </div>
      </>
    );
  }

  function renderCortisolLevels() {
    return (
      <div className="pdf-page">
        {renderHeader()}
        <div className="pdf-subsection">
          <div className="pdf-header">Cortisol Profile Results</div>
          <p className={TestResultPDFStyles.testResultSubHeading}>
            Your cortisol profile results will give you a general look at how
            your cortisol hormones are performing.
          </p>
        </div>

        <div className={TestResultPDFStyles.test_result_cortisol_graph}>
          <div className={TestResultPDFStyles.test_result_graph_item}>
            <h1> Your Results </h1>
            <CanvasJSChart options={optionsResult} />
          </div>
          <div className={TestResultPDFStyles.test_result_graph_item}>
            <h1> Optimal Results </h1>
            <CanvasJSChart options={optionsOptimalResult} />
          </div>
        </div>

        <p className={TestResultPDFStyles.testResultSubHeading}>
          <h1 className={TestResultPDFStyles.testResultHeading}>
            Cortisol Levels (ng/ml)
          </h1>
          Your cortisol results are divided into four intervals; pre-awakening,
          mid-morning, afternoon and evening. See how your cortisol levels
          change throught the day to better understand your stress and feel
          empowered to make changes.
        </p>

        <div className={TestResultPDFStyles.test_result_cortisol_card}>
          {getTestResult.userInfo.data.testResult.tests.map(
            (item, index) =>
              item.name === "Cortisol" && (
                <div
                  key={index}
                  className={TestResultPDFStyles.test_result_item}
                >
                  <div>
                    <RangeInput
                      min={item.rangeMinimum}
                      max={item.rangeMaximum}
                      values={[item.result]}
                      pdfMode
                    />
                  </div>
                  <h1
                    className={
                      TestResultPDFStyles.test_result_cortisol_card_heading
                    }
                  >
                    {" "}
                    {extractString(item.range)} {item.range.split(" ")[0]}{" "}
                  </h1>
                  <h3
                    className={
                      TestResultPDFStyles.test_result_cortisol_card_description
                    }
                  >
                    You’re
                    {getRangeLabel(item)}
                  </h3>
                </div>
              )
          )}
        </div>
      </div>
    );
  }

  function renderDHEASLevels() {
    return (
      <div className="pdf-page">
        <p className={TestResultPDFStyles.testResultSubHeading}>
          <h1 className={TestResultPDFStyles.testResultHeading}>
            DHEAS Daily Level (ng/ml)
          </h1>
          Your dehydroepiandrosterone (DHEAS) daliy level will give you a
          general overview at how this key stress hormone is performing
          throughout the day.
        </p>
        <div className={TestResultPDFStyles.test_result_cortisol_card}>
          {getTestResult.userInfo.data.testResult.tests.map(
            (item, index) =>
              item.name === "DHEAS" && (
                <div
                  key={index}
                  className={TestResultPDFStyles.test_result_item}
                >
                  <div>
                    <RangeInput
                      min={item.rangeMinimum}
                      max={item.rangeMaximum}
                      values={[item.result]}
                      pdfMode
                    />
                  </div>
                  <h1
                    className={
                      TestResultPDFStyles.test_result_cortisol_card_heading
                    }
                  >
                    Daily Optimal Range: {item.range.split(" ")[0]}{" "}
                    {item.range.split(" ")[item.range.split(" ").length - 2]}{" "}
                    {item.range.split(" ")[item.range.split(" ").length - 1]}{" "}
                  </h1>
                  <h3
                    className={
                      TestResultPDFStyles.test_result_cortisol_card_description
                    }
                  >
                    You’re
                    {getRangeLabel(item)}
                  </h3>
                </div>
              )
          )}
        </div>

        <p className={TestResultPDFStyles.testResultSubHeading}>
          <h1 className={TestResultPDFStyles.testResultHeading}>
            Your Biological Results Overview
          </h1>
          Your biological response overview indicated below will explain how
          your biology is handling your specific stress load.
        </p>
        <p className={TestResultPDFStyles.testResultSubHeading}>
          {getTestResult &&
            getTestResult.userInfo &&
            getTestResult.userInfo.data &&
            getTestResult.userInfo.data.testResult &&
            getTestResult.userInfo.data.testResult.comments &&
            "Your " +
              getTestResult.userInfo.data.testResult.comments
                .split("\r\n")[2]
                .split("For additional")[0]}
        </p>
        <p className={TestResultPDFStyles.testResultSubHeading}>
          {getTestResult &&
            getTestResult.userInfo &&
            getTestResult.userInfo.data &&
            getTestResult.userInfo.data.testResult &&
            getTestResult.userInfo.data.testResult.comments &&
            "Your " +
              getTestResult.userInfo.data.testResult.comments.split("\r\n")[0]}
        </p>

        <h1 className={TestResultPDFStyles.test_result_h1}>
          <svg className={TestResultPDFStyles.test_result_icon}>
            <use xlinkHref={`./assets/sprite.svg#icon-open-book`} />
          </svg>
          Our Stresskase Medical Staff Recommended Reads:
        </h1>

        <p
          className={[
            TestResultPDFStyles.testResultDesc,
            "medical_staff_details",
          ].join(" ")}
        >
          {getTestResult &&
            getTestResult.userInfo &&
            getTestResult.userInfo.data &&
            getTestResult.userInfo.data.testResult &&
            getTestResult.userInfo.data.testResult.comments &&
            ReactHtmlParser(
              getTestResult.userInfo.data.testResult.comments
                .split("\r\n")[2]
                .split(":")
                [
                  getTestResult.userInfo.data.testResult.comments
                    .split("\r\n")[2]
                    .split(":").length - 1
                ].replaceAll('"The', "<br/> <b> The")
                .replaceAll("Adrenal Fatigue", "<b>Adrenal Fatigue</b>")
                .replaceAll('"', "</b>")
                .replaceAll(";", "")
            )}
        </p>
      </div>
    );
  }

  function renderRecommendations() {
    const recs = getKitByDescription;
    if (!recs || !recs.shortDescription) return;
    const shortDesc = recs.shortDescription.split(".");
    return (
      <div className="pdf-page">
        <h1 className="pdf-header">Your Lifestyle Recommendations</h1>
        <div className="pdf-block" style={{ background: "#fff6e2" }}>
          <div className="pdf-block-title">Overview:</div>
          <div>1. How do we determine your Wellness & Lifestyle plan?</div>
          <div>
            2. Your Wellness & Lifestyle Plan has three components - Physical
            Activity, Dietary Advice and Supplements.
          </div>
          <div>3. Importance of Adherence, how to create your success.</div>
          <div>
            4. We will have regular follow-up to help you on your journey.
          </div>
        </div>
        <div className="pdf-block">
          <div className="recommendation-subsection">
            Your Wellness & Lifestyle plan has been determined by the biological
            samples and answers to the questionnaire you provided us. The{" "}
            <span style={{ fontWeight: 500 }}>supplements, dietary advice</span>{" "}
            and{" "}
            <span style={{ fontWeight: 500 }}>
              physical activity recommendations
            </span>{" "}
            will work in concert to specifically address the biochemical and
            emotional imbalances that lead to your symptoms.
          </div>
          <div className="recommendation-subsection">
            <div className="pdf-block-title">
              General Assessment & Symptoms:
            </div>
            {shortDesc.map((line, i) => {
              const last = i === shortDesc.length - 1;
              let str = line;
              if (!last) str += ".";
              return <div key={i}>{str}</div>;
            })}
            <ul className="recommend_ul">
              {recs.symptoms.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="recommendation-subsection">
            <div className="pdf-block-title">
              Physical Activity Recommendations:
            </div>
            <ul className="recommend_ul">
              {recs.Physical.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
          <div>
            <div className="pdf-block-title">Dietary Advice:</div>
            <ul className="recommend_ul">
              {recs.Dietary.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="pdf-block" style={{ background: "#e0eaf2" }}>
          <div className="pdf-block-title">Success Factors:</div>
          <ul>
            <li>Changing behavior is difficult</li>
            <li>
              The way to deal with implementing change is to set yourself small
              achievable goals.
            </li>
            <li>
              As opposed to medication, behavioral change provides you with a
              set of coping mechanisms that will last a lifetime and can be
              revisited from time to time.
            </li>
            <li>
              Behavioral change will come with ups and downs, do not get
              discouraged by this
            </li>
          </ul>
        </div>
      </div>
    );
  }

  function renderSupplements() {
    const productList = getTestResult.userInfo.data.supplements;
    if (!productList || !productList[0]) return;
    return (
      <div className="pdf-page">
        <h1 className="pdf-header">Suggested Supplements</h1>
        <div className="pdf-block" style={{ background: "#deeddf" }}>
          <div style={{ marginBottom: 20 }}>
            Our Supplement Recommendations are based on your unique cortisol
            profile and are to address any irregularities in an effort to
            support you throughout your day.
          </div>
          <div style={{ fontWeight: 500 }}>
            We recommend the following supplements and dosages:
          </div>
        </div>
        <div className="product-content" style={{ marginTop: 40 }}>
          {productList.map((product, i) => (
            <ProductCard key={i} index={i} data={product} pdfMode />
          ))}
        </div>
      </div>
    );
  }

  function renderLifestyle1() {
    return (
      <div className="pdf-page" style={{ pageBreakAfter: "auto" }}>
        <h1
          className={TestResultPDFStyles.recommendationsPDFHeading1}
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          General Lifestyle Recommendations
        </h1>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Sleep
        </h1>
        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          High evening cortisol not only sabotages the quantity of sleep, but
          even more importantly, it keeps you from getting the stages of sleep
          that are required for recovery and repair of the body and brain. It is
          difficult to improve other areas of your health because the body feels
          it is in crisis. During sleep the brain recovers in ways that it can’t
          during the day. The brain’s lymphatic system is turned on helping to
          clear all the waste products of metabolism. Thinking takes a lot of
          energy. Creating and using energy also produces waste products. Our
          ability to think and function mentally is directly related to how well
          this cleaning process happens at night. Deep sleep is mainly the time
          this process happens. REM sleep (or rapid eye movement sleep) is the
          time of sleep when we dream. A third of our sleep each night needs to
          be made up of a combination of deep sleep and REM sleep. When we don’t
          get the quality sleep we need, cortisol levels rise in response to our
          bodies lack of recovery.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Tips for Sleep:
        </h1>

        <ul
          className={[
            TestResultPDFStyles.recommendationsSubheading2,
            "collection_popup_decimal",
          ].join(" ")}
        >
          <li>
            Avoid eating large meals and large quantities of liquid before
            bedtime. It is best to cut off all calories 3 hours before bed.
          </li>
          <li>
            This allows your body to repair and recover instead of having to
            digest and process food.
          </li>
          <li>
            Minimize caffeine intake and avoid both caffeine and alcohol 4-5
            hours before bedtime.
          </li>
          <li>
            Avoid intense exercise 4-5 hours before the time you go to sleep.
          </li>
          <li>
            Follow a regular sleep wake schedule each day and try to maintain
            this schedule even on weekends and while traveling the best you can.
          </li>
          <li>
            Minimize light in your bedroom from windows, alarm clocks, night
            lights, TV, laptops, tablets and cell phones. Light disrupts our
            brain’s natural circadian rhythm. When the sun goes down, melatonin
            levels rise, and with first morning sun, melatonin levels fall.
          </li>
          <li>
            Minimize sound in your bedroom from pets, music, and other
            disturbances. Use earplugs or white noise like a fan or air filter
            if sound cannot be minimized.
          </li>
          <li>
            Maintain a moderate temperature and good ventilation in your
            bedroom.
          </li>
          <li>
            If you cannot fall asleep, go to another room and start a quiet and
            not too engaging activity. Return to bed only when you feel sleepy.
          </li>
          <li>
            If you have trouble breathing through your nose or you snore,
            address common allergies (dust, mold, pets). Nasal strips and air
            filters may be helpful.
          </li>
        </ul>

        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          If you suspect obstructive sleep apnea (where you stop breathing
          during sleep and wake up gasping for breath) contact your doctor to
          have a sleep study done. Untreated sleep apnea leads to high blood
          pressure and drives cortisol production up leading to other diseases.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Exercise
        </h1>
        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          Our bodies are meant to move each day. Exercise is perhaps the most
          effective component in managing fatigue and chronic pain. Physical
          activity prevents muscle wasting, increases mood and the ability to
          cope with stress. Physical inactivity contributes to over three
          million preventable deaths worldwide each year (that’s six percent of
          all deaths). It is the fourth leading cause of death due to
          non-communicable diseases. Exercise can also be a valuable way to
          balance our stress response. Even though our cortisol rises during
          exercise, the chemicals that are released during this time improve
          mood, increase our metabolism (making us more efficient in burning
          calories), improve our blood pressure, and helps prevent all types of
          cancer.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Exercise Tips:
        </h1>
        <ul
          className={[
            TestResultPDFStyles.recommendationsSubheading2,
            "collection_popup_decimal",
          ].join(" ")}
        >
          <li>
            The best exercise programs combine aerobic, flexibility and strength
            training. But the MOST important thing it to just get started doing
            something.
          </li>
          <li>
            If you haven’t exercised in a while, start with a “graded” program.
            Graded means that you start easy to see what you can handle and then
            slowly build up the intensity over time. If you start intense
            exercise too early, you can expect to experience a discouraging
            increase in pain and thereby decrease the chance of continuing.
          </li>
          <li>
            Try to exercise daily, it is easier to just make exercise part of
            your daily routine than decide what days you will or won’t exercise.
            It is easier to be 100 percent than 90 percent committed.
          </li>
          <li>
            Resistance exercise improves muscle tone, increases endurance,
            decreases abdominal fat and increases testosterone levels.
          </li>
          <li>
            Flexibility exercises like yoga can reduce muscle and joint pain,
            especially pain in the shoulders, neck and back.
          </li>
        </ul>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Nutrition
        </h1>
        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          Health and energy require good nutrition. Ensuring you have a healthy
          diet is imperative for healing from sickness and avoiding most chronic
          diseases. Stress raises our cortisol levels, thereby inhibiting our
          body’s ability to digest and absorb nutrients which in turn causes an
          imbalance of bacteria in our intestines leading many gastrointestinal
          disorders. Think of the digestive track as a long tube that goes
          through your body but is not in your body. It is a place with more
          bacteria than human cells and the barrier between the sterile
          environment of the body and the toxic environment of the digestive
          track is only one cell thick. Keeping this barrier healthy and strong
          is of upmost importance.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Nutrition Tips:
        </h1>

        <ul
          className={[
            TestResultPDFStyles.recommendationsSubheading2,
            "collection_popup_decimal",
          ].join(" ")}
        >
          <li>
            Eat a diet high in colorful (phytonutrient rich) vegetables and
            fruit. The color of the fruits and vegetables alone positively
            affect our DNA.
          </li>
          <li>
            Eat protein that is high quality - pasture raised chicken and eggs,
            grass fed dairy and red meat, turkey free of nitrites or
            preservatives.
          </li>
          <li>
            Avoid processed foods (foods that have a label of ingredients),
            sugars, baked goods and alcohol
          </li>
        </ul>
      </div>
    );
  }

  function renderLifestyle2() {
    return (
      <div className="pdf-page" style={{ pageBreakAfter: "auto" }}>
        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Detox
        </h1>
        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          We are exposed to many chemicals and toxins each day. In fact, we now
          know that the amount of toxins we are exposed to in 1 month is
          exponentially higher than what people were exposed to 2 generations
          ago. This is concerning since some of our organs are very sensitive to
          being damaged by the toxins in the environment. These toxins come from
          pesticides, chemicals and heavy metals in our food and water and even
          the air we breathe. These toxins pose an extra stress on our bodies
          and can lead to foggy brain, hormone abnormalities, anxiety,
          depression, sickness, and cancer. Our body is amazingly equipped at
          clearing out these toxins but there is a limit to what it can handle.
          If we cross over the threshold, exposing our bodies to more toxins
          than it can clear, the consequences can be detrimental to our health.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Tips for Detoxing:
        </h1>
        <ul
          className={[
            TestResultPDFStyles.recommendationsSubheading2,
            "collection_popup_decimal",
          ].join(" ")}
        >
          <li>
            Drink 1/3 to 1/2 your body weight in ounces of clean water each day.
            Water should be clear of chemicals. You can check EWG.org to
            evaluate what is in your drinking water where you live.
          </li>
          <li>
            Sweat! Sweating rids your body of toxins. Be sure to rinse off soon
            after exercise or sauna use so that you don’t re-absorb toxins back
            into your body.
          </li>
          <li>
            Eat more fiber! Fiber not only feeds the bacteria working hard to
            maintain our health in our digestive tracks but also binds heavy
            metals and other toxins allowing us to rid our body of them. The
            average American gets 8 grams of fiber in his or her diet. We need a
            minimum of 50 grams a day.
          </li>
        </ul>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Recharging
        </h1>
        <p className={TestResultPDFStyles.recommendationsPDFSubHeading2}>
          Managing stress is so important to our health and quality of life. It
          is of utmost importance to find ways to help your body get rid of
          stress. Having supportive people in your life and restorative
          practices will help your body and immune system stay balanced and in a
          state of health. Daily practices of mindfulness and spending time with
          people who uplift and show you loving kindness are necessary to
          recharge your mind and body.
        </p>

        <h1 className={TestResultPDFStyles.recommendationsPDFHeading1}>
          Tips for Recharging:
        </h1>
        <ul
          className={[
            TestResultPDFStyles.recommendationsSubheading2,
            "collection_popup_decimal",
          ].join(" ")}
        >
          <li>
            Find a stress relief technique that works for you and practice it
            every day. Some examples are: meditation, prayer, deep-breathing
            techniques, listening to relaxation/guided meditation recordings,
            singing, walking, sitting in a peaceful area, contact with pets, or
            anything that brings you joy and allows you to relax.
          </li>
          <li>
            Avoid stressful people and situations as much as possible. People
            who ae negative, unsupportive, and abusive will drain you mentally
            and physically and emotionally sap your energy.
          </li>
          <li>
            Start trying to listen to your body attend to its needs when it
            needs rest. You will recover more quickly if you can recognize your
            body’s need for rest.
          </li>
          <li>
            Each day you have a finite amount of energy available. Trial and
            error will help you fine-tune that inner sense of how much energy
            you have each day and at what point you are better off stopping to
            rest.
          </li>
          <li>
            You will feel and do your best by pushing almost to the limit of
            your daily energy allowance. However, be careful to moderate your
            activity so that you stay within your daily energy allowance and not
            exceed it.
          </li>
        </ul>
      </div>
    );
  }
}
