import React from 'react';
import styles from './TextBox.module.css';

const TextBox = ({ type = 'text', name, placeholder, value, onChange, disabled, errorMessage, textBoxStyle, containerStyle }) => {
	return (
		<div style={{ width: '100%' }}>
			<div
				className={errorMessage && errorMessage.length > 0 ? styles.textBox_invalid_input : styles.textBox__input}
				style={containerStyle}>
				<label htmlFor={name} className={value ? styles.label : [styles.label, styles.labelHide].join(' ')}>
					{placeholder}
				</label>
				<input
					type={type}
					name={name}
					id={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					className={value ? styles.textBox : [styles.textBox, styles.textBoxTop].join(' ')}
				/>
			</div>
			<div style={{ height: '2rem' }}>
				{errorMessage && (<p className='form_input__error'>{errorMessage}</p>)}
			</div>
		</div> 
	);
};

export default TextBox;
