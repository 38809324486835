
export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAIL = 'GET_PRODUCT_LIST_FAIL';
export const GET_PRODUCT_LIST_RESET = 'GET_PRODUCT_LIST_RESET';

export const GET_COLLECTION_LIST_REQUEST = 'GET_COLLECTION_LIST_REQUEST';
export const GET_COLLECTION_LIST_SUCCESS = 'GET_COLLECTION_LIST_SUCCESS';
export const GET_COLLECTION_LIST_FAIL = 'GET_COLLECTION_LIST_FAIL';
export const GET_COLLECTION_LIST_RESET = 'GET_COLLECTION_LIST_RESET';

export const GET_COLLECTION_BY_PRODUCT_REQUEST = 'GET_COLLECTION_BY_PRODUCT_REQUEST';
export const GET_COLLECTION_BY_PRODUCT_SUCCESS = 'GET_COLLECTION_BY_PRODUCT_SUCCESS';
export const GET_COLLECTION_BY_PRODUCT_FAIL = 'GET_COLLECTION_BY_PRODUCT_FAIL';
export const GET_COLLECTION_BY_PRODUCT_RESET = 'GET_COLLECTION_BY_PRODUCT_RESET';

export const GET_USER_BY_CART_REQUEST = 'GET_USER_BY_CART_REQUEST';
export const GET_USER_BY_CART_SUCCESS = 'GET_USER_BY_CART_SUCCESS';
export const GET_USER_BY_CART_FAIL = 'GET_USER_BY_CART_FAIL';
export const GET_USER_BY_CART_RESET = 'GET_USER_BY_CART_RESET';

export const ADD_USER_BY_CART_REQUEST = 'ADD_USER_BY_CART_REQUEST';
export const ADD_USER_BY_CART_SUCCESS = 'ADD_USER_BY_CART_SUCCESS';
export const ADD_USER_BY_CART_FAIL = 'ADD_USER_BY_CART_FAIL';
export const ADD_USER_BY_CART_RESET = 'ADD_USER_BY_CART_RESET';


export const GET_USER_BY_CART_COUNT_REQUEST = 'GET_USER_BY_CART_COUNT_REQUEST';
export const GET_USER_BY_CART_COUNT_SUCCESS = 'GET_USER_BY_CART_COUNT_SUCCESS';
export const GET_USER_BY_CART_COUNT_FAIL = 'GET_USER_BY_CART_COUNT_FAIL';
export const GET_USER_BY_CART_COUNT_RESET = 'GET_USER_BY_CART_COUNT_RESET';


export const CHECKOUT_CART_REQUEST = 'CHECKOUT_CART_REQUEST';
export const CHECKOUT_CART_SUCCESS = 'CHECKOUT_CART_SUCCESS';
export const CHECKOUT_CART_FAIL = 'CHECKOUT_CART_FAIL';
export const CHECKOUT_CART_RESET = 'CHECKOUT_CART_RESET'; 


export const GET_RECOMMENDATION_BY_PRODUCT_REQUEST = 'GET_RECOMMENDATION_BY_PRODUCT_REQUEST';
export const GET_RECOMMENDATION_BY_PRODUCT_SUCCESS = 'GET_RECOMMENDATION_BY_PRODUCT_SUCCESS';
export const GET_RECOMMENDATION_BY_PRODUCT_FAIL = 'GET_RECOMMENDATION_BY_PRODUCT_FAIL';
export const GET_RECOMMENDATION_BY_PRODUCT_RESET = 'GET_RECOMMENDATION_BY_PRODUCT_RESET';

export const GET_PRODUCT_LIST_CART = 'GET_PRODUCT_LIST_CART';
export const ADD_ITEM_IN_CART = 'ADD_ITEM_IN_CART';
export const ADJUST_ITEM_IN_CART = 'ADJUST_ITEM_IN_CART';
export const DELETE_ITEM_IN_CART = 'DELETE_ITEM_IN_CART';
export const LOAD_ITEM_IN_CART = 'LOAD_ITEM_IN_CART';
export const CART_RESET = 'CART_RESET';
export const DELETE_ALL_ITEM_IN_CART = 'DELETE_ALL_ITEM_IN_CART'; 
export const ADD_ORDER_RESET = 'ADD_ORDER_RESET';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';