import React, { Fragment } from 'react';
import Button from '../formInputs/Button';
import { SelectInput } from '../formInputs/SelectBox';
import SubscriptionsCardStyles from './SubscriptionsCardStyles.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { formatter } from '../../utils/for';

const SubscriptionsCard = ({
	data,
	setShowCancelModal,
	subscriptionsList,
	setSubscriptionsList,
	updateSubscriptionHandler,
	setProductDetailModal,
	onQtyChange,
	onFrequncyChange,
	editAddressHandler,
	editPaymentHandler
}) => {

	const openDatePickerHandler = async (e, data) => {
		e.preventDefault();
		let newFormValues = [...subscriptionsList];
		data.datePickerIsOpen = !data.datePickerIsOpen;
		setSubscriptionsList([...newFormValues]);
	};

	const handleDateChange = (date, data) => {
		let newFormValues = [...subscriptionsList];
		data.subscriptionNextDateString = moment(date).format('MM/DD/YYYY');
		data.datePickerIsOpen = !data.datePickerIsOpen;
		setSubscriptionsList([...newFormValues]);
	};

	return (
		<div style={{ width: '100%' }}>
			<div className={SubscriptionsCardStyles.subscriptionsCardContainer}>
				{data &&
					data.product && (
						<div className={SubscriptionsCardStyles.columnImageThumb}>
							{data.product.image &&
								data.product.image.src && (
									<img
										src={data.product.image.src}
										alt='subscriptions thumb'
										style={data.style}
										className={SubscriptionsCardStyles.subscriptionsCardImage}
									/>
								)}
							<div className={SubscriptionsCardStyles.subscriptionsMobileContainer}>
								<h1 className={SubscriptionsCardStyles.subscriptionsHeading1}>{data.product.title}</h1>

								{data.productMetafield &&
									data.productMetafield.length > 0 &&
									data.productMetafield.map((meta) => (
										<Fragment key={meta.id}>
											{meta.key === 'sub_titlle' ? (
												<p className={SubscriptionsCardStyles.subscriptionsHeading2}>{meta.value}</p>
											) : (
												''
											)}
										</Fragment>
									))}

								{/* {data.product.body_html && (
								<div className={SubscriptionsCardStyles.subscriptionsHeading2}>
									{ReactHtmlParser(data.product.body_html)}
								</div>
							)} */}
							</div>
						</div>
					)}

				<div className={SubscriptionsCardStyles.column}>
					{data &&
						data.product && (
							<div className={SubscriptionsCardStyles.subscriptionsHeading}>
								<div className={SubscriptionsCardStyles.subscriptionsPriceDescription}>
									<h1 className={SubscriptionsCardStyles.subscriptionsHeading1}>{data.product.title}</h1>
									{data.productMetafield &&
										data.productMetafield.length > 0 &&
										data.productMetafield.map((meta) => (
											<Fragment key={meta.id}>
												{meta.key === 'sub_titlle' ? (
													<p className={SubscriptionsCardStyles.subscriptionsHeading2}>{meta.value}</p>
												) : (
													''
												)}
											</Fragment>
										))}

									{/* {data.product.body_html && (
									<div className={SubscriptionsCardStyles.subscriptionsHeading2}>
										{ReactHtmlParser(data.product.body_html)}
									</div>
								)} */}
								</div>

								<div className={SubscriptionsCardStyles.subscriptionsPriceContainer}>
									<div className={SubscriptionsCardStyles.priceTag}>
										<div>
											Price:{' '}
											<span style={{ textDecoration: 'line-through' }}>
												{formatter.format(data.product.variants && data.product.variants.length > 0 && data.product.variants[0].price)}
											</span>
										</div>
										<div>
											<span className={SubscriptionsCardStyles.offer}>
												Subscription Price:{formatter.format(data.subscriptionAmount)} 
											</span>
										</div>
									</div>

									<div className={SubscriptionsCardStyles.gridLayout} style={{ paddingRight: '21px' }}>
										<SelectInput
											value={data.quantity}
											onChange={(event) => {
												onQtyChange(event, data);
											}}
											errorMessage={false}
											icon={false}
											label='Qty'
											name='quantity'
											containerStyle={{ width: '100%' }}>
											{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((type, index) => (
												<option value={type} key={index + 1}>
													{type}
												</option>
											))}
										</SelectInput>
									</div>

									<div className={SubscriptionsCardStyles.gridLayout}>
										<SelectInput
											value={data.frequency}
											onChange={(event) => {
												onFrequncyChange(event, data);
											}}
											errorMessage={false}
											icon={false}
											label='Frequency'
											name='Frequency'
											containerStyle={{ width: '100%' }}>
											<option value='once'>Once</option>
											<option value='monthly'>Monthly</option>
										</SelectInput>
									</div>
								</div>

								<div className={SubscriptionsCardStyles.subscriptionsShippingTextContainer}>
									<div className={SubscriptionsCardStyles.subscriptionsContainer}>
										<h3 className={SubscriptionsCardStyles.subscriptionsHeading3}>
											Next Order Ships:{' '}
											{moment(data?.subscriptionNextDateString).format('MMMM Do, YYYY')}
										</h3>
										<h5 className={SubscriptionsCardStyles.subscriptionsShippingtext}>
											Always free shipping. Shipping times may vary.
										</h5>
									</div>
									<div className={SubscriptionsCardStyles.gridLayout}>
										<Button
											style={{ marginTop: '1rem', justifyContent: 'center', width: '100%' }}
											onClick={(e) => {
												openDatePickerHandler(e, data);
											}}
											label='Change Order Date'
											icon={false}
										/>

										<div>&nbsp;</div>
										<DatePicker
											selected={new Date(data?.subscriptionNextDateString)}
											onChange={(date) => handleDateChange(date, data)}
											open={data.datePickerIsOpen}
											hideText={true}
											minDate={moment().toDate()}
											maxDate={new Date(moment(data?.subscriptionNextDateString).add(25, 'days'))}
										/>
									</div>
									<div className={SubscriptionsCardStyles.gridLayout}>
										<Button
											style={{ marginTop: '1rem', justifyContent: 'center', width: '100%' }}
											onClick={(e) => { updateSubscriptionHandler(e, data) }}
											label='Process Now'
											icon={false}
										/>
										<span className='subscriptions-link'
											onClick={() => {
												setProductDetailModal(data);
												setShowCancelModal(true)
											}}>
											<span> Cancel Subscription </span>
										</span>
									</div>
								</div>

								<div className={SubscriptionsCardStyles.subscriptionsMobileContainer}>
									<p style={{ marginTop: '1rem' }}>
										Price:{' '}
										<span style={{ textDecoration: 'line-through' }}> 
											{formatter.format(data.product.variants && data.product.variants.length > 0 && data.product.variants[0].price)} 
										</span>
										<br />
										<span className={SubscriptionsCardStyles.offer}>Subscription Price: {formatter.format(data.subscriptionAmount)}</span>
									</p>

									<div className={SubscriptionsCardStyles.subscriptionsContainer}>
										<h3 className={SubscriptionsCardStyles.subscriptionsHeading3}>
											Next Order Ships:{' '}
											{moment(data?.subscriptionNextDateString).format('MMMM Do, YYYY')}
										</h3>
										<h5 className={SubscriptionsCardStyles.subscriptionsShippingtext}>
											Always free shipping. Shipping times may vary.
										</h5>
									</div>

									<div className={SubscriptionsCardStyles.subscriptionsInputMobileContainer}>
										<div className={SubscriptionsCardStyles.gridLayout}>
											<SelectInput
												value={data.quantity}
												onChange={(event) => {
													onQtyChange(event, data);
												}}
												errorMessage={false}
												icon={false}
												label='Qty'
												name='Qty'
												containerStyle={{ width: '100%' }}>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((type, index) => (
													<option value={type} key={index + 1}>
														{type}
													</option>
												))}
											</SelectInput>
										</div>

										<div className={SubscriptionsCardStyles.gridLayout}>
											<SelectInput
												value={data.frequency}
												onChange={(event) => {
													onFrequncyChange(event, data);
												}}
												errorMessage={false}
												icon={false}
												label='Frequency'
												name='Frequency'
												containerStyle={{ width: '100%' }}>
												<option value='once'>Once</option>
												<option value='monthly'>Monthly</option>
											</SelectInput>
										</div>
									</div>

									<div className={SubscriptionsCardStyles.gridLayout}>
										<Button
											style={{ marginTop: '2rem', justifyContent: 'center', width: '100%' }}
											onClick={(e) => { updateSubscriptionHandler(e, data) }}
											label='Process Now'
											icon={false}
										/>
									</div>
									<div className={SubscriptionsCardStyles.gridLayout}>
										<Button
											style={{ marginTop: '1rem', justifyContent: 'center', width: '100%' }}
											onClick={(e) => {
												openDatePickerHandler(e, data);
											}}
											label='Change Order Date'
											icon={false}
										/>

										<DatePicker
											selected={new Date(data?.subscriptionNextDateString)}
											onChange={(date) => handleDateChange(date, data)}
											open={data.datePickerIsOpen}
											hideText={true}
											minDate={moment().toDate()}
											maxDate={new Date(moment(data?.subscriptionNextDateString).add(25, 'days'))}
										/>

										<span className='subscriptions-link'
											onClick={() => {
												setProductDetailModal(data);
												setShowCancelModal(true)
											}}>
											<span> Cancel Subscription </span>
										</span>
									</div>
								</div>
							</div>
						)}
				</div>
			</div>

			<div className={SubscriptionsCardStyles.subscriptionsAddressContainer}>
				<div className={SubscriptionsCardStyles.column}>
					<div className={SubscriptionsCardStyles.subscriptionsAddressColumn}>
						<h1 className={SubscriptionsCardStyles.subscriptionsHeading3}>Shipping:</h1>
						<h1 className={SubscriptionsCardStyles.subscriptionsHeading3}
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => editAddressHandler(data)}>
							Edit
						</h1>
					</div>
					{data.address && (
						<div className={SubscriptionsCardStyles.subscriptionsHeading2}>
							{data.address.address1}, {data.address.address2} <br />
							{data.address.city}, {data.address.province_code} <br />
							{data.address.zip} <br />
						</div>
					)}
				</div>
				<div className={SubscriptionsCardStyles.column}>
					<div className={SubscriptionsCardStyles.subscriptionsAddressColumn}>
						<h1 className={SubscriptionsCardStyles.subscriptionsHeading3}>Billing:</h1>
						<h1 className={SubscriptionsCardStyles.subscriptionsHeading3}
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => editPaymentHandler(data)}>
							Edit
						</h1>
					</div>
					{data.paymentMethod && (
						<div className={SubscriptionsCardStyles.subscriptionsHeading2}>
							{data.paymentMethod.brand}
							<br />
							{data.paymentMethod.exp_month}/{data.paymentMethod.exp_year &&
								String(data.paymentMethod.exp_year).slice(2)}
							<br />
						</div>
					)}
				</div>
				<div className={SubscriptionsCardStyles.OrderTotalcolumn}>
					<h1 className={SubscriptionsCardStyles.subscriptionsHeading3}> Order Total </h1>
					<h1 className={SubscriptionsCardStyles.subscriptionsHeading2}>  {formatter.format(data.subscriptionAmount)}</h1>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionsCard;
