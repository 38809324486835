export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.stresskase.com/api/v1";

export const Encrypt_key =
  "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY1ODczNDE3NywiaWF0IjoxNjU4NzM0MTc3fQ.oZKwOUtt8ED16R8X9V8lCrFOrAYxphpUix7cbxigK40";
export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51LRtCUJwu79numIK6EXSrauRXetTLeu3tgXyXs4ehLJSf0cSttrZXmJ8csJMrTwuGiI1mz31115W2mp5lv2u4606002h9Ux4ah";
export const STRIPE_SECRET_KEY =
  "sk_test_51LRtCUJwu79numIKZ9Fun1ajYkL1QsTvXRe3uOn6Rgv6OMwajIHQEKcMzsONOS3rHMOhYZAahldZDi7RBNIaLHxu00WwEj9AAK";
export const PAYPAL_CLIENT_KEY =
  "AVmy4oo0qDHRV3AQ5sH8sp6eVLY_OZgOGRQreqHCjaKQ7RiqucqkMpGNNIiNa1vSQDarWtolEqVvtVSg";
export const REACT_SHOPIFY_API = "85632e5f27c1ef4c65e8b6ca2fc4d06f";
export const REACT_SHOPIFY_DOMAIN = "stresskase.myshopify.com";
export const PRODUCT_COLLECTION_BY_ID_PROD = "269741654067";
export const SUPPLEMENTS_COLLECTION_BY_ID_PROD = "269741686835"; //'291747561651' dev

export const PRODUCT_TYPE = "The Stress Test";
