import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/userActions';
import './Sidebar.css';
import SidebarSkeleton from '../Skeletons/SidebarSkeleton';
import CustomerSidebar from './CustomerSidebar';

const Sidebar = ({ current = 'none' }) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.getUserInfo);

	useEffect(
		() => {
			if (userData && userData.error && userData.error.status === false) {
				dispatch(logout());
			}
		},
		[userData, dispatch]
	);

	return (
		<nav data-testid="Sidebar" className='navigation' style={{ zIndex: 500, position: 'unset' }}>
			{userData && userData.loading ? (
				<SidebarSkeleton />
			) : (
				<ul className='navigation__list'>
					<CustomerSidebar current={current} />
				</ul>)
			}
		</nav>
	);
};

export default Sidebar;
