import { GET_ORDER_HISTORY_FAIL, GET_ORDER_HISTORY_SUCCESS, GET_ORDER_HISTORY_REQUEST, GET_ORDER_CONFIRM_DETAILS_REQUEST, GET_ORDER_CONFIRM_DETAILS_SUCCESS, GET_ORDER_CONFIRM_DETAILS_FAIL } from "../constants/orderConstants";
import axios from "axios";
import { BASE_URL } from './ip';
import { logout } from "./userActions";


export const getOrderDetailsAction = (pageNumber=1, pageSize=5) => async (dispatch, getState) => { 
	try {
		dispatch({ type: GET_ORDER_HISTORY_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/order?pageNumber=${pageNumber}&pageSize=${pageSize}`, config);

		dispatch({
			type: GET_ORDER_HISTORY_SUCCESS,
			payload: data
		}); 
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_ORDER_HISTORY_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
}; 

export const getOrderConfirmDetailAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ORDER_CONFIRM_DETAILS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/order/${id}`, config);

		dispatch({
			type: GET_ORDER_CONFIRM_DETAILS_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_ORDER_CONFIRM_DETAILS_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};
