import axios from 'axios';
import { BASE_URL } from './ip';
import {
	GET_PRODUCT_LIST_REQUEST,
	GET_PRODUCT_LIST_SUCCESS,
	GET_PRODUCT_LIST_FAIL,

	GET_COLLECTION_LIST_SUCCESS,
	GET_COLLECTION_LIST_REQUEST,
	GET_COLLECTION_LIST_FAIL,

	GET_COLLECTION_BY_PRODUCT_REQUEST,
	GET_COLLECTION_BY_PRODUCT_SUCCESS,
	GET_COLLECTION_BY_PRODUCT_FAIL,

	ADD_USER_BY_CART_REQUEST,
	ADD_USER_BY_CART_SUCCESS,
	ADD_USER_BY_CART_FAIL,

	GET_USER_BY_CART_REQUEST,
	GET_USER_BY_CART_SUCCESS,
	GET_USER_BY_CART_FAIL,
	GET_USER_BY_CART_COUNT_REQUEST,
	GET_USER_BY_CART_COUNT_SUCCESS,
	GET_USER_BY_CART_COUNT_FAIL,
	CHECKOUT_CART_REQUEST,
	CHECKOUT_CART_SUCCESS,
	CHECKOUT_CART_FAIL,
	GET_PRODUCT_LIST_CART,
	DELETE_ITEM_IN_CART,
	DELETE_ALL_ITEM_IN_CART,
	ADJUST_ITEM_IN_CART,
	ADD_ORDER_RESET,
	ADD_ITEM_IN_CART,
	CHECKOUT,

	GET_RECOMMENDATION_BY_PRODUCT_REQUEST,
	GET_RECOMMENDATION_BY_PRODUCT_SUCCESS,
	GET_RECOMMENDATION_BY_PRODUCT_FAIL,

} from '../constants/productConstants';
import { logout } from './userActions';


export const getProductsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_PRODUCT_LIST_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/shopify/product`, config);

		dispatch({
			type: GET_PRODUCT_LIST_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_PRODUCT_LIST_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const getCollectionAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_COLLECTION_LIST_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/shopify/collection`, config);

		dispatch({
			type: GET_COLLECTION_LIST_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_COLLECTION_LIST_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};



export const getCollectionGenericByProductAction = (id) => async (dispatch) => {
	try {
		dispatch({ type: GET_COLLECTION_BY_PRODUCT_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/generic/collection/product/${id}`, config);

		dispatch({
			type: GET_COLLECTION_BY_PRODUCT_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_COLLECTION_BY_PRODUCT_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};

export const getCollectionByProductAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_COLLECTION_BY_PRODUCT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/shopify/collection/product/${id}`, {}, config);

		dispatch({
			type: GET_COLLECTION_BY_PRODUCT_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_COLLECTION_BY_PRODUCT_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const addCartByUserAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_USER_BY_CART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/cart`, formData, config);

		dispatch({
			type: ADD_USER_BY_CART_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: ADD_USER_BY_CART_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const getCartByUserAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_USER_BY_CART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/cart`, config);

		dispatch({
			type: GET_USER_BY_CART_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_USER_BY_CART_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const addCartSyncDataAction = (formData) => async (dispatch, getState) => {
	try {
		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/cart`, formData, config);

		return data;
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		return err.response && err.response.data ? err.response.data : err.message;
	}
};

export const getCartSyncDataAction = () => async (dispatch, getState) => {
	try {
		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/cart`, config);

		return data;
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		return err.response && err.response.data ? err.response.data : err.message;
	}
};


export const getCartCountByUserAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_USER_BY_CART_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/cart/cart-total-count`, config);

		dispatch({
			type: GET_USER_BY_CART_COUNT_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_USER_BY_CART_COUNT_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const checkOutAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHECKOUT_CART_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/cart/order-from-cart?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

		dispatch({
			type: CHECKOUT_CART_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: CHECKOUT_CART_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};


export const getProductImageAction = (id) => async (dispatch, getState) => {
	try {
		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.get(`${BASE_URL}/order/${id}/order-images`, config);

		return data;
	} catch (err) {
		return err.response && err.response.data ? err.response.data : err.message;
	}
};


// session storage cart

export const addProductsAction = (item) => async (dispatch) => {
	dispatch({
		type: GET_PRODUCT_LIST_CART,
		payload: item
	})
}

export const addToCartAction = (itemID) => async (dispatch) => {
	dispatch({ type: ADD_ORDER_RESET })
	dispatch({
		type: ADD_ITEM_IN_CART,
		payload: {
			id: itemID
		}
	})
}

export const deleteItemCartAction = (itemID) => async (dispatch) => {
	dispatch({
		type: DELETE_ITEM_IN_CART,
		payload: {
			id: itemID
		}
	})
}

export const deleteAllItemCartAction = () => async (dispatch) => {
	dispatch({
		type: DELETE_ALL_ITEM_IN_CART,
		payload: {}
	})
}


export const adjustQtyCartAction = (itemID, value, frequency) => async (dispatch) => {
	dispatch({
		type: ADJUST_ITEM_IN_CART,
		payload: {
			id: itemID,
			quantity: value,
			frequency: frequency
		}
	})
}

export const loadCurrentItemAction = (item) => async (dispatch) => {
	dispatch({
		type: ADJUST_ITEM_IN_CART,
		payload: item
	})
}

export const checkOutLocalAction = (prices) => async (dispatch) => {
	dispatch({ type: ADD_ORDER_RESET })
	dispatch({
		type: CHECKOUT,
		payload: prices
	})
}

export const getRecommendationByProductAction = (id, formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_RECOMMENDATION_BY_PRODUCT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.data}`,
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.post(`${BASE_URL}/shopify/collection/product/${id}`, formData, config);

		dispatch({
			type: GET_RECOMMENDATION_BY_PRODUCT_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_RECOMMENDATION_BY_PRODUCT_FAIL,
			payload: err.response && err.response.data ? err.response.data : err.message
		});
	}
};