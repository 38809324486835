import React from 'react';
import Content from '../../components/Content';
import './NotificationScreen.css';
import ProductSquareCard from '../../components/ProductSquareCard';
import { getCartByUserAction, getRecommendationByProductAction } from '../../redux/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getNotificationsAction, getUserByIdAction, readNotificationAction } from '../../redux/actions/userActions';
import Button from '../../components/formInputs/Button';
import Spinner from '../../components/Spinner/Spinner';
import NoData from '../../components/NoData';
import { SUPPLEMENTS_COLLECTION_BY_ID_PROD } from '../../redux/actions/ip';
import jwt_decode from 'jwt-decode';
import { getTestResultAction } from '../../redux/actions/resultActions';

const NotificationScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);
	const getRecommendationById = useSelector((state) => state.getRecommendationById);
	const notifications = useSelector((state) => state.notifications);
	const getUserInfo = useSelector((state) => state.getUserInfo);
	const getTestResult = useSelector((state) => state.getTestResult);
	const [productList, setProductList] = React.useState([]);

	React.useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
				dispatch(getCartByUserAction());
				dispatch(getNotificationsAction());
				var decoded = jwt_decode(userLogin.userInfo.data);
				dispatch(getUserByIdAction(decoded._id));
			} else {
				history('/login');
			}
		},
		[userLogin, dispatch, history]
	);

	const readNotificationHandler = async (id) => {
		const data = await dispatch(readNotificationAction(id));
		dispatch(getNotificationsAction());
	};

	React.useEffect(
		() => {
			if (getUserInfo && getUserInfo.userInfo && getUserInfo.userInfo.data) {
				dispatch(getTestResultAction(getUserInfo.userInfo.data.kitActivatedID));
			}
		},
		[getUserInfo, dispatch]
	);

	React.useEffect(
		() => {
			if (
				getTestResult &&
				getTestResult.userInfo &&
				getTestResult.userInfo.data &&
				getTestResult.userInfo.data.tests &&
				getTestResult.userInfo.data.tests.length > 0
			) {
				let cortisol_range = {
					post_awakening: 0,
					mid_morning: 0,
					afternoon: 0,
					evening: 0
				};

				getTestResult.userInfo.data.tests.forEach((item, index) => {
					if (item.name === 'Cortisol') {
						var extracted = item.range.split(' ');
						if (extracted[extracted.length - 1] === '(morning)') {
							cortisol_range.post_awakening = parseFloat(item.result);
						} else if (extracted[extracted.length - 1] === '(noon)') {
							cortisol_range.mid_morning = parseFloat(item.result);
						} else if (extracted[extracted.length - 1] === '(evening)') {
							cortisol_range.afternoon = parseFloat(item.result);
						} else if (extracted[extracted.length - 1] === '(night)') {
							cortisol_range.evening = parseFloat(item.result);
						}
					}
				});
				dispatch(
					getRecommendationByProductAction(SUPPLEMENTS_COLLECTION_BY_ID_PROD, { cortisol_range: cortisol_range })
				);
			}
		},
		[getTestResult, dispatch]
	);

	React.useEffect(
		() => {
			if (
				getRecommendationById &&
				getRecommendationById.data &&
				getRecommendationById.data.data &&
				getRecommendationById.data.data.length > 0
			) {
				getRecommendationById.data.data.forEach((el) => {
					el.quantity = 1;
					el.frequency = 'once';
				});

				setProductList(getRecommendationById.data.data);
			}
		},
		[getRecommendationById]
	);

	return (
		<Content
			currentMenu='Notifications'
			headerTitle='Notifications'
			navigationRoute={[
				{
					path: '/',
					label: 'Dashboard',
					routes: [
						// {
						// 	path: "/notifications",
						// 	label: "Notifications",
						// },
					]
				}
			]}
			addBtn={false}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => { }}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={'search'}
			searchOnChange={''}>
			<div className='notification-container'>
				{notifications.loading && <Spinner />}

				<h6 className='notification_container-top notification-heading'>Notifications</h6>
				{notifications && notifications.data && notifications.data.data && notifications.data.data.length > 0 ? (
					notifications.data.data.map((item, i) => (
						<div className='notification-card' key={i}>
							<div className='notification-left'>
								<h1 className='notification-heading1'>
									{' '}
									{item.title} <span style={{ fontWeight: '400' }}>{item.shortDescription}</span>
									{
										item.type === 'zrt_result' ?
											(<Button
												style={{
													backgroundColor: '#fffafa00',
													border: 'none',
													color: '#77b778',
													fontWeight: '500',
													fontSize: '20px',
													lineHeight: '30px',
													justifyContent: 'center',
													padding: '0.5rem'
												}}
												label={item.typeLabel}
												onClick={() => history('/test-results')}
											/>)
											:
											item.type === 'shop_now' ?
												(<Button
													style={{
														backgroundColor: '#fffafa00',
														border: 'none',
														color: '#77b778',
														fontWeight: '500',
														fontSize: '20px',
														lineHeight: '30px',
														justifyContent: 'center',
														padding: '0.5rem'
													}}
													label={item.typeLabel}
													onClick={() => history('/supplement-collections')}
												/>)
												:
												item.type === 'question' ? (<Button
													style={{
														backgroundColor: '#fffafa00',
														border: 'none',
														color: '#77b778',
														fontWeight: '500',
														fontSize: '20px',
														lineHeight: '30px',
														justifyContent: 'center',
														padding: '0.5rem'
													}}
													label={item.typeLabel}
													onClick={() => history('/questionnaire')}
												/>)
													:
													item.type === 'subscription' ?
														(<Button
															style={{
																backgroundColor: '#fffafa00',
																border: 'none',
																color: '#77b778',
																fontWeight: '500',
																fontSize: '20px',
																lineHeight: '30px',
																justifyContent: 'center',
																padding: '0.5rem'
															}}
															label={item.typeLabel}
															onClick={() => history('/subscriptions')}
														/>)
														:
														item.type === 'recommendation' ?
															(<Button
																style={{
																	backgroundColor: '#fffafa00',
																	border: 'none',
																	color: '#77b778',
																	fontWeight: '500',
																	fontSize: '20px',
																	lineHeight: '30px',
																	justifyContent: 'center',
																	padding: '0.5rem'
																}}
																label={item.typeLabel}
																onClick={() => history('/recommendations-supplements')}
															/>)
															:
															item.type === 'account' ?
																(<Button
																	style={{
																		backgroundColor: '#fffafa00',
																		border: 'none',
																		color: '#77b778',
																		fontWeight: '500',
																		fontSize: '20px',
																		lineHeight: '30px',
																		justifyContent: 'center',
																		padding: '0.5rem'
																	}}
																	label={item.typeLabel}
																	onClick={() => history('/account')}
																/>)
																:
																item.type === 'order_history' &&
																(<Button
																	style={{
																		backgroundColor: '#fffafa00',
																		border: 'none',
																		color: '#77b778',
																		fontWeight: '500',
																		fontSize: '20px',
																		lineHeight: '30px',
																		justifyContent: 'center',
																		padding: '0.5rem'
																	}}
																	label={item.typeLabel}
																	onClick={() => history('/order-history')}
																/>)

									}
								</h1>

							</div>
							<button
								className='notification-delete'
								onClick={() => {
									readNotificationHandler(item._id);
								}}>
								<svg className='notification_delete_icon'>
									<use xlinkHref={`/assets/sprite.svg#icon-close`} />
								</svg>
							</button>
						</div>
					))
				) : (
					<></>
				)}

				{productList &&
					productList.length > 0 && (
						<div className='notification-recommended-container'>
							<h6 className='notification_container-top notification-heading2'> Recommended for You </h6>
							<div className='notification-product-content'>
								{productList.map((item, i) => <ProductSquareCard key={i} data={item} id={i} />)}
							</div>
						</div>
					)}
			</div>
		</Content>
	);
};

export default NotificationScreen;
