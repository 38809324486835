
export const GET_QUESTIONAIRE_SECTION_REQUEST = 'GET_QUESTIONAIRE_SECTION_REQUEST';
export const GET_QUESTIONAIRE_SECTION_SUCCESS = 'GET_QUESTIONAIRE_SECTION_SUCCESS';
export const GET_QUESTIONAIRE_SECTION_FAIL = 'GET_QUESTIONAIRE_SECTION_FAIL';
export const GET_QUESTIONAIRE_SECTION_RESET = 'GET_QUESTIONAIRE_SECTION_RESET';  


export const ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST = 'ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST';
export const ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS = 'ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS';
export const ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL = 'ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL';
export const ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET = 'ADD_QUESTIONAIRE_RESULT_BY_SECTION_RESET';  

export const GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST = 'GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST';
export const GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS = 'GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS';
export const GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL = 'GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL';
export const GET_QUESTIONAIRE_RESULT_BY_SECTION_RESET = 'GET_QUESTIONAIRE_RESULT_BY_SECTION_RESET';  

export const GET_OPTION_LIST_REQUEST = 'GET_OPTION_LIST_REQUEST';
export const GET_OPTION_LIST_SUCCESS = 'GET_OPTION_LIST_SUCCESS';
export const GET_OPTION_LIST_FAIL = 'GET_OPTION_LIST_FAIL';
export const GET_OPTION_LIST_RESET = 'GET_OPTION_LIST_RESET';  


export const GET_FILTER_MONTH_REQUEST = 'GET_FILTER_MONTH_REQUEST';
export const GET_FILTER_MONTH_SUCCESS = 'GET_FILTER_MONTH_SUCCESS';
export const GET_FILTER_MONTH_FAIL = 'GET_FILTER_MONTH_FAIL';
export const GET_FILTER_MONTH_RESET = 'GET_FILTER_MONTH_RESET';  


export const FINAL_QUESTIONAIRE_RESULT_REQUEST = 'FINAL_QUESTIONAIRE_RESULT_REQUEST';
export const FINAL_QUESTIONAIRE_RESULT_SUCCESS = 'FINAL_QUESTIONAIRE_RESULT_SUCCESS';
export const FINAL_QUESTIONAIRE_RESULT_FAIL = 'FINAL_QUESTIONAIRE_RESULT_FAIL';
export const FINAL_QUESTIONAIRE_RESULT_RESET = 'FINAL_QUESTIONAIRE_RESULT_RESET';  

export const GET_KIT_DESCRIPTIONS_REQUEST = 'GET_KIT_DESCRIPTIONS_REQUEST';
export const GET_KIT_DESCRIPTIONS_SUCCESS = 'GET_KIT_DESCRIPTIONS_SUCCESS';
export const GET_KIT_DESCRIPTIONS_FAIL = 'GET_KIT_DESCRIPTIONS_FAIL';
export const GET_KIT_DESCRIPTIONS_RESET = 'GET_KIT_DESCRIPTIONS_RESET';  
