import React, { useState } from "react";
import { formatter } from "../../utils/for";
import { FaAngleRight, FaInfoCircle } from "react-icons/fa";
import { useResponsive } from "../../utils/utility";
import Button from "../formInputs/Button";

export default function ProductCard(props) {
  const { data, onQtyChange, showAddToCart, pdfMode } = props;
  const {
    title,
    price,
    desc1,
    desc2,
    supplementCount,
    suggestedQty,
    id,
    quantity,
  } = data;

  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [expanded, setExpanded] = useState(false);

  const { isMobile } = useResponsive();

  const imageStyle = { ...styles.image };
  if (isMobile && !pdfMode) imageStyle.marginBottom = 20;

  const cardStyle = { ...styles.productCard };
  if (isMobile && !pdfMode) cardStyle.display = "block";

  const handleAddToCart = () => {
    window.location.href = `https://stresskase.com/cart/?add-to-cart=${id}&quantity=${quantity}`;
    console.log("in handle add to cart");
  };

  const titleStyle = { ...styles.title };
  const priceStyle = { ...styles.price };
  const textStyle = {};

  if (pdfMode) {
    titleStyle.fontSize = 15;
    priceStyle.fontSize = 15;
    textStyle.fontSize = 12;
    imageStyle.height = 128;
    imageStyle.width = 128;
    cardStyle.borderRadius = 8;
    cardStyle.padding = 16;
  }

  return (
    <div style={cardStyle} className="product-card">
      <img src={data.imageUrl} alt="product thumb" style={imageStyle} />
      <div style={{ flexGrow: 1 }}>
        <div style={titleStyle}>{title}</div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: "flex" }}>
            <div style={textStyle}>{desc1}</div>
            {!expanded && !pdfMode && (
              <div style={styles.learnMore} onClick={() => setExpanded(true)}>
                Learn More <FaAngleRight style={styles.angleRight} />
              </div>
            )}
          </div>
          {expanded && <div style={{ marginTop: 20 }}>{desc2}</div>}
        </div>
        {renderIngredients()}
        <div>
          <div style={textStyle}>
            <div>Supplement Count: {supplementCount} Capsules</div>
            <div>Daily Suggested Use: {suggestedQty} Capsules</div>
          </div>
          <div style={styles.priceWrapper}>
            <div style={priceStyle}>{formatter.format(price)}</div>
            {!pdfMode && (
              <div style={styles.qtySelectWrapper}>
                <div style={styles.qtyLabel}>Qty</div>
                <select
                  style={styles.qtySelect}
                  defaultValue={quantity}
                  onChange={(e) => {
                    onQtyChange(e.target.value, id);
                    setSelectedQuantity(e.target.value);
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {showAddToCart && (
              <Button
                style={{
                  justifyContent: "center",
                }}
                label="Add To Cart"
                onClick={handleAddToCart}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function renderIngredients() {
    if (!expanded) return;
    return (
      <div style={{ marginBottom: 18 }}>
        <div style={{ fontWeight: 500, marginBottom: 6 }}>Key Ingredients:</div>
        <div>
          {data.ingredients.map((ing, i) => (
            <div key={i} style={styles.ingredient}>
              {ing.title}
              <div className="tooltip">
                <FaInfoCircle style={styles.infoIcon} />
                <span className="tooltip_msg">
                  <div style={{ fontSize: 18, marginBottom: 4 }}>
                    {ing.title}:
                  </div>
                  <div style={{ fontWeight: 400 }}>{ing.desc}</div>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  productCard: {
    display: "flex",
    width: "100%",
    marginBottom: 20,
    padding: 30,
    fontSize: 17,
    fontWeight: 400,
    borderRadius: 20,
    background: "#f8f8f8",
  },
  image: {
    width: 160,
    height: 160,
    objectFit: "cover",
    borderRadius: 10,
    marginRight: 20,
  },
  title: {
    fontSize: 20,
    marginBottom: 4,
    fontWeight: 500,
  },
  learnMore: {
    fontWeight: 500,
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    lineHeight: 1,
  },
  angleRight: {
    position: "relative",
    top: 1,
  },
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 24,
  },
  price: {
    fontSize: 22,
    fontWeight: 500,
  },
  qtySelectWrapper: {
    display: "flex",
    alignItems: "center",
  },
  qtyLabel: {
    fontWeight: 500,
    marginRight: 6,
  },
  qtySelect: {
    height: 32,
    background: "none",
    padding: "0px 2px",
    borderRadius: 4,
  },
  ingredient: {
    border: "1px solid #4b4b4b",
    borderRadius: 3,
    padding: "3px 6px",
    marginRight: 6,
    marginBottom: 6,
    display: "inline-flex",
    fontWeight: 500,
    fontSize: 17,
    alignItems: "center",
  },
  infoIcon: {
    fontSize: 14,
    marginLeft: 4,
    position: "relative",
    top: 1,
    cursor: "pointer",
  },
};
