import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Content from '../../components/Content';
import { BackButton } from '../../components/formInputs/Button';
import ProductCard from '../../components/ProductCard';
import {
	addCartByUserAction,
	getCartByUserAction,
	getCartCountByUserAction,
	getCollectionByProductAction
} from '../../redux/actions/productActions';
import { ADD_USER_BY_CART_RESET } from '../../redux/constants/productConstants';
import './SupplementCollectionsScreen.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Spinner from '../../components/Spinner/Spinner';
import { encryptData } from '../../utils/for';
import { SUPPLEMENTS_COLLECTION_BY_ID_PROD } from '../../redux/actions/ip';
import {getAllSupplements} from "../../utils/api";


const SupplementCollectionsScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate(); 
	const getCollectionById = useSelector((state) => state.getCollectionById);
	const [productList, setProductList] = React.useState([]);
	const getCartByUser = useSelector((state) => state.getCartByUser);
	const addCartByUser = useSelector((state) => state.addCartByUser);
	const getUserInfo = useSelector((state) => state.getUserInfo);
 

	React.useEffect(
		() => {
			if (getUserInfo && getUserInfo.userInfo && getUserInfo.userInfo.data && getUserInfo.userInfo.data.isRecommendationProduct) {
				dispatch(getCartByUserAction());
				dispatch(getCollectionByProductAction(SUPPLEMENTS_COLLECTION_BY_ID_PROD));
			} else if (getUserInfo && getUserInfo.userInfo && getUserInfo.userInfo.data && !getUserInfo.userInfo.data.isRecommendationProduct) {
				history('/');
			}  
		},
		[getUserInfo, dispatch]
	);


	React.useEffect(
		() => {
			if (getCollectionById && getCollectionById.data && getCollectionById.data.data && getCollectionById.data.data.length > 0) {
				getCollectionById.data.data.forEach((el) => {
					el.quantity = 1;
					el.frequency = 'once';
					el.ingredients = JSON.parse(el.metaField.find(o => o.key === 'ingredients').value);
				});

				setProductList(getCollectionById.data.data)
			}
		},
		[getCollectionById]
	);

	async function getSupplementsData() {
		const supplementRecData = await getAllSupplements();
		setProductList(supplementRecData.data);
	  }

	useEffect(() => {
		getSupplementsData();
	}, [])


	React.useEffect(
		() => {
			if (addCartByUser && addCartByUser.data && addCartByUser.data.message) {
				toast.success(addCartByUser.data.message, {
					icon: "✔"
				});
				dispatch(getCartByUserAction());
				dispatch(getCartCountByUserAction());
				dispatch({ type: ADD_USER_BY_CART_RESET });
			} else if (addCartByUser && addCartByUser.data && !addCartByUser.data.data) {
				dispatch({ type: ADD_USER_BY_CART_RESET });
				toast.error(addCartByUser.data.message, {
					icon: "✕"
				});
			}
		},
		[addCartByUser, dispatch]
	);

	const onQtyChange = (qty, id) => {
    	console.log('qty', qty, 'id', id);
		setProductList(x => {
			const itemIndex = x.findIndex((i) => i.id === id);
			x[itemIndex].quantity = qty;
			return x;
		  });
	}


	const onFrequncyChange = (event, item) => {
		let newFormValues = [...productList];
		item.frequency = event.target.value;
		setProductList([...newFormValues]);
	}



	const handleSubmit = (e, data) => {
		let products = [{
			variant_id: data.variants[0].id,
			quantity: data.quantity,
			product_id: data.id,
			frequency: data.frequency,
			collectionId: SUPPLEMENTS_COLLECTION_BY_ID_PROD,
			"product_type": data.product_type
		}];
		if (getCartByUser &&
			getCartByUser.data &&
			getCartByUser.data.data &&
			getCartByUser.data.data.product &&
			getCartByUser.data.data.product.length > 0) {
			getCartByUser.data.data.product.forEach((item) => {
				if (item.product_id === data.id) {
					products.push({
						variant_id: item.variant_id,
						quantity: data.quantity,
						product_id: data.id,
						frequency: data.frequency,
						collectionId: SUPPLEMENTS_COLLECTION_BY_ID_PROD,
						product_type: item.product_type
					});
				} else {
					products.push({
						variant_id: item.variant_id,
						quantity: item.quantity,
						product_id: item.product_id,
						frequency: item.frequency,
						collectionId: SUPPLEMENTS_COLLECTION_BY_ID_PROD,
						product_type: item.product_type
					});
				}
			})
		}

		const arrayUniqueByKey = [...new Map(products.map(item => [item["product_id"], item])).values()];

		dispatch(addCartByUserAction({
			products: encryptData(arrayUniqueByKey)
		}));
	}
	console.log('rerender');
	return (
		<Content
			currentMenu='SupplementCollections'
			headerTitle='Supplements Collections'
			navigationRoute={[
				{
					path: "/",
					label: "Dashboard",
					routes: [
						{
							path: "/recommendations-supplements",
							label: "Recommendations + Supplements",
							routes: [
								{
									path: "/supplement-collections",
									label: "Supplements Collections",
								},
							]
						},
					]
				}
			]}
			addBtn={false}
			addBtnText='Add New User'
			addBtnIcon='plus'
			addBtnClick={() => { }}
			search={true}
			searchPlaceholder='Search Users...'
			searchIcon='search'
			searchvalue={'search'}
			searchOnChange={''}>
			<div className='supplements_collect-container'>
				{(getCollectionById.loading || addCartByUser.loading) && <Spinner />}
				<h6 className='supplements_collect-heading'>
					<h1 className='supplements_collect-heading'>Stresskase Supplement Collection</h1>
					<p>
						Our supplements utilize superior raw materials and evidence-based formulas to address specific cortisol irregularities brought on by stress.
						At stresskase, we are committed to quality and efficacy and believe that better ingredients means better outcomes.
						{/* <b> * All supplements are derived from raw ingredients, natural flavors and colors, dairy free, soy free and gluten free.</b> */}
					</p>
				</h6>
				<div className='supplements_product-content' >
					{
						productList &&
						productList.length > 0 &&
						productList.map((item, i) =>{
							return (<div className='supplements_product-card' key={i} >
							<ProductCard
								index={i}
								data={item}
								handleSubmit={handleSubmit}
								showAddToCart={true}
								onQtyChange={onQtyChange}
								onFrequncyChange={onFrequncyChange}
								type="collection"
							/>
						</div>)
						}
							
						)
					}
				</div>

				<div className='supplements_collect-section-previous-btn'>
					<BackButton
						style={{
							backgroundColor: '#fffafa00',
							border: 'none',
							color: '#4B4B4B',
							fontWeight: '500',
							fontSize: '18px',
							lineHeight: '23px',
							justifyContent: 'center',
							padding: '0.5rem'
						}}
						label="< Back to My Recommended Supplements"
						onClick={() => history('/recommendations-supplements')}
					/>
				</div>
			</div>
		</Content>
	);
};

export default SupplementCollectionsScreen;
