import axios from "axios";
import { BASE_URL } from "./ip";
import {
  GET_QUESTIONAIRE_SECTION_REQUEST,
  GET_QUESTIONAIRE_SECTION_SUCCESS,
  GET_QUESTIONAIRE_SECTION_FAIL,
  GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST,
  GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
  GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
  ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
  ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
  ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST,
  GET_OPTION_LIST_SUCCESS,
  GET_OPTION_LIST_REQUEST,
  GET_OPTION_LIST_FAIL,
  GET_FILTER_MONTH_FAIL,
  GET_FILTER_MONTH_REQUEST,
  GET_FILTER_MONTH_SUCCESS,
  FINAL_QUESTIONAIRE_RESULT_REQUEST,
  FINAL_QUESTIONAIRE_RESULT_SUCCESS,
  FINAL_QUESTIONAIRE_RESULT_FAIL,
  GET_KIT_DESCRIPTIONS_REQUEST,
  GET_KIT_DESCRIPTIONS_SUCCESS,
  GET_KIT_DESCRIPTIONS_FAIL,
} from "../constants/questionnaireConstants";
import { logout } from "./userActions";

export const getQuestionnaireAction =
  (id, month, year) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_QUESTIONAIRE_SECTION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/questionnaire/${id}/section?year=${year}&month=${month}`,
        config
      );

      dispatch({
        type: GET_QUESTIONAIRE_SECTION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: GET_QUESTIONAIRE_SECTION_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

// export const getQuestionnaireAction = (id, kitId, month, year) => async (dispatch, getState) => {
// 	try {
// 		dispatch({ type: GET_QUESTIONAIRE_SECTION_REQUEST });

// 		const { userLogin: { userInfo } } = getState();

// 		const config = {
// 			headers: {
// 				Authorization: `Bearer ${userInfo.data}`,
// 				'Content-Type': 'application/json'
// 			}
// 		};

// 		const { data } = await axios.get(`${BASE_URL}/questionnaire/${id}/${kitId}?year=${year}&month=${month}`, config);

// 		dispatch({
// 			type: GET_QUESTIONAIRE_SECTION_SUCCESS,
// 			payload: data
// 		});
// 	} catch (err) {
// 		if (err.response?.status === 401) {
// 			dispatch(logout());
// 		}
// 		dispatch({
// 			type: GET_QUESTIONAIRE_SECTION_FAIL,
// 			payload: err.response && err.response.data ? err.response.data : err.message
// 		});
// 	}
// };

export const getQuestionnaireResultBySectionAction =
  (id, kitId, month, year) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/questionnaire/result/${id}/${kitId}?year=${year}&month=${month}`,
        config
      );

      dispatch({
        type: GET_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: GET_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const addQuestionnaireResultBySectionAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_QUESTIONAIRE_RESULT_BY_SECTION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/questionnaire/result?timezone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
        formData,
        config
      );

      dispatch({
        type: ADD_QUESTIONAIRE_RESULT_BY_SECTION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: ADD_QUESTIONAIRE_RESULT_BY_SECTION_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const finalQuestionnaireResultAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: FINAL_QUESTIONAIRE_RESULT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data}`,
          "Content-Type": "application/json",
        },
      };

      const { data, status } = await axios.post(
        `${BASE_URL}/questionnaire/result/final?timezone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
        formData,
        config
      );

      if (status === 203) {
        dispatch({
          type: FINAL_QUESTIONAIRE_RESULT_FAIL,
          payload: data,
        });
      } else {
        dispatch({
          type: FINAL_QUESTIONAIRE_RESULT_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: FINAL_QUESTIONAIRE_RESULT_FAIL,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const getOptionListAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OPTION_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/questionnaire/options/option-list`,
      config
    );

    dispatch({
      type: GET_OPTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_OPTION_LIST_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getMonthListAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FILTER_MONTH_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/questionnaire/question-month`,
      config
    );

    dispatch({
      type: GET_FILTER_MONTH_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_FILTER_MONTH_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const getKitDecriptionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_KIT_DESCRIPTIONS_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}/questionnaire/result/kit-description?kitID=${id}`,
      config
    );

    dispatch({
      type: GET_KIT_DESCRIPTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_KIT_DESCRIPTIONS_FAIL,
      payload:
        err.response && err.response.data ? err.response.data : err.message,
    });
  }
};
