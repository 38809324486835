import { GET_ORDER_HISTORY_FAIL, 
    GET_ORDER_HISTORY_RESET,
    GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_HISTORY_REQUEST, 
	GET_ORDER_CONFIRM_DETAILS_REQUEST,
	GET_ORDER_CONFIRM_DETAILS_SUCCESS,
	GET_ORDER_CONFIRM_DETAILS_FAIL,
	GET_ORDER_CONFIRM_DETAILS_RESET} from "../constants/orderConstants";


export const orderHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ORDER_HISTORY_REQUEST:
			return { loading: true };

		case GET_ORDER_HISTORY_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_ORDER_HISTORY_FAIL:
			return { loading: false, error: action.payload };

		case GET_ORDER_HISTORY_RESET:
			return {};

		default:
			return state;
	}
};


export const orderConfirmReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ORDER_CONFIRM_DETAILS_REQUEST:
			return { loading: true };

		case GET_ORDER_CONFIRM_DETAILS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_ORDER_CONFIRM_DETAILS_FAIL:
			return { loading: false, error: action.payload };

		case GET_ORDER_CONFIRM_DETAILS_RESET:
			return {};

		default:
			return state;
	}
};
