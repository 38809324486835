import React, { Fragment } from "react";
import ModalForm from "../../components/Modal/ModalForm";
import InputBox from "./InputBox";
import Button from "../../components/formInputs/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  addCartSyncDataAction,
  deleteAllItemCartAction,
  getCartCountByUserAction,
  getCartSyncDataAction,
} from "../../redux/actions/productActions";
import {
  GET_USER_BY_CART_COUNT_RESET,
  GET_USER_BY_CART_RESET,
} from "../../redux/constants/productConstants";
import { encryptData } from "../../utils/for";
import FormValidation from "../../utils/formValidation";
import { useDispatch, useSelector } from "react-redux";
import { REGISTER_KIT_RESET } from "../../redux/constants/userConstants";
import {
  getUserByIdAction,
  login,
  registerAction,
  subcribeNewsLetterAction,
} from "../../redux/actions/userActions";
import jwt_decode from "jwt-decode";
import { stateValidatorSchema } from "../../utils/validators";
import InputsSections from "../../components/Modal/InputSections";
import "./SignInRegisterWeb.css";
import CheckBox from "./CheckBox";
import ForgotPasswordModel from "./ForgotPasswordModel";

const { REACT_APP_BASE_URL } = process.env;

const SignInRegisterWebMain = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const registerKit = useSelector((state) => state.registerKit);
  const cartProducts = useSelector((state) => state.cartProducts.cart);
  const [isSubscribe, setIsSubscribe] = React.useState(false);
  const [showFogotPwdModal, setShowFogotPwdModal] = React.useState(false);
  // const [isRemember, setIsRemember] = React.useState(false)

  // Register form
  React.useEffect(() => {
    if (registerKit && registerKit.userInfo && registerKit.userInfo.data) {
      clearData();
      toast.success(registerKit.userInfo.message, {
        icon: "✔",
      });
      dispatch({ type: REGISTER_KIT_RESET });
    } else if (
      registerKit &&
      registerKit.userInfo &&
      !registerKit.userInfo.data
    ) {
      toast.error(registerKit.userInfo.message, {
        icon: "✕",
      });
      dispatch({ type: REGISTER_KIT_RESET });
    }
  }, [registerKit, dispatch]);

  const userData = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
  };

  const registerHandler = (state) => {
    if (state.password === "" && state.password.trim() === "") {
      setErrors({ ...errors, confirmPassword: "Please enter your password" });
      setDisable(true);
    } else {
      if (isSubscribe) {
        dispatch(subcribeNewsLetterAction(state.email));
      }
      dispatch(
        registerAction({
          firstname: state.firstName,
          lastname: state.lastName,
          email: state.email,
          password: state.password,
          role: "customer",
        })
      );
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    disable,
    setDisable,
    setValues,
    setErrors,
    setDirty,
  } = FormValidation(userData, stateValidatorSchema, registerHandler);

  const {
    firstName,
    lastName,
    email,
    password,
    // gender,
    // genderAssigned,
    // confirmPassword
  } = values;

  // Login form
  const userLoginSchema = {
    emailLogin: { value: "", error: "" },
    passwordLogin: { value: "", error: "" },
  };

  const handleSubmit = (state) => {
    dispatch(login(state.emailLogin, state.passwordLogin));
  };

  const {
    values: loginValues,
    errors: loginErrors,
    dirty: loginDirty,
    handleOnChange: loginHandleOnChange,
    handleOnSubmit: loginHandleOnSubmit,
    disable: loginDisable,
  } = FormValidation(userLoginSchema, stateValidatorSchema, handleSubmit);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data) {
      var decoded = jwt_decode(userLogin.userInfo.data);
      dispatch(getUserByIdAction(decoded._id));
      // sync data automatically Start
      if (cartProducts && cartProducts.length > 0) {
        getCart();
        handleAddModalClose();
      } else handleAddModalClose();
    }
  }, [userLogin, cartProducts, dispatch]);

  // sync data automatically Start
  const getCart = React.useCallback(async () => {
    const data = await dispatch(getCartSyncDataAction());
    syncCartHandler(data);
  }, [dispatch]);

  const syncCartHandler = React.useCallback(
    async (getCartByUser) => {
      let products = [];
      if (
        getCartByUser &&
        getCartByUser.data &&
        getCartByUser.data.product &&
        getCartByUser.data.product.length > 0
      ) {
        getCartByUser.data.product.forEach((item) => {
          if (item.product) {
            products.push({
              variant_id: item.variant_id
                ? item.variant_id
                : item.variants[0].id,
              quantity: item.quantity ? item.quantity : item.quantity,
              product_id: item.product_id ? item.product_id : item.id,
              frequency: item.frequency,
              product_type: item.product_type,
            });
          }
        });
      }

      if (cartProducts && cartProducts.length > 0) {
        cartProducts.forEach((item) => {
          products.push({
            variant_id: item.variant_id ? item.variant_id : item.variants[0].id,
            quantity: item.quantity ? item.quantity : item.quantity,
            product_id: item.product_id ? item.product_id : item.id,
            frequency: item.frequency,
            product_type: item.product_type,
          });
        });
      }

      const arrayUniqueByKey = [
        ...new Map(products.map((item) => [item["product_id"], item])).values(),
      ];

      const addCartByUser = await dispatch(
        addCartSyncDataAction({
          products: encryptData(arrayUniqueByKey),
        })
      );

      if (addCartByUser && addCartByUser.data && addCartByUser.data) {
        localStorage.removeItem("cart");
        localStorage.removeItem("products");
        dispatch(deleteAllItemCartAction());
        dispatch(getCartCountByUserAction());
        dispatch({ type: GET_USER_BY_CART_RESET });
        dispatch({ type: GET_USER_BY_CART_COUNT_RESET });
      }
    },
    [dispatch]
  );
  // sync data automatically End

  const clearData = () => {
    setValues({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });

    setErrors({
      firstName: "*This is required field",
      lastName: "*This is required field",
      email: "*This is required field",
      password: "*This is required field",
    });

    setDirty({
      firstName: false,
      lastName: false,
      email: false,
      password: false,
    });
    setIsSubscribe(false);
    setDisable(true);
  };

  const handleCancelModalClose = () => {
    setShowFogotPwdModal(false);
  };

  const handleAddModalClose = () => {
    // window.open('${REACT_APP_BASE_URL}/', "_self");
  };

  return (
    <div className="SignInRegisterWeb_content">
      <h1 className="signInRegister_web_top_heading">My Account</h1>
      <div className="signInRegister_container_web">
        <ModalForm
          onSubmit={loginHandleOnSubmit}
          style={{
            // height: '100%',
            width: "100%",
            border: "2px solid #f0f0f0e6",
            borderRadius: "0px 3px 3px 0px",
          }}
        >
          <div className="signInRegister_container_form_web">
            {userLogin.loading && (
              <div className="spinner_style">
                <img
                  src={`${REACT_APP_BASE_URL}/wp-content/uploads/2022/09/loaing_circle.gif`}
                  style={{
                    width: "15rem",
                    position: "absolute",
                  }}
                  alt="Loading..."
                />
              </div>
            )}
            <div>
              <h1 className="signInRegister_container_web_heading">
                Returning Customer
              </h1>
              <p className="signInRegister_container_web_description">
                To complete your order, you must first create an account.
              </p>
            </div>
            <Fragment>
              <InputBox
                id="emailLogin"
                type="text"
                name="emailLogin"
                placeholder="Email"
                errorMessage={
                  loginErrors &&
                  loginErrors.emailLogin &&
                  loginDirty.emailLogin &&
                  loginErrors.emailLogin
                }
                value={loginValues && loginValues.emailLogin}
                onChange={loginHandleOnChange}
                label={false}
                icon="email"
              />
              <InputBox
                id="passwordLogin"
                type="password"
                placeholder="Password"
                name="passwordLogin"
                errorMessage={
                  loginErrors &&
                  loginErrors.passwordLogin &&
                  loginDirty.passwordLogin &&
                  loginErrors.passwordLogin
                }
                value={loginValues && loginValues.passwordLogin}
                onChange={loginHandleOnChange}
                label={false}
                icon="key"
              />

              <Button
                disabled={loginDisable}
                style={{
                  justifyContent: "center",
                  width: "100%",
                  padding: "2rem",
                  fontSize: "18px",
                  lineHeight: "23px",
                }}
                onClick={loginHandleOnSubmit}
                label="Complete Sign In & Order"
                icon={false}
              />

              <div className="signInRegister_container">
                <span
                  className="signInRegister_text"
                  onClick={() => {
                    setShowFogotPwdModal(true);
                  }}
                >
                  Forgot Password?
                </span>
              </div>
            </Fragment>
          </div>
        </ModalForm>
        <ModalForm
          onSubmit={handleOnSubmit}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            // height: '100%',
            border: "2px solid #f0f0f0e6",
            borderRadius: "0px 3px 3px 0px",
            backgroundColor: "#f0f0f0e6",
            width: "100%",
          }}
        >
          <div className="signInRegister_container_form_web signInRegister_register_conatianer">
            {registerKit.loading && (
              <div className="spinner_style">
                <img
                  src={`${REACT_APP_BASE_URL}/wp-content/uploads/2022/09/loaing_circle.gif`}
                  style={{
                    width: "15rem",
                    position: "absolute",
                  }}
                  alt="Loading..."
                />
              </div>
            )}
            <div>
              <h1 className="signInRegister_container_web_heading">
                New Customer
              </h1>
              <p className="signInRegister_container_web_description">
                To complete your order, you must first create an account. Your
                profile will be where you activate your test kit, find your
                results and recommendations and order history.
              </p>
            </div>
            <Fragment>
              <InputsSections>
                <InputBox
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  errorMessage={
                    errors &&
                    errors.firstName &&
                    dirty.firstName &&
                    errors.firstName
                  }
                  value={firstName}
                  onChange={handleOnChange}
                  label={false}
                  icon="user"
                />

                <InputBox
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  errorMessage={
                    errors &&
                    errors.lastName &&
                    dirty.lastName &&
                    errors.lastName
                  }
                  value={lastName}
                  onChange={handleOnChange}
                  label={false}
                  icon="user"
                />
              </InputsSections>

              <InputsSections>
                <InputBox
                  id="email"
                  type="text"
                  placeholder="Email"
                  errorMessage={
                    errors && errors.email && dirty.email && errors.email
                  }
                  value={email}
                  onChange={handleOnChange}
                  label={false}
                  icon="email"
                />
              </InputsSections>
              <InputBox
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                errorMessage={
                  errors && errors.password && dirty.password && errors.password
                }
                value={password}
                onChange={handleOnChange}
                label={false}
                icon="key"
              />
              <div>
                <CheckBox
                  name="subscribe"
                  id="subscribe"
                  type="checkbox"
                  checked={isSubscribe}
                  style={{ gap: "2rem" }}
                  onChange={(e) => {
                    setIsSubscribe(!isSubscribe);
                  }}
                  errorMessage={false}
                  label="I want to receive updates, tips, and other emails from stresskase. Don’t worry, you can unsubscribe any time."
                  icon="key"
                />
              </div>

              <Button
                disabled={disable}
                style={{
                  justifyContent: "center",
                  width: "100%",
                  padding: "2rem",
                  fontSize: "18px",
                  lineHeight: "23px",
                }}
                onClick={handleOnSubmit}
                label="Complete Sign Up & Order"
                icon={false}
              />

              <p className="footer_copyright-text">
                By creating an account, I agree to the
                <a
                  href={`${REACT_APP_BASE_URL}/terms-of-service/`}
                  target="_self"
                >
                  Terms of Service{" "}
                </a>
                and
                <a
                  href={`${REACT_APP_BASE_URL}/privacy-policy/`}
                  target="_self"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </Fragment>
          </div>
        </ModalForm>
      </div>
      <ForgotPasswordModel
        showFogotPwdModal={showFogotPwdModal}
        handleCancelModalClose={handleCancelModalClose}
      />
    </div>
  );
};

export default SignInRegisterWebMain;
