import React from 'react';
import styles from './Button.module.css';

const Button = ({ label, onClick, style, disabled }) => {
	return (
		<button className={styles.btn} onClick={onClick} style={style} disabled={disabled}>
			{label}
		</button>
	);
};

export default Button;
